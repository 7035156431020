import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import { Debouncer } from '../utils/Debouncer'

const debouncer = new Debouncer()
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    dialogContent: {
      minHeight: 80,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    paper: {
      minHeight: 0,
    },
  })
)

interface IErrorModalProps {
  error: any
  refetch?: () => Promise<any>
  onClose?: () => void
}
// React.Ref<HTMLElement>
const ApolloErrorModal = (props: IErrorModalProps) => {
  const { error, refetch } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const onClose = () => {
    setOpen(false)
    if (props.onClose) {
      props.onClose()
    }
  }
  const retry = () => {
    setLoading(true)
    if (refetch) {
      refetch().finally(() => {
        debouncer.bounce(() => setLoading(false), 700)
      })
    }
  }

  if (error.networkError) {
    return (
      <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <DialogTitle>Network Problem Detected</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {(loading && <Typography>Retrying...</Typography>) || (
            <Typography>Seems like there is a problem with your internet access.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            {(refetch && (
              <>
                <Button onClick={retry} color="primary" disabled={loading}>
                  Retry
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </>
            )) || (
              <Button onClick={onClose} color="primary" disabled={loading}>
                Close
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    )
  } else {
    return (
      <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <DialogTitle>An error occured</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography>{error.message || 'Unexpected error.'}</Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            {(refetch && (
              <>
                <Button onClick={retry} color="primary" disabled={loading}>
                  Retry
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </>
            )) || (
              <Button onClick={onClose} color="primary" disabled={loading}>
                Close
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ApolloErrorModal
