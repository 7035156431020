import { Divider, List, ListItemIcon, ListItemText } from '@material-ui/core'
import EditVoyageIcon from '@material-ui/icons/EditOutlined'
import EditEventIcon from '@material-ui/icons/EditLocationOutlined'
import CommittedIcon from '@mui/icons-material/EditOffOutlined'
import Stay from '@griegconnect/krakentools-react-icons/Port'
import Transit from '@griegconnect/krakentools-react-icons/Voyage'
import Typography from '@material-ui/core/Typography'
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt'
import ToolTip from '@mui/material/Tooltip'
import { DateTime } from 'luxon'
import { voyageListItemkey } from '../atoms'
import { useRecoilState } from 'recoil'

import { UiListItemLink } from './UiListItemLink'
import { useQuery } from 'react-query'
import LoadingSpinner from './LoadingSpinner'
import ErrorModal from './ApolloErrorModal'
import { useStyles } from './VoyageList.styles'
import { RED_HIGHLIGHT, GREEN_HIGHLIGHT, WHITE_HIGHLIGHT } from '../constants/colors'
import { timeFormat } from '../constants/timeFormat'
import { IconButton } from '@mui/material'
import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'
import { VesselWithEngines } from 'src/lib/apis/types/vessel'
import { useApis } from 'src/lib/ApiProvider'
import {Voyage, VoyageWithOverride} from 'src/lib/apis/types/voyage'

interface IVoyageListProps {
  voyages: Voyage[] | null
  allVoyages: VoyageWithOverride[] | undefined
  offshoreZones?: any
  readOnly?: boolean
  selectedVoyage: number | null
  currentPeriod: NoxPeriod
  vesselId: string
  onVoyageSelected: (voyage: any, vessel: any) => void
  //  onHandleOverride: (voyage: number, vesselId: number, overrideType: string) => void
}

/* YAE 30.11.21 -> these two are no longer in use
type VoyageReportStatus = 'details-missing' | 'submission-ready' | 'submission-done'

// eslint-disable-next-line
const VoyageReportStatus = (props: any) => {
  const classes = useStyles()
  switch (props.voyage.status) {
    case 'details-missing':
      return (
        <Typography {...props} className={classes.textRed}>
          Missing details
        </Typography>
      )
    case 'submission-ready':
      return (
        <Typography {...props} className={classes.textYellow}>
          Ready for submission
        </Typography>
      )
    case 'submission-done':
      return (
        <Typography {...props} className={classes.textGreen}>
          Submitted
        </Typography>
      )
    default:
      props.voyage.status = 'details-missing'
      return <VoyageReportStatus {...props} voyage={props.voyage} />
    // return <Typography {...props}>Unknown status: '{props.voyage.status}'</Typography>;
  }
}
 */

const ListItemContent = (props: any) => {
  const { voyage, readOnly } = props
  const classes = useStyles()
  const events = voyage.events
  const startEvent = events[0]
  const endEvent = events[events.length - 1]
  const startLocation = startEvent.name
  const endLocation = endEvent.name
  //YAE 25.08.21 :: Changed coloring of nox-details based on taxable status
  const emissionStyle = voyage.taxable
    ? { marginLeft: 8, color: GREEN_HIGHLIGHT } // S.H. 20.10.2021 Tiles color to be the same as map tails color: ;
    : voyage.taxable === null
    ? { marginLeft: 8, color: RED_HIGHLIGHT }
    : { marginLeft: 8, color: WHITE_HIGHLIGHT } // S.H. 20.10.2021 Tiles color to be the same as map tails color: ;

  //Has this voyage been edited?
  const voyageOverride = voyage.overrides !== null
  const eventOverride = startEvent.source === 'user' || endEvent.source === 'user'
  const commitOverride = readOnly

  const startTime = DateTime.fromISO(voyage.timeStart).toLocal()
  const endTime = DateTime.fromISO(voyage.timeEnd).toLocal()
  const duration = endTime.diff(startTime).normalize().shiftTo('days', 'hours', 'minutes', 'seconds')
  const durationString =
    duration.days > 0
      ? duration.days + 'd ' + duration.hours + 'h ' + duration.minutes + 'm '
      : duration.hours + 'h ' + duration.minutes + 'm '

  const distance = props.distance ? props.distance + 'nm' : ''
  const noxEmission = voyage.consumption && voyage.consumption.noxCalculated ? voyage.consumption.noxCalculated : 0

  return (
    <div className={classes.outerGrid}>
      <div className={classes.grid}>
        <div className={classes.firstLine}>
          <Typography>
            {startTime.toFormat(timeFormat)}&nbsp;&nbsp;&#8212;&nbsp;&nbsp;{endTime.toFormat(timeFormat)}
          </Typography>
          <Typography>
            {!commitOverride && voyageOverride && (
              <ToolTip title={'Voyage details have been modified by user'} placement={'top'}>
                <IconButton>
                  <EditVoyageIcon style={{ color: '#64C8FF', marginTop: -16 }} fontSize="default" />
                </IconButton>
              </ToolTip>
            )}
            {!commitOverride && eventOverride && (
              <ToolTip title={'Additional location info has been added by user'} placement={'top'}>
                <IconButton>
                  <EditEventIcon style={{ color: '#64C8FF', marginTop: -16 }} fontSize="default" />
                </IconButton>
              </ToolTip>
            )}
            {commitOverride && (
              <ToolTip
                title={'Voyages for this period have been archived after reporting. No changes are allowed.'}
                placement={'top'}
              >
                <IconButton>
                  <CommittedIcon style={{ color: '#FFFFFC', marginTop: -16 }} fontSize="medium" />
                </IconButton>
              </ToolTip>
            )}
          </Typography>
        </div>
        <div className={classes.secondLine + ' ' + classes.mediumEmphasis}>
          <Typography>{startLocation}</Typography>
          {(voyage.classification !== 'stay' ||
            startEvent.name.substr(0, 12).toLowerCase() === 'period start' ||
            endEvent.name.substr(0, 10).toLowerCase() === 'period end') && (
            <>
              <ArrowRightIcon style={{ justifySelf: 'center' }} /> <Typography>{endLocation}</Typography>
            </>
          )}
        </div>

        <div className={classes.thirdLine + ' ' + classes.mediumEmphasis}>
          <span>{durationString}</span>
          <span style={{ marginLeft: 8 }}>{distance}</span>
          <span style={emissionStyle}>
            {noxEmission > 0 && Number(noxEmission.toFixed(0)).toLocaleString() + ' kg NOx'}
          </span>
        </div>
      </div>
      {/*      <div style={{ alignSelf: 'center' }}>
        <EditVoyageIcon style={{ color: '#64C8FF' }} fontSize="large" />
      </div>*/}
    </div>
  )
}

const VoyageList = (props: IVoyageListProps) => {
  const { voyages, allVoyages, vesselId, currentPeriod, offshoreZones, readOnly } = props
  const classes = useStyles()
  const { apis } = useApis()
  const [voyageListItemId, setVoyageListItemId] = useRecoilState<{
    vesselIdentifier: null | string
    listItemId: null | number
  }>(voyageListItemkey)
  const {
    isLoading,
    error,
    isError,
    data: vessel,
    refetch,
  } = useQuery<VesselWithEngines, Error>(['vessel', vesselId], async () => apis.vesselsApi.getVessel(vesselId), {
    enabled: !!vesselId,
  })

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return <ErrorModal error={error} refetch={refetch} />
  }

  const listItems = voyages?.map((voyage, key) => {
    const emissionGroupStyles = voyage.taxable
      ? { marginTop: 10, paddingLeft: 15, borderLeft: `3px solid ${GREEN_HIGHLIGHT}` }
      : voyage.taxable === null
      ? { marginTop: 10, paddingLeft: 15, borderLeft: `3px solid ${RED_HIGHLIGHT}` }
      : { marginTop: 10, paddingLeft: 15, borderLeft: `3px solid ${WHITE_HIGHLIGHT}` }

    if (
      ((voyageListItemId.vesselIdentifier === vesselId &&
        voyageListItemId.listItemId === voyage.id &&
        props.selectedVoyage === null) ||
        voyage.id === props.selectedVoyage) &&
      !isLoading
    ) {
      props.onVoyageSelected(voyage.id, vesselId)
      setTimeout(() => {
        const anchor = document?.getElementById(`voyage-list-${voyage.id}-${vesselId}`)
        anchor?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 100)
    }

    return (
      <div
        onClick={(event) => {
          setVoyageListItemId({ vesselIdentifier: vesselId, listItemId: voyage.id })
        }}
        key={voyage.id}
        id={`voyage-list-${voyage.id}-${vesselId}`}
        className={
          (voyageListItemId.vesselIdentifier === vesselId &&
            voyageListItemId.listItemId === voyage.id &&
            props.selectedVoyage === null) ||
          voyage.id === props.selectedVoyage
            ? //              || ( voyage.id === props.selectedVoyage && !(voyageListItemId.vesselIdentifier === vesselId && voyageListItemId.listItemId === voyage.id) )
              classes.active
            : ''
        }
      >
        <UiListItemLink
          to={{
            pathname: '/vessel/' + props.vesselId + '/voyage/' + voyage.id,
            state: {
              voyage: voyage,
              readOnly: readOnly,
              voyages: voyages,
              allVoyages: allVoyages,
              vessel: vessel,
              currentPeriod: currentPeriod,
              offshoreZones: offshoreZones,
            },
          }}
        >
          <ListItemIcon>
            {voyage.classification === 'stay' ? (
              <Stay fontSize="large" style={{ marginRight: 5 }} />
            ) : (
              <Transit fontSize="large" style={{ marginRight: 5 }} />
            )}
          </ListItemIcon>
          <ListItemText inset={false} style={emissionGroupStyles} primary={<ListItemContent voyage={voyage} readOnly={readOnly}/>} />
        </UiListItemLink>
        <Divider variant="inset" component="li" />
      </div>
    )
  })
  return <List>{listItems}</List>
}
export default VoyageList
