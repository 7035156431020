import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: 'grid',
    },
    outerGrid: {
      display: 'grid',
      gridTemplateColumns: 'auto 50px',
    },
    firstLine: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
    },
    secondLine: {
      display: 'grid',
      gridTemplateColumns: 'max-content 30px max-content',
    },
    thirdLine: {},
    active: {
      backgroundColor: '#333',
    },
    textRed: {
      color: theme.palette.error.main,
    },
    textGreen: {
      color: theme.palette.success.main,
    },
    textYellow: {
      color: theme.palette.error.main,
    },
    mediumEmphasis: {
      color: theme.palette.text.secondary,
    },
  })
)
