import {Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Grid} from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import ErrorModal from '../../../components/ApolloErrorModal'

import { useMutation } from 'react-query'

import ExpandingInfoBox from '../../vessels/add/components/ExpandingInfoBox'
import EngineConsumption from './EngineConsumption'
import NoxCalculator from "../../../utils/NoxCalculator"
import VoyageFactHandler from "./VoyageFactHandler"
import useMediaQuery from "@mui/material/useMediaQuery"
import { VesselId, VesselWithEngines } from 'src/lib/apis/types/vessel'
import { VoyageOverride, VoyageWithOverride } from 'src/lib/apis/types/voyage'
import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'
import { useApis } from 'src/lib/ApiProvider'
import { Consumption } from 'src/lib/apis/types/consumption'

interface IVoyageCalculationProps {
  voyage: VoyageWithOverride;
  allVoyages: VoyageWithOverride[];
  vessel: VesselWithEngines;
  currentPeriod: NoxPeriod;
  readOnly: boolean;
  manualOverride: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    totalGrid: {
      display: 'grid',
      gridGap: 8,
      gridTemplateColumns: 'auto min-content min-content',
    },
    totalValue: {
      justifySelf: 'end',
      whiteSpace: 'nowrap',
    },
    totalUnit: {
      justifySelf: 'start',
      paddingLeft: 5,
      whiteSpace: 'nowrap',
    },
    textRed: {
      color: theme.palette.error.main,
    },
    textGreen: {
      color: theme.palette.success.main,
    },
    textYellow: {
      color: theme.palette.error.main,
    },
    mediumEmphasis: {
      color: theme.palette.text.secondary,
    },
    normalEmphasis: {
      color: theme.palette.text.primary,
    },
  })
)

const VoyageNoxCalculation = (props: IVoyageCalculationProps) => {
  const { vessel, voyage, allVoyages, currentPeriod, readOnly, manualOverride } = props
  const largerWidth = useMediaQuery('(min-width: 884px)')
  //YAE 07.09.21 -> Added inclusion of state here, so EditEngine can use, update and return it
//  const { state } = useLocation<{voyage: VoyageOverview_voyages, vessel: VesselLookup_vessels}>()
  const classes = useStyles()
  const [engineEmissions, setEngineEmissions] = useState<Array<number>>([])
  const [taxability, setTaxability] = useState<boolean | null>(typeof voyage.taxable !== 'undefined' ? voyage.taxable : null)
  const orig_taxable = typeof voyage.taxable !== 'undefined' ? voyage.taxable : null
  //YAE 11.11.21 -> use temp_consumptions for all user-input. Close=do nothing, changes will be gone. Save=copy temp_consumptions to voyage.consumptions
  const temp_consumptions = JSON.parse(JSON.stringify(voyage.consumptions))

    const [eventOverrideMode, setEventOverrideMode] = useState<boolean>(false)
    const { apis } = useApis()

    const activateEventOverrideMode = () => {
      setEventOverrideMode(true)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const back = () => {
        //YAE 10.11.21 -> Reset all use-State elements to initial/default content so edited values don't appear on next visit...
        setNoxEmission('0')
        setTaxability(orig_taxable)
        setEngineEmissions([])
        history.push('/vessel/' + vessel.id + '/voyages')
  }

  const handleUpdateCompleted = () => {
//      history.push('/vessel/' + vessel.id + '/voyages')
  }


  const { mutate: updateVoyageConsumption, error: mutationError } = useMutation((args: {id: VesselId, form: VoyageOverride}) => { 
    return apis.voyagesApi.createOverride(args.id, args.form)
  }, {
    onSuccess: handleUpdateCompleted,
  })

  const [noxEmission, setNoxEmission] = useState<string>('0')
//  const [consumptions, setConsumptions] = use-State<VoyageOverview_voyageConsumptions[] | null>(voyage.consumptions);
//    const noxrate = taxability ? (vessel.noxRate === 'RateLow' ? currentPeriod.rateLow : vessel.noxRate === 'RateHigh' ? currentPeriod.rateHigh : vessel.noxRate === 'RateFull' ? currentPeriod.rateFull : 0.0) : 0.0;
const noxrate: number =
taxability
? (
    vessel.noxRate === 'rate-low'
        ? currentPeriod.rateLow ?? 0.0
        : vessel.noxRate === 'rate-high'
              ? currentPeriod.rateHigh ?? 0.0
              : vessel.noxRate === 'rate-full'
                      ? currentPeriod.rateFull ?? 0.0
                      : 0.0
  )
: 0.0

/*  const handleUpdateNoxEmission = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoxEmission(String(Number.parseFloat(event.target.value)))
  }
*/
  const history = useHistory()

  const engines = vessel.engines
    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((engine) => {
      const consumption = temp_consumptions?.filter((c) => c.engineId === engine.id)

      const updateEngine = (emission, consumption?) => {
        updateEmission(engine, emission)
        updateConsumptions(engine, emission, consumption)
      }

      return (
        <EngineConsumption
          readOnly={readOnly}
          key={engine.id}
          engine={engine}
          consumption={consumption && consumption[0]}
          onUpdate={updateEngine}
// YEA 25.10.21: Removed edit engine option          onHandleEditEngine={handleEditEngine}
        />
      )
    })

  const updateEmission = (engine, emission) => {
    engineEmissions[engine.id] = emission
    setEngineEmissions(engineEmissions)
    setNoxEmission(engineEmissions.reduce((acc, emission) => emission + acc, 0).toFixed(2))
    }

  const updateConsumptions = (engine, emission, consumption) => {
      //consumption must exist and it must either have zero fuel-entries * nox-factors or it must already exist
      //otherwise there is no point in creating an override for it.
      //Using temp_consumption to avoid 'persistent' values in display after Back. (See also save())
      if (consumption &&
          (
              (
                  (isNaN(Number(consumption.fuelConsumption) * Number(engine.noxFactor)) ? 0 : Number(consumption.fuelConsumption) * Number(engine.noxFactor))
                    + (isNaN(Number(consumption.lngConsumption) * Number(engine.noxFactorLng)) ? 0 : Number(consumption.lngConsumption) * Number(engine.noxFactorLng))
                    + (isNaN(Number(consumption.fuelConsumptionScr) * Number(engine.noxFactorScr)) ? 0 : Number(consumption.fuelConsumptionScr) * Number(engine.noxFactorScr))
                    + (isNaN(Number(consumption.ureaConsumption)) ? 0 : Number(consumption.ureaConsumption))
                    > 0
              )
              ||
              (
                  (temp_consumptions && temp_consumptions?.findIndex(c => c.engineId === consumption.engineId) >= 0)
              )
          )
         ) {
                if(temp_consumptions) {
                    const calculated = NoxCalculator.calculateEngineEmission(
                        engine.noxFactorLng,
                        engine.noxFactor!,
                        engine.scrFactor,
                        Number(consumption.lngConsumption),
                        Number(consumption.fuelConsumption),
                        Number(consumption.fuelConsumptionScr),
                        Number(consumption.ureaConsumption)
                    )
                    const estimatedNoxTax = (calculated.nox + calculated.scrNox - calculated.scrNoxReduction) * noxrate

                    const index = temp_consumptions?.findIndex(c => c.engineId === consumption.engineId)
                    if(index >= 0) {
                      temp_consumptions[index].lngConsumption = isNaN(Number(consumption.lngConsumption)) ? 0 : Number(consumption.lngConsumption)
                      temp_consumptions[index].fuelConsumption = isNaN(Number(consumption.fuelConsumption)) ? 0 : Number(consumption.fuelConsumption)
                      temp_consumptions[index].fuelConsumptionScr = isNaN(Number(consumption.fuelConsumptionScr)) ? 0 : Number(consumption.fuelConsumptionScr)
                      temp_consumptions[index].ureaConsumption = isNaN(Number(consumption.ureaConsumption)) ? 0 : Number(consumption.ureaConsumption)
                      temp_consumptions[index].noxCalculated = emission
                      temp_consumptions[index].estimatedNoxTax = estimatedNoxTax
                    } else {
                        const newConsumption: Consumption = {
                            lngConsumption:  isNaN(Number(consumption.lngConsumption)) ? 0 : Number(consumption.lngConsumption),
                            fuelConsumption: isNaN(Number(consumption.fuelConsumption)) ? 0 : Number(consumption.fuelConsumption),
                            ureaConsumption: isNaN(Number(consumption.ureaConsumption)) ? 0 : Number(consumption.ureaConsumption),
                            fuelConsumptionScr: isNaN(Number(consumption.fuelConsumptionScr)) ? 0 : Number(consumption.fuelConsumptionScr),
                            noxCalculated: emission,
                            co2Emission: 0,
                            estimatedNoxTax: estimatedNoxTax,
                            engineId: engine.id
                        }
                        temp_consumptions.push(newConsumption)
                    }
                }
          }
  }


  const handleTaxabilityRadioChange = (event) => {
    setTaxability(event.target.value === "taxable")
  }

  const save = () => {
      //User modifications are all located in temp_consumptions
      //Need the temporary variables to address non-numeric conversion from display-fields
      let voyageFuelConsumption = 0
      let voyageLngConsumption = 0
      let voyageUreaConsumption = 0
      let voyagefuelConsumptionScr = 0
      let voyageNoxCalculated = 0
      let voyageCo2Emission = 0
      temp_consumptions?.forEach((cons) => {
          voyageFuelConsumption += isNaN(Number(cons.fuelConsumption)) ? 0 : Number(cons.fuelConsumption)
          voyageLngConsumption += isNaN(Number(cons.lngConsumption)) ? 0 : Number(cons.lngConsumption)
          voyageUreaConsumption += isNaN(Number(cons.ureaConsumption)) ? 0 : Number(cons.ureaConsumption)
          voyagefuelConsumptionScr += isNaN(Number(cons.fuelConsumptionScr)) ? 0 : Number(cons.fuelConsumptionScr)
          voyageNoxCalculated += isNaN(Number(cons.noxCalculated)) ? 0 : Number(cons.noxCalculated)
          voyageCo2Emission += isNaN(Number(cons.co2Emissions)) ? 0 : Number(cons.co2Emissions)
      })
      voyage.consumption.co2Emission = voyageCo2Emission
      voyage.consumption.fuelConsumption = voyageFuelConsumption
      voyage.consumption.lngConsumption = voyageLngConsumption
      voyage.consumption.fuelConsumptionScr = voyagefuelConsumptionScr
      voyage.consumption.noxCalculated = voyageNoxCalculated
      voyage.consumption.ureaConsumption = voyageUreaConsumption
      voyage.taxable = taxability ?? undefined
      voyage.consumptions = temp_consumptions
      updateVoyageConsumption({
        id: vessel.id,
          form: {
              timeStart: voyage.timeStart,
              timeEnd: voyage.timeEnd,
              vesselId: vessel.id,
              taxable: taxability ?? undefined,
              consumption: voyage.consumption,
              consumptions: voyage.consumptions,
              //    Number(engineEmissions[consumption.engineId]) * ((vessel.noxRate === 'RateHigh' && 16.5) || 10.5),
          },
        })
      if (mutationError) {
          return <ErrorModal error={mutationError} />
      }
  }

  //By users command, switch to eventOverride and return voyageFactHandler
  if(eventOverrideMode) {
      return <VoyageFactHandler voyage={voyage} allVoyages={allVoyages} vessel={vessel} currentPeriod={currentPeriod} manualOverride={true} />
  }  else
  //Normal return - edit taxability and consumption option
  return (
    <div>
        <div style={{ textAlign: 'center', marginTop: 2, marginBottom: 2, paddingBottom: 2 }}>
            <Button style={{ marginRight: 32, padding: "7px 32px" }}
                    variant="outlined" color="secondary" onClick={back}>
                BACK
            </Button>
            <Button  style={{ padding: "7px 32px" }}
                     disabled={readOnly}
                     variant="contained" color="primary" onClick={save}>
                SAVE
            </Button>
        </div>
      <ExpandingInfoBox
        title="Calculation of NOx-Emission"
        text="NOx-emissions are calculated for all periods when a vessel is NOx-taxable according to “Avgift på utslipp av NOx 2020 – Skattedirektoratet”. Based on the input provided, NOxDigital uses the specific NOx-factors for each engine, with any fuel, lng and urea consumed, to calculate emissions in accordance with guidelines from the Norwegian NOx-Fund, “Veiledning til rapportering av NOx-utslipp”."
      />
      <Divider/>
      <FormControl component="fieldset" style={{ marginTop: 16, marginBottom: 16, marginLeft: 16 }} >
          <FormLabel component="legend">Voyage taxable status</FormLabel>
          <Grid
              container
              style={
                  largerWidth
                      ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }
                      : { display: 'flex', flexDirection: 'column', gap: 10 }
              }
          >
              <RadioGroup onChange={handleTaxabilityRadioChange}
                          value={(taxability === null) ? "undefined" : taxability ? "taxable" : "non-taxable" }
                          aria-label="taxabilitystatus"
                          name="taxability-radios"
                          row
              >
                  <FormControlLabel
                      value="undefined"
                      disabled
                      control={<Radio />}
                      label="Undefined"
                  />
                  <FormControlLabel value="taxable" control={<Radio />} label="Taxable" disabled={readOnly} />
                  <FormControlLabel value="non-taxable" control={<Radio />} label="Non-taxable" disabled={readOnly} />
              </RadioGroup>
              <Button   disabled={
                            (
                                (voyage.events[0].direction === 'arrival' || voyage.classification === 'stay')
                                &&
                                (
                                    voyage.events[0].name.toLowerCase().substr(0,6) !== 'period'
                                    &&
                                    (
                                        voyage.events[1].name.toLowerCase().substr(0,6) !== 'period'
                                        ||
                                        new Date(voyage.events[1].timestamp) < new Date(currentPeriod.timeEnd)
                                    )
                                )
                            )
                            || manualOverride
                            || readOnly
                        }
                        style={{ padding: "7px 32px" }}
                        variant="outlined"
                        color="primary"
                        onClick={activateEventOverrideMode}>
                  ADD A MISSING LOCATION
              </Button>
          </Grid>
      </FormControl>
      <Divider style={{ marginBottom: 16 }} />
      {engines}
      <Typography style={{ marginLeft: 16 }}>Voyage NOx emissions</Typography>
{/*      <div
        style={{
          marginTop: 16,
          marginBottom: 16,
          marginLeft: 32,
          marginRight: 16,
        }}
      >
        <TextField
          disabled={true}
          fullWidth={true}
          label="NOx-emission"
          placeholder="0.0"
          value={noxEmission}
          onChange={handleUpdateNoxEmission}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
*/}
      <div
        style={{
          marginLeft: 16,
          marginTop: 16,
          marginBottom: 32,
          marginRight: 16,
        }}
        className={classes.totalGrid}
      >
        <Typography className={classes.mediumEmphasis}>Total emission</Typography>
        <Typography className={classes.totalValue}>
          {Number(Number(noxEmission).toFixed(1)).toLocaleString()}
        </Typography>
        <Typography className={classes.totalUnit}>kg NOx</Typography>

        <Typography className={classes.mediumEmphasis}>
          NOx-rate
        </Typography>
        <Typography className={classes.totalValue}>{Number(noxrate.toFixed(1)).toLocaleString()}</Typography>
        <Typography className={classes.totalUnit}>NOK/kg</Typography>

        <Divider
          style={{
            gridColumn: '1/-1',
            backgroundColor: 'rgba(255,255,255,0.36)',
          }}
        />

        <Typography>
            {(taxability === null) ? "Undefined voyage" : !taxability ? "Non-taxable voyage" : "Total NOx tax" }
        </Typography>
        <Typography className={classes.totalValue} component="span">
          {' '}
          = {Number((Number.parseFloat(noxEmission) * noxrate).toFixed(1)).toLocaleString()}
        </Typography>
        <Typography className={classes.totalUnit}>NOK</Typography>
        <div style={{ gridColumn: '1/-1' }}>
          <Divider
            style={{
              marginBottom: 2,
              backgroundColor: 'rgba(255,255,255,0.36)',
            }}
          />
          <Divider style={{ backgroundColor: 'rgba(255,255,255,0.36)' }} />
        </div>
      </div>
        <div style={{ textAlign: 'center', marginTop: 32, marginBottom: 32, paddingBottom: 32 }}>
            <Button style={{ marginRight: 32, padding: "7px 32px" }}
                    variant="outlined" color="secondary" onClick={back}>
                BACK
            </Button>
            <Button  style={{ padding: "7px 32px" }}
                     disabled={readOnly}
                     variant="contained" color="primary" onClick={save}>
                SAVE
            </Button>
        </div>
    </div>
  )
}

export default VoyageNoxCalculation
