import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import Fade from '@material-ui/core/Fade'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'

import { makeStyles, Theme } from '@material-ui/core/styles'

export const drawerWidthOpen = 260
export const drawerWidthClosedDesktop = 64

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidthOpen,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerClosed: {
    position: 'fixed',
    zIndex: 100,
    width: 0,
    [theme.breakpoints.up('md')]: {
      width: `${drawerWidthClosedDesktop}px`,
    },
  },
  drawerOpen: {
    position: 'fixed',
    zIndex: 100,
    width: drawerWidthOpen,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  paper: {
    paddingTop: `env(safe-area-inset-top)`,
    paddingLeft: `env(safe-area-inset-left)`,
    width: drawerWidthOpen,
    overflowX: 'hidden',
    overflowY: 'auto',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
      delay: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      transition: theme.transitions.create('width', {
        delay: 0,
      }),
    },
  },
  paperClosed: {
    paddingTop: `env(safe-area-inset-top)`,
    paddingLeft: `env(safe-area-inset-left)`,
    width: '0px',
    overflowX: 'hidden',
    overflowY: 'auto',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
      width: `${drawerWidthClosedDesktop}px`,
    },
  },
  footer: {
    marginTop: 'auto',
    alignSelf: 'center',
    width: '100%',
    textAlign: 'center',
  },
  appVersion: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
  appVersionRed: {
    padding: theme.spacing(1),
    color: theme.palette.error.main,
    fontWeight: 800
  },
}))

export type AppDrawerProps = {
  children?: React.ReactNode;
  appBarTopMargin?: boolean;
  applicationVersion?: string;
  open?: boolean;
  onClose?: () => void;
};

export const AppDrawer = (props: AppDrawerProps) => {
  const { children, appBarTopMargin, applicationVersion, open, onClose } = props
  const classes = useStyles()
  const onTest = (applicationVersion && applicationVersion.indexOf('Test') > 0)

  const onCloseHandler = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        className={open ? classes.drawer : classes.drawerClosed}
        classes={{ paper: open ? classes.paper : classes.paperClosed }}
        onClose={onCloseHandler}
        open={open ? open : false}
      >
        {appBarTopMargin && <Toolbar />}
        <Fade in={true}>
          <div>{children}</div>
        </Fade>
        <Container disableGutters={true} className={classes.footer}>
          <Divider />
          {(onTest) ? (
          <Typography component="div" className={classes.appVersionRed} variant="caption">
            {applicationVersion}
          </Typography>
          ): (
          <Typography component="div" className={classes.appVersion} variant="caption">
            {applicationVersion}
          </Typography>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default AppDrawer
