// import { AppCurtain } from '@griegconnect/krakentools-react-ui'; // Olg Grieg logo curtain
import { AppCurtain } from '../../../AppCurtain' // New curtain with NOx Digital logo
import React from 'react'

import GriegConfigProvider from './grieg-config-provider'

type ConfigWrapperProps = {
  children: React.ReactNode
}

export const ConfigWrapper = (props: ConfigWrapperProps) => {
  const { children } = props

  return (
    <GriegConfigProvider
      path="/config/config.json"
      loadingComponent={<AppCurtain />}
      errorComponent={<p>Error loading application configuration</p>}
    >
      {children}
    </GriegConfigProvider>
  )
}

export default ConfigWrapper
