import {Container, FormControl, Grid, IconButton, FormControlLabel, FormGroup, Paper} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FilterIcon from '@griegconnect/krakentools-react-icons/Filter'
import InfoIcon from '@griegconnect/krakentools-react-icons/Information'
import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import {Duration} from 'luxon'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import PeriodsSelect from '../../utils/PeriodsSelect'

import {offshoreZonesState, periodFilterState} from '../../atoms'
import { useAppContext } from '../../common/GriegProvider/wrappers/AppContextWrapper'
import ErrorModal from '../../components/ApolloErrorModal'
import LoadingSpinner from '../../components/LoadingSpinner'
import UiFilterDrawer, { IUiFilterSelections } from '../../components/UiFilterDrawer'
import UiShowActiveFilter from '../../components/UiShowActiveFilter'
import VoyageList from '../../components/VoyageList'
import VoyageMap from '../../components/VoyageMap'
import { IVoyagesSummary, VoyagesSummary } from './VoyagesSummary'
import { RED_HIGHLIGHT, COMPLETED, PENDING } from '../../constants/colors'
import MapLegendLabel from 'src/components/MapLegendLabel'

import { useApis } from 'src/lib/ApiProvider'
import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'
import { Vessel, VesselPeriod } from 'src/lib/apis/types/vessel'
import { Area } from "src/lib/apis/types/area"

import { VoyageWithOverride } from 'src/lib/apis/types/voyage'

import {DateTimePicker, LocalizationProvider} from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import {parseISO} from "date-fns"
import MuiTextField from "@mui/material/TextField"
import useMediaQuery from "@mui/material/useMediaQuery"

import { useAuth } from '@griegconnect/krakentools-react-auth'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      width: 'calc(100% + 15px)',
      height: '100%',
      marginLeft: -7,
      marginTop: -16,
      marginBottom: 30,
      minHeight: 300,
      maxHeight: 400,
      '& canvas': {
        outline: 0,
      },
      position: 'sticky',
      top: 55,
      zIndex: 5,
    },
    filterBtn: {
      marginLeft: 'auto',
    },
    titleRow: {
      paddingTop: 15,
      marginBottom: 25,
      paddingRight: 15,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    mapLabelContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0.7em 0',
      backgroundColor: '#11181e',
    },
    paper: {
      maxWidth: 400,
      padding: 20,
      margin: '0 auto',
    },
    mediumEmphasis: {
      color: theme.palette.text.secondary,
    },
    normalEmphasis: {
      color: theme.palette.text.primary,
    },
    dateInput: {
      borderRadius: 3,
      '& .MuiInputLabel-root': {
        color: '#94979a',
      },
      '& .Mui-focused': {
        color: '#ffffff !important',
      },
      '& .MuiSvgIcon-root': {
        color: '#94979a',
      },
      '& .MuiFilledInput-underline': {
        color: '#fff',
        borderBottom: '1px solid #94979a',
      },
      '& .MuiFilledInput-root::after': {
        borderBottom: '1.8px solid #ffffff',
      },
    },
  })
)

const ApprovedSwitch = withStyles({
  switchBase: {
    color: RED_HIGHLIGHT,
    '&$checked': {
      color: COMPLETED,
    },
    '&$checked + $track': {
      backgroundColor: COMPLETED,
    },
  },
  checked: {},
  track: {},
})(Switch)

const CommittedSwitch = withStyles({
  switchBase: {
    color: RED_HIGHLIGHT,
    '&$checked': {
      color: COMPLETED,
    },
    '&$checked + $track': {
      backgroundColor: COMPLETED,
    },
  },
  checked: {},
  track: {},
})(Switch)

const VesselVoyagesList = (props: GriegRouteProps) => {
  const classes = useStyles()
  const { vesselId } = useParams<{ vesselId: string }>()
  const { apis } = useApis()
  const { user } = useAuth()

  const [selectedVoyageId, setSelectedVoyageId] = useState<number | null>(null)
  const [approved, setApproved] = useState(false)
  const [committed, setCommitted] = useState(false)

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [filterState, setFilterState] = useRecoilState(periodFilterState)
  const { route } = props
  const { setRouteOptionsCallback } = useAppContext()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))
  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps

  const queryClient = useQueryClient()
  const [filterConfig, setFilterConfig] = useState({
    quarter: {
      label: 'Period',
      multiple: false,
      type: 'select',
      values: [] as any,
    },
    show_voyages: {
      label: 'Show',
      multiple: false,
      type: 'select',
      values: [
        {
          label: 'All Voyages',
          value: 0,
        },
        {
          label: 'Taxable only',
          value: 1,
        },
        {
          label: 'Non-taxable only',
          value: 2,
        },
        {
          label: 'Undefined only',
          value: 3,
        },
      ],
    },
  })

  const [offshoreZones, setOffshoreZones ] = useRecoilState(offshoreZonesState)

  const { 
    isLoading: isLoadingPeriods, 
    isError: isErrorPeriods, 
    data: periods, 
    error: errorPeriods, 
    refetch: refetchPeriods 
  } = useQuery<NoxPeriod[], Error>('noxPeriods', async () =>
    await apis.periodsApi.getPeriods()
  )

  const currentPeriod: NoxPeriod | undefined = PeriodsSelect.getPeriod(periods, filterState)
  //Display dates for additional filtering - can only be within the currentPeriod range
  const [displayFromDate, setDisplayFromDate] = useState<string | undefined>(currentPeriod?.timeStart)
  const [displayToDate, setDisplayToDate] = useState<string | undefined>(currentPeriod?.timeEnd)

  const {
    isLoading: isLoadingVessel,
    isError: isErrorVessel,
    error: errorVessel,
    data: vessel,
    refetch: refetchVessel,
  } = useQuery<Vessel, Error>(['vessel', vesselId], async () =>
    await apis.vesselsApi.getVessel(vesselId),
    { enabled: !!vesselId }
  )

  const {
    isLoading: isLoadingVesselPeriods,
    error: errorVesselPeriods,
    data: vesselPeriods,
    isError: isErrorVesselPeriods,
    refetch: refetchVesselPeriods,
  } = useQuery<VesselPeriod[], Error>(['vesselPeriod', currentPeriod, vesselId], async () =>
    await apis.vesselsApi.getVesselPeriods(vesselId, currentPeriod?.id),
    { enabled: !!vesselId && !!currentPeriod }
  )

  const {
    isLoading: isLoadingVoyages,
    error: errorVoyages,
    data: allVoyages,
    isError: isErrorVoyages,
    refetch: refetchVoyages,
  } = useQuery<VoyageWithOverride[], Error>(['voyages', vesselId, currentPeriod], async () =>
    await apis.voyagesApi.getVoyages(vesselId, undefined, currentPeriod?.id),
    { enabled: !!vesselId && !!currentPeriod}
  )

  //YAE 14.03.22 -> getting offshore installation zones (only if necessary)
  const {
    isLoading: isLoadingAreas,
    data: areaData,
  } = useQuery<Area[], Error>('areas', async () =>
          await apis.areasApi.getAreas(['offshore']),
      {enabled: !!vesselId && !!currentPeriod && typeof offshoreZones.length === 'undefined' }
  )

  useEffect(() => {
    if( typeof areaData !== 'undefined' ) {
      setOffshoreZones(areaData
          .filter((area) => {
           // return area.id === 93 //name.toUpperCase().substr(9,10) === 'IVAR AASEN' || area.name.toUpperCase().substr(0,10) === 'IVAR AASEN'
            return area.name.toUpperCase().substr(0, 6) !== 'SUBSEA'
          })
          .map((area) => {
            return {
              id: area.id,
              name: area.name,
              coords: area.polygon.length > 12 ?
                  area.polygon
                      .substr(10, area.polygon.length - 12)
                      .split(', ')
                      .map((pair) => {
                        return pair.split(' ').map(Number)
                      })
                  : [[0,0]],
            }
          })
      )
    }
  }, [areaData,setOffshoreZones])

  useEffect(() => {
    if (typeof currentPeriod !== 'undefined' && typeof displayFromDate !== 'undefined' && (new Date(displayFromDate) < new Date(currentPeriod.timeStart) || new Date(displayFromDate) > new Date(currentPeriod.timeEnd))) {
      //period has changed
      setDisplayFromDate(currentPeriod.timeStart)
      setDisplayToDate(currentPeriod.timeEnd)
      setSelectedVoyageId(0)
    }
  }, [currentPeriod]) // eslint-disable-line react-hooks/exhaustive-deps

    //may need to adjust stupid user choices as well
  useEffect(() => {
    if (typeof displayToDate !== 'undefined' && typeof displayFromDate !== 'undefined' && displayFromDate > displayToDate) {
      setDisplayToDate(currentPeriod?.timeEnd)
    }
  }, [displayFromDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof displayToDate !== 'undefined' && typeof displayFromDate !== 'undefined' && new Date(displayToDate) < new Date(displayFromDate)) {
      setDisplayFromDate(currentPeriod?.timeStart)
    }
  }, [displayToDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilterConfig({
      quarter: {
        label: 'Period',
        multiple: false,
        type: 'select',
        values: periods?.map((period) => ({
          label: period.name,
          value: period.id,
        })),
      },
      show_voyages: {
        label: 'Show',
        multiple: false,
        type: 'select',
        values: [
          {
            label: 'All Voyages',
            value: 0,
          },
          {
            label: 'Taxable only',
            value: 1,
          },
          {
            label: 'Non-taxable only',
            value: 2,
          },
          {
            label: 'Undefined only',
            value: 3,
          },
        ],
      },
    } as any)

    if (!filterState.quarter && currentPeriod) {
      setFilterState({ ...filterState, quarter: [currentPeriod.id] })
    }

    //YAE :: Initialize show_voyages drop-down in filter
    if (!filterState.show_voyages) {
      setFilterState({ ...filterState, show_voyages: [0] })
    }
    // eslint-disable-next-line
  }, [periods])

  //YAE 09.08.21: Does not refresh submissionStatus correctly - sticks with the first status received after login...
  // (Problem was in the query parameter - vesselId was missing)
  useEffect(() => {
    if (!isLoadingVesselPeriods && vesselPeriods) {
      if (vesselPeriods.length > 0) {
        setApproved(vesselPeriods[0].submissionStatus === 'approved'  || vesselPeriods[0].submissionStatus === 'committed')
        setCommitted(vesselPeriods[0].submissionStatus === 'committed')
        } else {
        setApproved(false)
        setCommitted(false)
      }
    }
  }, [isLoadingVesselPeriods, vesselPeriods, currentPeriod])

  if (isLoadingVessel || isLoadingPeriods || isLoadingVesselPeriods || isLoadingAreas) {
    return <LoadingSpinner />
  }

  if (isErrorVessel) {
    return <ErrorModal error={errorVessel} refetch={refetchVessel} />
  }

  if (isErrorPeriods) {
    return <ErrorModal error={errorPeriods} refetch={refetchPeriods} />
  }

  if (isErrorVesselPeriods) {
    return <ErrorModal error={errorVesselPeriods} refetch={refetchVesselPeriods} />
  }
  /* //YAE 02.11.21 removed:
  if (stats_e) {
    return <ErrorModal error={stats_e} refetch={stats_r} />
  }
*/
  if (isErrorVoyages) {
    return <ErrorModal error={errorVoyages} refetch={refetchVoyages} />
  }

  // Remove entries with 0 timespan unless it is a user-modified voyage (event source === 'user')
  const reportableVoyages = !isLoadingVoyages && allVoyages
    ? allVoyages.filter((v) => v.timeStart !== v.timeEnd || v.events[0].source === 'user' || v.events[1].source === 'user')
    : []

  //YAE -> adding running id + promoting consumption et al from overrides on voyages_d.voyages
  if (!isLoadingVessel && !isLoadingVesselPeriods && reportableVoyages) {
    if (reportableVoyages.length > 0) {
      reportableVoyages
        .map((v: any, i) => {
        v.id = i + 1
        v.vesselId = vesselId
        if (v.overrides) {
          v.consumption = v.overrides.consumption || v.consumption
          v.consumptions = v.overrides.consumptions || v.consumptions
          v.taxable = v.overrides.taxable
        }
        return v
      })
    }
  }

  const voyagesT =
    currentPeriod && !isLoadingVoyages && reportableVoyages
      ? reportableVoyages
          .sort((a: any, b: any) => (new Date(a.timeEnd) >= new Date(b.timeEnd) && new Date(a.timeStart) >= new Date(b.timeStart) ? 1 : -1)) // Ordered from back-end, but apparently not after archiving...
          .filter((voyage) => {
            const hideVoyage = filterState.show_voyages
              ? (filterState.show_voyages[0] === 1 && voyage.taxable !== true) ||
                (filterState.show_voyages[0] === 2 && voyage.taxable !== false) ||
                (filterState.show_voyages[0] === 3 && voyage.taxable !== null) ||
                (voyage.timeStart === voyage.timeEnd && voyage.consumption.noxCalculated === 0)
              : false
            return !hideVoyage
          })
          .filter((voyage) => {
            return displayFromDate && displayToDate && new Date(voyage.timeStart) >= new Date(displayFromDate) && new Date(voyage.timeEnd) <= new Date(displayToDate)
          })
            //VoyageMap will expect voyage.vessel to have relevant data
          .map((voyage: any) => {
            voyage.vessel = vessel
            return voyage
          })
      : []

  //YAE 16.09.21 -> adding taxableNox from voyages.consumptions[]
  const taxableNox = voyagesT
    ? voyagesT
        .filter((v) => v.taxable)
        .reduce(
          (totNox, nox) =>
            totNox + nox.consumption.noxCalculated * 1, 0)
    : 0

  const nonTaxableNox = voyagesT
    ? voyagesT
        .filter((v) => v.taxable === false) // && v.inNoxZone ??
        .reduce(
            (totNox, nox) =>
                totNox + nox.consumption.noxCalculated * 1, 0)
    : 0

  const otherNox = (voyagesT
    ? voyagesT
        .reduce(
            (totNox, nox) =>
                totNox + nox.consumption.noxCalculated * 1, 0)
    : 0) - taxableNox - nonTaxableNox

  const SelectedDateRange = ((props: any) => {
    const largerWidth = useMediaQuery('(min-width: 884px)')
    return (
        <>
          <FormControl variant="filled" component="fieldset" style={{ marginTop: 8, marginBottom: 8, marginLeft: 16 }} >
          <Grid
              container
              style={
                largerWidth
                    ? { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10 }
                    : { display: 'flex', flexDirection: 'column', gap: 6 }
              }
          >
            <Typography>
              Display date range:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                  PopperProps={{placement: 'top-start'}}
                  inputFormat="dd.MM.yy"
                  mask="__.__.__"
                  ampm={false}
                  ampmInClock={false}
                  minutesStep={1440}
                  minDateTime={parseISO(currentPeriod?.timeStart ?? '')}
                  maxDateTime={parseISO(currentPeriod?.timeEnd ?? '')}
                  renderInput={(props) => (
                      <MuiTextField
                          style={largerWidth ? { marginRight: 5, width: '60%', marginTop: -32 } : { margin: '5px 0' }}
                          className={classes.dateInput}
                          variant="filled"
                          {...props}
                      />
                  )}
                  label=""
                  value={displayFromDate}
                  onChange={(newValue) => {
                    if (newValue !== null && isNaN(newValue.getTime()) === false) {
                      const utcArrivalTime = new Date(Math.floor(newValue.getTime() / 60000) * 60000)
                      setDisplayFromDate(utcArrivalTime.toISOString())
                      setSelectedVoyageId(0)
                    }
                  }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                  PopperProps={{placement: 'top-start'}}
                  inputFormat="dd.MM.yy"
                  mask="__.__.__"
                  ampm={false}
                  ampmInClock={false}
                  minutesStep={1440}
                  maxDateTime={parseISO(currentPeriod?.timeEnd ?? '')}
                  minDateTime={displayFromDate ? parseISO(new Date(displayFromDate).toISOString()) : parseISO(currentPeriod?.timeStart ?? '')}
                  renderInput={(props) => (
                      <MuiTextField
                          style={largerWidth ? { marginRight: 5, width: '60%', marginTop: -32  } : { margin: '5px 0' }}
                          className={classes.dateInput}
                          variant="filled"
                          {...props}
                      />
                  )}
                  value={displayToDate}
                  onChange={(newValue: Date | null) => {
                    if (newValue !== null && isNaN(newValue.getTime()) === false) {
                      const utcNextDepartureTime = new Date(Math.floor(newValue.getTime() / 60000) * 60000)
                      setDisplayToDate(utcNextDepartureTime.toISOString())
                      setSelectedVoyageId(0)
                    }
                  }}
              />
            </LocalizationProvider>
          </Grid>
        </FormControl>
        </>
    )
  })

  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!filterDrawerOpen)
  }

  const handleFilterChange = (newFilterSelection: IUiFilterSelections) => {
    setFilterState({ ...(newFilterSelection as any) } as any)
  }

  const handleVoyageSelected = (voyageId: number, vesselId: number) => {
    setSelectedVoyageId(voyageId)
  }

  const summary: IVoyagesSummary = {
    opTime: Duration.fromObject({ days: 4, hours: 11, minutes: 33 }),
    noxTime: null, // noxDuration,
    taxableNox: taxableNox ? taxableNox : null, // taxable NOx-emissions
    nonTaxableNox: nonTaxableNox ? nonTaxableNox : null, // non-taxable NOx emissions in NOx zone
    otherNox: otherNox ? otherNox : null, // The rest of it
    vesselName: vessel?.name ? vessel?.name : null,
    imo: vessel?.imo ? vessel?.imo : null,
  }

  const handleChangeApprovedStatus = async () => {
    if (typeof currentPeriod !== 'undefined' && typeof vesselPeriods !== 'undefined') {
      //Does this vessel already have a vesselPeriods entry for this period?
      if (vesselPeriods.filter((vp) => Number(vp.periodId) === Number(currentPeriod.id) && Number(vp.vesselId) === Number(vesselId)).length === 1) {
        await apis.vesselsApi.updateVesselPeriod(vesselId, currentPeriod.id, {
          periodId: currentPeriod.id,
          submissionStatus: (!approved && 'approved') || 'not-approved',
        })
      } else { //No entries, create a new one
        await apis.vesselsApi.createVesselPeriod(vesselId, {
          periodId: currentPeriod.id,
          submissionStatus: (!approved && 'approved') || 'not-approved',
        })
      }
      queryClient.invalidateQueries(['vesselPeriod', currentPeriod, vesselId])
      queryClient.invalidateQueries('vesselPeriods')
      setApproved(!approved)
    }
  }

  const handleChangeCommittedStatus = async () => {
    if (typeof currentPeriod !== 'undefined' && typeof vesselPeriods !== 'undefined') {
      //Vessel must already have a 'submitted' (Completed) vesselPeriods entry for this period
        await apis.vesselsApi.updateVesselPeriod(vesselId, currentPeriod.id, {
          periodId: currentPeriod.id,
          submissionStatus: (!committed && 'committed') || 'approved',
        })
      queryClient.invalidateQueries(['vesselPeriod', currentPeriod, vesselId])
      queryClient.invalidateQueries('vesselPeriods')
      setCommitted(!committed)
    }
  }

  return (
    <>
      <div className={classes.map}>
        <VoyageMap
          lat={62}
          lng={6}
          voyages={voyagesT}
          offshoreZones={offshoreZones}
          preSelectedVoyageId={selectedVoyageId}
          onVoyageSelected={handleVoyageSelected}
        />
        <MapLegendLabel />
      </div>
      <Container>
        <div className={classes.titleRow}>
          <Typography variant="h5">NOx-Voyages ({voyagesT.length})</Typography>
          <IconButton onClick={toggleFilterDrawer} className={classes.filterBtn}>
            <FilterIcon fontSize="large" style={{ color: 'white' }} />
          </IconButton>
        </div>

        <UiShowActiveFilter
          filterConfig={filterConfig as any}
          filterSelections={{ ...filterState }}
          onChange={handleFilterChange}
        />

        <UiFilterDrawer
          filterConfig={filterConfig as any}
          filterSelections={{ ...filterState }}
          open={filterDrawerOpen}
          onClose={toggleFilterDrawer}
          onChange={handleFilterChange}
        />
        <div>
            <VoyagesSummary voyagesSummary={summary} />
            <SelectedDateRange/>
            {(isLoadingVoyages && !isLoadingVessel && !isLoadingPeriods && !isLoadingVesselPeriods) &&
              <LoadingSpinner label={'Generating voyages...'} />
            }
          {voyagesT.length > 0 && typeof currentPeriod !== 'undefined' ? (
            <>
              <VoyageList
                vesselId={vesselId}
                voyages={voyagesT}
                allVoyages={reportableVoyages}
                offshoreZones={offshoreZones}
                readOnly={committed}
                currentPeriod={currentPeriod}
                selectedVoyage={selectedVoyageId}
                onVoyageSelected={handleVoyageSelected}
              />
            </>
             ) : (
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                This vessel does not have any voyages that match the current Active filter
              </div>
            )}
          </div>
          {!committed && (
          <>
            <Paper
              style={{
                background: approved ? '#232F2F' : '#2e2829',
                marginLeft: -8,
                marginRight: -8,
              }}
            >
          <FormGroup>
            <FormControlLabel
              control={
                <ApprovedSwitch checked={approved} onChange={handleChangeApprovedStatus} name="ApprovedSwitch" disabled={committed} />
              }
              label="Completed"
              style={{ marginLeft: 0 }}
            />
          </FormGroup>
          </Paper>
          <div
            style={{
              background: approved ? '#232F2F' : '#2e2829',
              marginLeft: -8,
              marginRight: -8,
              display: 'flex',
              alignItems: 'center',
              marginBottom: 16,
            }}
          >
            {(approved && (
              <>
                <InfoIcon fontSize="large" style={{ color: COMPLETED, marginRight: 16, marginLeft: 8 }} />
                <Typography style={{ color: COMPLETED, display: 'inline-block' }}>
                  {' '}
                  Vessel emissions will be included in Report for this period
                  {/*YAE removed this: registrationEndDate ? registrationEndDate.toLocaleString(DateTime.DATE_SHORT) : '-'*/}
                </Typography>
              </>
            )) || (
              <>
                <InfoIcon fontSize="large" style={{ color: PENDING, marginRight: 16, marginLeft: 8 }} />
                <Typography style={{ color: PENDING, display: 'inline-block' }}>
                  {' '}
                  Vessel must be set as Completed to appear in emissions Report for this period
                </Typography>
              </>
            )}
          </div>
        </>
        )}
        {approved && (currentPeriod?.timeEnd && currentPeriod.timeEnd < new Date().toISOString()) && (
        <>
          <Paper
              style={{
                background: approved ? '#232F2F' : '#2e2829',
                marginLeft: -8,
                marginRight: -8,
              }}
          >
            <FormGroup>
              <FormControlLabel
                  control={
                    <CommittedSwitch
                      checked={committed}
                      onChange={handleChangeCommittedStatus}
                      name="CommittedSwitch"
                      disabled={ (committed || !approved) && !(user?.email?.includes('yxney.com') || user?.email?.includes('v-p-s.com')) } //Yxney users can revert this
                    />
                  }
                  label="Archived"
                  style={{ marginLeft: 0 }}
              />
            </FormGroup>
          </Paper>
          <div
              style={{
                background: committed ? '#232F2F' : '#2e2829',
                marginLeft: -8,
                marginRight: -8,
                display: 'flex',
                alignItems: 'center',
                marginBottom: 16,
              }}
          >
            {(committed && (
                <>
                  <InfoIcon fontSize="large" style={{ color: COMPLETED, marginRight: 16, marginLeft: 8 }} />
                  <Typography style={{ color: COMPLETED, display: 'inline-block' }}>
                    {' '}
                    Vessel voyages and emissions have been Archived and can no longer be modified
                    {/*YAE removed this: registrationEndDate ? registrationEndDate.toLocaleString(DateTime.DATE_SHORT) : '-'*/}
                  </Typography>
                </>
            )) || (
                <>
                  <InfoIcon fontSize="large" style={{ color: PENDING, marginRight: 16, marginLeft: 8 }} />
                  <Typography style={{ color: PENDING, display: 'inline-block' }}>
                    {' '}
                    Vessel should be set to Archived after the emissions Report has been filed. This will prevent future modification of the periods results.
                  </Typography>
                </>
            )}
          </div>
        </>
        )}
        <div style={{marginBottom: 16}}>&nbsp;</div>
      </Container>
    </>
  )
}

export default VesselVoyagesList
