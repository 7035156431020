import { Divider, List, ListItemIcon, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import VesselIcon from '@griegconnect/krakentools-react-icons/Vessel'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { UiListItemLink } from '../../components/UiListItemLink'
import {PENDING, COMPLETED, COMMITTED} from '../../constants/colors'
import { VesselWithEnginesAndStatus } from 'src/lib/apis/types/vessel'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      marginLeft: -8,
      marginRight: -8,
    },
    titleRow: {
      marginBottom: 25,
      marginLeft: 15,
      paddingRight: 15,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    filterBtn: {
      marginLeft: 'auto',
    },
    textPending: {
      color: PENDING,
    },
    textCompleted: {
      color: COMPLETED,
    },
    textCommitted: {
      color: COMMITTED,
    },
    textYellow: {
      color: theme.palette.error.main,
    },
    mediumEmphasis: {
      color: theme.palette.text.secondary,
    },
  })
)

interface IFleetProps extends RouteComponentProps {
  vessels: VesselWithEnginesAndStatus[]
  selectedVesselId?: string
  inZone: Map<string, boolean>
}

interface IListItemProps {
  vessel: VesselWithEnginesAndStatus
  selected?: boolean
  inZone: boolean
}

const ListItem = React.memo((props: IListItemProps) => {
  const { vessel, selected, inZone } = props

  // const isReady = vessel.voyages?.reduce((acc, voyage) => voyage.reportStatus !== 'submission-ready' && (acc = false), true);
  const vesselStatus = vessel?.status
  const classes = useStyles()
  return (
    <div style={{marginBottom: '4px', backgroundColor: (selected && '#333') || 'inherit' }}>
      <UiListItemLink to={{ pathname: '/vessel/' + vessel.id + '/voyages' }} style={{marginBottom: '4px'}}>
        <ListItemIcon>
          <VesselIcon fontSize="large" style={{ marginRight: '5px', color: (!inZone && '#808080') || 'inherit'}} />
        </ListItemIcon>
        <ListItemText
          inset={false}
          style={{ marginTop: 10 }}
          primary={
            <>
              <Typography component="span" variant="body1" style={{ color: (!inZone && '#808080') || 'inherit' }}>
                {vessel.name || 'No name vessel'}
              </Typography>
              {(vesselStatus === 'approved') && (
                <Typography className={classes.textCompleted} component="span" variant="body1" style={{ float: 'right' }}>
                  Completed
                </Typography>
              )}
              {(vesselStatus === 'not-approved') && ( 
                <Typography className={classes.textPending} component="span" variant="body1" style={{ float: 'right' }}>
                  Pending
                </Typography>
              )}
              {(vesselStatus === 'committed') && (
                <Typography className={classes.textCommitted} component="span" variant="body1" style={{ float: 'right' }}>
                    Archived
                </Typography>
              )}
            </>
          }
        />
      </UiListItemLink>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />
    </div>
  )
})

const FleetVoyagesVesselList = (props: IFleetProps) => {
  const { vessels, selectedVesselId, inZone } = props
  if (!vessels) {
    return <span>No Vessel</span>
  }
  const listItems = vessels
    //YAE 23.08.21 --> removed .filter((vessel) => vessel.voyages && vessel.voyages!.length > 0)
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((vessel, key) => {
      return <ListItem vessel={vessel} key={key} selected={vessel.id === selectedVesselId} inZone={inZone.get(vessel.id) ?? true}/>
    })

  return <List>{listItems}</List>
}

export default withRouter(FleetVoyagesVesselList)
