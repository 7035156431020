import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 0.5em 0em 0.5em 0em;
  margin-top: 2.2em;
`

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
`
