import { makeStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import styled from 'styled-components'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectInputLabel: {
      color: '#dbdbdcab  !important',
      marginLeft: -10,
      marginTop: -10,
    },
    selectInputIcon: {
      marginTop: -5,
      fill: '#dbdbdcab !important',
    },
    vesselSelect: {
      width: '100%',
      margin: '0 0 !important',
      background: '#11181E !important',
      color: 'white !important',

      '&:before': {
        borderColor: '#dbdbdcab !important',
      },
      '&:after': {
        borderColor: '#dbdbdcab !important',
      },
    },
    charterTextField: {
      width: '100%',
      padding: '0 !important',
      margin: '0.6em 0 !important',
    },
  })
)

export const InputContainer = styled.div`
  @media screen and (min-width: 884px) {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
`

export const VesselInputWrapper = styled.div`
  width: 100%;
  margin-bottom: -0.5em;
`

export const CharterInputWrapper = styled.div`
  width: 100%;
  margin-bottom: -0.5em;

  @media screen and (max-width: 884px) {
    margin: 0.7em 0 -1em 0;
  }
`

export const UreaPriceWrapper = styled.div`
  width: 30%;

  .input {
    width: 100%;
    margin-top: 0.6em;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  @media screen and (max-width: 884px) {
    width: 100%;
    margin: 1.7em 0 -1em 0;
  }
`

export const AdditionalTextWrapper = styled.div`
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;

  @media screen and (max-width: 884px) {
    margin: 2em 0 -1em 0;
  }
`
