import { Link, Typography } from '@material-ui/core'
import React from 'react'
import { IReleaseNoteProps } from './ReleaseNote1'

const ReleaseNote7 = (props: IReleaseNoteProps) => {
  return (!props.english) ? (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q1-2024
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Tolkning av turer
      </Typography>
      <Typography variant="body1">
        Oppdateringer av regelverket sidestiller nå alle offshore installasjoner, inkl. vindfarmer, med petroleumsrelaterte installasjoner.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Se også <Link style={{cursor: "pointer", color:'lightskyblue'}}><span onClick={props.openTaxRules}>"Tolkning av regelverket"</span></Link> for detaljer.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Utenlandske havner
      </Typography>
      <Typography variant="body1">
        I denne versjonen er det 34 utenlandske havner:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Lerwick, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Gi oss beskjed om hvilke andre utenlandshavner dere trenger, så vil vi oppdatere med de viktigste fortløpende.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        NOx Voyages
      </Typography>
      <Typography variant="body1">
        Det er gjort flere optimaliseringer både for generering av turer og overføring av Maress-data.
      </Typography>
      <Typography variant="body1">
        Den samlede ytelsesforbedringen fra optimaliseringene er betydelig.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        NOx Report
      </Typography>
      <Typography variant="body1">
        Hastighetsøkningen er spesielt merkbar i NOx Report.
      </Typography>
      <Typography variant="body1">
        Vi har samtidig fjernet en feil som ved høy belastning på server i sjeldne tilfeller ga manglende forbruksdata for fartøy i Maress.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Vessel Details
      </Typography>
      <Typography variant="body1">
        Redigering av navn/MMSI har nå et ekstra valg for å angi "Active in Maress" status manuelt.
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q1-2024
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Tax rule interpretation
      </Typography>
      <Typography variant="body1">
        Regulatory updates confirm that all offshore installations, including wind- and fish-farms, can be treated equally regarding NOx tax exemptions.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        See also <Link style={{cursor: "pointer", color:'lightskyblue'}}><span onClick={props.openTaxRules}>"Tax rule interpretations"</span></Link> for details.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Foreign ports
      </Typography>
      <Typography variant="body1">
        This version has 34 foreign ports:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Lerwick, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Let us know which other ports are needed, and we will add the most frequently requested.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        NOx Voyages
      </Typography>
      <Typography variant="body1">
        We have implemented several server-side optimizations, both in voyage generation and in Maress data transfer.
      </Typography>
      <Typography variant="body1">
        The accumulated speed improvements in voyage generation is significant.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        NOx Report
      </Typography>
      <Typography variant="body1">
        These optimizations have a great effect on the NOx Report page loading speed.
      </Typography>
      <Typography variant="body1">
        We have also eliminated a situation where heavy server loads on rare occasions produced lacking consumption data for Maress vessels.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Vessel Details
      </Typography>
      <Typography variant="body1">
        The edit-function for vessel name/MMSI now has an additional selector to set the "Active in Maress" status manually.
      </Typography>
    </>
  )}

export default ReleaseNote7