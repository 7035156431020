import axios, { AxiosResponse } from 'axios'
import React, { useContext, useEffect, useState } from 'react'

import { apiConfigType } from './types/ApiConfig'
import { appConfigType } from './types/AppConfig'
import { authConfigType } from './types/AuthConfig'
import { optionsConfigType } from './types/OptionsConfig'

export type GriegConfigType = {
  api: apiConfigType;
  auth0: authConfigType;
  app: appConfigType;
  options: optionsConfigType;
};

export type GriegConfigProviderProps = {
  children: React.ReactNode;
  path: string;
  loadingComponent: React.ReactNode;
  errorComponent: React.ReactNode;
};

export type GriegConfigContextValues = {
  config: GriegConfigType | null;
  error: boolean;
  refresh: () => void;
};

export const GriegConfigContext = React.createContext<GriegConfigContextValues | null>(null)

export const useConfig = () => useContext(GriegConfigContext)!

export const GriegConfigProvider = (props: GriegConfigProviderProps) => {
  const { children, path, loadingComponent, errorComponent } = props
  const [config, setConfig] = useState<GriegConfigType | null>(null)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const getConfigurationFile = () => {
    axios
      .get(path)
      .then((response: AxiosResponse) => {
        if (response.data) {
          setConfig(response.data)
          setError(false)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.error(error)
        setError(true)
        setLoading(false)
      })
  }

  useEffect(getConfigurationFile, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GriegConfigContext.Provider value={{ config: config, error: error, refresh: getConfigurationFile }}>
      {loading ? loadingComponent : error ? errorComponent : children}
    </GriegConfigContext.Provider>
  )
}

export default GriegConfigProvider
