import VesselIcon from '@griegconnect/krakentools-react-icons/Vessel'
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, TextField } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { aisCountryMap } from 'src/constants/aisCountryMap'
import { AisStatic } from 'src/lib/apis/types/ais'
import { Lucene } from 'src/lib/apis/types/lucene'


const useStyles = makeStyles((theme: Theme) => ({
	gridContainer: {
		marginLeft: -8,
		marginRight: -8
	},

	titleRow: {
		marginBottom: 25,
		marginLeft: 15,
		paddingRight: 15,
		display: 'flex',
		alignItems: 'center',
		width: '100%'
	},
	filterBtn: {
		marginLeft: 'auto'
	},
	listroot: {
		padding: "0 16px"
	}
}))

interface IVesselSearchProps {
	vessels?: Lucene.Source<AisStatic.Data>[];
	vesselSearchValue: string;
	onInputChange: (value: string) => void;
	onVesselSelect: (id: string) => void;
}

interface IListItemProps {
	vessel: Lucene.Source<AisStatic.Data>;
	onChange: (id: string) => (event: any) => void;
}

const UiListItem = React.memo((props: IListItemProps) => {
	const { vessel, onChange } = props

	const country = aisCountryMap.get(Number(String(vessel.payload.mmsi).slice(0, 3)))

	return (
		<>
			<ListItem button onClick={onChange(vessel.id)}>
				<ListItemIcon>
					<VesselIcon fontSize='large' style={{ marginRight: '5px' }} />
				</ListItemIcon>
				<ListItemText inset={false} style={{ marginTop: 10 }} primary={
					<>
						<Grid container={true} justify="space-between">
							<Grid item={true}>
								<Grid container={true}>
									<Grid item={true} xs={12}>
										<Typography component='span' variant='body1' >{vessel.name}</Typography>
									</Grid>
									<Grid item={true} xs={12}>
										<Typography component='span' variant='body1' >{vessel.payload.imo} (IMO)</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item={true}>
								{country && `[${country}]`}
							</Grid>
						</Grid>
						<Divider style={{ marginTop: 5 }} />
					</>
				} />
			</ListItem>
		</>
	)
})

const VesselSearch: React.FunctionComponent<IVesselSearchProps> = (props) => {
	const [queryString, setQueryString] = useState<string>('')
	const handleInputChange = (evt: any) => {
		const currentValue = evt.target.value
		setQueryString(currentValue)
		props.onInputChange(currentValue)
	}

	const handleVesselSelect = (id: string) => (event: any) => {
		props.onVesselSelect(id)
	}

	const classes = useStyles()
	const listItems = props.vessels?.map(vessel => 
		<UiListItem onChange={handleVesselSelect} vessel={vessel} key={vessel.id} />
	)
	return (
		<div style={{ padding: 36 }}>
			<div className={classes.titleRow}>
				<Typography variant='h5'>Add new vessel</Typography>
			</div>
			<div style={{ padding: '0 16px' }}>
				<TextField variant="filled"
						   margin="normal"
						   fullWidth={true}
						   autoFocus={true}
						   autoComplete="off"
						   label={'Vessel name or IMO-number ' + (((queryString.length > 0 && queryString.length < 3) && '(minimum 3 letters)') || '')}
						   onChange={handleInputChange}
						   name="vesselName"
						   value={props.vesselSearchValue} />
			</div>
			{props.vesselSearchValue && props.vesselSearchValue.length > 0 &&
				<List className={classes.listroot}>
					{(typeof listItems !== 'undefined' && listItems.length > 0 && listItems) ||
						<ListItem button>
							<ListItemText inset={false} style={{ marginTop: 10 }} primary={(props.vesselSearchValue.length > 3 && 'No Hits') || 'Minimum 3 letters'}>
							</ListItemText>
						</ListItem>
					}
				</List>

			}
		</div>
	)
}

export default VesselSearch
