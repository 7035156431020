import { useQuery } from 'react-query'
import { TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import { NumberFormat } from '../../../utils/NumberFormat'

import { VesselListProps } from './NoxVesselList.models'
import ErrorModal from '../../../components/ApolloErrorModal'
import { VoyageOverride, VoyageWithOverride } from 'src/lib/apis/types/voyage'
import { useApis } from 'src/lib/ApiProvider'
import { NoxPeriodId } from 'src/lib/apis/types/noxPeriod'
import { Consumption } from 'src/lib/apis/types/consumption'

const NoxVesselList = (props: VesselListProps) => {
  const { apis } = useApis()
  let totalNoxEmission: number = 0
  let totalEstimatedNoxTax: number = 0

  const { vessel, currentPeriod, printable } = props
  const format = new NumberFormat()
  const lowRateVessel = vessel.noxRate === 'rate-low' ? true : false
  const highRateVessel = vessel.noxRate === 'rate-high' ? true : false
  const fullRateVessel = vessel.noxRate === 'rate-full' ? true : false
  const HIGH_RATE_FACTOR: number = currentPeriod.rateHigh ?? 0
  const LOW_RATE_FACTOR: number = currentPeriod.rateLow ?? 0
  const FULL_RATE_FACTOR: number = currentPeriod.rateFull ?? 0

  const VESSEL_RATE =
    vessel.noxRate === 'rate-low'
      ? 'Low'
      : vessel.noxRate === 'rate-high'
      ? 'High'
      : vessel.noxRate === 'rate-full'
      ? 'Full'
      : 'N/A'

  const TableCellColumn = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
      color: printable ? '#000' : '#FFF',
      borderBottom: printable
        ? '1px solid rgba(182, 181, 181, 0.2) !important'
        : '1px solid rgba(81, 81, 81, 0.5) !important',
    },
  }))(TableCell)

  const {
    isLoading: isLoadingVoyages,
    error: errorVoyages,
    data: voyages,
    isError: isErrorVoyages,
  } = useQuery<VoyageWithOverride[], Error>(['voyages', vessel, currentPeriod], async () =>
    await apis.voyagesApi.getVoyages(vessel.id, undefined, currentPeriod?.id, undefined, undefined, true, props.delay),
    { enabled: !!vessel && !!currentPeriod, staleTime: 300000, cacheTime: 300000 }
  )

  if (isErrorVoyages) return <ErrorModal error={errorVoyages} />

  if (!isLoadingVoyages) {
    // Remove entries with 0 timespan unless it is a user-modified voyage (event source === 'user')
    const reportableVoyages = voyages?.filter((v) => v.timeStart !== v.timeEnd)
    const voyagesOverrides: {periodeId: NoxPeriodId, taxable?: boolean, consumption?: Consumption, consumptions?: Consumption[], overrides?: VoyageOverride}[] = []

    reportableVoyages?.map((v) =>
      v.overrides !== null
        ? voyagesOverrides.push({
            periodeId: v.periodId,
            taxable: v.overrides ? v.overrides?.taxable : v.taxable,
            consumption: v.overrides ? v.overrides?.consumption : v.consumption,
            consumptions: v.overrides ? v.overrides?.consumptions : v.consumptions,
            overrides: v.overrides,
          })
        : voyagesOverrides.push({
            periodeId: v.periodId,
            taxable: v.taxable,
            consumption: v.consumption,
            consumptions: v.consumptions,
            overrides: v.overrides,
          })
    )

    const noxCalculated: number = voyagesOverrides
      .filter((v) => v.taxable && (v?.consumptions?.length ?? 0) > 0)
      .map((voyage) => voyage.consumptions)
      .flat()
      .map((con) => con?.noxCalculated)
      .reduce((tot, amt) => (tot ?? 0) + (amt ?? 0), 0) ?? 0

    const calculatedNoxTaxHigh = (noxCalculated * HIGH_RATE_FACTOR).toFixed(0)
    const calculatedNoxTaxLow = (noxCalculated * LOW_RATE_FACTOR).toFixed(0)
    const calculatedNoxTaxFull = (noxCalculated * FULL_RATE_FACTOR).toFixed(0)

    totalNoxEmission = format.largeNumber(noxCalculated.toFixed(0))
    totalEstimatedNoxTax = highRateVessel
      ? format.largeNumber(calculatedNoxTaxHigh)
      : lowRateVessel
      ? format.largeNumber(calculatedNoxTaxLow)
      : fullRateVessel
      ? format.largeNumber(calculatedNoxTaxFull)
      : 0
  }

  return (
    <TableRow style={printable ? { background: 'white', color: '#000 !important' } : {}} hover={true}>
      <TableCellColumn component="th" scope="row">
        {!isLoadingVoyages ? vessel.name : 'Loading...'}
      </TableCellColumn>
      <TableCellColumn align="right"> {!isLoadingVoyages ? vessel.imo : ''} </TableCellColumn>
      <TableCellColumn style={{ minWidth: 80 }} align="right">
        {!isLoadingVoyages ? VESSEL_RATE : ''}
      </TableCellColumn>
      <TableCellColumn style={{ minWidth: 80 }} align="right">
        {!isLoadingVoyages ? totalNoxEmission : ''}
      </TableCellColumn>
      <TableCellColumn align="right"> {!isLoadingVoyages ? totalEstimatedNoxTax : ''} </TableCellColumn>
    </TableRow>
  )
}

export default NoxVesselList
