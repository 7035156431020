import { Divider, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'

import NoxCalculator from '../../../utils/NoxCalculator'
import { Engine } from 'src/lib/apis/types/engine'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailgrid: {
      display: 'grid',
      gridTemplateColumns: '70% 30%',
      marginBottom: 32,
    },
    valueColumn: {
      justifySelf: 'end',
      whiteSpace: 'pre',
    },
    paper: {
      maxWidth: 400,
      padding: 20,
      margin: '0 auto',
    },
    equationGrid: {
      display: 'grid',
      gridTemplateColumns: 'auto 25px max-content',
      gridRowGap: 4,
    },
    textRed: {
      color: theme.palette.error.main,
    },
    textGreen: {
      color: theme.palette.success.main,
    },
    textYellow: {
      color: theme.palette.error.main,
    },
    mediumEmphasis: {
      color: theme.palette.text.secondary,
    },
    normalEmphasis: {
      color: theme.palette.text.primary,
    },
  })
)

interface IEngineConsumptions {
  withLNG: number
  withSCR: number
  withoutSCR: number
  urea: number
}
interface IEngineCalculationsProps {
  engine: Engine
  consumption: IEngineConsumptions
  classes?: any
}

export const EngineCalculationDetails = React.forwardRef((props: IEngineCalculationsProps, ref: any) => {
  const { engine, consumption } = props
  const classes = useStyles()
  const consumptionWithLNG = consumption.withLNG || 0
  const consumptionWithSCR = consumption.withSCR || 0
  const consumptionWithoutSCR = consumption.withoutSCR || 0
  const ureaConsumption = consumption.urea || 0
  const noxFactorLng = engine.noxFactorLng || 0
  const noxFactor = engine.noxFactor || 0
  const scrFactor = engine.scrFactor || 0
  const haveSCR = scrFactor !== 0
  const noxCalculation = NoxCalculator.calculateEngineEmission(
    noxFactorLng,
    noxFactor,
    scrFactor,
    consumptionWithLNG,
    consumptionWithoutSCR,
    consumptionWithSCR,
    ureaConsumption
  )

  return (
    <div {...props} ref={ref} style={{ outline: 'none' }}>
      <Paper className={classes.paper}>
        <Typography variant="h6" style={{ marginBottom: 16 }}>
          Engine emission details
        </Typography>
        <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
          {engine.name}
        </Typography>
          <>
            <div className={classes.detailgrid}>

              {consumptionWithLNG > 0 && (
              <>
                <Typography variant="body1" className={classes.mediumEmphasis}>
                    LNG consumption:
                </Typography>
                <Typography variant="body1" className={classes.valueColumn}>
                    {consumptionWithLNG}t
                </Typography>
              </>
              )}

              <Typography variant="body1" className={classes.mediumEmphasis}>
                Fuel consumption without SCR:
              </Typography>
              <Typography variant="body1" className={classes.valueColumn}>
                {Number(consumptionWithoutSCR.toFixed(2)).toLocaleString()} t
              </Typography>

              {haveSCR && (
                <>
                  <Typography variant="body1" className={classes.mediumEmphasis}>
                    Fuel consumption with SCR:
                  </Typography>
                  <Typography variant="body1" className={classes.valueColumn}>
                    {Number(consumptionWithSCR.toFixed(2)).toLocaleString()} t
                  </Typography>
                  <Typography variant="body1" className={classes.mediumEmphasis}>
                    Urea consumption:
                  </Typography>
                  <Typography variant="body1" className={classes.valueColumn}>
                    {Number(ureaConsumption.toFixed(2)).toLocaleString()} l
                  </Typography>
                </>
              )}

              {consumptionWithLNG > 0 && (
                <>
                  <Typography variant="body1" className={classes.mediumEmphasis}>
                      LNG NOx-factor:
                  </Typography>
                  <Typography variant="body1" className={classes.valueColumn}>
                    {engine.noxFactorLng} kg NOx/t
                  </Typography>
                </>
              )}

              <Typography variant="body1" className={classes.mediumEmphasis}>
                Engine NOx-factor:
              </Typography>
              <Typography variant="body1" className={classes.valueColumn}>
                {(engine.noxFactor && Number(engine.noxFactor.toFixed(2)).toLocaleString()) || '0'} kg NOx/t
              </Typography>

              {haveSCR && (
                <>
                  <Typography variant="body1" className={classes.mediumEmphasis}>
                    Min. NOx-factor with SCR:
                  </Typography>
                  <Typography variant="body1" className={classes.valueColumn}>
                    {(engine.scrFactor && Number(engine.scrFactor.toFixed(2)).toLocaleString()) || '0'} kg NOx/t
                  </Typography>
                </>
              )}
            </div>

            {(consumptionWithLNG > 0 && (
                <div style={{ marginBottom: 16 }}>
                    <Typography className={classes.mediumEmphasis}>LNG emission:</Typography>
                    <Typography className={classes.normalEmphasis}>
                       {consumptionWithLNG}t * {engine.noxFactorLng} kg NOX/t ={' '}
                        {Number((consumptionWithLNG * noxFactorLng).toFixed(2)).toLocaleString()} kg NOx
                    </Typography>
                </div>
            ))}

            <div style={{ marginBottom: 16 }}>
              <Typography className={classes.mediumEmphasis}>Fuel emission:</Typography>
              <Typography className={classes.normalEmphasis}>
                {consumptionWithoutSCR} t * {Number(engine.noxFactor.toFixed(2)).toLocaleString()} kg NOX/t ={' '}
                {Number((consumptionWithoutSCR * noxFactor).toFixed(2)).toLocaleString()} kg NOx
              </Typography>
            </div>

            {noxCalculation.scrTestFailed ? (
              <div style={{ marginBottom: 16 }}>
                <Typography color="error">
                    {ureaConsumption} l Urea exceeds the maximum reduction limit!
                </Typography>
                <Typography color="error">
                    Min. NOx-factor with SCR is used instead.
                </Typography>
                <Typography className={classes.mediumEmphasis}>Fuel emission with SCR:</Typography>
                <Typography className={classes.normalEmphasis}>
                  {consumptionWithSCR} t * {Number(engine.scrFactor.toFixed(2)).toLocaleString()} kg NOX/t ={' '}
                  {Number((consumptionWithSCR * scrFactor).toFixed(2)).toLocaleString()} kg NOx
                </Typography>
              </div>
            ) : (
              <div style={{ marginBottom: 16 }}>
                <Typography className={classes.mediumEmphasis}>Fuel emission with SCR:</Typography>
                <Typography className={classes.normalEmphasis}>
                  {consumptionWithSCR} t * {Number(engine.noxFactor.toFixed(2)).toLocaleString()} kg NOX/t ={' '}
                  {Number((consumptionWithSCR * noxFactor).toFixed(2)).toLocaleString()} kg NOx
                </Typography>
              </div>
            )}

            {!noxCalculation.scrTestFailed && (
              <div style={{ marginBottom: 32 }}>
                <Typography className={classes.mediumEmphasis}>Urea SCR-reduction:</Typography>
                <Typography className={classes.normalEmphasis}>
                  {ureaConsumption} l / 1.5 kg NOx/l = {Number((ureaConsumption / 1.5).toFixed(2)).toLocaleString()} kg NOx
                </Typography>
              </div>
            )}

            <div className={classes.equationGrid}>
              {consumptionWithLNG > 0 && (
                <>
                    <Typography className={classes.mediumEmphasis}>LNG emission</Typography>
                    <Typography style={{ justifySelf: 'center' }}></Typography>
                    <Typography className={classes.normalEmphasis} style={{ justifySelf: 'end' }}>
                        {Number((consumptionWithLNG * noxFactorLng).toFixed(2)).toLocaleString()} kg NOx
                    </Typography>
                </>
              )}

              <Typography className={classes.mediumEmphasis}>Fuel emission</Typography>
              <Typography style={{ justifySelf: 'center' }}></Typography>
              <Typography className={classes.normalEmphasis} style={{ justifySelf: 'end' }}>
                  {Number((consumptionWithoutSCR * noxFactor).toFixed(2)).toLocaleString()} kg NOx
              </Typography>

              <Typography className={classes.mediumEmphasis}>Fuel emission with SCR</Typography>
              <Typography style={{ justifySelf: 'center' }}></Typography>
              <Typography className={classes.normalEmphasis} style={{ justifySelf: 'end' }}>
                {Number(noxCalculation.scrNox.toFixed(2)).toLocaleString()} kg NOx
              </Typography>

              {!noxCalculation.scrTestFailed && (
                <>
                  <Typography className={classes.mediumEmphasis}>SCR-reduction</Typography>
                  <Typography style={{ justifySelf: 'center' }}>-</Typography>
                  <Typography className={classes.normalEmphasis} style={{ justifySelf: 'end' }}>
                    {Number(noxCalculation.scrNoxReduction.toFixed(2)).toLocaleString()} kg NOx
                  </Typography>
                </>
              )}
              <Divider style={{ gridColumn: '1/4' }} />

              <Typography className={classes.normalEmphasis}>Total emission</Typography>
              <Typography style={{ justifySelf: 'center' }}> = </Typography>
              <Typography className={classes.normalEmphasis}>
                {Number(
                  (noxCalculation.nox + noxCalculation.scrNox - noxCalculation.scrNoxReduction).toFixed(2)
                ).toLocaleString()}{' '}
                kg NOx
              </Typography>
              <div style={{ gridColumn: '1/4' }}>
                <Divider style={{ marginBottom: 2 }} />
                <Divider />
              </div>
            </div>
          </>
      </Paper>
    </div>
  )
})

interface IEngineConsumptionProps {
  readOnly: boolean
  engine: Engine
  consumption: any | undefined
  onUpdate: (emission, consumption?) => void
// YAE 25.10.21 -> removed this  onHandleEditEngine: () => void
}

const EngineConsumption = (props: IEngineConsumptionProps) => {
  const { engine, consumption, onUpdate, readOnly } = props
  const [lngConsumption, setLngConsumption] = useState<string>(
      consumption?.lngConsumption ? String(Number(consumption?.lngConsumption).toFixed(2)) : '0'
  )
  const [fuelConsumption, setFuelConsumption] = useState<string>(
    consumption?.fuelConsumption ? String(Number(consumption?.fuelConsumption).toFixed(2)) : '0'
  )
  const [fuelConsumptionScr, setFuelConsumptionScr] = useState<string>(
    consumption?.fuelConsumptionScr ? String(Number(consumption?.fuelConsumptionScr).toFixed(2)) : '0'
  )
  const [ureaConsumption, setUreaConsumption] = useState<string>(
    consumption?.ureaConsumption ? String(Number(consumption?.ureaConsumption).toFixed(2)) : '0'
  )
  const [showCalculations, setShowCalculations] = useState<boolean>(false)
  const [totalEmission, setTotalEmission] = useState<string>('0')

  useEffect(() => {
    const calculated = NoxCalculator.calculateEngineEmission(
      engine.noxFactorLng,
      engine.noxFactor!,
      engine.scrFactor,
      (isNaN(Number(lngConsumption)) ? 0 : Number(lngConsumption)),
      (isNaN(Number(fuelConsumption)) ? 0 : Number(fuelConsumption)),
      (isNaN(Number(fuelConsumptionScr)) ? 0 : Number(fuelConsumptionScr)),
      (isNaN(Number(ureaConsumption)) ? 0 : Number(ureaConsumption)),
    )

    const total = calculated.nox + calculated.scrNox - calculated.scrNoxReduction
    setTotalEmission(total.toFixed(2))

    onUpdate(total, {
      engineId: engine.id,
      lngConsumption,
      fuelConsumption,
      fuelConsumptionScr,
      ureaConsumption,
    })
  }, [lngConsumption, fuelConsumption, fuelConsumptionScr, ureaConsumption, engine, onUpdate])

  const doNothing = (e: any) => {
      e.target.blur()
  }

  const parseValue = (value) => String(isNaN(Number.parseFloat(value)) ? 0 : Number.parseFloat(value))

  const handleUpdateLngConsumption = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLngConsumption(parseValue(event.target.value))
  const handleUpdateFuelConsumption = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFuelConsumption(parseValue(event.target.value))
  const handleUpdatefuelConsumptionScr = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFuelConsumptionScr(parseValue(event.target.value))
  const handleUpdateUreaConsumption = (event: React.ChangeEvent<HTMLInputElement>) =>
    setUreaConsumption(parseValue(event.target.value))
  const handleOpenCalculation = (event: React.MouseEvent) => setShowCalculations(true)
  const handleCloseCalculation = () => setShowCalculations(false)

  return (
    <div
      style={{
        marginLeft: 16,
        marginRight: 16,
      }}
    >
      <Dialog fullScreen={true} open={showCalculations} onClose={handleCloseCalculation}>
        <EngineCalculationDetails
          engine={engine}
          consumption={{
            withLNG: Number(lngConsumption),
            withSCR: Number(fuelConsumptionScr),
            withoutSCR: Number(fuelConsumption),
            urea: Number(ureaConsumption),
          }}
        />
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Button variant="contained" onClick={handleCloseCalculation} color="primary">
            Close
          </Button>
        </div>
      </Dialog>

      {/* Only display engine details if noxFactor or noxFactorLng has value */}
      { ( (engine.noxFactor && engine.noxFactor > 0) || (engine.noxFactorLng && engine.noxFactorLng > 0) ) && (
      <>
          <Typography style={{ marginBottom: 16, marginRight: 32 }} component="span">
            {engine.name} ({engine.id})
          </Typography>
    {/* YAE 25.10.21 :: Disallow edit engine factors here to reduce complexity / number of state handling levels
          <Typography style={{ fontSize: "small" }} color="primary" component="a" onClick={props.onHandleEditEngine}>
            <u>Edit engine</u>
          </Typography>
    */}
          <div style={{marginLeft: 16}}>
            {engine.noxFactorLng && engine.noxFactorLng > 0 ? (
              <TextField
                  disabled={readOnly}
                  style={{ marginBottom: 16, marginTop: 16 }}
                  fullWidth={true}
                  label={"LNG consumption (NOx-factor: " +
                        Number(engine.noxFactorLng?.toFixed(2)).toLocaleString() + ' kg NOx/t)'}
                  placeholder=""
                  value={lngConsumption}
                  type = "number"
                  onChange={handleUpdateLngConsumption}
                  onWheel={doNothing}
                  InputProps={{
                      endAdornment: <InputAdornment position="end">tonnes</InputAdornment>,
                  }}
                  InputLabelProps={{
                      shrink: true,
                  }}
              />
            ) : ('')}
            {engine.noxFactor && engine.noxFactor > 0 ? (
                <TextField
                  disabled={readOnly}
                  style={{ marginBottom: 16, marginTop: 16 }}
                  fullWidth={true}
                  label={"Fuel consumption - no SCR  (NOx-factor: " +
                        Number(engine.noxFactor?.toFixed(2)).toLocaleString() + ' kg NOx/t)'}
                  placeholder=""
                  value={fuelConsumption}
                  type = "number"
                  onChange={handleUpdateFuelConsumption}
                  onWheel={doNothing}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">tonnes</InputAdornment>,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            ) : ('')}
            {engine.scrFactor && engine.scrFactor > 0 ? (
              <>
                <TextField
                  disabled={readOnly}
                  style={{ marginBottom: 16 }}
                  fullWidth={true}
                  label={"Fuel consumption - with SCR  (NOx-factor: "+
                        Number(engine.noxFactor?.toFixed(2)).toLocaleString() +" / Min. NOx-factor: " +
                        Number(engine.scrFactor.toFixed(2)).toLocaleString() + ')'}
                  placeholder=""
                  value={fuelConsumptionScr}
                  type = "number"
                  onChange={handleUpdatefuelConsumptionScr}
                  onWheel={doNothing}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">tonnes</InputAdornment>,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  disabled={readOnly}
                  style={{ marginBottom: 16 }}
                  fullWidth={true}
                  label="Urea consumption"
                  placeholder=""
                  value={ureaConsumption}
                  type = "number"
                  onChange={handleUpdateUreaConsumption}
                  onWheel={doNothing}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">liters</InputAdornment>,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            ) : ('')}
            <div style={{ marginBottom: 32 }}>
              <Typography style={{ marginRight: 32 }} component="span">
                NOx: {Number(Number(totalEmission).toFixed(2)).toLocaleString()} kg{' '}
              </Typography>
              { Number(totalEmission) > 0 && <Typography component="a" color="primary" style={{ fontSize: "small" }} onClick={handleOpenCalculation}>
                <u>Show calculation</u>
              </Typography> }
            </div>
      </div>
      </>
      )}
    </div>
  )
}

export default EngineConsumption
