import { Link, Typography } from '@material-ui/core'
import React from 'react'
import { IReleaseNoteProps } from './ReleaseNote1'

const ReleaseNote3 = (props: IReleaseNoteProps) => {
  return (!props.english) ? (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q3-2022
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Tolkning av turer
      </Typography>
      <Typography variant="body1">
        Korrigert håndtering av spesialskip mellom utenlandsk havn og installasjon, samt 'Period start' før utenlandsk havn og utenlandsk havn før 'Period end'.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Se også <Link style={{cursor: "pointer", color:'lightskyblue'}}><span onClick={props.openTaxRules}>"Tolkning av regelverket"</span></Link> for detaljer.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Utenlandske havner
      </Typography>
      <Typography variant="body1">
        I denne versjonen er det 33 utenlandske havner:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Gi oss beskjed om hvilke andre utenlandshavner dere trenger, så vil vi oppdatere med de viktigste fortløpende.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        NOx Voyages
      </Typography>
      <Typography variant="body1">
        Det er lagt til en forhåndsevaluering av skattbarhet for kvartalet. Båter som ikke antas å være i skatteposisjon markeres med dempet skrift i listen.
        Vær oppmerksom på at dette er tidkrevende, så hvis det er en stor flåte kan prosessen ta et par minutter å fullføre.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        <b>NB!</b>&nbsp;&nbsp;Dette er ikke en full evaluering, kun en grov sortering på detekterte bevegelser i nox-sonen!
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        Det er nå mulig å velge "Include all voyages" i charter report slik at fuel- og urea-forbruk kan inkluderes også for ikke-skattbare turer.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Utskriftsversjonen er forbedret med hensyn til marger og kolonner, så den er nær identisk med skjermversjonen.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Vessel Details
      </Typography>
      <Typography variant="body1">
        En ny funksjon er lagt til: "Verify Maress availability".
      </Typography>
      <Typography variant="body1">
        Denne sjekker om det finnes Maress aktiviteter for hele flåten og vil oppdatere Maress-status ikonet på hver enkelt båt.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Generelle forbedringer
      </Typography>
      <Typography variant="body1">
        Tidvis genererte evalueringen turer med samme start- og stopp-tid. Dette er nå fjernet.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        DP+ mode er lagt til i modellen og er sidestilt med DP i evalueringen.
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q3-2022
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Tax rule interpretation
      </Typography>
      <Typography variant="body1">
        Improved handling of Special vessels between foreign ports and installations, and 'Period start' prior to foreign port and foreign port before 'Period end'.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        See also <Link style={{cursor: "pointer", color:'lightskyblue'}}><span onClick={props.openTaxRules}>"Tax rule interpretations"</span></Link> for details.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Foreign ports
      </Typography>
      <Typography variant="body1">
        This version has 33 foreign ports:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Let us know which other ports are needed, and we will add the most frequently requested.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        NOx Voyages
      </Typography>
      <Typography variant="body1">
        We have added a taxability evaluation for the selected quarter. Vessels will have toned-down text in the list if they are presumed non-taxable.
        Note that this is a time-consuming exercise, for a large fleet the process could take minutes to complete.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        <b>NB!</b>&nbsp;&nbsp;This is not a full evaluation, only a rough detection of movements within the nox-zone!
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        You can now choose "Include all voyages" in the charter report so fuel- and urea-cost can be included even for non-taxable voyages.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        The print version has been improved regarding margins and columns, and is now near identical to the on-screen version.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Vessel Details
      </Typography>
      <Typography variant="body1">
        A new function has been added: "Verify Maress availability".
      </Typography>
      <Typography variant="body1">
        This will check the entire fleet for Maress activities, and update the Maress-status icon accordingly.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        General improvements
      </Typography>
      <Typography variant="body1">
        Occasionally our evaluation would generate voyages with identical start- and stop-times. This is now removed.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        DP+ mode has been added to the model and will be treated identically to DP in the evaluation.
      </Typography>
    </>
  )}

export default ReleaseNote3