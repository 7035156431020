import { AxiosInstance, AxiosResponse } from "axios"
import { Engine, EngineForm, EngineId } from "./types/engine"
import { NoxPeriodId } from "./types/noxPeriod"
import { VesselForm, VesselId, VesselPeriod, VesselCharterReport, VesselPeriodForm, VesselWithEngines, VesselInNoxZone } from "./types/vessel"

export default class VesselsAPI {
  httpClient: AxiosInstance
  baseUrl: string

  constructor(httpClient: AxiosInstance, baseUrl: string) {
    this.httpClient = httpClient
    this.baseUrl = baseUrl
  }

  async getVessels(): Promise<VesselWithEngines[]> {
    const response = await this.httpClient.get<VesselWithEngines[]>(`${this.baseUrl}/vessels`)
    return response.data
  }

  async getVesselInNoxZoneStatus(id: VesselId, timeFrom?: string, timeTo?: string): Promise<[VesselId, boolean]> {
    const fromParam = typeof timeFrom !== 'undefined' ? `time_from=${timeFrom}&` : ''
    const toParam = typeof timeTo !== 'undefined' ? `time_to=${timeTo}` : ''
    const queryParams = fromParam + toParam
    const response = await this.httpClient.get<VesselInNoxZone>(`${this.baseUrl}/vessels/${id}/noxzone?${queryParams}`)
    return [id, response.data.inside]
  }

  async setVesselInMaressStatus(id: VesselId): Promise<void> {
    await this.httpClient.post<void>(`${this.baseUrl}/vessels/${id}/maress`)
    return
  }

  async searchVessels(q: string): Promise<VesselWithEngines[]> {
    const response = await this.httpClient.get<VesselWithEngines[]>(`${this.baseUrl}/vessels/search?q=${q}`)
    return response.data
  }

  async getVesselsPeriods(periodId?: NoxPeriodId): Promise<VesselPeriod[]> {
    const response = await this.httpClient.get<VesselPeriod[]>(`${this.baseUrl}/vessels-periods?period_id=${typeof periodId !== 'undefined' ? periodId : ''}`)
    return response.data
  }

  async addVessel(form: VesselForm): Promise<void> {
    await this.httpClient.post(`${this.baseUrl}/vessels`, form)
  }

  async getVessel(id: VesselId): Promise<VesselWithEngines> {
    const response: AxiosResponse = await this.httpClient.get(`${this.baseUrl}/vessels/${id}`)
    return response.data
  }

  async updateVessel(id: VesselId, form: VesselForm): Promise<void> {
    await this.httpClient.put(`${this.baseUrl}/vessels/${id}`, form)
    return
  }
  async removeVessel(id: VesselId): Promise<void> {
    await this.httpClient.delete(`${this.baseUrl}/vessels/${id}`)
    return
  }

  async getVesselPeriods(id: VesselId, periodId?: NoxPeriodId): Promise<VesselPeriod[]> {
    if(typeof periodId == 'undefined') {
      const response: AxiosResponse = await this.httpClient.get(`${this.baseUrl}/vessels/${id}/periods`)
      return response.data
    } else {
      const response: AxiosResponse = await this.httpClient.get(`${this.baseUrl}/vessels/${id}/periods/${periodId}`)
      return response.data
    }
    
  }

  async createVesselPeriod(id: VesselId, form: VesselPeriodForm): Promise<void> {
    await this.httpClient.post(`${this.baseUrl}/vessels/${id}/periods`, form)
    return
  }

  async updateVesselPeriod(id: VesselId, periodId: NoxPeriodId, form: VesselPeriodForm): Promise<void> {
    await this.httpClient.put(`${this.baseUrl}/vessels/${id}/periods/${periodId}`, form)
    return
  }

  async createVesselCharterReport(id: VesselId, form: VesselCharterReport): Promise<void> {
    await this.httpClient.post(`${this.baseUrl}/vessels/${id}/charter-reports`, form)
    return
  }

  async removeVesselCharterReport(id: VesselId, charterReportId: string): Promise<void> {
    await this.httpClient.delete(`${this.baseUrl}/vessels/${id}/charter-reports/${charterReportId}`)
    return
  }

  async getVesselCharterReports(id: VesselId, after: string): Promise<VesselCharterReport[]> {
    const response: AxiosResponse = await this.httpClient.get(`${this.baseUrl}/vessels/${id}/charter-reports?after=${after}`)
    return response.data
  }

  async getEngines(id: VesselId): Promise<Engine[]> {
    const response: AxiosResponse = await this.httpClient.get(`${this.baseUrl}/vessels/${id}/engines`)
    return response.data
  }

  async createEngine(id: VesselId, form: EngineForm): Promise<void> {
    await this.httpClient.post(`${this.baseUrl}/vessels/${id}/engines`, form)
    return
  }
  
  async getEngine(id: VesselId, engineId: EngineId): Promise<Engine> {
    const response: AxiosResponse = await this.httpClient.get(`${this.baseUrl}/vessels/${id}/engines/${engineId}`)
    return response.data
  }

  async updateEngine(id: VesselId, engineId: EngineId, form: EngineForm): Promise<void> {
    await this.httpClient.put(`${this.baseUrl}/vessels/${id}/engines/${engineId}`, form)
    return
  }

  async deleteEngine(id: VesselId, engineId: EngineId): Promise<void> {
    await this.httpClient.delete(`${this.baseUrl}/vessels/${id}/engines/${engineId}`)
    return
  }


}