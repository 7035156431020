import { Typography } from '@material-ui/core'
import { CharterDataHeaderProps } from './CharterReport.types'
import { HeaderWrapper, RowWrapper } from './CharterDataHeader.styles'

const CharterDataHeader = (props: CharterDataHeaderProps) => {
  const { vesselId, vesselName, charterBy, printable, additionalText } = props

  const isPrintableView = printable ? { color: 'black', padding: '0 1em' } : { padding: '0 1em' }

  return (
    <HeaderWrapper style={isPrintableView}>
      <RowWrapper>
        <Typography variant="body1">Vessel:</Typography>
        <Typography variant="body1">{vesselId === '' ? 'Please select a vessel' : vesselName} </Typography>
      </RowWrapper>
      <RowWrapper>
        <Typography variant="body1" display="inline">
          Chartered by:{' '}
        </Typography>
        <Typography variant="body1" display="inline">
          {charterBy ? charterBy?.toLocaleUpperCase() : 'Not specified'}
        </Typography>
      </RowWrapper>
      {additionalText ? (
        <>
          <Typography variant="body1">Details:</Typography>
          <pre>
            <Typography variant="body1">{additionalText}</Typography>
          </pre>
        </>
      ) : (
        ''
      )}
    </HeaderWrapper>
  )
}

export default CharterDataHeader
