import {Paper} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import {useState} from "react"
import Dialog from "@material-ui/core/Dialog"
import {useHistory} from "react-router-dom"
import {makeStyles} from "@material-ui/core/styles"
import {Theme} from "@material-ui/core/styles/createMuiTheme"
import createStyles from "@material-ui/core/styles/createStyles"
import {IconButton} from "@mui/material"
import EditVoyageIcon from "@material-ui/icons/EditOutlined"
import EditEventIcon from "@material-ui/icons/EditLocationOutlined"
import CommittedIcon from '@mui/icons-material/EditOffOutlined'
import ArrowRed from "../../../assets/icons/arrow_red.svg"
import { useApis } from "src/lib/ApiProvider"
import { useMutation } from 'react-query'
import { VesselId } from "src/lib/apis/types/vessel"

interface IOverrideDeleteHandler {
    voyages: any[]
    currentVoyageId: number
    vesselId: VesselId
    readOnly: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: 400,
            padding: 20,
            margin: '0 auto',
        },
        mediumEmphasis: {
            color: theme.palette.text.secondary,
        },
        normalEmphasis: {
            color: theme.palette.text.primary,
        },
        infoGrid: {
            display: 'grid',
            gridTemplateColumns: '30px 100% auto',
            gridAutoRows: 'minmax(max-content, 20px)',
            margin: '0 auto',
        },
        materialIcons: {
            color: '#64C8FF',
            marginTop: -8,
        },
    })
)

const OverrideDeleteHandler = (props: IOverrideDeleteHandler) => {
    const classes = useStyles()
    const {voyages, currentVoyageId, vesselId, readOnly} = props
    const currentIndex = voyages?.findIndex(v => v.id === currentVoyageId)
    const currentVoyage = currentIndex >= 0 ? voyages[currentIndex] : null
    const hasVoyageOverride = currentVoyage.overrides !== null
    const hasEventOverride = currentVoyage.events[0].source === "user" || currentVoyage.events[1].source === "user"
    const isUndefined = currentVoyage.taxable === null

    const { apis } = useApis()

    //YAE 24.02.22 -> controls for override handling
    const [showVoyageOverride, setShowVoyageOverride] = useState<boolean>(false)
    const [showEventOverride, setShowEventOverride] = useState<boolean>(false)
    const history = useHistory()

    const { mutate: deleteOverride } = useMutation(
        ['deleteOverride', vesselId],
        async (ts: String) => { return await apis.voyagesApi.deleteOverride(vesselId, ts)}
    )

    //YAE 23.02.22 -> Need to handle overrides here based on users action
    const handleVoyageOverride = () => {
            setShowVoyageOverride(true)
        }
    const handleEventOverride = () => {
        setShowEventOverride(true)
    }

    const handleCloseVoyageOverride = () => {
        setShowVoyageOverride(false)
    }

    const handleSavedVoyageOverride = () => {
//        console.log("pushing history")
        history.push('/vessel/' + vesselId + '/voyages')
    }

    const handleCloseEventOverride = () => {
        setShowEventOverride(false)
    }

    const handleSavedEventOverride = () => {
//        console.log("pushing history")
        history.push('/vessel/' + vesselId + '/voyages')
    }

    const VoyageOverrideDetails = ((props: any) => {
        const {voyages, currentIndex} = props
        const voyage = voyages[currentIndex]

        const deleteVoyageOverride = () => {
            deleteOverride(voyage.timeStart)
            handleSavedVoyageOverride()
        }

        return (
            <div style={{outline: 'none'}}>
                <Paper className={classes.paper}>
                    <Typography variant="h6" style={{marginBottom: 16}}>
                        Voyage details
                    </Typography>
                    <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
                        Taxable status and/or fuel consumption have been manually changed for this voyage.
                    </Typography>
                    <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
                        NOTE: A reset may affect other, adjacent voyages.
                        The reset action will return you to the voyage overview.
                        &nbsp;
                    </Typography>
                    <Typography variant="body1" className={classes.mediumEmphasis}>
                        Click the "Reset to original" button below to reset this voyage to original status/values.
                    </Typography>
                    <div style={{marginTop: 32, marginBottom: 32 }} >
                        <Button style={{marginRight: 32 }} variant="outlined" onClick={deleteVoyageOverride} color="secondary">
                            Reset to original
                        </Button>
                        <Button variant="contained" onClick={handleCloseVoyageOverride} color="primary">
                            Close
                        </Button>
                    </div>
                </Paper>
            </div>
        )
    })

    const EventOverrideDetails = ((props: any) => {
        const {voyages, vesselId, currentIndex} = props
        let voyage = voyages[currentIndex]

        const { apis } = useApis()


        const { mutate: deleteEvent } = useMutation(
            ['deleteOverride', vesselId],
            async (ts: String) => { return await apis.voyagesApi.deleteEvent(vesselId, ts)}
        )

        const deleteSingleEventOverride = (timestamp: string) => {
            deleteEvent(timestamp)
        }

        //Identify which one of four cases that could have got us here
        const eventTypeChange = voyage.events[0].name.toLowerCase().substr(0,6) === 'period' && voyage.events[1].name.toLowerCase().substr(0,6) === 'period'
                                    ? 'fullperiod'
                                    : voyage.events[0].name.toLowerCase().substr(0,6) === 'period'
                                        ? 'start'
                                        : voyage.events[1].name.toLowerCase().substr(0,6) === 'period'
                                            ? 'end'
                                            : 'mid'

        const deleteEventOverrides = () => {
            if (eventTypeChange === 'fullperiod') {
                //For the off chance that the period has only one voyage, it will have both a period start and a period end event
                deleteSingleEventOverride(voyage.events[0].timestamp)
                deleteSingleEventOverride(voyage.events[1].timestamp)
            }
            //one-sided start- and end-events are easy - they only have one end to worry about.
            if (eventTypeChange === 'start') {
                deleteSingleEventOverride(voyage.events[0].timestamp)
            }
            if (eventTypeChange === 'end') {
                deleteSingleEventOverride(voyage.events[1].timestamp)
            }
            // mid-events, however, will have a 'source: user' tag in event[0], event[1] or both and user may have selected any one of max three voyages involved
            // we have all voyages at hand, and can locate and delete the events with source:user.
            if (eventTypeChange === 'mid') {
                console.log('these three: ', voyages[currentIndex-1], voyages[currentIndex], voyages[currentIndex+1])
                if(voyage.events[0].source === 'user' && voyage.events[1].source === 'user') {
                    // user chose the middle one, delete both ends and we're done
                    deleteSingleEventOverride(voyage.events[0].timestamp)
                    deleteSingleEventOverride(voyage.events[1].timestamp)
                } else {
                    // check which side we're on, then traverse to next or previous voyage accordingly to delete the other
                    if(voyage.events[1].source === 'user') {
                        // user has picked the first leg, so delete its event override and go to next voyage
                        deleteSingleEventOverride(voyage.events[1].timestamp)
                        voyage = voyages[currentIndex + 1]
                    } else if(voyage.events[0].source === 'user') {
                        // user has picked the last leg, so delete its event override and go to previous voyage
                        deleteSingleEventOverride(voyage.events[0].timestamp)
                        voyage = voyages[currentIndex - 1]
                    }
                    // the current voyage could now have a match on one or both of its events
                    if(voyage.events[1].source === 'user') {
                            deleteSingleEventOverride(voyage.events[1].timestamp)
                        }
                    if(voyage.events[0].source === 'user') {
                        deleteSingleEventOverride(voyage.events[0].timestamp)
                    }
                }
            }
            handleSavedEventOverride()
        }

        return (
            <div style={{outline: 'none'}}>
                <Paper className={classes.paper}>
                    <Typography variant="h6" style={{marginBottom: 16}}>
                        Voyage details
                    </Typography>
                    <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
                        The original voyage has been altered by the user, with an additional {eventTypeChange}-point definition.
                    </Typography>
                    <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
                        NOTE: A reset may affect other, adjacent voyages.
                        The reset action will return you to the voyage overview.
                        &nbsp;
                    </Typography>
                    <Typography variant="body1" className={classes.mediumEmphasis}>
                        Click the "Reset to original" button below to reset this voyage to its original state.
                    </Typography>
                    <div style={{marginTop: 32, marginBottom: 32 }} >
                        <Button style={{marginRight: 32 }} variant="outlined" onClick={deleteEventOverrides} color="secondary">
                            Reset to original
                        </Button>
                        <Button variant="contained" onClick={handleCloseEventOverride} color="primary">
                            Close
                        </Button>
                    </div>
                </Paper>
            </div>
        )
    })

    if ( !readOnly && (hasEventOverride || hasVoyageOverride) ) { //We have something to tell that may need user interaction
        return (
            <div style={{ marginLeft: 16, marginBottom: 32}}>
                    {hasVoyageOverride && (
                        <div className={classes.infoGrid}>
                            <IconButton onClick={handleVoyageOverride}>
                                <EditVoyageIcon className={classes.materialIcons}/>
                            </IconButton>
                            <Typography  component="span" variant="body1">
                                Voyage details have been modified by user. Click on icon to reset.
                            </Typography>
                        </div>
                    )}
                    {hasEventOverride && (
                        <div className={classes.infoGrid}>
                            <IconButton onClick={handleEventOverride}>
                                <EditEventIcon className={classes.materialIcons}/>
                            </IconButton>
                            <Typography component="span" variant="body1">
                                Additional location info has been added by user. Click on icon to reset.
                            </Typography>
                        </div>
                    )}

                <Dialog fullScreen={true} open={showVoyageOverride} onClose={handleCloseVoyageOverride}>
                    <div style={{textAlign: 'center', marginTop: 100, width: '100%'}}>
                        <VoyageOverrideDetails voyages={voyages} currentIndex={currentIndex}/>
                    </div>
                </Dialog>
                <Dialog fullScreen={true} open={showEventOverride} onClose={handleCloseEventOverride}>
                    <div style={{textAlign: 'center', marginTop: 100, width: '100%'}}>
                        <EventOverrideDetails voyages={voyages} vesselId={vesselId} currentIndex={currentIndex}/>
                    </div>
                </Dialog>
            </div>
        )
    } else if(readOnly) {
        return (
            <div style={{ marginLeft: 16, marginBottom: 16, marginTop: -16}}>
                <CommittedIcon style={{ color: '#aaaaaa' }} fontSize="medium" />
                <Typography style={{ verticalAlign: "top" }} component="span" variant="body1">
                    &nbsp;&nbsp;Voyage is archived.
                </Typography>
            </div>
        )
    } else if(isUndefined) { //Just tell user that this is undefined and suggest action
        return (
            <div style={{ marginLeft: 16, marginBottom: 32}}>
                <Typography  component="span" variant="body1">
                    <img style={{ margin: '.3em 0 0 .5em', width: 33 }} src={ArrowRed} alt="Red tail" />
                    &nbsp;&nbsp;Taxable status is Undefined. Try to "Add a missing location" to improve the evaluation of this and connected voyages.
                </Typography>
            </div>
        )
    } else {
        return null
    }
}

export default OverrideDeleteHandler