export class NumberFormat {
  num: number | null = null

  // https://blog.abelotech.com/posts/number-currency-formatting-javascript/
  largeNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }

  currency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
}
