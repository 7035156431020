import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import { useAppContext } from 'common/GriegProvider/wrappers/AppContextWrapper'
import React, { useLayoutEffect, useState } from 'react'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useApis } from 'src/lib/ApiProvider'
import { AisStatic } from 'src/lib/apis/types/ais'
import { EngineForm } from 'src/lib/apis/types/engine'
import { Lucene } from 'src/lib/apis/types/lucene'
import { VesselForm, VesselId, VesselNoxRate, VesselType } from 'src/lib/apis/types/vessel'

import EngineConfig from './components/EngineConfig'
import VesselInfo from './components/VesselInfo'
import VesselSearch from './components/VesselSearch'

export interface IConfig {
  id: string
  name: string
  amount: string
  system: string
  nox: string
  noxScr?: string
  noxLng: string
  noxScrLng?: string
}

type Pages = 'search' | 'info' | 'engineconfig'

const AddVessel: React.FC<GriegRouteProps> = (props) => {
  const [selectedPage, setSelectedPage] = useState<Pages>('search')
  const [vesselSearchValue, setVesselSearchValue] = useState<string>('')
  const [selectedVessel, setSelectedVessel] = useState<Lucene.Source<AisStatic.Data> | undefined>()
  const [selectedRate, setSelectedRate] = useState<VesselNoxRate>('rate-high')
  const [selectedType, setSelectedType] = useState<VesselType | undefined>()
  const [engineName, setEngineName] = useState<string>('')
  const [engineAmount, setEngineAmount] = useState<string>('')
  const [system, setSystem] = useState<string>('')
  const [noxFactor, setNoxFactor] = useState<string>('')
  const [noxFactorScr, setNoxFactorScr] = useState<string>('')
//YAE 27.10.21 -> Added lng handling x2
  const [noxFactorLng, setNoxFactorLng] = useState<string>('')
  const [noxFactorScrLng, setNoxFactorScrLng] = useState<string>('')
  const [configs, setConfigs] = useState<IConfig[]>([])
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [selectedConfig, setSelectedConfig] = useState<string>('')
  const [configStatus, setConfigStatus] = useState<'show' | 'add' | 'edit'>('show')
  const [infoNoxRate, setInfoNoxRate] = useState<boolean>(true)
  const [infoEngines, setInfoEngines] = useState<boolean>(false)
  const [infoSetup, setInfoSetup] = useState<boolean>(false)
  const [infoSystem, setInfoSystem] = useState<boolean>(false)
  const [infoNoxFactor, setInfoNoxFactor] = useState<boolean>(false)
  const { apis } = useApis()
  const history = useHistory()
  const { route } = props
  const { setRouteOptionsCallback } = useAppContext()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))

  const queryClient = useQueryClient()

  const { 
    mutate: addVesselMutation,
  } = useMutation(
    'addVessel',
    async (form: VesselForm) => { return await apis.vesselsApi.addVessel(form)},
    {
      onSuccess: () => {
        queryClient.invalidateQueries('vessels')
        queryClient.invalidateQueries('vesselPeriods')
        if (!!selectedVessel) {
          configs.forEach((config) => {
            addEngineMutation({
              id: selectedVessel?.id,
              form: {
                name: config.name,
                noxFactor: Number(config.nox),
                scrFactor: Number(config.noxScr),
                noxFactorLng: Number(config.noxLng),
              },
            })
          })
        }
      }
    }
    )

  const { 
    mutate: addEngineMutation
   } = useMutation(async (args: {id: VesselId, form: EngineForm}) => {
    await apis.vesselsApi.createEngine(args.id, args.form)
  })


  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps

  const {data: vessels} = useQuery<Lucene.Source<AisStatic.Data>[], Error>(
    ['vessel-search', vesselSearchValue], 
    () => apis.searchitApi.searchVessels(vesselSearchValue),
    { 
      keepPreviousData : true, 
      enabled: typeof vesselSearchValue !== undefined && vesselSearchValue.length >= 3 }
    )


  const handleInputChange = (value: string) => {
    setVesselSearchValue(value)
  }

  const handleVesselSelect = (id: string) => {
    const vessel = vessels?.find((v) => v.id === id)
    setSelectedVessel(vessel)
    setSelectedPage('info')
  }

  const navigateToPageHandler = (pageNo: number, vessel?: any) => {
    if (vessel) {
      setSelectedVessel(vessel)
    }
    setConfigStatus(pageNo === 2 ? 'add' : 'show')

    if (pageNo === 0) {
      setSelectedPage('search')
    } else if (pageNo === 1) {
      setSelectedPage('info')
    } else if (pageNo === 2) {
      setSelectedPage('engineconfig')
    }
  }

  const handleDrawerToggle = (open: boolean, id?: string) => {
    setSelectedConfig(id || selectedConfig)
    setOpenDrawer(open)
  }

  const handleConfigEdit = () => {
    setSelectedPage('engineconfig')
    const currentConfig = configs.filter((c) => c.id === selectedConfig)[0]
    setEngineName(currentConfig.name)
    setEngineAmount(currentConfig.amount)
    setSystem(currentConfig.system)
    setNoxFactor(currentConfig.nox)
    setNoxFactorScr(currentConfig.noxScr || '')
    setNoxFactorLng(currentConfig.noxLng)
    setNoxFactorScrLng(currentConfig.noxScrLng || '')
    setConfigStatus('edit')
  }

  const handleConfigDelete = () => {
    setConfigs(configs.filter((c) => c.id !== selectedConfig))
  }

  const handleAddVessel = () => {
    if (!!selectedVessel) {
      addVesselMutation(
        {
          imo: selectedVessel.payload.imo ?? 0,
          mmsi: selectedVessel.payload.mmsi,
          name: selectedVessel.name,
          length: selectedVessel.payload.dims ? selectedVessel.payload.dims[0] + selectedVessel.payload.dims[1] : 0,
          width: selectedVessel.payload.dims ? selectedVessel.payload.dims[2] + selectedVessel.payload.dims[3] : 0,
          noxRate: selectedRate,
          vesselType: selectedType
        }
      )
      history.push('/vessels')
    }
  }

  const handleInfoToggle = (key: string) => {
    switch (key) {
      case 'noxRate':
        setInfoNoxRate(!infoNoxRate)
        break
      case 'engines':
        setInfoEngines(!infoEngines)
        break
      case 'setup':
        setInfoSetup(!infoSetup)
        break
      case 'system':
        setInfoSystem(!infoSystem)
        break
      case 'noxFactor':
        setInfoNoxFactor(!infoNoxFactor)
        break
      default:
        break
    }
  }

  const handleConfigChange = (key: string, value: string) => {
    switch (key) {
      case 'engineName':
        setEngineName(value)
        break
      case 'engineAmount':
        setEngineAmount(value)
        break
      case 'system':
        setSystem(value)
        break
      case 'noxFactor':
        setNoxFactor(value)
        break
      case 'noxFactorScr':
        setNoxFactorScr(value)
        break
      case 'noxFactorLng':
        setNoxFactorLng(value)
        break
      case 'noxFactorScLng':
        setNoxFactorScrLng(value)
        break
      default:
        break
    }
  }

  const handleRateAndTypeChange = (noxRate: VesselNoxRate, vesselType?: VesselType) => {
    setSelectedRate(noxRate)
    setSelectedType(vesselType)
    //Doing exactly what the doctor ordered
  }

  const handleConfigLeave = (action: 'cancel' | 'complete') => {
    if (action === 'complete') {
      const newConfig: IConfig = {
        id: `engine-${engineName}`,
        name: engineName,
        amount: engineAmount,
        system,
        nox: noxFactor,
        noxScr: noxFactorScr,
        noxLng: noxFactorLng,
        noxScrLng: noxFactorScrLng,
      }
      if (configStatus === 'add') {
        setConfigs([...configs, newConfig])
      } else {
        const currentConfig = configs.find((c) => c.id === selectedConfig)
        if (currentConfig) {
          configs[configs.indexOf(currentConfig)] = newConfig
        }
      }
    }
    setEngineName('')
    setEngineAmount('')
    setSystem('')
    setNoxFactor('')
    setNoxFactorScr('')
    setSelectedPage('info')
    setConfigStatus('show')
  }

  const vesselDetails = selectedVessel ? {
    mmsi: selectedVessel.payload.mmsi,
    imo: selectedVessel.payload.imo ?? 0,
    name: selectedVessel.name,
    enabledMaress: false,
    length: selectedVessel.payload.dims ? selectedVessel.payload.dims[0] + selectedVessel.payload.dims[1] : 0,
    width: selectedVessel.payload.dims ? selectedVessel.payload.dims[2] + selectedVessel.payload.dims[3] : 0
  } : undefined

  return (
    (
      <>
        {selectedPage === 'search' && (
          <VesselSearch
            vessels={vessels}
            vesselSearchValue={vesselSearchValue}
            onInputChange={handleInputChange}
            onVesselSelect={handleVesselSelect}
          />
        )}
        {selectedPage === 'info' && (
          <VesselInfo
            vesselDetails={vesselDetails}
            configs={configs}
            selectedRate={selectedRate}
            selectedVesselType={selectedType}
            onRateOrClassChange={handleRateAndTypeChange}
            onNameOrMMSIChange={(()=>{})}
            onConfigStart={navigateToPageHandler}
            openDrawer={openDrawer}
            onDrawerToggle={handleDrawerToggle}
            onConfigEdit={handleConfigEdit}
            onConfigDelete={handleConfigDelete}
            infoNoxRate={infoNoxRate}
            infoEngines={infoEngines}
            onInfoToggle={handleInfoToggle}
            onCompleted={handleAddVessel}
          />
        )}
        {selectedPage === 'engineconfig' && (
          <EngineConfig
            action={configStatus}
            onChange={handleConfigChange}
            onLeave={handleConfigLeave}
            engineName={engineName}
            // engineAmount={engineAmount} S.H. Removed Engine amount selection
            system={system}
            noxFactor={noxFactor}
            noxFactorScr={noxFactorScr}
            hasLng={''}
            noxFactorLng={noxFactorLng}
            noxFactorScrLng={noxFactorScrLng}
            infoSetup={infoSetup}
            infoSystem={infoSystem}
            infoNoxFactor={infoNoxFactor}
            onInfoToggle={handleInfoToggle}
          />
        )}
      </>
    )) ||
    null
  
}

export default AddVessel


