import { AxiosInstance, AxiosResponse } from "axios"
import { EventOverride } from "./types/event"
import { NoxPeriodId } from "./types/noxPeriod"
import { VesselId } from "./types/vessel"
import { VoyageOverride, VoyageWithOverride } from "./types/voyage"

export default class VoyagesAPI {
  httpClient: AxiosInstance
  baseUrl: string

  constructor(httpClient: AxiosInstance, baseUrl: string) {
    this.httpClient = httpClient
    this.baseUrl = baseUrl
  }
  
  async getVoyages(id: VesselId, live?: boolean, periodId?: NoxPeriodId, timeFrom?: string, timeTo?: string, slim?:boolean, delay?: number): Promise<VoyageWithOverride[]> {
    function waitMs(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
    const liveParam = typeof live !== 'undefined' ? `live=${live}&` : ''
    const periodParam = typeof periodId !== 'undefined' ? `period_id=${periodId}&` : ''
    const fromParam = typeof timeFrom !== 'undefined' ? `time_from=${timeFrom}&` : ''
    const toParam = typeof timeTo !== 'undefined' ? `time_to=${timeTo}&` : ''
    const slimParam = typeof slim === 'boolean' && slim ? `slim=true` : ''
    const queryParams = liveParam + periodParam + fromParam + toParam + slimParam
    const delayParam = typeof delay === 'number' && delay > 0 ? delay : 0
    if (delayParam > 0) {
      await waitMs(delayParam)
    }
    const response: AxiosResponse = await this.httpClient.get(`${this.baseUrl}/vessels/${id}/voyages?${queryParams}`)
    return response.data
  }

  async createEvent(id: VesselId, form: EventOverride): Promise<void> {
    await this.httpClient.post(`${this.baseUrl}/vessels/${id}/voyages/events`, form)
    return
  }

  async deleteEvent(id: VesselId, timeStamp: String): Promise<void> {
    await this.httpClient.delete(`${this.baseUrl}/vessels/${id}/voyages/events?time_stamp=${timeStamp}`)
    return
  }

  async createOverride(id: VesselId, form: VoyageOverride): Promise<void> {
    await this.httpClient.post(`${this.baseUrl}/vessels/${id}/voyages/overrides`, form)
    return
  }

  async deleteOverride(id: VesselId, timeStamp: String): Promise<void> {
    await this.httpClient.delete(`${this.baseUrl}/vessels/${id}/voyages/overrides?time_stamp=${timeStamp}`)
    return
  }

  
}