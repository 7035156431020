import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

// UI libs
import { Box, List, ListItem, ListItemAvatar, ListItemText, Avatar, IconButton, Grid, FormControl } from '@mui/material'
import MuiTextField from '@mui/material/TextField'
import { LocalizationProvider, DateTimePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import EventIcon from '@griegconnect/krakentools-react-icons/Event'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import {Button, Container, Divider, FormControlLabel, Radio, RadioGroup} from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery'

import useLocalStorage from 'src/hooks/useLocalStorage'
import { PERIODS_COL_KEY } from '../constants'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from 'luxon'
import { timeFormat } from '../../../constants/timeFormat'

import CharterDataHeader from './CharterDataHeader'
import CharterDataTable from './CharterDataTable'
import {
  PeriodSelectionType,
  CharterPeriodsSelectProps
} from './CharterReport.types'

import { useStyles, printStyleOverrides, ReportTooltip } from './CharterPeriodsSelect.styles'
import { useQuery } from 'react-query'
import ErrorModal from '../../../components/ApolloErrorModal'
import LoadingSpinner from "../../../components/LoadingSpinner"
import { useApis } from 'src/lib/ApiProvider'
import { VoyageWithOverride } from 'src/lib/apis/types/voyage'
import {parseISO} from "date-fns"
import StoredCharterPeriods from "./StoredCharterPeriods"
import ExpandingInfoBox from "../../vessels/add/components/ExpandingInfoBox"
import {VesselCharterReport} from "../../../lib/apis/types/vessel"
import {RED_TAIL} from "../../../constants/colors"
import {useRecoilState} from "recoil"
import {charterReportTotal} from "../../../atoms"
import Checkbox from '@material-ui/core/Checkbox'

const CharterPeriodsSelect = (props: CharterPeriodsSelectProps) => {
  const { vesselId, vesselName, charterBy, additionalText, currentPeriod } = props
  let charterPeriodsList: PeriodSelectionType[] | any = []
  const history = useHistory()
  const generatedId = uuidv4()

  const [periods, setPeriods] = useLocalStorage<PeriodSelectionType[]>(PERIODS_COL_KEY, [])
  const [timeFrom, setTimeFrom] = useState<Date | any>(new Date() as Date)
  const [timeTo, setTimeTo] = useState<Date | any>(new Date() as Date)
  const [storedCharterPeriodsTag, setStoredCharterPeriodsTag] = useState<number>(0)
  const [printActivated, setPrintActivated] = useState<boolean>(false)
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodSelectionType>({} as PeriodSelectionType)
  const [reportButtonClicked, setReportButtonClicked] = useState<boolean>(false)
  const [showDetails, setShowDetails] = useState<boolean>(true)
  const [taxableOnly, setTaxableOnly] = useState<boolean>(true)
  const [includeAllNox, setIncludeAllNox] = useState<boolean>(false)
  const [, setTotalConsumptions] = useRecoilState(charterReportTotal)
  const componentRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      pageStyle: printStyleOverrides,
  })

  const preparePrint = () => {
      if (handlePrint) {
          handlePrint()
      }
      setPrintActivated(true)
  }
  
  const classes = useStyles()
  const largerWidth = useMediaQuery('(min-width: 884px)')
  const { apis } = useApis()

  const handleAddNewPeriod = (): void => {
    charterPeriodsList = periods
    const isOverlappingPeriod = periods?.filter((period) => !(Date.parse(period.timeFrom) > Date.parse(selectedPeriod.timeTo) || Date.parse(period.timeTo) < Date.parse(selectedPeriod.timeFrom)) ).length > 0

    if (
      isOverlappingPeriod ||
      timeFrom === timeTo ||
      timeFrom.toString() === 'Invalid Date' ||
      timeTo.toString() === 'Invalid Date' ||
      timeFrom.toISOString() === timeTo.toISOString()
    )
      return

    charterPeriodsList.push(selectedPeriod)
    setPeriods(charterPeriodsList)

    history.push('/charter-report')
  }

  const handleRemovePeriod = (periodId) => {
    charterPeriodsList = periods?.filter((period) => period.id !== periodId)
    setPeriods(charterPeriodsList)
    setReportButtonClicked(false)
  }

  const handleClearPeriods = () => {
    setReportButtonClicked(false)
    charterPeriodsList = []
    setPeriods([])
  }

  const handleCharterReport = (e) => {
    setShowDetails(e.currentTarget.name === 'detailed')

    setReportButtonClicked(false)
    if (!vesselId || !periods) return

    return setTimeout(() => {
      setReportButtonClicked(true)
    }, 100)
  }

  const saveVesselCharterReport = () => {
    legalPeriods
        .forEach( (cpl) => {
        const form = {
            vessel: vesselId,
            name: charterBy || '',
            start: new Date(cpl.timeFrom).toISOString().substr(0,19)+'Z',
            end: new Date(cpl.timeTo).toISOString().substr(0,19)+'Z'
        }
        //Need to check for duplicate or overlap before adding
        apis.vesselsApi.createVesselCharterReport(vesselId,form)
        handleRemovePeriod(cpl.id)
    })
    setPrintActivated(false)
    setTimeout(() => {
        setStoredCharterPeriodsTag(storedCharterPeriodsTag+1)
    }, 600)
  }

  const createVacantPeriods = (storedPeriods: VesselCharterReport[], monthNum: number) => {
      handleClearPeriods()
      const month = ( (Number(currentPeriod.name.substr(6,1)) -1) * 3) + (monthNum-1)
      const year = Number(currentPeriod.name.substr(0,4))
      let currentStart = monthNum <= 2 // 1 = whole quarter, 2 = 1st month only, 3 = 2nd month only, 4 = 3rd month only
                            ? currentPeriod.timeStart
                            : new Date(year,month-1,1).toISOString().substr(0,19) + 'Z'
      const periodEnd = (monthNum === 1 || monthNum === 4)
                            ? currentPeriod.timeEnd
                            : new Date(new Date(year,month,1).getTime() -1000).toISOString().substr(0,19) + 'Z'

      const currPeriods = storedPeriods
          .filter((p) => p.end > currentStart)
          .filter((p) => p.start < periodEnd)

      currPeriods
          .forEach((p) => {
              if (p.start > currentStart) {
                  charterPeriodsList.push(
                      {
                          id: uuidv4(),
                          timeFrom: currentStart,
                          timeTo: p.start < periodEnd ? p.start : periodEnd
                      }
                  )
              }
              currentStart = (p.end <= currentStart) ? currentStart : (p.end < periodEnd) ? p.end : periodEnd
              if (currentStart < periodEnd && currPeriods[currPeriods.length-1] === p) {
                  charterPeriodsList.push(
                      {
                          id: uuidv4(),
                          timeFrom: currentStart,
                          timeTo: periodEnd
                      }
                  )
              }
          })

      if (currPeriods.length === 0) {
          charterPeriodsList.push(
              {
                  id: uuidv4(),
                  timeFrom: currentStart,
                  timeTo: periodEnd
              })
      }

      setPeriods(charterPeriodsList)
  }

  useEffect(() => {
    setSelectedPeriod({
      id: generatedId,
      timeFrom: timeFrom,
      timeTo: timeTo,
    })
    // eslint-disable-next-line
  }, [timeFrom, timeTo])

  useEffect(() => {
    setTimeFrom(parseISO(currentPeriod.timeStart))
    setTimeTo(parseISO(currentPeriod.timeEnd))
  }, [currentPeriod])

    useEffect(() => {
        setReportButtonClicked(false)
        setTotalConsumptions({
            totalNox: 0,
            totalFuel: 0,
            totalLng: 0,
            totalUrea: 0,
        })
        // eslint-disable-next-line
    }, [currentPeriod, vesselId])

    const {
    isLoading: isLoadingVoyages,
    error: errorVoyages,
    data: voyages,
    isError: isErrorVoyages,
    refetch: refetchVoyages,
  } = useQuery<VoyageWithOverride[], Error>(['voyages', vesselId, currentPeriod], async () =>
    await apis.voyagesApi.getVoyages(vesselId, undefined, currentPeriod.id),
    { enabled: !!vesselId }
  )

  if (isLoadingVoyages) {
    return <LoadingSpinner label={'Loading viable voyages...'}/>
  }

  if (isErrorVoyages) {
      return <ErrorModal error={errorVoyages} refetch={refetchVoyages} />
  }

  const undefinedVoyages = voyages?.filter((v) => v.timeEnd !== v.timeStart && ( v.taxable === null && (v.overrides === null || v.overrides?.taxable === null) ) )

    const reportableVoyages: any = voyages?.map((v) => ({
        timeStart: v.timeStart,
        timeEnd: v.timeEnd,
        events: v.events,
        taxable: !!v.overrides ? typeof v.overrides.taxable !== 'undefined' ? v.overrides.taxable : null : typeof v.taxable !== 'undefined' ? v.taxable : null,
        consumption: !!v.overrides ? v.overrides.consumption : v.consumption,
        consumptions: !!v.overrides ? v.overrides.consumptions : v.consumptions,
        overrides: v.overrides,
        periodId: v.periodId,
      }))
      .filter((vo) => (taxableOnly ? vo.taxable === true : true) && vo.timeEnd !== vo.timeStart) //filter on taxable only when requested
      .sort((a: any, b: any) => (new Date(a.timeEnd) >= new Date(b.timeEnd) && new Date(a.timeStart) >= new Date(b.timeStart) ? 1 : -1)) // Ordered from back-end, but apparently not after archiving...

  const handleTaxabilityRadioChange = (event) => {
    setTaxableOnly(event.target.value === "taxable")
  }

  const toggleIncludeAllNox = () => {
      setIncludeAllNox(!includeAllNox)
  }

  //Exclude periods outside current quarter or having undefined voyages
  const legalPeriods = periods?.filter((p) => !(Date.parse(p.timeTo) < Date.parse(currentPeriod.timeStart) || Date.parse(p.timeFrom) >= Date.parse(currentPeriod.timeEnd)) )
                               .filter((p) => undefinedVoyages?.filter((v) => !(Date.parse(v.timeStart) > Date.parse(p.timeTo) || Date.parse(v.timeEnd) < Date.parse(p.timeFrom) )).length === 0 )

    return (
    <>
      <StoredCharterPeriods
          key={storedCharterPeriodsTag}
          vesselId={vesselId}
          currentPeriod={currentPeriod}
          createVacantPeriods={createVacantPeriods}
      />
      <FormControl variant="filled" fullWidth>
          <ExpandingInfoBox
              title="Active report periods"
              text={"These are the periods the current charter report will use. Periods may be added using the controls below. Any dates or intervals not in "+currentPeriod.name+" will be ignored."}
          />
        {/* DateTime picker wrapper */}
        <div
          style={largerWidth ? { justifyContent: 'space-evenly' } : { flexDirection: 'column' }}
          className={classes.dateInputWrapper}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              inputFormat="dd.MM.yyyy HH:mm"
              mask="__.__.____ __:__"
              ampm={false}
              ampmInClock={false}
              minutesStep={5}
              minDate={new Date() && parseISO(currentPeriod.timeStart)}
              maxDate={new Date() && timeTo}
              renderInput={(props) => (
                <MuiTextField
                  style={largerWidth ? { marginRight: 5, width: '100%' } : { margin: '5px 0' }}
                  className={classes.dateInput}
                  variant="filled"
                  {...props}
                />
              )}
              label="Time from"
              value={timeFrom}
              onChange={(newValue) => {
                const utcFromDate = new Date(Math.floor(newValue / 60000) * 60000)
                setTimeFrom(utcFromDate)
              }}
            />

            <DateTimePicker
              inputFormat="dd.MM.yyyy HH:mm"
              mask="__.__.____ __:__"
              ampm={false}
              ampmInClock={false}
              minutesStep={5}
              minDate={timeFrom}
              maxDate={new Date() && parseISO(currentPeriod.timeEnd)}
              renderInput={(props) => (
                <MuiTextField
                  style={largerWidth ? { marginLeft: 5, width: '100%' } : { margin: '5px 0' }}
                  className={classes.dateInput}
                  variant="filled"
                  {...props}
                />
              )}
              label="Time to"
              value={timeTo}
              onChange={(newValue) => {
                const utcToDate = new Date(Math.floor(newValue / 60000) * 60000)
                setTimeTo(utcToDate)
              }}
            />
          </LocalizationProvider>
        </div>

        <Grid
          container
          style={
            largerWidth
              ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }
              : { display: 'flex', flexDirection: 'column', gap: 20 }
          }
        >
          <Button variant="outlined" color="secondary" onClick={handleClearPeriods}>
            Clear All
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleAddNewPeriod}>
            Add period
          </Button>
        </Grid>

        {periods.length === 0 && (
            <>
              <div style={{marginLeft:20, marginTop:20, marginBottom:16}}>&lt; No periods added &gt;</div>
              <Divider variant="fullWidth" style={{marginBottom: -8}} />
            </>
        )}
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <List dense={true}>
                    {periods
                        ?.sort((a, b) => (Date.parse(a.timeFrom) < Date.parse(b.timeFrom) ? -1 : (Date.parse(a.timeTo) < Date.parse(b.timeTo) ? -1 : 1 )))
                        ?.map((period) => {
                          const start = Date.parse(period.timeFrom)
                          const end = Date.parse(period.timeTo)
                          const pStart = Date.parse(currentPeriod.timeStart)
                          const pEnd = Date.parse(currentPeriod.timeEnd)

                          const timeStartFrom = DateTime.fromMillis(start).toFormat(timeFormat)
                          const timeEndTo = DateTime.fromMillis(end).toFormat(timeFormat)

                          const cleanPeriod = typeof undefinedVoyages === 'undefined' ? true : undefinedVoyages.length === 0 ? true : undefinedVoyages.filter((v) => !(Date.parse(v.timeStart) >= end || Date.parse(v.timeEnd) <= start) ).length === 0
                          const inCurrentPeriod = (pStart > start || pEnd < end)

                          return (
                              <div className={classes.periodsList} style={ inCurrentPeriod ? {color: '#888'} : cleanPeriod ? {color: '#fff'} : {color: RED_TAIL}} key={period.id}>
                                <ReportTooltip
                                    title={
                                      (inCurrentPeriod)
                                          ? 'This period is outside current quarter and will not be included in the report'
                                          : (cleanPeriod)
                                                ? 'This period is good to go'
                                                : "This period contains Undefined voyages and will not be included in the report. Use NOx Voyages to ensure all affected voyages are well-defined."
                                    }
                                >
                                  <span>
                                    <ListItem
                                        secondaryAction={
                                          <IconButton
                                              onClick={() => handleRemovePeriod(period.id)}
                                              color="inherit"
                                              edge="end"
                                              aria-label="delete"
                                          >
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        }
                                    >
                                      <ListItemAvatar>
                                        <Avatar sx={inCurrentPeriod ? { bgcolor: 'inherit', color: '#888' } : cleanPeriod ? { bgcolor: 'inherit', color: '#fff' } : { bgcolor: 'inherit', color: RED_TAIL } }>
                                          <EventIcon />
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText primary={`${timeStartFrom} - ${timeEndTo}`} />
                                    </ListItem>
                                    <Divider variant="fullWidth" component="li" />
                                  </span>
                                </ReportTooltip>
                              </div>
                          )
                        })}
                  </List>
                </Grid>
              </Grid>
            </Box>

          <ReportTooltip
              title={
                !vesselId && legalPeriods.length === 0
                    ? 'Please select vessel and add valid report periods'
                    : !vesselId
                        ? 'Please select a vessel'
                        : legalPeriods.length === 0
                            ? 'Please add valid report periods'
                            : ''
              }
              enterDelay={200}
              leaveDelay={200}
          >
            <span>
              <Grid className={classes.getReportBtnWrapper}
                    container
                    style={
                      largerWidth
                        ? { display: 'grid', gridTemplateColumns: '50% auto', gap: 70 }
                        : { display: 'flex', flexDirection: 'column', gap: 20 }
                    }
              >
                  <RadioGroup onChange={handleTaxabilityRadioChange}
                              value={taxableOnly ? "taxable" : "non-taxable"}
                              aria-label="taxableonlyystatus"
                              name="taxableOnly-radios"
                              row
                  >
                      <FormControlLabel value="taxable" control={<Radio />} label="NOx-taxable only" disabled={!vesselId || legalPeriods.length === 0}/>
                      <FormControlLabel value="non-taxable" control={<Radio />} label="Include all voyages" disabled={!vesselId || legalPeriods.length === 0}/>
                  </RadioGroup>
                  <FormControlLabel
                      style={{alignContent: 'end'}}
                      disabled={!vesselId || legalPeriods.length === 0 || taxableOnly}
                      control={
                          <Checkbox
                              checked={includeAllNox}
                              onChange={toggleIncludeAllNox}
                          />
                      }
                      label={'Include NOx from non-taxable voyages'}
                  />
              </Grid>
              <Grid className={classes.getReportBtnWrapper}
                    container
                    style={
                      largerWidth
                          ? { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10 }
                          : { display: 'flex', flexDirection: 'column', gap: 20 }
                    }
              >

                <Button name={'detailed'}
                    disabled={!vesselId || legalPeriods.length === 0}
                    onClick={handleCharterReport}
                    style={{ width: '100%' }}
                    variant="contained"
                    color="primary"
                    type="button"
                >
                    Get detailed report
                </Button>
                <Button
                    name={'compact'}
                    disabled={!vesselId || legalPeriods.length === 0}
                    onClick={handleCharterReport}
                    style={{ width: '100%' }}
                    variant="contained"
                    color="primary"
                    type="button"
                >
                    Get compact report
                </Button>
                <Button
                    name={'saveperiod'}
                    disabled={!vesselId || legalPeriods.length === 0 || !printActivated}
                    onClick={saveVesselCharterReport}
                    style={{ width: '100%' }}
                    variant="contained"
                    color="primary"
                    type="button"
                >
                    Log completed periods
                </Button>
              </Grid>
            </span>
          </ReportTooltip>
      </FormControl>

      {/* UI view Table Date container */}
      <div style={reportButtonClicked ? { display: 'block' } : { display: 'none' }}>
        {reportButtonClicked && (
          <>
            <h2 style={{ textAlign: 'center', marginTop: '2.5em' }}>Charter Report</h2>
            <CharterDataHeader
              vesselId={vesselId}
              vesselName={vesselName}
              charterBy={charterBy}
              additionalText={additionalText}
            />
            <CharterDataTable
              detailedView={showDetails}
              printable={false}
              vesselId={vesselId}
              periods={legalPeriods}
              currentPeriod={currentPeriod}
              reportableVoyages={reportableVoyages}
              taxableOnly={taxableOnly}
              includeAllNox={includeAllNox}
            />
          </>
        )}
      </div>

      {/* Printable PDF view container */}
      <Container style={{ display: 'none' }}>
        <div className={classes.reportWrapperPrint} ref={componentRef}>
          <h1 style={{ textAlign: 'center', color: '#000' }}>Charter Report</h1>
          <CharterDataHeader
            printable={true}
            vesselId={vesselId}
            vesselName={vesselName}
            charterBy={charterBy}
            additionalText={additionalText}
          />
          <CharterDataTable
            detailedView={showDetails}
            printable={true}
            vesselId={vesselId}
            periods={legalPeriods}
            currentPeriod={currentPeriod}
            reportableVoyages={reportableVoyages}
            taxableOnly={taxableOnly}
            includeAllNox={includeAllNox}
          />
          <div className="bottom-text"></div>
        </div>
      </Container>

      <Container className={classes.printBtnWrapper}>
        <Button
          style={reportButtonClicked ? { display: 'block' } : { display: 'none' }}
          variant="contained"
          color="primary"
          onClick={preparePrint}
        >
          Print report
        </Button>
      </Container>
    </>
  )
}

export default CharterPeriodsSelect
