import {
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
    Grid,
    TextField
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'

import ErrorModal from '../../../components/ApolloErrorModal'

import { useMutation } from 'react-query'

import ExpandingInfoBox from '../../vessels/add/components/ExpandingInfoBox'
import {Typography} from "@mui/material"
import { LocalizationProvider, DateTimePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import useMediaQuery from "@mui/material/useMediaQuery"
import MuiTextField from "@mui/material/TextField"
import { useStyles } from '../../charterReport/components/CharterPeriodsSelect.styles'
import {parseISO} from "date-fns"
import VoyageNoxCalculation from "./VoyageNoxCalculation"
import { VesselId, VesselWithEngines } from 'src/lib/apis/types/vessel'
import { VoyageWithOverride } from 'src/lib/apis/types/voyage'
import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'
import { useApis } from 'src/lib/ApiProvider'
import { EventOverride, EventType } from 'src/lib/apis/types/event'

interface IVoyageFactProps {
    voyage: VoyageWithOverride;
    allVoyages: VoyageWithOverride[];
    vessel: VesselWithEngines;
    currentPeriod: NoxPeriod;
    manualOverride: boolean;
}


const VoyageFactHandler = (props: IVoyageFactProps) => {
//Static value definitions for dropdown/radio selectors
    const Undefined = {value: 'Undefined', id: 0}
    const NOPort = {value: 'Norwegian Port', id: 1}
    const Other = {value: 'Other', id: 10}
    const NOPlace = {value: 'Norwegian Place (installation or remote island)',id: 100}
    const FOPort = {value: 'Foreign Port', id: 1000}
    const eventTypeMap = new Map<string, EventType>([['0','undefined' as EventType], ['1','berth'],['10','foreign-offshore'],['100','offshore'],['1000','foreign-berth']])
    const displayTypeMap = ['berth','Norwegian port','foreign-offshore','Other','offshore','Norwegian place','foreign-berth','Foreign port']
    const largerWidth = useMediaQuery('(min-width: 884px)')
    const classes = useStyles()
    const { apis } = useApis()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { vessel, voyage, allVoyages, currentPeriod, manualOverride } = props
    const events = voyage.events ? JSON.parse(JSON.stringify(voyage.events)) : []
    const startEvent = events[0]
    const endEvent = events[events.length - 1]
    //eventOverride mutation dictates capitalized strings, but api delivers minuscles...
    const [startEventType, setStartEventType] = useState<EventType>(startEvent.eventType)
    const [endEventType, setEndEventType] = useState<EventType>(endEvent.eventType)
    const [midPointEventType, setMidPointEventType] = useState<string>('Undefined')
    const startEventDirection = startEvent.direction.charAt(0).toUpperCase() + startEvent.direction.slice(1).toLowerCase()

    const [taxability, setTaxability] = useState<boolean | null>(typeof voyage.taxable !== 'undefined' ? voyage.taxable : null)

    const setCorrectDropdownId = (event: any) => {
        const eType = event.eventType
        return (
            eType === 'berth' || eType === 'foreign-berth'
            ? ((event.foreign) ? FOPort.id : NOPort.id)
            : (eType === 'offshore')
                ? NOPlace.id
                : (eType === 'period')
                    ? Undefined.id
                    : Other.id
        )  
    }
    
    const [origin, setOrigin] = useState<number>(setCorrectDropdownId(startEvent))
    const [destination, setDestination] = useState<number>(setCorrectDropdownId(endEvent))
    const [midPoint, setMidPoint] = useState<number>(0) // Undefined

    const midUpdateMaxTime = new Date(new Date(voyage.timeEnd).getTime() - 1000).toISOString().substr(0, 19) + 'Z'
    const [arrivalTime, setArrivalTime] = useState<Date | any>(endEvent.timestamp)
    const [nextDepartureTime, setNextDepartureTime] = useState<Date | any>(endEvent.timestamp)
    const [completed, setCompleted] = useState<boolean>(false)
    const [nextDepartureVisible, setNextDepartureVisible] = useState<boolean>(false)
    const originActive = startEvent.name.substr(0,12).toLowerCase() === 'period start'
    const destinationActive = (endEvent.name.substr(0,10).toLowerCase() === 'period end')
                              &&
                              (endEvent.timestamp >= new Date(currentPeriod.timeEnd).toISOString())
    const [newMidpointName, setNewMidpointName] = useState<string>("")
    const history = useHistory()

    const [userOverrideMode, setUserOverrideMode] = useState<boolean>(false)

    const activateUserOverrideMode = () => {
        setUserOverrideMode(true)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        let userInputValid = false
        if (origin > 0 && destination > 0) {
            // Both endpoints are valid (not 'Undefined'), as they most often are, but need additional logic if start or end is editable
            if (originActive || destinationActive) {
                // A 'Period start' or 'Period end' voyage - either one or both may be active
                if (midPoint === 0) {
                    userInputValid = true  // No midpoint defined - will update only start and/or end
                } else {  // When the user has defined the midPoint - all fields within it must be validated
                    userInputValid =
                        (
                            arrivalTime > startEvent.timestamp
                            &&
                            arrivalTime < nextDepartureTime
                            &&
                            nextDepartureTime <= midUpdateMaxTime
                            &&
                            newMidpointName.length > 1
                        )
                }
            } else {
                // normal voyage with predefined (firm) endpoints - here midPoint must be completely defined to enable any update
                userInputValid =
                    (
                        midPoint > 0
                        &&
                        arrivalTime > startEvent.timestamp
                        &&
                        arrivalTime < nextDepartureTime
                        &&
                        nextDepartureTime <= midUpdateMaxTime
                        &&
                        newMidpointName.length > 1
                    )
            }
        }
        setCompleted(userInputValid)
        
        // evaluate taxability options
        const sum = origin + destination
        if (origin > 0 && destination > 0) {
            //Both drop-downs have active values
            if (sum === 2 || sum % 2 === 1) {
                //Norwegian Port has value 1, so here at least one Norwegian port is active - potentially taxable
                if (sum === 1001) { //Norwegian port <-> Foreign Port is classified by back-end logic using en-route activities
                    setTaxability(null)
                } else if (sum === 11) { //Norwegian port + Other = taxable (should check range + nationality here, since taxability at 250nm or 12nm depends on nationality. But this is not possible, so leave it to the user.
                    setTaxability(true)
                } else { //Norwegian port + Norwegian Port or Place, (should check for Fishing outside the 250 nm zone, But this is not possible, so leave it to the user.
                    setTaxability(true)
                }
            }
            else { //No Norwegian port involved here = always non-tax
                setTaxability(false)
            }
        } else {
            setTaxability(null) // don't know
        }
    }, [origin, originActive, destination, destinationActive, midPoint, arrivalTime, nextDepartureTime, midUpdateMaxTime, newMidpointName, startEvent.timestamp])

    const back = () => {
        history.push('/vessel/' + vessel.id + '/voyages')
    }

    const handleUpdateCompleted = () => {
        //need to force complete reload after eventOverride added
        history.push('/vessel/' + vessel.id + '/voyages')
    }

    const handleOriginChange = (event) => {
        setOrigin(event.target.value)
        const eventType = eventTypeMap.get(event.target.value.toString())
        if (typeof eventType !== 'undefined') {
            setStartEventType(eventType)
        }
    }

    const handleDestinationChange = (event) => {
        setDestination(event.target.value)
        const eventType = eventTypeMap.get(event.target.value.toString())
        if (typeof eventType !== 'undefined') {
            setEndEventType(eventType)
        }
    }

    const handlenewMidpointNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setNewMidpointName(event.target.value as string)
    }

    const handleMidPointChange = (event) => {
        setMidPoint(event.target.value)
        const eventType = eventTypeMap.get(event.target.value.toString())
        if (typeof eventType !== 'undefined') {
            setMidPointEventType(eventType)
        }
    }

    const handleArrivalTimeChange = () => {
        if (arrivalTime < nextDepartureTime) {
            setNextDepartureVisible(true)
        }
        else {
            setNextDepartureTime(midUpdateMaxTime)
            setNextDepartureVisible(false)
        }
    }

// NOTE: beware that voyage.id must be translated to a valid index of before traversing
    const findLastValidDepartureEvent = (currentVoyageId: number) => {
        const currentVoyageRow = allVoyages?.findIndex(v => v.id === currentVoyageId) || -1
        //Need to step back through voyages and find the last departure that is not a zone...
        for (let i=currentVoyageRow; i >= 0; i--) {
            if( allVoyages &&
                allVoyages[i].events[0].direction.toLowerCase() === 'departure'
                &&
                ['berth','foreign-berth'].indexOf(allVoyages[i].events[0].eventType.toLowerCase()) >= 0
            ) {
                return allVoyages[i].events[0]
            }
        }
        return null
    }

    const isForeign = (currentEventType: string): boolean => {
        return currentEventType.includes('foreign')
    }

    const {mutate: updateEvent, isError, error} = useMutation((vars: {id: VesselId, form: EventOverride}) => { return apis.voyagesApi.createEvent(vars.id, vars.form)})

    const updateStartEvent = () => {
        const ts = new Date(currentPeriod.timeStart).toISOString().substr(0,19)+'Z'
        
        updateEvent({
            id: vessel.id,
            form: {
                vesselId: vessel.id,
                name: startEvent.name+' ('+displayTypeMap[displayTypeMap.indexOf(startEventType)+1]+')',
                timestamp: ts,
                direction: 'departure',
                eventType: startEventType,
                foreign: isForeign(startEventType),
                eventClassification: 'start'
            },
        })
    }

    const updateEndEvent = () => {
        //Set ts to exact timestamp and format for end-of-period
        const ts = new Date(currentPeriod.timeEnd).toISOString().substr(0, 19) + 'Z'

        updateEvent({
            id: vessel.id,
            form: {
                vesselId: vessel.id,
                name: 'Period end ('+displayTypeMap[displayTypeMap.indexOf(endEventType)+1]+')',
                timestamp: ts,
                direction: 'arrival',
                eventType: endEventType,
                foreign: isForeign(endEventType),
                eventClassification: 'end'
            },
        })

        //Try to use the input from user to define a valid starting point for next period
        //Set ts0 to exact timestamp for next period
        const ts0 = new Date(new Date(currentPeriod.timeEnd).getTime() + 1000).toISOString().substr(0, 19) + 'Z'
        //Find the most recent 'valid' departure for next period startEvent or skip creating it
        const lastValidDepartureEvent = findLastValidDepartureEvent(voyage.id)
        if (lastValidDepartureEvent !== null && lastValidDepartureEvent.foreign === isForeign(endEventType)) {
            //time0 event for next period
            updateEvent({
                id: vessel.id,
                form: {
                    vesselId: vessel.id,
                    name: 'Period start ('+lastValidDepartureEvent.name+')',
                    timestamp: ts0,
                    direction: 'departure',
                    eventType: lastValidDepartureEvent.eventType,
                    foreign: isForeign(lastValidDepartureEvent.eventType),
                    eventClassification: 'start'
                },
            })
        } else if(endEventType === 'foreign-berth') {
            //Special case. If user has to set foreign-berth on period end, odds are it is not geo-fenced
            //So there is no harm in terms of taxability if next period starting point is set as foreign port
            updateEvent({
                id: vessel.id,
                form: {
                    vesselId: vessel.id,
                    name: 'Period start (Foreign port)',
                    timestamp: ts0,
                    direction: 'arrival',
                    eventType: endEventType,
                    foreign: true,
                    eventClassification: 'start'
                },
            })
        }
    }

    const updateMidEvent = () => {
        // leave original start and end, then add a pair of waypoints in-between with corresponding directions")
        // both start and end eventType should be disabled in UI, so user only can adjust times
        // Verify that all involved times are valid
        if (midPoint > 0 && arrivalTime > startEvent.timestamp && arrivalTime < nextDepartureTime && nextDepartureTime <= midUpdateMaxTime) {
            updateEvent({
                id: vessel.id,
                form: {
                    vesselId: vessel.id,
                    name: newMidpointName,
                    timestamp: arrivalTime,
                    direction: 'arrival',
                    eventType: midPointEventType as EventType,
                    foreign: isForeign(midPointEventType),
                    eventClassification: 'other'
                },
            })
            updateEvent({
                id: vessel.id,
                form: {
                    vesselId: vessel.id,
                    name: newMidpointName,
                    timestamp: nextDepartureTime,
                    direction: 'departure',
                    eventType: midPointEventType as EventType,
                    foreign: isForeign(midPointEventType),
                    eventClassification: 'other'
                },
            })
        }
    }

    const save = () => {
        if (originActive) {
            updateStartEvent()
        }
        if (destinationActive) {
            updateEndEvent()
        }
        if (newMidpointName.length > 1) {
            updateMidEvent()
        }
        if (isError) {
            return <ErrorModal error={error}/>
        } else {
            handleUpdateCompleted()
        }
    }

    //By users command, switch to userOverride and return voyageNoxCalculation
    if(userOverrideMode) {
        return <VoyageNoxCalculation vessel={vessel} voyage={voyage} allVoyages={allVoyages} currentPeriod={currentPeriod} manualOverride={true} readOnly={false}/>
    }  else
    //Normal return - eventOverride
    return (
        <div>
            <ExpandingInfoBox
                title="Voyage origin"
                text="To determine the taxability of a voyage its origin must be defined. The term 'Norwegian Place' refers to fixed, movable or subsea offshore installations as well as Norwegian sovereign territories outside the NOx zone."
                text2="Note that Norwegian and Foreign port are the only options that always yield a definite conclusion."
            />
            <FormControl variant="filled" component="fieldset" style={{ marginTop: 8, marginBottom: 8, marginLeft: 16 }} >
                <Grid
                    container
                    style={
                        largerWidth
                            ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }
                            : { display: 'flex', flexDirection: 'column', gap: 20 }
                    }
                >
                    {originActive && (
                        <>
                            <Select
                            variant = 'standard'
                            value={origin}
                            onChange={handleOriginChange}
                            >
                                <MenuItem disabled value={Undefined.id}>{Undefined.value}</MenuItem>
                                <MenuItem value={NOPort.id}>{NOPort.value}</MenuItem>
                                <MenuItem value={NOPlace.id}>{NOPlace.value}</MenuItem>
                                <MenuItem value={FOPort.id}>{FOPort.value}</MenuItem>
                                <MenuItem value={Other.id}>{Other.value}</MenuItem>
                            </Select>
                            <Typography>
                            {startEvent.name}
                            </Typography>
                        </>
                        )}
                    {!originActive && (
                        <>
                            <Typography style={{color: "grey"}}>
                                {displayTypeMap[displayTypeMap.indexOf(startEventType)+1]}
                            </Typography>
                            <Typography style={{color: "grey"}}>
                            ({startEvent.name})
                            </Typography>
                        </>
                    )}
                </Grid>
            </FormControl>

            <Divider style={{ marginTop: 16, marginBottom: 16 }} />

            {(startEventDirection === "Arrival" && startEvent.name !== 'Period start') &&  // for some reason period start has direction = Arrival
                (
                <>
                    <Typography style={{ marginTop: 8, marginBottom: 8, marginLeft: 16 }}>
                        Additional destinations can only be added to Transit voyages
                    </Typography>
                    <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                </>
                )
            }
            {(startEventDirection === "Departure" || startEvent.name === 'Period start') &&
                (<>
                    <ExpandingInfoBox
                        title="Additional Destination"
                        text="Key events that impact taxability, can be added here. The term 'Norwegian Place' refers to fixed, movable or subsea offshore installations as well as Norwegian sovereign territories outside the NOx zone."
                        text2="Note that if the duration of an added destination doesn't match the existing positional data the map-trail may be affected."
                    />
                    <FormControl variant="filled" component="fieldset" style={{ marginTop: 8, marginBottom: 8, marginLeft: 16 }} >
                        <Grid
                            container
                            style={
                                largerWidth
                                    ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }
                                    : { display: 'flex', flexDirection: 'column', gap: 20 }
                            }
                        >
                            <Select
                                label = 'Destination type'
                                variant = 'standard'
                                value={midPoint}
                                onChange={handleMidPointChange}
                            >
                                <MenuItem disabled value={Undefined.id}>{Undefined.value}</MenuItem>
                                <MenuItem value={NOPort.id}>{NOPort.value}</MenuItem>
                                <MenuItem value={NOPlace.id}>{NOPlace.value}</MenuItem>
                                <MenuItem value={FOPort.id}>{FOPort.value}</MenuItem>
                                <MenuItem value={Other.id}>{Other.value}</MenuItem>
                            </Select>
                            <TextField
                                fullWidth={true}
                                label="Destination name"
                                name="newMidpointName"
                                value={newMidpointName}
                                onChange={handlenewMidpointNameChange}
                            />

                        </Grid>
                        <Grid
                            container
                            style={
                                largerWidth
                                    ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }
                                    : { display: 'flex', flexDirection: 'column', gap: 20 }
                            }
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    inputFormat="dd.MM.yy HH:mm"
                                    mask="__.__.__ __:__"
                                    ampm={false}
                                    ampmInClock={false}
                                    minutesStep={5}
                                    minDateTime={parseISO(voyage.timeStart)}
                                    maxDateTime={parseISO(voyage.timeEnd)}
                                    renderInput={(props) => (
                                        <MuiTextField
                                            style={largerWidth ? { marginRight: 5, width: '100%' } : { margin: '5px 0' }}
                                            className={classes.dateInput}
                                            variant="filled"
                                            {...props}
                                        />
                                    )}
                                    label="Arrival time"
                                    value={arrivalTime}
                                    onChange={(newValue) => {
                                        const needsVerification = Date.parse(newValue)
                                        if (isNaN(needsVerification) === false) {
                                            const utcArrivalTime = new Date(Math.floor(newValue / 60000) * 60000).toISOString()
                                            setArrivalTime(utcArrivalTime)
                                            handleArrivalTimeChange()
                                        }
                                    }}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    disabled = {!nextDepartureVisible}
                                    inputFormat="dd.MM.yy HH:mm"
                                    mask="__.__.__ __:__"
                                    ampm={false}
                                    ampmInClock={false}
                                    minutesStep={5}
                                    minDateTime={parseISO(new Date(new Date(arrivalTime).getTime() + 60000).toISOString())}
                                    maxDateTime={parseISO(voyage.timeEnd)}
                                    renderInput={(props) => (
                                        <MuiTextField
                                            style={largerWidth ? { marginRight: 5, width: '100%' } : { margin: '5px 0' }}
                                            className={classes.dateInput}
                                            variant="filled"
                                            {...props}
                                        />
                                    )}
                                    label="Departure time"
                                    value={nextDepartureTime}
                                    onChange={(newValue) => {
                                        const needsVerification = Date.parse(newValue)
                                        if (isNaN(needsVerification) === false) {
                                            const utcNextDepartureTime = new Date(Math.floor(newValue / 60000) * 60000).toISOString()
                                            setNextDepartureTime(utcNextDepartureTime)
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </FormControl>

                    <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                </>
                )
            }

            <>
                <ExpandingInfoBox
                    title="Voyage Destination"
                    text="To determine the taxability of a voyage its destination must be defined."
                    text2="The term 'Norwegian Place' refers to fixed, movable or subsea offshore installations as well as Norwegian sovereign territories outside the NOx zone"
                />
                <FormControl variant="filled" component="fieldset" style={{ marginTop: 8, marginBottom: 8, marginLeft: 16 }} >
                    <Grid
                        container
                        style={
                            largerWidth
                                ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }
                                : { display: 'flex', flexDirection: 'column', gap: 20 }
                        }
                    >
                        {destinationActive && (
                            <>
                                <Select style={{marginBottom: 16}}
                                    variant = 'standard'
                                    value={destination}
                                    onChange={handleDestinationChange}
                                >
                                    <MenuItem disabled value={Undefined.id}>{Undefined.value}</MenuItem>
                                    <MenuItem value={NOPort.id}>{NOPort.value}</MenuItem>
                                    <MenuItem value={NOPlace.id}>{NOPlace.value}</MenuItem>
                                    <MenuItem value={FOPort.id}>{FOPort.value}</MenuItem>
                                    <MenuItem value={Other.id}>{Other.value}</MenuItem>
                                </Select>
                                <Typography>
                                    {endEvent.name}
                                </Typography>
                            </>
                        )}
                        {!destinationActive && (
                            <>
                                <Typography style={{color: "grey"}}>
                                    {displayTypeMap[displayTypeMap.indexOf(endEventType)+1]}
                                </Typography>
                                <Typography style={{color: "grey"}}>
                                    ({endEvent.name})
                                </Typography>
                            </>
                        )}
                    </Grid>
                </FormControl>

            </>

            <Divider style={{ marginTop: 16, marginBottom: 16 }} />

            <div style={{ textAlign: 'center', marginTop: 32, marginBottom: 16, paddingBottom: 16 }}>
                <Button style={{ marginRight: 32, padding: "7px 32px" }}
                        variant="outlined" color="secondary" onClick={back}>
                    BACK
                </Button>
                <Button  style={{ padding: "7px 32px" }}
                         disabled={!completed}
                         variant="contained" color="primary" onClick={save}>
                    SAVE
                </Button>
            </div>

            <Divider style={{ marginTop: 16, marginBottom: 16 }} />

            <FormControl component="fieldset" style={{ marginTop: 8, marginBottom: 32, marginLeft: 16 }} >
                <FormLabel component="legend">Voyage taxable status</FormLabel>
                <Grid
                    container
                    style={
                        largerWidth
                            ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }
                            : { display: 'flex', flexDirection: 'column', gap: 10 }
                    }
                >
                    <RadioGroup value={(taxability === null) ? Undefined.value : taxability ? "taxable" : "non-taxable" } aria-label="taxabilitystatus" name="taxability-radios" row >
                        <FormControlLabel disabled value={Undefined.value} control={<Radio />} label={Undefined.value} />
                        <FormControlLabel disabled value="taxable" control={<Radio />} label="Taxable" />
                        <FormControlLabel disabled value="non-taxable" control={<Radio />} label="Non-taxable" />
                    </RadioGroup>
                    <Button   disabled={manualOverride}
                              style={{ padding: "7px 32px" }}
                              variant="outlined"
                              color="primary"
                              onClick={activateUserOverrideMode}>
                        EDIT TAXABILITY AND FUEL
                    </Button>
                </Grid>
            </FormControl>
            {/*            <Typography  style={{ marginTop: 0, marginBottom: 4, marginLeft: 16, fontSize: "small" }}>{taxplanation}</Typography> */}
        </div>
    )
}

export default VoyageFactHandler