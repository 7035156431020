import {
  GriegRouteHierarchyType,
  GriegRouteType,
  GriegSwitch,
  GriegSwitchProps,
} from '@griegconnect/krakentools-react-router'
import { AppLoader } from '@griegconnect/krakentools-react-ui'
import React, { Suspense } from 'react'

import NoActiveTenant from './ErrorPages/NoActiveTenant'
import NotAuthenticated from './ErrorPages/NotAuthenticated'
import NotAuthorized from './ErrorPages/NotAuthorized'
import NotFound from './ErrorPages/NotFound'

interface GriegRouterProps extends GriegSwitchProps {
  routes: GriegRouteType[];
  parentRoute: GriegRouteHierarchyType;
}

export const GriegRouter = (props: GriegRouterProps) => {
  const { routes, parentRoute } = props
  return (
    <Suspense fallback={<AppLoader description="Loading module" />}>
      <GriegSwitch
        routes={routes}
        parentRoute={parentRoute}
        noActiveTenantComponent={NoActiveTenant}
        notAuthenticatedComponent={NotAuthenticated}
        notAuthorizedComponent={NotAuthorized}
        notFoundComponent={NotFound}
      />
    </Suspense>
  )
}

GriegRouter.defaultProps = {
  routes: [],
  parentRoute: undefined,
}

export default GriegRouter
