import { AxiosInstance, AxiosResponse } from 'axios'
import {
  IAccess,
  ITenantUser,
  ITenantUserAccess,
} from './types/role'
import { Identity} from './types/user'

export class TenantsApi {
  httpClient: AxiosInstance
  tenantRef: string | null
  constructor(httpClient: any, apiUrl: string, tenant?: string | null, useAuth: boolean = true) {
    this.httpClient = httpClient(apiUrl, useAuth)
    this.tenantRef = tenant ? tenant : null
  }

  async updateProfileImage(b64: string): Promise<Identity> {
    const file = await fetch(b64).then((res) => res.blob())
    const formData = new FormData()
    formData.append('category', 'avatar')
    formData.append('file', file, 'profileImage.jpg')
    const response = await this.httpClient.post('/identity/image', formData)
    return response.data
  }

  async getCurrentUserAccess(): Promise<ITenantUserAccess> {
    const response: AxiosResponse<{
      access: IAccess
      user: ITenantUser
    }> = await this.httpClient.get(`/users/me`)
    return response.data
  }

  async updateUser(name: string, mobile: string): Promise<any> {
    const response = await this.httpClient.post(`/users/me`, {
      name,
      mobile,
    })
    return response.data
  }


  async signup(email: string, name: string, password: string, mobile?: string, returnTo?: string): Promise<any> {
    const returnToAfterAuth0 = returnTo ? returnTo : window.location.origin + '/login'
    const response = await this.httpClient.post(`/users/signup`, {
      email,
      name,
      password,
      mobile,
      returnTo: returnToAfterAuth0,
    })
    return response.data.id
  }
}
