import { useState, useEffect } from 'react'

// Components
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { TextField, Container } from '@material-ui/core'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ErrorModal from '../../../components/ApolloErrorModal'

import CharterPeriodsSelect from './CharterPeriodsSelect'
import { CharterOptionsProps } from './CharterReport.types'
import { useRecoilState } from 'recoil'
import { ureaPriceState, fuelPriceState, lngPriceState, vesselNoxRateState, VesselNoxRateStateType } from '../../../atoms'
import { useQuery } from 'react-query'

import {
  useStyles,
  InputContainer,
  CharterInputWrapper,
  VesselInputWrapper,
  AdditionalTextWrapper,
  UreaPriceWrapper,
} from './CharterOptions.styles'
import { VesselWithEngines } from 'src/lib/apis/types/vessel'
import { useApis } from 'src/lib/ApiProvider'

const CharterOptions = (props: CharterOptionsProps) => {
  const { fleetVessels, currentPeriod } = props
  const [vesselId, setVesselId] = useState<string>('')
  const [vesselName, setVesselName] = useState<string>('')
  const [charterBy, setCharterBy] = useState<string>('')
  const [additionalText, setAdditionalText] = useState<string>('')
  const [ureaPrice, setUreaPrice] = useRecoilState<number>(ureaPriceState)
  const [fuelPrice, setFuelPrice] = useRecoilState<number>(fuelPriceState)
  const [lngPrice, setLngPrice] = useRecoilState<number>(lngPriceState)
  const [, setVesselInfo] = useRecoilState<VesselNoxRateStateType>(vesselNoxRateState)
  const { apis } = useApis()

  const classes = useStyles()

  const {
    isLoading,
    error,
    isError,
    data: vessel,
    refetch,
  } = useQuery<VesselWithEngines, Error>(['vessel', vesselId], async () =>
    apis.vesselsApi.getVessel(vesselId),
    { enabled: !!vesselId }
  )

  const handleVesselSelection = (event: SelectChangeEvent) => {
    setVesselId(event.target.value)
  }

  const handleCharterChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setCharterBy(event.target.value as string)
  }

  const handleAdditionalText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalText(event.target.value as string)
  }

  const handleUreaInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(event.target.value as any)) return
    setUreaPrice(parseFloat(event.target.value) as number)
  }

  const handleFuelInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(event.target.value as any)) return
    setFuelPrice(parseFloat(event.target.value) as number)
  }

  const handleLngInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(event.target.value as any)) return
    setLngPrice(parseFloat(event.target.value) as number)
  }

  useEffect(() => {
    setVesselInfo({
      name: vessel?.name,
      imo: vessel?.imo,
      noxRate: vessel?.noxRate,
    })
    if(typeof vessel !== 'undefined') {
      setVesselName(vessel.name)
    }
    // eslint-disable-next-line
  }, [vesselId && !isLoading])

  if (isError) return <ErrorModal error={error} refetch={refetch} />

  return (
    <Container style={{ padding: '0 2em' }}>
      <FormControl variant="filled" fullWidth>
        <InputContainer>
          <VesselInputWrapper>
            <InputLabel className={classes.selectInputLabel} variant="filled" id="select">
              Vessel
            </InputLabel>
            <Select
              className={classes.vesselSelect}
              inputProps={{
                classes: {
                  icon: classes.selectInputIcon,
                },
              }}
              labelId="select-vessel"
              value={vesselId}
              label="Vessel"
              onChange={handleVesselSelection}
            >
              {fleetVessels?.map((v) => (
                <MenuItem key={v.id} value={v.id}>
                  {' '}
                  {v.name}{' '}
                </MenuItem>
              ))}
            </Select>
          </VesselInputWrapper>

          <CharterInputWrapper>
            <TextField
              className={classes.charterTextField}
              onChange={handleCharterChange}
              label="Chartered by:"
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </CharterInputWrapper>

          <UreaPriceWrapper>
            <TextField
                className="input"
                id="standard-number"
                label="Fuel kr/t"
                type="number"
                defaultValue={fuelPrice}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                onChange={handleFuelInput}
                error={fuelPrice < 0}
                helperText={(fuelPrice < 0) && 'Invalid number'}
            />
          </UreaPriceWrapper>

          <UreaPriceWrapper>
            <TextField
                className="input"
                id="standard-number"
                label="Lng kr/t"
                type="number"
                defaultValue={lngPrice}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                onChange={handleLngInput}
                error={lngPrice < 0}
                helperText={(lngPrice < 0) && 'Invalid number'}
            />
          </UreaPriceWrapper>

          <UreaPriceWrapper>
            <TextField
                className="input"
                id="standard-number"
                label="Urea kr/l"
                type="number"
                defaultValue={ureaPrice}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                onChange={handleUreaInput}
                error={ureaPrice < 0 }
                helperText={(ureaPrice < 0) && 'Invalid number'}
            />
          </UreaPriceWrapper>
        </InputContainer>
        <AdditionalTextWrapper>
          <TextField
            style={{ width: '100%' }}
            id="outlined-multiline-static"
            label="Details"
            multiline={true}
            rows={4}
            onChange={handleAdditionalText}
          />
        </AdditionalTextWrapper>
      </FormControl>

      <CharterPeriodsSelect
        vesselId={vesselId}
        vesselName={vesselName}
        charterBy={charterBy}
        additionalText={additionalText}
        currentPeriod={currentPeriod}
      />
    </Container>
  )
}

export default CharterOptions
