import { useEffect, useLayoutEffect, useState, useMemo } from 'react'
import { Typography, Container, IconButton } from '@material-ui/core'
import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import FilterIcon from '@griegconnect/krakentools-react-icons/Filter'
import { useRecoilState } from 'recoil'

import { useQuery } from 'react-query'

import { periodFilterState } from '../../atoms'
import { useAppContext } from 'src/common/GriegProvider/wrappers/AppContextWrapper'
import { useTenants } from '../../common/GriegTenants'
import PeriodsSelect from '../../utils/PeriodsSelect'

// Components
import { initialFilterConfig, selectedFilterConfig } from './config/filterConfig'
import UiFilterDrawer, { IUiFilterSelections } from '../../components/UiFilterDrawer'
import UiShowActiveFilter from 'src/components/UiShowActiveFilter'
import ErrorModal from '../../components/ApolloErrorModal'
import LoadingSpinner from '../../components/LoadingSpinner'
import NoxReportPrintView from './components/NoxReportPrintView'
import NoxReportMessage from './components/NoxReportMessage'

import useStyles from './noxReport.styles'
import { useApis } from 'src/lib/ApiProvider'
import { VesselPeriod, VesselWithEngines } from 'src/lib/apis/types/vessel'
import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'

const NoxReport = (props: GriegRouteProps): JSX.Element => {
  const classes = useStyles()
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [filterState, setFilterState] = useRecoilState(periodFilterState)
  const { route } = props
  const { apis } = useApis()
  const { setRouteOptionsCallback } = useAppContext()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))
  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps
  const [filterConfig, setFilterConfig] = useState(initialFilterConfig)

  const { activeTenant } = useTenants()

  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!filterDrawerOpen)
  }

  const handleFilterChange = (newFilterSelection: IUiFilterSelections) => {
    setFilterState({ ...(newFilterSelection as any) } as any)
  }

/* YAE 22.02.22 -> fleet_data not needed
  const {
    loading: fleet_loading,
    error: fleet_error,
    data: fleet_data,
    refetch: fleet_refetch,
  } = useQuery<Fleets>(fleetQuery, {
    fetchPolicy: 'network-only',
    variables: {
      companyId,
    },
  })
*/
  const { 
    isLoading: isLoadingPeriods, 
    isError: isErrorPeriods, 
    data: periods, 
    error: errorPeriods, 
    refetch: refetchPeriods 
  } = useQuery<NoxPeriod[], Error>('noxPeriods', async () =>
    await apis.periodsApi.getPeriods()
  )

  const currentPeriod: any = PeriodsSelect.getPeriod(periods, filterState)

  useEffect(() => {
    const selectedFilterConf = selectedFilterConfig(periods)
    setFilterConfig(selectedFilterConf)

    //YAE :: added initial values for quarter and show_report drop-down in filter
    if (!filterState.quarter && currentPeriod) {
      setFilterState({ ...filterState, quarter: [currentPeriod.id] })
    }

    if (!filterState.show_report) {
      setFilterState({ ...filterState, show_report: [0] })
    }
    // eslint-disable-next-line
  }, [periods])

  const {
    isLoading: isLoadingVesselsPeriods,
    error: errorVessselsPeriods,
    data: vesselsPeriods,
    isError: isErrorVesselsPeriods,
    refetch: refetchVesselsPeriods,
  } = useQuery<VesselPeriod[], Error>(['vesselPeriods', currentPeriod], async () =>
    await apis.vesselsApi.getVesselsPeriods(currentPeriod?.id),
    { enabled: !!currentPeriod }
  )

/* YAE 15.02.22 -> replacing this with FleetOverview query
const {
  loading: fleetVessel_loading,
  error: fleetVessel_error,
  data: fleetVessel_data,
  refetch: fleetVessel_refetch,
} = useQuery<FleetVesselVoyages>(
  FleetVesselVoyagesQuery,

  {
    skip: !companyId || !currentPeriod,
    fetchPolicy: 'network-only',
    variables: {
//        fleetId: fleet_data && fleet_data.fleets[0] && fleet_data?.fleets[0].id,
      companyId: companyId,
      periodId: (currentPeriod as any).id,
    },
  }
)
*/

const {
  isLoading: isLoadingVessels,
  isError: isErrorVessels,
  error: errorVessels,
  data: vessels,
  refetch: refetchVessels,
} = useQuery<VesselWithEngines[], Error>('vessels', async () =>
  await apis.vesselsApi.getVessels()
)

const completedVessels = useMemo( () => {
  const approvedVesselsPeriods = vesselsPeriods?.filter(
    (ves) => ves.submissionStatus === 'approved' || ves.submissionStatus === 'committed'
  )
  return vessels?.filter((v) => approvedVesselsPeriods?.some((vp) => v.id === vp.vesselId)).sort((a, b) => (a.name > b.name ? 1 : -1))
  // eslint-disable-next-line
}, [currentPeriod])

//  if (fleet_error) return <ErrorModal error={fleet_error} refetch={fleet_refetch} />
if (isErrorPeriods) return <ErrorModal error={errorPeriods} refetch={refetchPeriods} />
if (isErrorVesselsPeriods) return <ErrorModal error={errorVessselsPeriods} refetch={refetchVesselsPeriods} />
if (isErrorVessels) return <ErrorModal error={errorVessels} refetch={refetchVessels} />

if (isLoadingPeriods || isLoadingVesselsPeriods || isLoadingVessels) return <LoadingSpinner /> //removed fleet_loading

return (
  <Container className={classes.container}>
    <div className={classes.titleRow}>
      <UiShowActiveFilter
        filterConfig={filterConfig as any}
        filterSelections={{ ...filterState }}
        onChange={handleFilterChange}
      />
      <IconButton onClick={toggleFilterDrawer} className={classes.filterBtn}>
        <FilterIcon fontSize="large" className={classes.filterIcon} />
      </IconButton>
    </div>

    <UiFilterDrawer
      filterConfig={filterConfig as any}
      filterSelections={{ ...filterState }}
      open={filterDrawerOpen}
      onClose={toggleFilterDrawer}
      onChange={handleFilterChange}
    />
    <div className={classes.titleRow}>
        <Typography variant="h5" style={{marginLeft: 25}}>NOx Report by period</Typography>
    </div>

    {completedVessels?.length === 0 ? (
      <NoxReportMessage />
    ) : (
      <NoxReportPrintView
        activeTenant={activeTenant}
        currentPeriod={currentPeriod}
        completedVessels={completedVessels}
      />
    )}
  </Container>
)
}

export default NoxReport
