import { IconButton, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FilterIcon from '@griegconnect/krakentools-react-icons/Filter'
import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import Container from '@material-ui/core/Container'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useLayoutEffect, useState } from 'react'
//import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import PeriodsSelect from '../../utils/PeriodsSelect'

import {useQueries, useQuery, useQueryClient} from 'react-query'

import { periodFilterState } from '../../atoms'
import { useAppContext } from '../../common/GriegProvider/wrappers/AppContextWrapper'
import ErrorModal from '../../components/ApolloErrorModal'
import LoadingSpinner from '../../components/LoadingSpinner'
import UiFilterDrawer, { IUiFilterSelections } from '../../components/UiFilterDrawer'
import UiShowActiveFilter from '../../components/UiShowActiveFilter'
//import FleetVoyageMap from '../../components/VoyageMap'
import FleetVoyagesVesselList from './fleetVoyagesVesselList'
import { VesselPeriod, VesselWithEngines, VesselWithEnginesAndStatus } from 'src/lib/apis/types/vessel'
import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'
import { useApis } from 'src/lib/ApiProvider'
import Button from "@material-ui/core/Button"
import {useHistory} from "react-router-dom"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      width: 'calc(100% + 15px)',
      height: '100%',
      marginLeft: -7,
      marginTop: -16,
      marginBottom: 16,
      minHeight: 300,
      maxHeight: 400,
      '& canvas': {
        outline: 0,
      },
    },
    titleRow: {
      marginBottom: 25,
      marginLeft: 15,
      paddingRight: 15,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    filterBtn: {
      marginLeft: 'auto',
    },
    paper: {
      padding: 36,
    },
    container: {
      padding: 36,
      maxWidth: 900,
      margin: '0 auto',
    },
  })
)

const FleetVoyagesRoute = (props: GriegRouteProps) => {
  //  const history = useHistory()
  const classes = useStyles()
  const [selectedVessel] = useState<string | undefined>() //YAE removed ", setSelectedVessel" not currently in use
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [filterState, setFilterState] = useRecoilState(periodFilterState)
  const history = useHistory()
  const { route } = props
  const { apis } = useApis()
  const { setRouteOptionsCallback } = useAppContext()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))
  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps
  const [filterConfig, setFilterConfig] = useState({
    quarter: {
      label: 'Period',
      multiple: false,
      type: 'select',
      values: [] as any,
    },
    show_vessels: {
      label: 'Show',
      multiple: false,
      type: 'select',
      values: [
        {
          label: 'All vessels',
          value: 0,
        },
        {
          label: 'Pending only',
          value: 1,
        },
        {
          label: 'Completed only',
          value: 2,
        },
        {
          label: 'Archived only',
          value: 3,
        },
      ],
    },
  })

  const queryClient = useQueryClient()

  const {
    isLoading: isLoadingVessels,
    isError: isErrorVessels,
    error: errorVessels,
    data: vessels,
    refetch: refetchVessels,
  } = useQuery<VesselWithEngines[], Error>('vessels', async () =>
    await apis.vesselsApi.getVessels()
  )

  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!filterDrawerOpen)
  }

  const gotoVessels = () => {
    history.push('/vessels')
  }

  const handleFilterChange = async (newFilterSelection: IUiFilterSelections) => {
    setFilterState({ ...(newFilterSelection as any) } as any)
    await queryClient.invalidateQueries('vessels')
    await queryClient.invalidateQueries('vesselPeriods')
  }

  const { 
    isLoading: isLoadingPeriods, 
    isError: isErrorPeriods, 
    data: periods, 
    error: errorPeriods, 
    refetch: refetchPeriods
  } = useQuery<NoxPeriod[], Error>('noxPeriods', async () =>
    await apis.periodsApi.getPeriods()
  )

  const currentPeriod: NoxPeriod | undefined = PeriodsSelect.getPeriod(periods, filterState)

  const {
    isLoading: isLoadingVesselsPeriods,
    error: errorVessselsPeriods,
    data: vesselsPeriods,
    isError: isErrorVesselsPeriods,
    refetch: refetchVesselsPeriods,
  } = useQuery<VesselPeriod[], Error>(
    ['vesselPeriods', currentPeriod],
    async () => await apis.vesselsApi.getVesselsPeriods(currentPeriod?.id),
    { enabled: !!currentPeriod }
  )

  const vesselNoxZone = new Map(useQueries(
    vessels?.map(v => {
      return {
        queryKey: ['vesselNoxZone', v.id, currentPeriod?.id],
        queryFn: () => apis.vesselsApi.getVesselInNoxZoneStatus(v.id, currentPeriod?.timeStart, currentPeriod?.timeEnd),
        options: { enabled: !!currentPeriod && !isLoadingVessels && vessels.length, staleTime: (1000*60*60*2), cacheTime: (1000*60*60*4) }
      }
    }) ?? []
  ).map(r => r.data ?? ["", false])) 

  //YAE 12.08.21 added "Show" filter selection to drawer
  useEffect(() => {
    setFilterConfig({
      quarter: {
        label: 'Period',
        multiple: false,
        type: 'select',
        values: periods?.map((period) => ({
          label: period.name,
          value: period.id,
        })),
      },
      show_vessels: {
        label: 'Show',
        multiple: false,
        type: 'select',
        values: [
          {
            label: 'All vessels',
            value: 0,
          },
          {
            label: 'Pending only',
            value: 1,
          },
          {
            label: 'Completed only',
            value: 2,
          },
          {
            label: 'Archived only',
            value: 3,
          },
        ],
      },
    } as any)

    if (!filterState.quarter && currentPeriod) {
      setFilterState({ ...filterState, quarter: [currentPeriod.id] })
    }

    if (!filterState.show_vessels) {
      setFilterState({ ...filterState, show_vessels: [0] })
    }
    // eslint-disable-next-line
  }, [periods])


  if (isErrorVessels) {
    return <ErrorModal error={errorVessels} refetch={refetchVessels} />
  }

  if (isErrorPeriods) {
    return <ErrorModal error={errorPeriods} refetch={refetchPeriods} />
  }

  if (isErrorVesselsPeriods) {
    return <ErrorModal error={errorVessselsPeriods} refetch={refetchVesselsPeriods} />
  }

  if (isLoadingVessels || isLoadingPeriods || isLoadingVesselsPeriods) {
    return <LoadingSpinner />
  }

  if (!vessels || vessels.length === 0) { //YAE 15.02.22 -> changed from data.vessels
    return (
      <Paper className={classes.paper}>
        No vessels have been added to this account yet.<br/><br/>
        <Button size="small" color="primary" onClick={gotoVessels}>
          Go to Vessel Details
        </Button>
      </Paper>
    )
  }

  const filteredVessels: any = currentPeriod
    ? vessels.map((v: VesselWithEnginesAndStatus) => {
      const vesselperiod = vesselsPeriods?.find((vp) => vp.vesselId === v.id)
      v.status = vesselperiod ? vesselperiod.submissionStatus : 'not-approved'
      return v
    }).filter((v: VesselWithEnginesAndStatus) => {

        //YAE 12.08.21 apply the "Show" filter added to FilterDrawer
        const hideVessel = filterState.show_vessels
          ? (filterState.show_vessels[0] === 1 && v.status !== 'not-approved') ||
            (filterState.show_vessels[0] === 2 && v.status !== 'approved') ||
            (filterState.show_vessels[0] === 3 && v.status !== 'committed')
          : false

        return !hideVessel //YAE 23.08.21 --> removed condition: voyages && voyages.length > 0
      })
    : []

  return (
    <>
      <Container style={{ marginTop: 16 }}>
        <UiShowActiveFilter
          filterConfig={filterConfig as any}
          filterSelections={{ ...filterState }}
          onChange={handleFilterChange}
        />

        <UiFilterDrawer
          filterConfig={filterConfig as any}
          filterSelections={{ ...filterState }}
          open={filterDrawerOpen}
          onClose={toggleFilterDrawer}
          onChange={handleFilterChange}
        />
        <div className={classes.container}>
          <div className={classes.titleRow}>
            <Typography variant="h5">Vessels with NOx-voyages ({filteredVessels.length})</Typography>
            <IconButton onClick={toggleFilterDrawer} className={classes.filterBtn}>
              <FilterIcon fontSize="large" style={{ color: 'white' }} />
            </IconButton>
          </div>
          <FleetVoyagesVesselList vessels={filteredVessels} selectedVesselId={selectedVessel} inZone={vesselNoxZone} />
        </div>
      </Container>
    </>
  )
}

export default FleetVoyagesRoute
