import { Typography } from '@material-ui/core'
import React from 'react'
import { IReleaseNoteProps } from './ReleaseNote1'

const ReleaseNote4 = (props: IReleaseNoteProps) => {
  return (!props.english) ? (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q4-2022
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Utenlandske havner
      </Typography>
      <Typography variant="body1">
        I denne versjonen er det 34 utenlandske havner:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Lerwick, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Gi oss beskjed om hvilke andre utenlandshavner dere trenger, så vil vi oppdatere med de viktigste fortløpende.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        Etter at en charter rapport er generert kan man nå lagre rapportintervallene med "Log completed periods". Dette gir full oversikt over hvilke perioder som er fakturert og til hvem.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Funksjonen "Create vacant periods" vil legge alle ikke-rapporterte intervaller til "Active report periods", slik at man enkelt kan generere en charter rapport for ikke-fakturerte perioder.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Generelle forbedringer
      </Typography>
      <Typography variant="body1">
        Forbedret håndtering av "Add a missing location" når den brukes i slutten av perioden.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Det er nå flere situasjoner der "Undefined" i starten av påfølgende periode blir eliminert.
      </Typography>
      <Typography variant="h6" style={{marginTop: 16, marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q4-2022 (rev. 2)
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        Det er nå lagt til flere valg for generering av ikke-fakturerte perioder, slik at dette kan gjøres både måneds- og kvartalsvis.
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q4-2022
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Foreign ports
      </Typography>
      <Typography variant="body1">
        This version has 34 foreign ports:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Lerwick, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Let us know which other ports are needed, and we will add the most frequently requested.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        When a charter report has been generated, you can save the reported intervals using "Log completed periods". When present, the saved intervals will show which periods have been invoiced and to whom.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        The corresponding "Create vacant periods" function will fill "Active report periods" with all the remaining time-slots, thus simplifying getting a charter report for non-invoiced periods.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        General improvements
      </Typography>
      <Typography variant="body1">
        Improved handling of "Add a missing location" when used at the end of a report period.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        There are now more instances where "Undefined" voyages can be avoided at the start of next period.
      </Typography>
      <Typography variant="h6" style={{marginTop: 16, marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q4-2022 (rev. 2)
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        Generating non-invoiced periods is now available on both a monthly and a quarterly basis.
      </Typography>
    </>
  )}

export default ReleaseNote4