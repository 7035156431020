import { useAuth } from '@griegconnect/krakentools-react-auth'
import { GriegHttpClientProvider } from '@griegconnect/krakentools-react-http'
import React from 'react'

export type HttpWrapperProps = {
  children: React.ReactElement;
};

export const HttpWrapper = (props: HttpWrapperProps) => {
  const { children } = props
  const { getTokenSilently } = useAuth()

  const getBearerToken = () => {
    return new Promise<string>((resolve, reject) => {
      getTokenSilently().then((token: string | undefined) => {
        if (token) {
          resolve(token)
        } else {
          reject()
        }
      })
    })
  }

  return <GriegHttpClientProvider onGetBearerToken={getBearerToken}>{children}</GriegHttpClientProvider>
}

export default HttpWrapper
