interface ICalculatedEmission {
  nox: number
  scrNox: number
  scrNoxReduction: number
  scrTestFailed: boolean
}

export default new (class NoxCalculator {
  calculateVoyageEmission(voyage: any) {
    if (voyage.consumption) {
      const consumption = voyage.consumption //YAE 12.08.21 API change JSON.parse(voyage.consumption)
      return consumption
    }
  }

  calculateEngineEmission(
    noxFactorLng: number | null,
    noxFactor: number,
    scrFactor: number | null,
    lngConsumption: number | null,
    fuelConsumption: number,
    fuelConsumptionScr: number,
    ureaConsumption: number
  ): ICalculatedEmission {
    let noxFuel = 0
    let noxLng = 0
    let scrNox = 0
    let scrNoxReduction = 0
    let scrTestFailed = false

    if (noxFactorLng !== null && noxFactorLng > 0 && lngConsumption !== null && lngConsumption > 0) {
      noxLng = noxFactorLng * lngConsumption
    }
    if (scrFactor !== null && scrFactor > 0 && noxFactor) {
      // Calculation of Nox when engine running with SCR-enabled
      // Use maximum value of urea-reduction or threshold nox-factor calculations.
      const ureaCalculated = fuelConsumptionScr * noxFactor - ureaConsumption / 1.5
      const scrFactorCalculated = fuelConsumptionScr * scrFactor
      if (ureaCalculated < scrFactorCalculated) {
        //use scrFactorCalculated - too much Urea used
        scrNox = scrFactorCalculated
        scrNoxReduction = 0
        scrTestFailed = true
      } else {
        scrNox = fuelConsumptionScr * noxFactor
        scrNoxReduction = ureaConsumption / 1.5
      }
    }
    if (noxFactor > 0 && fuelConsumption > 0) {
      noxFuel = noxFactor * fuelConsumption
    }
    return {
      nox: noxFuel + noxLng,
      scrNox: scrNox,
      scrNoxReduction: scrNoxReduction,
      scrTestFailed: scrTestFailed,
    }
  }
})()
