import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import { useTenants } from '../GriegTenants'
import { useHistory } from 'react-router-dom'

export const TenantSwitcher = () => {
  const { activeTenant, changeTenant, tenants } = useTenants()
  const history = useHistory()

  const onChangeTenant = (event: React.ChangeEvent<{ value: unknown }>) => {
    const locationA = history.location.pathname.split('/')
    locationA[1] = event.target.value as string
    history.push('/company/' + event.target.value)
    changeTenant(event.target.value as string)
  }

  return (
    <>
      <FormControl variant="standard" fullWidth={true} style={{ minWidth: 150 }}>
        <InputLabel id="tenants-selector-input">Company</InputLabel>
        <Select
          labelId="tenants-selector-input"
          id="tenants-selector"
          value={activeTenant?.code}
          onChange={onChangeTenant}
        >
          {tenants.map((tenant, key) => (
            <MenuItem key={key} value={tenant.code}>
              {tenant.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default TenantSwitcher
