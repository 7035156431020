import { Typography } from '@material-ui/core'
import React from 'react'
import { IReleaseNoteProps } from './ReleaseNote1'

const ReleaseNote5 = (props: IReleaseNoteProps) => {
  return (!props.english) ? (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q2-2023
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Posisjonsdata / Tolkning av turer
      </Typography>
      <Typography variant="body1">
        Det har tidvis vært problemer med tolkning av turer grunnet manglende eller for gamle posisjonsdata. Vi har forbedret AIS-håndteringen betydelig i denne versjonen.
        (Dessverre har det ikke tilbakevirkende kraft, så turer før 20. mars er fortsatt sårbare)
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Utenlandske havner
      </Typography>
      <Typography variant="body1">
        I denne versjonen er det 34 utenlandske havner:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Lerwick, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Gi oss beskjed om hvilke andre utenlandshavner dere trenger, så vil vi oppdatere med de viktigste fortløpende.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        Det er ikke lenger mulig å legge inn overlappende intervaller eller perioder med udefinerte turer i charter report.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Vessel Details
      </Typography>
      <Typography variant="body1">
        Det er nå åpnet for å endre navn og MMSI-nummer på alle enheter i flåten.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Generelle forbedringer
      </Typography>
      <Typography variant="body1">
        Forbedret håndtering av arkiverte perioder i charter report.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Korrigert feilvisning av rapport-status for båter uten tur-data
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q2-2023
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Positioning data / Voyage interpretation
      </Typography>
      <Typography variant="body1">
        Earlier versions have had internittent voyage interpretation issues due to lacking or too old positioning data. We have now improved the AIS-data handling significantly.
        (Unfortunately this change is not retroactive, so voyages before March 20th are still vulnerable)
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Foreign ports
      </Typography>
      <Typography variant="body1">
        This version has 34 foreign ports:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Lerwick, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Let us know which other ports are needed, and we will add the most frequently requested.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        It is no longer possible to add overlapping periods or use periods containing undefined voyages in charter report.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Vessel Details
      </Typography>
      <Typography variant="body1">
        You may now change vessel name or MMSI-number directly.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        General improvements
      </Typography>
      <Typography variant="body1">
        Improved handling of archived periods in charter report.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Fixed erroneous display of vessel report-status for vessels with missing voyage-data.
      </Typography>
    </>
  )}

export default ReleaseNote5