import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'

import NoxVesselList from './NoxVesselList'

const NoxReportTable = (props): JSX.Element => {
  const { completedVessels, currentPeriod, printable } = props
//  const completedVesselsSorted = completedVessels.sort((a, b) => (a.name > b.name ? 1 : -1))

  const TableCellColumn = withStyles((theme) => ({
    head: {
      backgroundColor: printable && theme.palette.common.white,
      color: printable && theme.palette.common.black,
      borderTop: '1px solid #b8babc',
      borderBottom: '1px solid #b8babc',
    },
    body: {
      fontSize: 14,
      borderBottom: '1px solid rgba(81, 81, 81, 0.5)',
    },
  }))(TableCell)

  const useStyles = makeStyles((theme) => ({
    tableContainer: {
      marginTop: 20,
    },
    table: {
      minWidth: 400,
      borderBottom: '1px solid rgba(81, 81, 81, 0.5)',
    },
  }))

  const classes = useStyles()
  const apiDelay = 0 //add delay (in ms) if backend starts struggling with multiple, parallel voyages-requests

  return (
    <TableContainer elevation={0} className={classes.tableContainer} component={Paper}>
      <Table
        style={printable ? { backgroundColor: '#fff', color: '#000' } : {}}
        stickyHeader={true}
        className={classes.table}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <TableCellColumn>Vessel Name</TableCellColumn>
            <TableCellColumn align="right">
              Identifier <small>(IMO)</small>
            </TableCellColumn>
            <TableCellColumn align="right">Rate</TableCellColumn>
            <TableCellColumn align="right">
              NOx <small>(kg)</small>
            </TableCellColumn>
            <TableCellColumn align="right">
              Estimated Tax&nbsp;<small>(NOK)</small>
            </TableCellColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {completedVessels.map((vessel, i) => (
            <NoxVesselList printable={printable} key={vessel.id} vessel={vessel} currentPeriod={currentPeriod} delay={i * apiDelay} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default NoxReportTable
