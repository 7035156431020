import { Link, Typography } from '@material-ui/core'
import React from 'react'
import { IReleaseNoteProps } from './ReleaseNote1'

const ReleaseNote2 = (props: IReleaseNoteProps) => {
    return (!props.english) ? (
      <>
        <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
          NOxDigital Q2-2022
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Tolkning av regelverket
        </Typography>
        <Typography variant="body1">
          Den største endringen i denne versjon er i tolkning av turer. Endringene er et resulatat av samarbeid med våre kunder og NOx-fondet.
          Se <Link style={{cursor: "pointer", color:'lightskyblue'}}><span onClick={props.openTaxRules}>"Tolkning av regelverket"</span></Link> for detaljer.
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Utenlandske havner
        </Typography>
        <Typography variant="body1">
          I denne versjonen er det 33 utenlandske havner:
        </Typography>
        <Typography variant="body1" style={{marginLeft: 16}}>
          Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
        </Typography>
        <Typography variant="body1" style={{marginTop: 8}}>
          Gi oss beskjed om hvilke andre utenlandshavner dere trenger, så vil vi oppdatere med de viktigste fortløpende.
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Arkivering
        </Typography>
        <Typography variant="body1">
          Ikke arkiver før kvartalet er ferdig rapportert. Det er ikke mulig å gjøre endringer på enkeltturer etter arkivering.
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Charter Report
        </Typography>
        <Typography variant="body1">
          En ny, kompakt versjon av Charter Report viser samlet antall turer, utslipp/forbruk og kostnad for hver periode som er lagt inn i rapporten.
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Forbedringer
        </Typography>
        <Typography variant="body1">
          Enkelte forespørsler etter statiske data er optimalisert for å spare tid.
        </Typography>
        <Typography variant="h6" style={{marginTop: 16, marginBottom: 16}}>
          Endringer i Revisjon 2 (Juli)
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Charter Report
        </Typography>
        <Typography variant="body1">
          Charter Report vil nå inkludere både fuel-, lng- og urea forbruk og total kostnad dersom man angir pris i de respektive feltene.
        </Typography>
        <Typography variant="body1" style={{marginTop: 6}}>
          <b>NB!</b>&nbsp;&nbsp;Du kan kun velge dato-intervaller innenfor det aktive kvartalet!
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          NOx-Voyages
        </Typography>
        <Typography variant="body1">
          Forbedret sortering av arkiverte perioder.
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
          NOxDigital Q2-2022
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Tax rule interpretations
        </Typography>
        <Typography variant="body1">
          The most significant change in this version regards voyage taxability. The new interpretation is a result of the combined efforts and input of our users in cooperation with the Norwegian NOx Fund.
        </Typography>
        <Typography variant="body1" style={{marginTop: 6}}>
          See <Link style={{cursor: "pointer", color:'lightskyblue'}}><span onClick={props.openTaxRules}>"Tax rule interpretations"</span></Link> for more detail.
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Foreign ports
        </Typography>
        <Typography variant="body1">
          This version has 33 foreign ports:
        </Typography>
        <Typography variant="body1" style={{marginLeft: 16}}>
          Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
        </Typography>
        <Typography variant="body1" style={{marginTop: 6}}>
          Let us know which other ports are needed, and we will add the most frequently requested.
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Archiving
        </Typography>
        <Typography variant="body1">
          Only use the archiving option after the quarterly reporting is completed. No voyage modifications are allowed after archiving.
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Charter Report
        </Typography>
        <Typography variant="body1">
          A new compact version of the Charter Report shows the total number of voyages, emission/consumption and cost for each of the periods included in the report.
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Optimizations
        </Typography>
        <Typography variant="body1">
          Some requests for static data have been optimized to save time.
        </Typography>
        <Typography variant="h6" style={{marginTop: 16, marginBottom: 16}}>
          Updates in Revision 2 (July)
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          Charter Report
        </Typography>
        <Typography variant="body1">
          Charter Report can now include both fuel-, lng- and urea consumption and aggregated cost, provided that the respective prices are entered.
        </Typography>
        <Typography variant="body1" style={{marginTop: 6}}>
          <b>NB!</b>&nbsp;&nbsp;You can only select data-ranges within the active quarter!
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
          NOx-Voyages
        </Typography>
        <Typography variant="body1">
          Improved sorting for archived periods.
        </Typography>
      </>
    )
}

export default ReleaseNote2