import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import Logo from '../assets/NOxD_logo.png'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.tooltip + 1,
      backgroundColor: '#0d1217',
      opacity: 1,
      color: '#fff',
    },
    logo: {
      maxWidth: '70%',
    },
  })
)

export const AppCurtain = () => {
  const classes = useStyles()

  return (
    <Backdrop transitionDuration={0} className={classes.backdrop} open={true}>
      <img src={Logo} className={classes.logo} alt={'NOx Digital'} />
    </Backdrop>
  )
}

export default AppCurtain
