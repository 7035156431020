// Map tails colors

export const RED_TAIL = 'rgba(248, 61, 15, 0.900)'
export const GREEN_TAIL = 'rgba(19, 241, 37, 0.884)'
export const WHITE_TAIL = '#ddd'

// Highlight color in dark background
export const RED_HIGHLIGHT = 'rgba(187, 70, 66, 0.938)'
export const GREEN_HIGHLIGHT = 'rgba(102, 165, 93, 0.815)'
export const WHITE_HIGHLIGHT = '#d4d6d7'

// Pending / Completed
export const PENDING = '#dd6555'
export const COMPLETED = GREEN_HIGHLIGHT
export const COMMITTED = '#dddddd'
