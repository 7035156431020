import './UiMapBoxControl.scss'

import mapboxgl from 'mapbox-gl'
import ReactDOM from 'react-dom'

export default class UiMapBoxControl {
  map: mapboxgl.Map | undefined
  container: any
  component: any

  constructor(component: any) {
    this.component = component
  }

  onAdd(map: mapboxgl.Map) {
    this.map = map
    this.container = document.createElement('div')
    ReactDOM.render(this.component, this.container)
    return this.container
  }

  onRemove() {
    if (this.container) {
      ReactDOM.unmountComponentAtNode(this.container)
    }
    this.map = undefined
  }
}
