import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import {useEffect, useLayoutEffect, useState} from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useAppContext } from '../../common/GriegProvider/wrappers/AppContextWrapper'
//import ErrorModal from '../../components/ApolloErrorModal'
//import LoadingSpinner from '../../components/LoadingSpinner'
import VoyageMap from '../../components/VoyageMap'
//import VesselEnginesLookup from '../../graphql/queries/vesselEngines'
import { VoyageDetails } from './components/VoyageDetails'
import VoyageNoxCalculation from './components/VoyageNoxCalculation'
import MapLegendLabel from 'src/components/MapLegendLabel'
import OverrideDeleteHandler from "./components/OverrideDeleteHandler"
import { Voyage, VoyageWithOverride } from 'src/lib/apis/types/voyage'
import { VesselWithEngines } from 'src/lib/apis/types/vessel'
import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'
import Button from "@material-ui/core/Button"
import {mapZones} from "../../components/zones"

export interface VoyageOverviewVariables {
  voyageId: any
  vesselId: any
  periodId: any //YAE 25.08.21 :: Added this because voyages query requires it...
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      width: 'calc(100% + 15px)',
      height: '100%',
      marginLeft: -7,
      marginTop: -16,
      marginBottom: 36,
      minHeight: 300,
      maxHeight: 400,
      '& canvas': {
        outline: 0,
      },
    },
  })
)

const VoyageRoute = (props: GriegRouteProps) => {
  const { vesselId } = useParams<VoyageOverviewVariables>() //NB! voyageID is only a list reference, not an actual id to use for lookup!

  //YAE 25.08.21 :: Added current voyage and vessel via state in VesselVoyagesRoute --> UiListItemLink
  // This is to handle non-stored, live-generated voyages with id=0.
  const {state} = useLocation<{ voyage: VoyageWithOverride;
                                voyages: VoyageWithOverride[];
                                allVoyages: VoyageWithOverride[];
                                readOnly: boolean;
                                vessel: VesselWithEngines;
                                currentPeriod: NoxPeriod;
                                offshoreZones: mapZones[]}>()

  const classes = useStyles()
  const {route} = props
  const {setRouteOptionsCallback} = useAppContext()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))
  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps

  const transform = (voyage: VoyageWithOverride | undefined) => {
    if (!voyage) {
      return
    }
    let v: any = {...voyage}
    //    const consumption = voyage.consumption //YAE 12.08.21 API change ? JSON.parse(voyage.consumption) : {}
    //    const events = voyage.events //YAE 12.08.21 API change ? JSON.parse(voyage.events) : {}
    //    v.consumption = consumption
    //    v.events = events
    v.vessel = vesselId
    return v
  }

  const currentPeriod = state?.currentPeriod
  const voyages = state?.voyages
  const allVoyages = state?.allVoyages
  const offshoreZones = state?.offshoreZones
  const readOnly = state?.readOnly
  const [ voyage, setVoyage ] = useState<Voyage>( state?.voyage)
  const [ currentVoyageIndex, setCurrentVoyageIndex ] = useState<number>(voyages.findIndex(v => v.id === state?.voyage.id))

  const next = () => {
      if(currentVoyageIndex < voyages?.length - 1) {
          const indexNow = currentVoyageIndex
          setCurrentVoyageIndex(indexNow + 1)
          setVoyage(voyages[indexNow + 1])
      }
  }

  const prev = () => {
    if(currentVoyageIndex > 0) {
        const indexNow = currentVoyageIndex
        setCurrentVoyageIndex(indexNow - 1)
        setVoyage(voyages[indexNow - 1])
    }
}


    useEffect( () => {
 //           console.log("VR: active voyage has id:", voyage.id)
    }, [voyage])

    /* YAE 30.11.21 -> don't need to reload this - edit engines is no longer an option
      //YAE 07.09.21 -> reload engine data from back-end to get updated values from editEngine
      if (data?.engines) {
        state.vessel.engines = data.engines
      }
    */
  const vessel = state?.vessel
  //  const consumptions = state?.voyage.consumptions

  if (!vessel || !voyage) {
    return (
        <Paper>Could not find required data for this voyage. This should not happen, and is most likely an
          error.</Paper>
    )
  } else
      //have voyage and vessel, valid taxable state goes to VoyageNoxCalculation for detailed viewing and editing,
      return (
        <>
          <div className={classes.map}>
            <VoyageMap key={voyage.id} lat={62} lng={6} voyages={[transform(voyage)]} offshoreZones={offshoreZones} preSelectedVoyageId={1}/>
            <MapLegendLabel/>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button disabled={currentVoyageIndex===0}
                    style={{ marginRight: 32, marginTop: 8, marginBottom: 8, padding: "3px 48px" }}
                    variant="outlined" color="secondary" onClick={prev}>
                &lt;&lt; PREV
            </Button>
            <Button disabled={currentVoyageIndex === voyages?.length - 1}
                    style={{ marginTop: 8, marginBottom: 8, padding: "3px 48px" }}
                    variant="outlined" color="secondary" onClick={next}>
                NEXT &gt;&gt;
            </Button>
          </div>
          <VoyageDetails vessel={vessel} voyage={voyage}/>
          <OverrideDeleteHandler voyages={allVoyages} currentVoyageId={voyage.id} vesselId={vesselId} readOnly={readOnly}/>
          <VoyageNoxCalculation key={voyage.id} vessel={vessel} voyage={voyage} allVoyages={allVoyages} currentPeriod={currentPeriod} readOnly={readOnly} manualOverride={false}/>
            {/*}          <>
            {(voyage.taxable !== null) && (
                <VoyageNoxCalculation vessel={vessel} voyage={voyage} currentPeriod={currentPeriod} manualOverride={false}/>
            )}
            {(voyage.taxable === null) && (
                <VoyageFactHandler vessel={vessel} voyage={voyage} voyages={voyages} currentPeriod={currentPeriod} manualOverride={false}/>
            )}
          </>
          */}
        </>
    )
}

export default VoyageRoute
