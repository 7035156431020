import React, { useContext, useState } from 'react'

export const defaultLocalStorageKey = 'gc-active-tenant'

export type GriegTenantsProviderProps = {
  children: React.ReactElement
  storage?: Storage | null
  onGetTenants: () => Promise<Tenant[]>
  localStorageKey?: string
}

export type Tenant = {
  code: string
  name: string
  description?: string
  roles: string[]
  id: string
}

export type TenantsHandler = {
  tenants: Tenant[]
  activeTenant: Tenant | null
  roles: string[]
  tenantsLoading: boolean
  tenantsLoadingError: string | null
  fetchTenants(): Promise<Tenant[]>
  changeTenant(code: string): Promise<Tenant>
  removeActiveTenant(): Promise<boolean>
}

export const TenantsContext = React.createContext<TenantsHandler | null>(null)
export const useTenants = () => useContext(TenantsContext)!

export const GriegTenantsProvider = (props: GriegTenantsProviderProps) => {
  const { children, onGetTenants, localStorageKey, storage } = props
  const [tenantsLoading, setTenantsLoading] = useState<boolean>(false)
  const [tenantsLoadingError, setTenantsLoadingError] = useState<string | null>(null)
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [activeTenant, setActiveTenant] = useState<Tenant | null>(null)
  const [roles, setRoles] = useState<string[]>([])

  const getTenantFromStorage = (tn: Tenant[]) => {
    if (storage) {
      const tenantCode = storage.getItem(localStorageKey ?? defaultLocalStorageKey)
      if (tenantCode) {
        const tenant = tn.find((tenant: Tenant) => tenant.code === tenantCode)
        return tenant ? tenant : null
      } else {
        return null
      }
    } else {
      return activeTenant
    }
  }

  const changeTenant = (code: string) => {
    return new Promise<Tenant>((resolve, reject) => {
      const newActiveTenant = tenants.find((tenant: Tenant) => tenant.code === code)
      if (newActiveTenant) {
        setActiveTenant(newActiveTenant)
        setRoles(newActiveTenant.roles)
        if (storage) {
          storage.setItem(localStorageKey ?? defaultLocalStorageKey, newActiveTenant.code)
        }
        resolve(newActiveTenant)
      } else {
        reject('The selected tenant code is not on the list of available tenants and their respective tenant codes.')
      }
    })
  }

  const removeActiveTenant = () => {
    return new Promise<boolean>((resolve) => {
      setActiveTenant(null)
      setRoles([])
      if (storage) {
        storage.removeItem(localStorageKey ? localStorageKey : defaultLocalStorageKey)
      }
      resolve(true)
    })
  }

  const refreshActiveTenant = (currentTenant: Tenant | null, newTenants: Tenant[]) => {
    if (currentTenant) {
      const updatedActiveTenant = newTenants.find((tenant) => tenant.code === currentTenant.code)
      if (updatedActiveTenant) {
        setActiveTenant(updatedActiveTenant)
        setRoles(updatedActiveTenant.roles)
      } else {
        removeActiveTenant()
      }
    }
  }

  const fetchTenants = () => {
    return new Promise<Tenant[]>((resolve, reject) => {
      setTenantsLoading(true)
      setTenantsLoadingError(null)
      onGetTenants()
        .then((newTenants: Tenant[]) => {
          setTenants(newTenants)
          const currentTenant = getTenantFromStorage(newTenants)
          refreshActiveTenant(currentTenant, newTenants)
          setTenantsLoading(false)
          resolve(newTenants)
        })
        .catch((error: string) => {
          setTenantsLoadingError(error)
          setTenantsLoading(false)
          reject(error)
        })
    })
  }

  return (
    <TenantsContext.Provider
      value={{
        tenants: tenants,
        activeTenant: activeTenant,
        roles: roles,
        tenantsLoading: tenantsLoading,
        tenantsLoadingError: tenantsLoadingError,
        fetchTenants: fetchTenants,
        changeTenant: changeTenant,
        removeActiveTenant: removeActiveTenant,
      }}
    >
      {children}
    </TenantsContext.Provider>
  )
}

GriegTenantsProvider.defaultProps = {
  storage: localStorage,
}

export default GriegTenantsProvider
