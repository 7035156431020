import { AxiosInstance, AxiosResponse } from "axios"
import { NoxPeriod } from "./types/noxPeriod"

export default class PeriodsAPI {
  httpClient: AxiosInstance
  baseUrl: string

  constructor(httpClient: AxiosInstance, baseUrl: string) {
    this.httpClient = httpClient
    this.baseUrl = baseUrl
  }
  
  async getPeriods(): Promise<NoxPeriod[]> {
    const response: AxiosResponse = await this.httpClient.get(`${this.baseUrl}/nox-periods`)
    return response.data
  }
}