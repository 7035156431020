import { Typography } from '@material-ui/core'
import CompanyIcon from '@griegconnect/krakentools-react-icons/HostCompany'
import EventIcon from '@griegconnect/krakentools-react-icons/Event'

import useStyles from './NoxReportHeader.styles'

const NoxReportHeader = ({ activeTenant, currentPeriod, printable }) => {
  const classes = useStyles()

  return (
    <div style={printable ? { color: '#000' } : { color: 'rgba(255, 255, 255, 0.87)' }}>
      <Typography
        style={printable ? { color: '#000' } : { display: 'none' }}
        className={classes.reportTitle}
        variant="h6"
      >
        Report of NOx Emission
      </Typography>
      <div className={classes.headerRow}>
        <CompanyIcon className={classes.headerIcon} />
        <Typography component="span" variant="body1">
          Company
        </Typography>
        <Typography component="span" variant="body1" className={classes.headerRight}>
          {activeTenant?.name}
        </Typography>
      </div>
      <div className={classes.headerRow}>
        <EventIcon className={classes.headerIcon} />
        <Typography component="span" variant="body1">
          Period
        </Typography>
        <Typography component="span" variant="body1" className={classes.headerRight}>
          {currentPeriod?.name}
        </Typography>
      </div>
    </div>
  )
}

export default NoxReportHeader
