import { IconButton } from '@material-ui/core'
import IconAdd from '@material-ui/icons/Add'
import IconZoomExtent from '@material-ui/icons/CropSquare'
import IconSub from '@material-ui/icons/Remove'
import mapboxgl from 'mapbox-gl'
import React from 'react'

import UiMapBoxControl from './UiMapBoxControl'

interface INavigationControlProps {
  map: mapboxgl.Map
  setExtent: Function
  children?: any
}

function NavigationControl(props: INavigationControlProps): any {
  const { map, setExtent } = props

  const zoomIn = () => {
    map.zoomIn()
  }
  const zoomOut = () => {
    map.zoomOut()
  }
  const zoomExtent = () => {
    setExtent()
  }
  return (
    <>
      <IconButton onClick={zoomIn} className={'my-custom-control mapboxgl-ctrl'}>
        <IconAdd />
      </IconButton>
      <IconButton onClick={zoomExtent} className={'my-custom-control mapboxgl-ctrl'}>
        <IconZoomExtent />
      </IconButton>
      <IconButton onClick={zoomOut} className={'my-custom-control mapboxgl-ctrl'}>
        <IconSub />
      </IconButton>
    </>
  )
}
const NavigationControlMap = (map: mapboxgl.Map, setExtent: Function) =>
  new UiMapBoxControl(<NavigationControl map={map} setExtent={setExtent} />)
export default NavigationControlMap
