import { Divider, Fab, List, ListItemIcon, ListItemText, Paper, Button, Grid, useMediaQuery, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import VesselIcon from '@griegconnect/krakentools-react-icons/Vessel'
import MaressIcon from '../../assets/Maress_logo.png'
import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import React, {useLayoutEffect, useState} from 'react'
import {useQuery} from 'react-query'
import { useHistory } from 'react-router-dom'

import { useAppContext } from '../../common/GriegProvider/wrappers/AppContextWrapper'
import ErrorModal from '../../components/ApolloErrorModal'
import LoadingSpinner from '../../components/LoadingSpinner'
import { UiListItemLink } from '../../components/UiListItemLink'
import { useApis } from 'src/lib/ApiProvider'
import {VesselWithEngines} from 'src/lib/apis/types/vessel'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      marginLeft: -8,
      marginRight: -8,
    },
    titleRow: {
      marginBottom: 25,
      marginLeft: 15,
      paddingRight: 15,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    filterBtn: {
      marginLeft: 'auto',
    },
    paper: {
        padding: 36
    },
  })
)

// interface IFleetProps extends WithStyles<typeof styles>, GriegRouteProps {
//   classes: any;
// }

interface IListItemProps {
  vessel: any;
}

const ListItem = React.memo((props: IListItemProps) => {
  const { vessel } = props


  return (
    <>
      <UiListItemLink to={{ pathname: '/vessel/' + vessel.id }} style={{marginBottom: '4px'}}>
        <ListItemIcon>
          <VesselIcon fontSize="large" style={{ marginRight: '5px' }} />
        </ListItemIcon>
        <ListItemText
          inset={false}
          style={ { marginTop: 10 } }
          primary={
              <Grid
                  container
                  style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }}
              >
                  <Typography component="span" variant="body1">
                    {(vessel.name && vessel.name + ' (' + vessel.imo + ')') || 'No vessel name'}
                  </Typography>
                  {vessel.enabledMaress && (
                      <Box display = "flex" justifyContent = "right">
                          <img src={MaressIcon} style={{marginRight: 8}} height={23} alt={'Maress'} />
                      </Box>
                  )}
              </Grid>
          }
        />
      </UiListItemLink>
      <Divider style={{ marginTop: 4, marginBottom: 4 }} />
    </>
  )
})

const Fleet: React.FunctionComponent<GriegRouteProps> = (props) => {
  const history = useHistory()
  const classes = useStyles()
  const  { apis } = useApis()
  const { route } = props
  const { setRouteOptionsCallback } = useAppContext()
  const [maressTestOn, setMaressTestOn] = useState(false)
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))
  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps

  const largerWidth = useMediaQuery('(min-width: 884px)')

    const {
    isLoading: isLoadingVessels,
    isError: isErrorVessels,
    error: errorVessels,
    data: vessels,
    refetch: refetchVessels
  } = useQuery<VesselWithEngines[], Error>('vessels', async () => 
   await apis.vesselsApi.getVessels()
  )

  const addVesselHandler = () => {
    history.push('/vessels/new')
  }

  if (isErrorVessels) {
    return <ErrorModal error={errorVessels} refetch={refetchVessels} />
  }

  if (isLoadingVessels || maressTestOn) {
    return <LoadingSpinner />
  }
 
  const listItems = typeof vessels !== 'undefined' ? vessels
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((vessel, key) => {
      return <ListItem vessel={vessel} key={key} />
    }) : []

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    )

    const checkMaress = async () => {
        if (typeof (vessels) !== 'undefined' && vessels.length > 0 && !isLoadingVessels && !maressTestOn) {
            setMaressTestOn(true)
            vessels.forEach((v) => {
                apis.vesselsApi.setVesselInMaressStatus(v.id)
            })
            //Wait for it, then reload
            await delay(vessels.length * 400)
            setMaressTestOn(false)
            history.push('/dashboard')
            history.push('/vessels')
        }
    }

  return (
    <div style={{ padding: 36 }}>
      <div className={classes.titleRow}>
          <Grid
              container
              style={
                  largerWidth
                      ? { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10 }
                      : { display: 'flex', flexDirection: 'column-reverse', gap: 20 }
              }
          >
            <Typography variant="h5">Vessels ({vessels?.length})</Typography>
            <Box display = "flex" justifyContent = "center">
                <Button variant="outlined" color="secondary" onClick={checkMaress} disabled={maressTestOn}>
                    Verify Maress Availability
                </Button>
            </Box>
            <Box display = "flex" justifyContent = "right" style={{marginRight: 24}}>
              <Fab id="addicon" color="primary" aria-label="Add Vessel"
                  onClick={addVesselHandler}
              >
                  <AddIcon/>
              </Fab>
            </Box>
          </Grid>
      </div>
      {vessels?.length === 0 && (
        <Paper className={classes.paper}>
          Your fleet has no vessels yet. Click Add Vessel (+) button to build your fleet.
        </Paper>
      )}
      <List>{listItems}</List>
    </div>
  )
}

export default Fleet
