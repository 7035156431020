import { Auth0User, useAuth } from '@griegconnect/krakentools-react-auth'
import { useHttp } from '@griegconnect/krakentools-react-http'

import { GlobalContextNav, Profile, ProfileMenu } from '@griegconnect/krakentools-react-ui'
import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useMemo } from 'react'

import { useAppContext } from '../GriegProvider/wrappers/AppContextWrapper'
import { useConfig } from '../GriegProvider/wrappers/config/grieg-config-provider'
import { useTenants } from '../GriegTenants'

import Logo from '../../assets/NOxD_logo.png'
import { Identity, TenantsApi } from '../@griegconnect/react-tenants/src'

//import { Mobile } from '@griegconnect/krakentools-react-icons';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      paddingTop: `env(safe-area-inset-top)`,
      paddingLeft: `env(safe-area-inset-left)`,
      paddingRight: `env(safe-area-inset-right)`,
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    logo: {
      height: 38,
      paddingLeft: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      paddingLeft: theme.spacing(2),
    },
    settingsIcon: {
      marginRight: theme.spacing(2),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    userIcons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  })
)

export type NavigationWrapperProps = {
  appbarTitle?: string
  onToggleDrawer: () => void
}

export const TopBar = (props: NavigationWrapperProps) => {
  const { logout, loginWithRedirect, isAuthenticated } = useAuth()
  const classes = useStyles()
  const { config } = useConfig()
  const { onToggleDrawer } = props
  const { routeOptions } = useAppContext()
  const { activeTenant, tenants, changeTenant } = useTenants()
  const { httpClient } = useHttp()
  const getHttpClient = useMemo(() => httpClient, [httpClient])
  const tenantsApi = useMemo(
    () => new TenantsApi(getHttpClient, config!.api.tenantApiUrl, null),
    [getHttpClient, config]
  )
  const [user, setUser] = React.useState<Identity>()

  React.useEffect(() => {
    ;(async () => {
      if (isAuthenticated) {
        const res = await tenantsApi.getCurrentUserAccess()
        setUser(res.user)
      }
    })()
  }, [tenantsApi, isAuthenticated])

  const onProfileSave = async (profile: Profile) => {
    await tenantsApi.updateUser(profile.fullName ?? profile.email, profile.phone ? profile.phone.toString() : '')
    const cUser = await tenantsApi.getCurrentUserAccess()
    setUser(cUser.user)
  }
  const logoutHandler = () => {
    logout({
      client_id: config!.auth0.clientId,
      federated: true,
      returnTo: config!.auth0.redirectUri,
    })
  }

  const loginHandler = () => {
    const redirectOptions = {
      appState: {
        targetUrl: window.location.pathname + window.location.search + window.location.hash,
      },
    }
    loginWithRedirect(redirectOptions)
  }

  const changeTenantHandler = (tenant: any) => {
    changeTenant(tenant.code)
  }

  const createProfileFromIdToken = (idTokenUser: Auth0User): Profile => {
    return {
      fullName: idTokenUser.name ? idTokenUser.name : 'Missing name',
      email: idTokenUser.email ? idTokenUser.email : 'Missing email',
      picture: idTokenUser.picture ? idTokenUser.picture : undefined,
    }
  }

  const onProfileImageSave = async (imageString: string) => {
    const nUser = await tenantsApi.updateProfileImage(imageString)
    setUser(nUser)
  }
  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Hidden smUp>
          <IconButton color="inherit" onClick={onToggleDrawer}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <a href="/">
            <img alt={props.appbarTitle} src={Logo} className={classes.logo} />
          </a>
        </Hidden>
        <Typography variant="h6" component="h1" color="inherit" noWrap={true} className={classes.title}>
          <span></span>
        </Typography>
        <Slide in direction="left">
          <div className={classes.userIcons}>
            {routeOptions?.showGlobalNav && (
              <GlobalContextNav
                // enableAppSelection={true}
                // applications={[
                //   {
                //     name: 'Port Go',
                //     logo: <Mobile />,
                //     url: config!.auth0.redirectUri,
                //   },
                // ]}
                enableTenantSelection={true}
                activeTenant={activeTenant ? activeTenant : undefined}
                tenants={tenants}
                onTenantChange={changeTenantHandler}
              />
            )}
            {routeOptions?.showProfile && user && (
              <ProfileMenu
                profile={createProfileFromIdToken(user!)}
                onProfileSave={onProfileSave}
                onProfileImageSave={onProfileImageSave}
                onLogin={loginHandler}
                onLogout={logoutHandler}
              />
            )}
          </div>
        </Slide>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
