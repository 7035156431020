import { makeStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 900,
      margin: '0 auto',
    },
    titleRow: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 15,
      marginLeft: 15,
      paddingRight: 15,
    },
    filterBtn: {
      marginLeft: 'auto',
    },
    filterIcon: {
      color: 'white',
    },
  })
)

export default useStyles
