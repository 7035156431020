import { Container, makeStyles, Theme } from '@material-ui/core'
import createStyles from '@material-ui/core/styles/createStyles'
import ArrowRed from '../assets/icons/arrow_red.svg'
import ArrowGreen from '../assets/icons/arrow_green.svg'
import ArrowWhite from '../assets/icons/arrow_white.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapLabelContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0.7em 0',
      backgroundColor: '#11181e',
    },
  })
)

const MapLegendLabel = () => {
  const classes = useStyles()

  return (
    <Container className={classes.mapLabelContainer}>
      <span>
        <img style={{ margin: '.3em 0 0 .5em', width: 33 }} src={ArrowRed} alt="Red tail" /> Undefined
      </span>
      <span>
        <img style={{ margin: '-.5em -0.2em 0 1.5em', width: 40 }} src={ArrowWhite} alt="White tail" /> Non-taxable
      </span>
      <span>
        <img style={{ margin: '.5em 0 0 1.5em', width: 33 }} src={ArrowGreen} alt="Green tail" /> Taxable
      </span>
    </Container>
  )
}

export default MapLegendLabel
