// Components
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CharterListItems from './CharterListItems'

import { CharterDataTableProps } from './CharterReport.types'
import { useRecoilState } from 'recoil'
import { charterReportTotal, fuelPriceState, lngPriceState, ureaPriceState, vesselNoxRateState, VesselNoxRateStateType } from '../../../atoms'
import { NumberFormat } from '../../../utils/NumberFormat'

const CharterDataTable = (props: CharterDataTableProps) => {
  const { detailedView, vesselId, periods, printable, currentPeriod, reportableVoyages, taxableOnly, includeAllNox } = props
  const [totalConsumptions] = useRecoilState(charterReportTotal)
  const [fuelPrice] = useRecoilState<number>(fuelPriceState)
  const [lngPrice] = useRecoilState<number>(lngPriceState)
  const [ureaPrice] = useRecoilState<number>(ureaPriceState)
  const [vesselInfo] = useRecoilState<VesselNoxRateStateType>(vesselNoxRateState)
  const numbers = new NumberFormat()
  const NOX_TAX_RATE_HIGH: number = typeof currentPeriod.rateHigh === 'undefined' ? 0 : currentPeriod.rateHigh
  const NOX_TAX_RATE_LOW: number = typeof currentPeriod.rateLow === 'undefined' ? 0 : currentPeriod.rateLow
  const NOX_TAX_RATE_FULL: number = typeof currentPeriod.rateFull === 'undefined' ? 0 : currentPeriod.rateFull

  const font_color_white = 'rgba(255, 255, 255, 0.87)'
  const tableCellStyles = printable ? {} : { color: font_color_white }
  const numCellPrintableStyle = printable ? {paddingRight: 10, minWidth: 100} : { color: font_color_white, minWidth: 100 }

  const noxRate: number =
    vesselInfo.noxRate === 'rate-low'
      ? NOX_TAX_RATE_LOW
      : vesselInfo.noxRate === 'rate-high'
      ? NOX_TAX_RATE_HIGH
      : vesselInfo.noxRate === 'rate-full'
      ? NOX_TAX_RATE_FULL
      : 0

  const sortedPeriods = periods?.sort((a, b) => Number(new Date(a.timeFrom)) - Number(new Date(b.timeFrom)))

  const totalNox = typeof totalConsumptions.totalNox === 'number' ? numbers.largeNumber(totalConsumptions.totalNox.toFixed(0)) : totalConsumptions.totalNox
  const estimatedTotalNoxTax = typeof totalConsumptions.totalNox === 'number' && totalConsumptions.totalNox >= 0.01 ? Math.round(totalConsumptions.totalNox) * noxRate : 0
  const totalFuel = typeof totalConsumptions.totalFuel === 'number' ? numbers.largeNumber(totalConsumptions.totalFuel.toFixed(2)) : totalConsumptions.totalFuel
  const estimatedTotalFuelCost = typeof totalConsumptions.totalFuel === 'number' && totalConsumptions.totalFuel >= 0.01 ? totalConsumptions.totalFuel * fuelPrice : 0
  const totalLng = typeof totalConsumptions.totalLng === 'number' ? numbers.largeNumber(totalConsumptions.totalLng.toFixed(2)) : totalConsumptions.totalLng
  const estimatedTotalLngCost = typeof totalConsumptions.totalLng === 'number' && totalConsumptions.totalLng >= 0.01 ? totalConsumptions.totalLng * lngPrice : 0
  const totalUrea = typeof totalConsumptions.totalUrea === 'number' ? numbers.largeNumber(totalConsumptions.totalUrea.toFixed(0)) : totalConsumptions.totalUrea
  const estimatedTotalUreaCost =  typeof totalConsumptions.totalUrea === 'number' && ureaPrice >= 0.01 ? Math.round(totalConsumptions.totalUrea) * ureaPrice: 0

  return (
    <TableContainer elevation={0} style={printable ? {} : { backgroundColor: 'transparent' }} component={Paper}>
      <Table aria-label="spanning table">
        <TableHead style={{ borderTop: '1px solid #b8babc' }}>
          <TableRow>
            <TableCell style={printable ? {width: 130} : { color: font_color_white, width: 130 }} >
              Period
            </TableCell>
            <TableCell style={printable ? {width: 200} : { color: font_color_white, width: 200 }} >
              Activity
            </TableCell>
            <TableCell style={numCellPrintableStyle} align="right">
              NOx <small>(kg)</small>
            </TableCell>
            {fuelPrice > 0 && (
            <TableCell style={numCellPrintableStyle} align="right">
              Fuel <small>(t)</small>
            </TableCell>
            )}
            {lngPrice > 0 && (
            <TableCell style={numCellPrintableStyle} align="right">
              LNG <small>(t)</small>
            </TableCell>
            )}
            {ureaPrice > 0 && (
            <TableCell style={numCellPrintableStyle} align="right">
              Urea <small>(l)</small>
            </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {
          sortedPeriods?.map((period) => (
            <CharterListItems
              detailedView={detailedView}
              printable={printable}
              key={period.id}
              vesselId={vesselId}
              timeFrom={period.timeFrom}
              timeTo={period.timeTo}
              fuelPrice={fuelPrice}
              lngPrice={lngPrice}
              ureaPrice={ureaPrice}
              reportableVoyages={reportableVoyages}
              taxableOnly={taxableOnly}
              includeAllNox={includeAllNox}
            />
          ))}
        </TableBody>
      </Table>
      <Table aria-label="spanning table">
        <TableBody style={{ borderTop: '1px solid #b8babc', borderBottom: '0px' }}>
          <TableRow>
            <TableCell style={printable ? {width: 330} : { color: font_color_white, width: 330 }} variant="body">
              Total emission/consumption:
            </TableCell>
            <TableCell variant="body" style={numCellPrintableStyle} align="right">
              {totalNox}
            </TableCell>
            {fuelPrice > 0 && (
            <TableCell variant="body" style={numCellPrintableStyle} align="right">
              {totalFuel}
            </TableCell>
            )}
            {lngPrice > 0 && (
            <TableCell variant="body" style={numCellPrintableStyle} align="right">
              {totalLng}
            </TableCell>
            )}
            {ureaPrice > 0 && (
            <TableCell variant="body" style={numCellPrintableStyle} align="right">
              {totalUrea}
            </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell style={tableCellStyles} variant="body">
              Total cost: {fuelPrice + lngPrice + ureaPrice > 0 && (numbers.largeNumber((estimatedTotalNoxTax + estimatedTotalFuelCost + estimatedTotalLngCost + estimatedTotalUreaCost).toFixed(2)))}
            </TableCell>
            <TableCell variant="body" style={numCellPrintableStyle} align="right">
              {numbers.largeNumber((estimatedTotalNoxTax).toFixed(2))}
            </TableCell>
            {fuelPrice > 0 && (
            <TableCell variant="body" style={numCellPrintableStyle} align="right">
              {numbers.largeNumber((estimatedTotalFuelCost).toFixed(2))}
            </TableCell>
            )}
            {lngPrice > 0 && (
            <TableCell variant="body" style={numCellPrintableStyle} align="right">
              {numbers.largeNumber((estimatedTotalLngCost).toFixed(2))}
            </TableCell>
            )}
            {ureaPrice > 0 && (
            <TableCell variant="body" style={numCellPrintableStyle} align="right">
              {numbers.largeNumber((estimatedTotalUreaCost).toFixed(2))}
            </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CharterDataTable
