import {useLayoutEffect, useEffect, useState} from 'react'
import {Container, IconButton, Typography} from '@material-ui/core'
import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import { useAppContext } from 'src/common/GriegProvider/wrappers/AppContextWrapper'
import { useRecoilState } from 'recoil'
import { periodFilterState } from '../../atoms'
import PeriodsSelect from '../../utils/PeriodsSelect'

// Components
import { initialFilterConfig, selectedFilterConfig } from '../noxReport/config/filterConfig'
import ErrorModal from '../../components/ApolloErrorModal'
import LoadingSpinner from '../../components/LoadingSpinner'
import CharterOptions from './components/CharterOptions'

import { useQuery } from 'react-query'

import useStyles from './CharterReport.styles'
import { useApis } from 'src/lib/ApiProvider'
import { Vessel } from 'src/lib/apis/types/vessel'
import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'
import UiFilterDrawer, {IUiFilterSelections} from "../../components/UiFilterDrawer"
import UiShowActiveFilter from "../../components/UiShowActiveFilter"
import FilterIcon from "@griegconnect/krakentools-react-icons/Filter"

const CharterReport = (props: GriegRouteProps): JSX.Element => {
  // These 4 lines below assure the route highlight active state for the module
  const { route } = props
  const { setRouteOptionsCallback } = useAppContext()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))
  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [filterState, setFilterState] = useRecoilState(periodFilterState)
  const [filterConfig, setFilterConfig] = useState(initialFilterConfig)
  const { apis } = useApis()

  const classes = useStyles()

  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!filterDrawerOpen)
  }

  const handleFilterChange = (newFilterSelection: IUiFilterSelections) => {
    setFilterState({ ...(newFilterSelection as any) } as any)
  }
  const {
    isLoading: isLoadingVessels,
    isError: isErrorVessels,
    error: errorVessels,
    data: vessels,
    refetch: refetchVessels,
  } = useQuery<Vessel[], Error>('vessels', async () =>
    await apis.vesselsApi.getVessels()
  )

  const fleetVesselsSorted = vessels?.sort((a, b) => (a.name > b.name ? 1 : -1))

  const { isLoading: isLoadingPeriods, isError: isErrorPeriods, data: periods, error: errorPeriods, refetch: refetchPeriods } = useQuery<NoxPeriod[], Error>('noxPeriods', async () =>
    await apis.periodsApi.getPeriods()
  )

  const currentPeriod: any = PeriodsSelect.getPeriod(periods, filterState)

  useEffect(() => {
    const selectedFilterConf = selectedFilterConfig(periods)
    setFilterConfig(selectedFilterConf)

    if (!filterState.quarter && currentPeriod) {
      setFilterState({ ...filterState, quarter: [currentPeriod.id] })
    }

    if (!filterState.show_report) {
      setFilterState({ ...filterState, show_report: [0] })
    }
    // eslint-disable-next-line
  }, [filterState])

  if (isErrorVessels) return <ErrorModal error={errorVessels} refetch={refetchVessels} />
  if (isErrorPeriods) return <ErrorModal error={errorPeriods} refetch={refetchPeriods} />
  if (isLoadingVessels || isLoadingPeriods) return <LoadingSpinner />

  return (
    <Container className={classes.container}>
      <div className={classes.titleRow}>
        <UiShowActiveFilter
            filterConfig={filterConfig as any}
            filterSelections={{ ...filterState }}
            onChange={handleFilterChange}
        />
        <IconButton onClick={toggleFilterDrawer} className={classes.filterBtn}>
          <FilterIcon fontSize="large" className={classes.filterIcon} />
        </IconButton>
      </div>

      <UiFilterDrawer
          filterConfig={filterConfig as any}
          filterSelections={{ ...filterState }}
          open={filterDrawerOpen}
          onClose={toggleFilterDrawer}
          onChange={handleFilterChange}
      />
      <div className={classes.titleRow}>
        <Typography variant="h5" style={{marginLeft: 15}}>Charter Report</Typography>
      </div>
      <div className={classes.container}>
        <CharterOptions fleetVessels={fleetVesselsSorted} currentPeriod={currentPeriod} periodsData={periods} />
      </div>
    </Container>
  )
}

export default CharterReport
