import { AxiosInstance } from "axios"
import { AisStatic } from "./types/ais"
import { Lucene } from "./types/lucene"

export default class SearchitAPI {
  httpClient: AxiosInstance
  baseUrl: string

  constructor(httpClient: AxiosInstance, baseUrl: string) {
    this.httpClient = httpClient
    this.baseUrl = baseUrl
  }
  

  async searchVessels(searchTerm: string): Promise<Lucene.Source<AisStatic.Data>[]> {
    const endpointPath = `${this.baseUrl}/indices/ais-statics/statics`

      const resp = await this.httpClient.post<Lucene.Search<AisStatic.Data>>(
        endpointPath,
        {
          must: {
            multi_match: {
              query: searchTerm,
              type: 'bool_prefix',
              fields: [
                'name^3',
                'payload.callsign',
                'payload.imoText^2',
                'payload.mmsiText^4'
              ],
              prefix_length: 2
            }
          },
        }
      )
  
    return resp.data.hits.hits.map(h => h._source)
  }
}