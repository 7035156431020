import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { DateTime } from 'luxon'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
	main: {
		width: '58px',
		display: 'grid',
		gridTemplateRows: '18px 40px',
		justifyContent: 'stretch',
	},
	headerLine: {
		backgroundColor: '#FF9A7A',
		color: 'rgba(0,0,0,0.87)',
		textAlign: 'center',
		borderRadius: '2px 2px 0 0',
		display: 'grid',
		alignItems: 'center',
		textTransform: 'uppercase'
	},
	dateField: {
		backgroundColor: 'rgba(255,255,255)',
		color: 'rgba(0,0,0,0.87)',
		alignItems: 'center',
		textAlign: 'center',
		borderRadius: '0 0 2px 2px',
		display: 'grid',
	},
}))

interface ICalendarProps {
	date: Date;
}

const Calendar = (props: ICalendarProps) => {
	const classes = useStyles()
	const date = DateTime.fromJSDate(props.date)
	const monthName = date.toFormat('LLL')
	const dayNumber = date.toFormat('dd')
	return (
		<div className={classes.main}>
			<div className={classes.headerLine}><Typography variant="body2" style={{ fontWeight: 500, fontSize: '12px' }}>{monthName}</Typography></div>
			<div className={classes.dateField}><Typography variant="h5" style={{ fontSize: '26px' }}>{dayNumber}</Typography></div>
		</div>
	)
}

export default Calendar
