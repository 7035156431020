import React, { useContext, useEffect, useState } from 'react'
import { useHttp } from '@griegconnect/krakentools-react-http'
import { useTenants } from 'src/common/GriegTenants'
import { useConfig } from 'src/common/GriegProvider/wrappers/config/grieg-config-provider'
import AreasAPI from './apis/areasApi'
import PeriodsAPI from './apis/periodsApi'
import VesselsAPI from './apis/vesselsApi'
import VoyagesAPI from './apis/voyagesApi'
import { useAuth } from '@griegconnect/krakentools-react-auth'
import SearchitAPI from './apis/searchitApi'

export interface IApis {
  vesselsApi: VesselsAPI
  periodsApi: PeriodsAPI
  voyagesApi: VoyagesAPI
  areasApi: AreasAPI
  searchitApi: SearchitAPI
}

interface IApisContextValues {
  apis: IApis
}

export const ApisContext = React.createContext<IApisContextValues | null>(null)
export const useApis = () => useContext(ApisContext)!

export const ApisWrapper: React.FC = (props) => {
  const { children } = props
  const [apis, setApis] = useState<IApis | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const { config } = useConfig()
  const { activeTenant } = useTenants()
  const { httpClient: axiosClient } = useHttp()
  const httpClient = axiosClient('', true)
  const { getTokenSilently } = useAuth()

  useEffect(() => {
    getTokenSilently().then((data) => {
      setAccessToken(data ?? null)
    })
    if (activeTenant && accessToken) {
      httpClient.interceptors.request.use(
        (config) => {
          config.headers['Authorization'] = 'Bearer ' + accessToken
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )
      const apiBaseUrl = `${config?.api.noxApiUrl}/companies/${activeTenant.id}`
      const vesselsApi = new VesselsAPI(httpClient, apiBaseUrl)
      const periodsApi = new PeriodsAPI(httpClient, apiBaseUrl)
      const areasApi = new AreasAPI(httpClient, apiBaseUrl)
      const voyagesApi = new VoyagesAPI(httpClient, apiBaseUrl)
      const searchitApi = new SearchitAPI(httpClient, `${config?.api.searchitBaseUrl}/tenants/${activeTenant.id}`)
      setApis({
        vesselsApi,
        periodsApi,
        areasApi,
        voyagesApi,
        searchitApi
      })
    }
  }, [activeTenant, accessToken]) // eslint-disable-line react-hooks/exhaustive-deps

  return apis ? <ApisContext.Provider value={{ apis }}>{children}</ApisContext.Provider> : <>{children}</>
}

export default ApisWrapper
