import {
    Button, Divider,
    Drawer, FormControlLabel,
    Grid, IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Radio,
    Select, TextField
} from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import { Engine } from '@griegconnect/krakentools-react-icons'
import CloseIcon from '@griegconnect/krakentools-react-icons/Close'
import EditIcon from '@griegconnect/krakentools-react-icons/Edit'
import VesselIcon from '@griegconnect/krakentools-react-icons/Vessel'
import { DialogWithContent } from '@griegconnect/krakentools-react-ui'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Delete } from '@material-ui/icons'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { useTenants } from 'common/GriegTenants'
import { useState, useEffect } from 'react'
import React from 'react'

import { useQuery } from 'react-query'

import ExpandingInfoBox from '../../../vessels/add/components/ExpandingInfoBox'
import { IConfig } from '../AddVessel'
import PeriodsSelect from "../../../../utils/PeriodsSelect"
import { useApis } from 'src/lib/ApiProvider'
import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'
import { VesselNoxRate, VesselType } from 'src/lib/apis/types/vessel'
import { aisCountryMap } from 'src/constants/aisCountryMap'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleRow: {
            margin: '32px 24px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        infoContainer: {
            margin: '32px 24px',
            '& p': {
                '& span': {
                    color: '#BBBBBF',
                    marginRight: '16px',
                },
            },
        },
        radio: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        deleteButton: {
            color: '#FF9A7A',
            '& svg': {
                color: '#FF9A7A',
            },
        },
    })
)

export type Rate = 'rate-low' | 'rate-high' | 'rate-full' | ''

type IVesselInfo = {
    imo: number,
    mmsi: number,
    name: string,
    enabledMaress: boolean,
    length?: number,
    width?: number
}

interface IVesselInfoProps {
    vesselDetails?: IVesselInfo
    configs: IConfig[]
    selectedRate: VesselNoxRate
    selectedVesselType?: VesselType
    openDrawer: boolean
    onRateOrClassChange: (noxRate: VesselNoxRate, vesselType?: VesselType) => void
    onNameOrMMSIChange: (name: string, mmsi: number, enabledMaress: boolean) => void
    onConfigStart: (pageNumber: number) => void
    onDrawerToggle: (open: boolean, id?: string) => void
    onConfigEdit: () => void
    onConfigDelete: () => void
    infoNoxRate: boolean
    infoEngines: boolean
    onInfoToggle?: (key: string) => void
    onCompleted?: () => void
    editEngineAllowed?: boolean
    editMode?: boolean
    onRemoveVessel?: () => void
}


const VesselInfo: React.FunctionComponent<IVesselInfoProps> = (props) => {
    const { vesselDetails } = props
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false)
    const [showEditNameOrMMSI, setShowEditNameOrMMSI] = useState<boolean>(false)
    const [vesselName, setVesselName] = useState<string | undefined>(vesselDetails?.name)
    const [vesselMMSI, setVesselMMSI] = useState<number | undefined>(vesselDetails?.mmsi)
    const [vesselEnabledMaress, setVesselEnabledMaress] = useState<boolean>(vesselDetails?.enabledMaress || false)
    const classes = useStyles()
    const { activeTenant } = useTenants()
    const { apis } = useApis()
    
    //YAE 10.11.21 -> added to get correct rates for current period
    const { data: periods } = useQuery<NoxPeriod[], Error>('noxPeriods', async () =>
        await apis.periodsApi.getPeriods()
    )

    const { selectedRate, selectedVesselType } = props
    const currentPeriod = PeriodsSelect.getPeriod(periods, null)
    const [vesselClass, setVesselClass] = useState<number>(0)

    useEffect(() => {
        setVesselClass(selectedVesselType === "fishing" ? 2 : selectedVesselType === "special" ? 1 : 0)
    }, [selectedVesselType])

    useEffect(() => {
        if (vesselDetails) {
            setVesselMMSI(vesselDetails.mmsi)
            setVesselName(vesselDetails.name)
            setVesselEnabledMaress(vesselDetails.enabledMaress)
        }
    }, [vesselDetails])

    const handleInputChange = (key: string) => (evt: any) => {
        if(key === 'vesselName'){
            setVesselName(evt.target.value.toUpperCase())
        }
        if(key === 'vesselMMSI'){
            setVesselMMSI(evt.target.value)
        }
        if(key === 'vesselEnabledMaress'){
            setVesselEnabledMaress(evt.target.checked)
        }
    }

    const handleInputSave = () => {
        if (vesselDetails) {
            setShowEditNameOrMMSI(false)
            console.log(vesselDetails, vesselEnabledMaress)
            props.onNameOrMMSIChange(vesselName || vesselDetails.name, vesselMMSI || vesselDetails.mmsi, vesselEnabledMaress)
        }
    }

    const handleEditNameOrMMSIClick = () => {
        setShowEditNameOrMMSI(true)
    }
    
    const handleRateChange = (event) => {
        const value = event.target.value
        props.onRateOrClassChange(value, selectedVesselType)
    }

    const handleClassChange = (event) => {
        const value = event.target.value
        setVesselClass(value)
        if (value === 1) {
            props.onRateOrClassChange(selectedRate, "special")
        } else if (value === 2) {
            props.onRateOrClassChange(selectedRate, "fishing")
        } else {
            props.onRateOrClassChange(selectedRate)
        }
    }

    const handleConfigStart = (pageNumber: number) => (evt: any) => {
        props.onConfigStart(pageNumber)
    }

    const handleDrawerToggle = (open: boolean, id?: string) => (evt: any) => {
        if (id) {
            props.onDrawerToggle(open, id)
        } else {
            props.onDrawerToggle(open)
        }
    }

    const handleEdit = (evt: any) => {
        props.onConfigEdit()
    }

    const handleDelete = (evt: any) => {
        props.onConfigDelete()
    }

    const handleCancelDialogs = () => {
        setShowRemoveConfirmation(false)
        setShowEditNameOrMMSI(false)
        if (vesselDetails) {
            setVesselName(vesselDetails.name)
            setVesselMMSI(vesselDetails.mmsi)
        }
    }

    const handleConfirmRemove = () => {
        if (props.onRemoveVessel) {
            props.onRemoveVessel()
        }
    }

    const country = aisCountryMap.get(Number(String(vesselDetails?.mmsi).slice(0, 3)))

    return (
        <div style={{ padding: 36 }}>
            <Dialog
                open={showEditNameOrMMSI}
            >
                <DialogContent>
                    <TextField style={{marginTop: 20}}
                               fullWidth={true}
                               label="Edit vessel firstname"
                               name="vesselName"
                               value={vesselName}
                               onChange={handleInputChange("vesselName")}
                    />
                    <TextField style={{marginTop: 20, marginBottom: 20}}
                               fullWidth={true}
                               label="Edit vessel MMSI"
                               name="vesselMMSI"
                               value={vesselMMSI}
                               onChange={handleInputChange("vesselMMSI")}
                    />
                    <FormControlLabel
                        disabled={false}
                        control={
                            <Checkbox
                              checked={vesselEnabledMaress}
                              name={"Active in Maress"}
                              onChange={handleInputChange("vesselEnabledMaress")}
                              inputProps={{ 'aria-label': 'Active in Maress' }}
                            />
                        }
                        label={'Active in Maress'}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDialogs} style={{marginRight: 20}}>
                        Cancel
                    </Button>
                    <Button onClick={handleInputSave} variant={'outlined'} color={'secondary'} style={{marginRight: 20}}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <DialogWithContent
                open={showRemoveConfirmation}
                onClose={handleCancelDialogs}
                onNext={handleConfirmRemove}
                title="Remove vessel"
            >
                <Typography>
                    This will remove {vesselDetails?.name}(IMO: {vesselDetails?.imo}) from{' '}
                    {activeTenant?.name || ' your fleet'}. Are you sure you want to proceed ?
                </Typography>
            </DialogWithContent>
            <div className={classes.titleRow}>
                <VesselIcon fontSize="large" style={{ marginRight: '16px' }} />
                <Typography component="h1" variant="h5">
                    {vesselDetails?.name}
                </Typography>
                <span style={{marginLeft: 10}}>
                    {country && ` [${country}]`}
                </span>
                <IconButton onClick={handleEditNameOrMMSIClick} color="inherit" style={{marginLeft: 20}}>
                    <EditIcon/>
                </IconButton>
            </div>

            <div className={classes.infoContainer}>
                <Typography component="p" variant="body1">
                    <span>MMSI:</span> {vesselDetails?.mmsi}
                </Typography>
                <Typography component="p" variant="body1">
                    {/*S.H. - Correction of typo for object call for IMO number*/}
                    <span>IMO:</span> {vesselDetails?.imo}
                </Typography>
                {/* <Typography component='p' variant='body1'><span>Vessel Type (generic):</span> {props.vesselDetails.typeGeneric}</Typography>
				<Typography component='p' variant='body1'><span>Vessel Type (detailed):</span> {props.vesselDetails.typeDetailed}</Typography> */}
            </div>
            <div className={classes.infoContainer}>
                {/* <Typography component='p' variant='body1'><span>Status:</span> {props.vesselDetails.status}</Typography>
				<Typography component='p' variant='body1'><span>Flag:</span> {props.vesselDetails.country}</Typography>
				<Typography component='p' variant='body1'><span>Gross Tonnage:</span> {props.vesselDetails.gross}</Typography> */}
                {/* <Typography component='p' variant='body1'><span>Length:</span> {props.vesselDetails.length}</Typography>
				<Typography component='p' variant='body1'><span>Width:</span> {props.vesselDetails.width}</Typography> */}
                {/* <Typography component='p' variant='body1'><span>Year built:</span> {props.vesselDetails.year}</Typography> */}
            </div>
                    <ExpandingInfoBox
                        title="NOx rate"
                        text="Vessel NOx rate must be set according to NOx Fund definitions. High rate applies to oil and gas operations on the shelf. Low rate applies to all other NOx taxable activities, including shipping, fishing and industry. Full rate applies to vessels not enrolled with the NOx Fund. Rates are determined annually and shown below. "
                    />

                    <ListItem selected={props.selectedRate === 'rate-low'}>
                        <ListItemIcon>
                            <Radio
                                checked={props.selectedRate === 'rate-low'}
                                onChange={handleRateChange}
                                value="rate-low"
                                name="nox-rate"
                                inputProps={{ 'aria-label': 'Low rate' }}
                                disableRipple={true}
                                className={classes.radio}
                            />
                        </ListItemIcon>
                        <ListItemText
                            inset={false}
                            style={{ marginTop: 10 }}
                            primary={
                                <>
                                    <Grid container={true}>
                                        <Grid item={true} xs={12}>
                                            <Typography component="span" variant="body1">
                                                Low rate for shipping, fishing and industry
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container={true}>
                                        <Grid item={true} xs={12}>
                                            <Typography component="span" variant="body1">
                                                NOK {currentPeriod?.rateLow?.toLocaleString('no-NO',{minimumFractionDigits: 2,maximumFractionDigits: 2,})} pr. kg NOx
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem selected={props.selectedRate === 'rate-high'}>
                        <ListItemIcon>
                            <Radio
                                checked={props.selectedRate === 'rate-high'}
                                onChange={handleRateChange}
                                value="rate-high"
                                name="nox-rate"
                                inputProps={{ 'aria-label': 'High rate' }}
                                disableRipple={true}
                                className={classes.radio}
                            />
                        </ListItemIcon>
                        <ListItemText
                            inset={false}
                            style={{ marginTop: 10 }}
                            primary={
                                <>
                                    <Grid container={true}>
                                        <Grid item={true} xs={12}>
                                            <Typography component="span" variant="body1">
                                                High rate for oil and gas activities on the shelf
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Typography component="span" variant="body1">
                                                NOK {currentPeriod?.rateHigh?.toLocaleString('no-NO',{minimumFractionDigits: 2,maximumFractionDigits: 2,})}  pr. kg NOx
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem selected={props.selectedRate === 'rate-full'}>
                        <ListItemIcon>
                            <Radio
                                checked={selectedRate === 'rate-full'}
                                onChange={handleRateChange}
                                value="rate-full"
                                name="nox-rate"
                                inputProps={{ 'aria-label': 'Full rate' }}
                                disableRipple={true}
                                className={classes.radio}
                            />
                        </ListItemIcon>
                        <ListItemText
                            inset={false}
                            style={{ marginTop: 10 }}
                            primary={
                                <>
                                    <Grid container={true}>
                                        <Grid item={true} xs={12}>
                                            <Typography component="span" variant="body1">
                                                Full rate if vessel is not enrolled with the Norwegian NOx Fund
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container={true}>
                                        <Grid item={true} xs={12}>
                                            <Typography component="span" variant="body1">
                                                NOK {currentPeriod?.rateFull?.toLocaleString('no-NO',{minimumFractionDigits: 2,maximumFractionDigits: 2,})} pr. kg NOx
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        />
                    </ListItem>

                    <Divider style={{ marginTop: 16, marginBottom: 16 }} />

                    <ExpandingInfoBox
                        title="Vessel classification"
                        text="Special rules apply for tax-exemption of 'Special vessel'. (The option for 'Fishing vessel' is for future use.)"
                    />

                    <Select
                        style={{ marginTop: 10, marginLeft:16, marginBottom: 20 }}
                        variant = 'standard'
                        value={vesselClass}
                        onChange={handleClassChange}
                    >
                        <MenuItem value={0}>{"Normal vessel"}</MenuItem>
                        <MenuItem value={1}>{"Special vessel"}</MenuItem>
                        <MenuItem value={2}>{"Fishing vessel"}</MenuItem>
                    </Select>

                    <Divider style={{ marginTop: 16, marginBottom: 16 }} />

            <>
                {props.editMode && (
                    <ExpandingInfoBox
                        title="Engine(s)"
                        text="Engine names and configuration. The NOx-factors shown are with and without SCR if it is applicable for the selected engine. "
                    />
                )}

                {props.configs.length > 0 &&
                props.configs.map((config) => {
                        return (
                            <ListItem key={`config-${config.name}-${config.id}`} selected={true} style={{ marginBottom: 1 }}>
                                <Engine style={{ marginRight: 16 }} />

                                <ListItemText
                                    inset={false}
                                    style={{ marginTop: 10 }}
                                    primary={
                                        <>
                                            <Grid container={true} justify="space-between" alignItems="center">
                                                <Grid item={true}>
                                                    <Grid container={true}>
                                                        <Grid item={true} xs={12}>
                                                            <Typography component="span" variant="body1">
                                                                {config.name} ({config.id})
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item={true} xs={12}>
                                                            { config.nox && Number(config.nox) > 0 && (
                                                                <Typography component="span" variant="body1">
                                                                    {Number(config.nox).toFixed(2)} kg NOx per ton fuel
                                                                </Typography>
                                                            )}
                                                            { (isNaN(Number(config.nox)) && isNaN(Number(config.noxLng)) ) && (
                                                                <Typography component="span" variant="body1">
                                                                    NOx factors are missing for this engine!
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        {config.noxScr && Number(config.noxScr) > 0 && (
                                                            <Grid item={true} xs={12}>
                                                                <Typography component="span" variant="body1">
                                                                    {Number(config.noxScr).toFixed(2)} Threshold NOx factor with Urea (SCR)
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {config.noxLng && Number(config.noxLng) > 0 && (
                                                            <Grid item={true} xs={12}>
                                                                <Typography component="span" variant="body1">
                                                                    {Number(config.noxLng).toFixed(2)} kg NOx per ton LNG
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {config.noxScrLng && Number(config.noxScrLng) > 0 && (
                                                            <Grid item={true} xs={12}>
                                                                <Typography component="span" variant="body1">
                                                                    {Number(config.noxScrLng).toFixed(2)} Threshold NOx factor with Urea (SCR) for LNG
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                />
                                {props.editEngineAllowed && (
                                    <Grid item={true}>
                                        <MoreHorizIcon
                                            fontSize="large"
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleDrawerToggle(true, config.id)}
                                        />
                                    </Grid>
                                )}
                            </ListItem>
                        )
                    }
                )}
            </>


            <Grid container={true}>
                <>
                    {(props.editEngineAllowed && props.editMode) && (
                        <Grid item={true} xs={12} style={{ textAlign: 'center', marginTop: '32px' }}>
                            <Button color="primary" variant="contained" onClick={handleConfigStart(2)}>
                                Add engine configuration
                            </Button>
                        </Grid>
                    )}
                    { props.editMode && (
                        <Grid
                            item={true}
                            xs={12}
                            style={{
                                textAlign: 'center',
                                marginTop: '40px',
                                marginBottom: '16px',
                            }}
                        >
                            <Button color="secondary" variant="outlined" onClick={() => setShowRemoveConfirmation(true)}>
                                Remove vessel from fleet
                            </Button>
                        </Grid>
                    )}
                </>

                {!props.editMode && (
                    <Grid
                        item={true}
                        xs={12}
                        style={{
                            textAlign: 'center',
                            marginTop: '40px',
                            marginBottom: '16px',
                        }}
                    >
                        <Button color="primary" variant="contained" onClick={props.onCompleted}>
                            Add vessel to fleet
                        </Button>
                    </Grid>
                )}
            </Grid>

            <Drawer anchor="bottom" open={props.openDrawer} onClose={handleDrawerToggle(false)}>
                <div role="presentation" onClick={handleDrawerToggle(false)} onKeyDown={handleDrawerToggle(false)}>
                    <List>
                        {['Edit', 'Delete', 'Cancel'].map((text, index) => (
                            <ListItem
                                button
                                key={`drawer-item-${index + 1}`}
                                className={index === 1 ? classes.deleteButton : undefined}
                                onClick={index === 0 ? handleEdit : index === 1 ? handleDelete : undefined}
                            >
                                <ListItemIcon>{index === 0 ? <EditIcon /> : index === 1 ? <Delete /> : <CloseIcon />}</ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Drawer>
        </div>
    )
}

export default VesselInfo
