import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import { useEffect, useLayoutEffect, useState } from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useApis } from 'src/lib/ApiProvider'
import { VesselForm, VesselId, VesselNoxRate, VesselType, VesselWithEngines } from 'src/lib/apis/types/vessel'
import { EngineId } from 'src/lib/apis/types/engine'

import { useAppContext } from '../../common/GriegProvider/wrappers/AppContextWrapper'
import ErrorModal from '../../components/ApolloErrorModal'
import LoadingSpinner from '../../components/LoadingSpinner'
import { IConfig } from '../fleetoverview/add/AddVessel'
import VesselInfo from '../fleetoverview/add/components/VesselInfo'

const EditVessel = (props: GriegRouteProps) => {
  const [noxRate, setNoxRate] = useState<VesselNoxRate>('rate-high')
  const [vesselType, setVesselType] = useState<VesselType | undefined>()
  const [engineConfigs, setEngineConfigs] = useState<IConfig[]>([])
  const [engineId, setEngineId] = useState<number>(0)
  const [vesselDetails, setVesselDetails] = useState<any>({name: '', imo: '', mmsi: '', enabledMaress: undefined})
  const history = useHistory()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const { id } = useParams<{ id: string }>()
  const { route } = props
  const { apis } = useApis()
  const { setRouteOptionsCallback } = useAppContext()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))
  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps

  const queryClient = useQueryClient()

  const handleUpdateCompleted = () => {
    //
  }
  const {mutate: updateVessel, error: mutationError } = useMutation((args: {id: VesselId, form: VesselForm}) => apis.vesselsApi.updateVessel(args.id, args.form), {
    onSuccess: handleUpdateCompleted,
  })

  const {mutate: removeVessel } = useMutation((id: VesselId) => apis.vesselsApi.removeVessel(id), {
    onSuccess: handleUpdateCompleted,
  })

  const {mutate: deleteEngine } = useMutation((args: {id: VesselId, engineId: EngineId}) => apis.vesselsApi.deleteEngine(args.id, args.engineId), {
    onSuccess: handleUpdateCompleted,
  })

  const { isLoading, error, data: vessel, refetch } = useQuery<VesselWithEngines, Error>(
    ['vessel', id], 
    async () => await apis.vesselsApi.getVessel(id),
    { enabled: !!id }
  )

  useEffect(() => {
    if (!isLoading && vessel) {
      setNoxRate(vessel.noxRate)
      setVesselType(typeof vessel.vesselType === 'undefined' ? undefined : vessel.vesselType)
      setVesselDetails({name: vessel.name, imo: vessel.imo, mmsi: vessel.mmsi, enabledMaress: vessel.enabledMaress})
      const configs = vessel.engines?.map((engine) => ({
        id: engine.id,
        name: engine.name,
        amount: '1',
        system: (engine.scrFactor !== 0 && 'scr') || '',
        nox: '' + engine.noxFactor || '0',
        noxScr: '' + engine.scrFactor || '0',
        noxLng: '' + engine.noxFactorLng || '0',
//        noxScrLng: '' + engine.scrFactorLng || '0', //YAE 15.02.22 -> removed scrFactorLng
      })).sort((a, b) => a.name > b.name ? 1 : -1)
      if (configs && configs.length > 0) {
        setEngineConfigs(configs)
      }
    }
  }, [isLoading, vessel])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorModal error={error} refetch={refetch} />
  }

  if (mutationError) {
    return <ErrorModal error={mutationError} refetch={refetch} />
  }

  const handleAddEngine = () => {
    history.push('/vessel/' + id + '/addEngine')
  }

  const changeRateOrClass = async (vesselNoxRate: VesselNoxRate, vesselType?: VesselType) => {
    setNoxRate(vesselNoxRate)
    setVesselType(vesselType)
    await updateVessel({
      id,
      form: {
        imo: vessel?.imo ?? 0,
        name: vessel?.name ?? '',
        noxRate: vesselNoxRate,
        mmsi: vessel?.mmsi ?? 0,
        length: 0,
        width: 0,
        vesselType: vesselType ?? undefined,
        enabledMaress: vessel?.enabledMaress
      },
    })
  }

  const changeNameOrMMSI = async(vesselName: string, vesselMMSI: number, vesselEnabledMaress: boolean) => {
    await updateVessel({
      id,
      form: {
        imo: vessel?.imo ?? 0,
        name: vesselName,
        noxRate: noxRate,
        mmsi: vesselMMSI,
        length: 0,
        width: 0,
        vesselType: vesselType ?? undefined,
        enabledMaress: vesselEnabledMaress
      },
    })
    setVesselDetails({name: vesselName, imo: vessel?.imo, mmsi: vesselMMSI, enabledMaress: vesselEnabledMaress})
  }

  const toggleDrawer = (open, id) => {
    setEngineId(id)
    setDrawerOpen(!drawerOpen)
  }

  const handleEditEngineConfig = () => {
    history.push('/vessel/' + id + '/editengine/' + engineId)
  }

  const handleRemoveVessel = async () => {
    await removeVessel(id)
    await queryClient.invalidateQueries('vessels')
    await queryClient.invalidateQueries('vesselPeriods')
    history.push('/vessels')
  }

  const handleDeleteEngine = async () => {
    await deleteEngine({
      id,
      engineId: String(engineId)
    })
    setEngineConfigs(engineConfigs.filter((c) => Number(c.id) !== engineId))
  }

  return (
      <VesselInfo
          vesselDetails={vesselDetails}
          configs={engineConfigs}
          selectedRate={noxRate}
          selectedVesselType={vesselType}
          onNameOrMMSIChange={changeNameOrMMSI}
          onRateOrClassChange={changeRateOrClass}
          onConfigStart={handleAddEngine}
          openDrawer={drawerOpen}
          onDrawerToggle={toggleDrawer}
          onConfigEdit={handleEditEngineConfig}
          onConfigDelete={handleDeleteEngine}
          onRemoveVessel={handleRemoveVessel}
          infoNoxRate={false}
          infoEngines={false}
          editEngineAllowed={!vessel?.enabledMaress}
          editMode={true}
      />
  )
}

export default EditVessel
