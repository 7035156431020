import DashboardIcon from '@griegconnect/krakentools-react-icons/Dashboard'
import VesselIcon from '@griegconnect/krakentools-react-icons/Vessel'
import VoyageIcon from '@griegconnect/krakentools-react-icons/Voyage'
import { BookingReference, Activity } from '@griegconnect/krakentools-react-icons'
import { GriegRouteType } from '@griegconnect/krakentools-react-router'
import GriegRouter from 'common/GriegRouter'
import React from 'react'

import Dashboard from './modules/dashboard/Dashboard'
import EditEngine from './modules/editEngine/EditEngine'
import AddEngine from './modules/editVessel/AddEngine'
import EditVessel from './modules/editVessel/EditVessel'
import AddVessel from './modules/fleetoverview/add/AddVessel'
import FleetVesselList from './modules/fleetoverview/FleetVesselList'
import fleetVoyagesRoute from './modules/fleetVoyages/fleetVoyagesRoute'
import VesselVoyagesRoute from './modules/vesselVoyages/VesselVoyagesRoute'
import VoyageRoute from './modules/voyage/VoyageRoute'
import NoxReport from './modules/noxReport/NoxReport'
import CharterReport from './modules/charterReport/CharterReport'

export const routes: GriegRouteType[] = [
  {
    path: '/',
    name: 'Quarterly Summary',
    component: Dashboard,
    type: 'protected',
    requiresTenant: true,
    roles: [],
    options: {
      moduleIcon: <DashboardIcon />,
      showModuleInDrawer: true,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'Add Vessel',
    path: '/vessels/new',
    component: AddVessel,
    type: 'private',
    requiresTenant: true,
    options: {
      moduleIcon: <VesselIcon />,
      showModuleInDrawer: false,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'Edit Vessel',
    path: '/vessel/:id',
    component: EditVessel,
    type: 'private',
    requiresTenant: true,
    options: {
      moduleIcon: <VesselIcon />,
      showModuleInDrawer: false,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'Edit Engine',
    path: '/vessel/:vesselId/engine/:engineId',
    component: EditEngine,
    type: 'private',
    requiresTenant: true,
    options: {
      moduleIcon: <VesselIcon />,
      showModuleInDrawer: false,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'Edit Engine',
    path: '/vessel/:vesselId/voyage/:voyageId/editengine/:engineId',
    component: EditEngine,
    type: 'private',
    requiresTenant: true,
    options: {
      moduleIcon: <VesselIcon />,
      showModuleInDrawer: false,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'Edit Engine',
    path: '/vessel/:vesselId/editengine/:engineId',
    component: EditEngine,
    type: 'private',
    requiresTenant: true,
    options: {
      moduleIcon: <VesselIcon />,
      showModuleInDrawer: false,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'Add Engine',
    path: '/vessel/:vesselId/addEngine',
    component: AddEngine,
    type: 'private',
    requiresTenant: true,
    options: {
      moduleIcon: <VesselIcon />,
      showModuleInDrawer: false,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'VesselVoyages',
    path: '/vessel/:vesselId/voyages',
    component: VesselVoyagesRoute,
    type: 'private',
    requiresTenant: true,
    options: {
      moduleIcon: <VesselIcon />,
      showModuleInDrawer: false,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'Voyage',
    path: '/vessel/:vesselId/voyage/:voyageId',
    component: VoyageRoute,
    type: 'private',
    requiresTenant: true,
    options: {
      moduleIcon: <VoyageIcon />,
      showModuleInDrawer: false,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'NOx Voyages',
    path: '/voyages',
    component: fleetVoyagesRoute,
    type: 'protected',
    requiresTenant: true,
    roles: [],
    options: {
      moduleIcon: <VoyageIcon />,
      showModuleInDrawer: true,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'NOx Report',
    path: '/noxreport',
    component: NoxReport,
    type: 'protected',
    requiresTenant: true,
    roles: [],
    options: {
      moduleIcon: <BookingReference />,
      showModuleInDrawer: true,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'Charter Report',
    path: '/charter-report',
    component: CharterReport,
    type: 'protected',
    requiresTenant: true,
    roles: [],
    options: {
      moduleIcon: <Activity />,
      showModuleInDrawer: true,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
  {
    name: 'Vessel Details',
    path: '/vessels',
    component: FleetVesselList,
    type: 'private',
    requiresTenant: true,
    roles: [],
    options: {
      moduleIcon: <VesselIcon />,
      showModuleInDrawer: true,
      showAppBar: true,
      showAppDrawer: true,
      showProfile: true,
      showGlobalNav: true,
      showTenantSwitcher: false,
    },
  },
]

const Routes = () => <GriegRouter routes={routes} />

export default Routes
