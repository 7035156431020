import { useAuth } from '@griegconnect/krakentools-react-auth'
import React from 'react'

import { GriegTenantsProvider, Tenant } from '../../../GriegTenants'
import { useConfig } from '../config/grieg-config-provider'

export type TenantsWrapperProps = {
  children: React.ReactElement;
};

export const TenantsWrapper = (props: TenantsWrapperProps) => {
  const { children } = props
  const { getTokenSilently } = useAuth()
  const { config } = useConfig()

  const getTenants = () => {
    return new Promise<Tenant[]>((resolve) => {
      getTokenSilently().then((token: string | undefined) => {
        if (token) {
          fetch(config!.api.tenantApiUrl + '/users/me', {
            headers: { Authorization: 'Bearer ' + token },
          })
            .then((response) => response.json())
            .then((result) => {
              const tenants: Tenant[] = result.access?.map((acc: any) => {
                acc.tenant.code = acc.tenant.ref
                return acc.tenant
              })
              resolve(tenants)
            })
        }
      })
    })
  }

  return (
    <GriegTenantsProvider storage={null} onGetTenants={getTenants}>
      {children}
    </GriegTenantsProvider>
  )
}

export default TenantsWrapper
