import VesselIcon from '@griegconnect/krakentools-react-icons/Vessel'
import { List, ListItemIcon, ListItemText, Paper } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { UiListItemLink } from '../../components/UiListItemLink'
import NoxProgressCircle from './NoxProgressCircle'
import { COMPLETED, PENDING } from '../../constants/colors'
import { VesselWithEnginesAndStatus } from 'src/lib/apis/types/vessel'

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'grid',
    gridTemplateColumns: '58px 1fr',
    gridTemplateRows: '75px 1fr',
  },
  circle: {
    color: '#FFDA6A',
  },
  progressWrapper: {
    position: 'relative',
  },
  backgroundCircle: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  vesselIcon: {
    position: 'absolute',
  },
  text: {
    padding: '0px 0px 7px 16px',
  },
  textheader: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    padding: 0,
  },
  yellowtext: {
    color: '#FFDA6A',
  },
  textRed: {
    color: PENDING,
  },
  textGreen: {
    color: COMPLETED,
  },
  textYellow: {
    color: theme.palette.error.main,
  },
  mediumEmphasis: {
    color: theme.palette.text.secondary,
  },
  paper: {
    padding: 24,
    margin: 0,
  },
}))

interface IProgressWidgetProps {
  progress: number
  vessels: VesselWithEnginesAndStatus[]
}

const ProgressWidget = (props: IProgressWidgetProps) => {
  const classes = useStyles()
  const { progress, vessels } = props
  const vesselsSorted = vessels.sort((a, b) => (a.name > b.name ? 1 : -1))
  const [firstVoyage, secondVoyage] = vesselsSorted
  const listVessels = vesselsSorted.length > 2 ? [firstVoyage, secondVoyage] : vesselsSorted // S.H. 30.08.2021 - Replaced the method [ ? vesselsSorted.splice(0, 2) ] with [firstVoyage, secondVoyage]
  const history = useHistory()
  const gotoVoyages = () => {
    history.push('/voyages')
  }
  const gotoVessels = () => {
    history.push('/vessels')
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.main}>
        <NoxProgressCircle
          size={58}
          thickness={2}
          progress={props.progress}
          Icon={VesselIcon}
          iconSize={24}
          color="#FFDA6A"
        />
        <div className={classes.text}>
          <div className={classes.textheader}>Progress</div>
          <div>
            <span className={classes.yellowtext}>{isNaN(progress) ? 'None ' : progress + ' % '}</span>of your vessels
            have been Completed for this period.
          </div>
        </div>
      </div>
      <div>
        <List>
          {listVessels.map((vessel) => (
            <UiListItemLink to={{ pathname: '/vessel/' + vessel.id + '/voyages' }} key={vessel.id}>
              <ListItemIcon style={{ minWidth: 35 }}>
                <VesselIcon fontSize="default" style={{ marginRight: '0px' }} />
              </ListItemIcon>
              <ListItemText
                inset={false}
                style={{ marginTop: 0 }}
                primary={
                  <>
                    <Typography component="span" variant="caption">
                      {vessel.name || 'Unnamed vessel'}
                    </Typography>
                    {(vessel.status === 'approved' && (
                      <Typography
                        className={classes.textGreen}
                        component="span"
                        variant="subtitle2"
                        style={{ float: 'right' }}
                      >
                        Completed
                      </Typography>
                    ))}
                    {(vessel.status === 'not-approved' && (
                      <Typography
                        className={classes.textRed}
                        component="span"
                        variant="subtitle2"
                        style={{ float: 'right' }}
                      >
                        Pending
                      </Typography>
                    ))}
                    {(vessel.status === 'committed' && (
                        <Typography
                            component="span"
                            variant="subtitle2"
                            style={{ float: 'right' }}
                        >
                          Archived
                        </Typography>
                    ))}
                  </>
                }
              />
            </UiListItemLink>
          ))}
        </List>
        <div style={{ textAlign: 'center' }}>
          {vesselsSorted.length > 0 ?
              (
                  <Button size="small" color="primary" onClick={gotoVoyages}>
                    {vesselsSorted.length > 2 ? 'See all ' + vesselsSorted.length + ' vessels' : ''}
                  </Button>
              ) : (
                  <Button size="small" color="primary" onClick={gotoVessels}>
                    Add vessels to your fleet
                  </Button>
              )
          }
        </div>
      </div>
    </Paper>
  )
}

export default ProgressWidget
