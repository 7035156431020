import EmissionsIcon from '@griegconnect/krakentools-react-icons/Emission'
//import CompanyIcon from '@griegconnect/krakentools-react-icons/HostCompany'
//import TaxIcon from '@griegconnect/krakentools-react-icons/Tax';
import TimeDurationIcon from '@griegconnect/krakentools-react-icons/TimeDuration'
import VesselIcon from '@griegconnect/krakentools-react-icons/Vessel'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import Typography from '@material-ui/core/Typography'
import { Duration } from 'luxon'
import React from 'react'
import {GREEN_HIGHLIGHT, RED_HIGHLIGHT} from "../../constants/colors"

//import { useTenants } from '../../common/GriegTenants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoGrid: {
      display: 'grid',
      gridTemplateColumns: '30px 50% auto',
      gridAutoRows: '40px',
      margin: '0 auto',
      padding: 16,
    },
  })
)

export interface IVoyagesSummary {
  opTime: Duration | null
  noxTime: Duration | null
  taxableNox: number | null
  nonTaxableNox: number | null
  otherNox: number | null
  vesselName: string | null
  imo: number | null
}

interface IVoyageSummaryProps {
  voyagesSummary: IVoyagesSummary
}

const VoyagesSummaryFC = (props: IVoyageSummaryProps) => {
  const { voyagesSummary } = props
  const classes = useStyles()
//  const { activeTenant } = useTenants()
  // const opTime = voyagesSummary.opTime.normalize().shiftTo('days', 'hours', 'minutes', 'seconds');
  const noxTime =
    voyagesSummary.noxTime && voyagesSummary.noxTime.normalize().shiftTo('days', 'hours', 'minutes', 'seconds')

  return (
    <>
      <div className={classes.infoGrid}>
        {/* <TimeDurationIcon />
				<Typography component='span' variant='body1'>Operational time:</Typography>
				<Typography component='span' variant='body1' style={{ justifySelf: 'end' }}>{opTime.days}d {opTime.hours}h {opTime.minutes}m</Typography> */}
        {voyagesSummary.vesselName && (
          <>
            <VesselIcon />
            <Typography component="span" variant="body1">
              Vessel name
            </Typography>
            <Typography component="span" variant="body1" style={{ justifySelf: 'end' }}>
              {voyagesSummary.vesselName}
            </Typography>
          </>
        )}
        {voyagesSummary.imo && (
          <>
            <VesselIcon />
            <Typography component="span" variant="body1">
              IMO
            </Typography>
            <Typography component="span" variant="body1" style={{ justifySelf: 'end' }}>
              {voyagesSummary.imo}
            </Typography>
          </>
        )}
{/*        <>
          <CompanyIcon />
          <Typography component="span" variant="body1">
            Company
          </Typography>
          <Typography component="span" variant="body1" style={{ justifySelf: 'end' }}>
            {activeTenant?.name}
          </Typography>
        </>
*/}        {noxTime && (
          <>
            <TimeDurationIcon />
            <Typography component="span" variant="body1">
              Time in NOx-area:
            </Typography>
            <Typography component="span" variant="body1" style={{ justifySelf: 'end' }}>
              {noxTime.days}d {noxTime.hours}h {noxTime.minutes}m
            </Typography>
          </>
        )}
        <EmissionsIcon />
        <Typography component="span" variant="body1">
          NOx emission:
        </Typography>
        <Typography component="span" variant="body1" style={{ justifySelf: 'end' }}>
          <span style={{ color: GREEN_HIGHLIGHT }}>
              {(voyagesSummary.taxableNox && Math.round(voyagesSummary.taxableNox).toLocaleString()) || '--'}
          </span>
          {(voyagesSummary.nonTaxableNox && (' / ' + Math.round(voyagesSummary.nonTaxableNox).toLocaleString()) ) || ' / --'}
          <span style={{ color: RED_HIGHLIGHT }}>
              {( voyagesSummary.otherNox && Math.abs(voyagesSummary.otherNox) >= 0.5 && (' / ' + Math.round(voyagesSummary.otherNox).toLocaleString()) ) || ' / --'}
          </span>
          &nbsp;&nbsp;kg
        </Typography>

        {/*        <TaxIcon />
        <Typography component="span" variant="body1">
          NOx tax:
        </Typography>
        <Typography component="span" variant="body1" style={{ justifySelf: 'end' }}>
          {(voyagesSummary.tax > 0 && Number(voyagesSummary.tax.toFixed(2)).toLocaleString()) || '---'} NOK
        </Typography>
*/}{' '}
      </div>
    </>
  )
}

export const VoyagesSummary = VoyagesSummaryFC
