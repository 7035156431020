import { Paper } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { DateTime } from 'luxon'
import React from 'react'
import { COMPLETED, PENDING } from '../../constants/colors'

import Calendar from './Calendar'
import Button from "@material-ui/core/Button"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: 'grid',
      gridTemplateColumns: '58px 1fr',
    },
    text: {
      padding: '0px 0px 7px 16px',
    },
    textheader: {
      fontWeight: 500,
      fontSize: '14px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      padding: 0,
    },
    opentext: {
      color: COMPLETED, // S.H. Changed to the common highlight color from '#A3D6A6'
    },
    closedtext: {
      color: PENDING, // S.H. Changed to the common highlight color from 'red'
    },
    durationtext: {
      color: PENDING, // S.H. Replaced light red with highlight red for consistancy from '#FF9A7A'
    },
    paper: {
      padding: '0px 24px 24px 24px',
      margin: 0,
    },
  })
)

interface IDueWidgetProps {
  registrationStart: Date | undefined
  registrationEnd: Date | undefined
  periodName: string | undefined
  toggleDateFilter: () => void
}

const DueWidget = (props: IDueWidgetProps) => {
  const classes = useStyles()
  const { registrationStart, registrationEnd, periodName } = props
  if (!registrationStart || !registrationEnd || !periodName) {
    return <Paper>No valid periods</Paper>
  }

  const now = new Date()
  registrationStart.setHours(0, 0, 0)
  registrationEnd.setHours(23, 59, 59)
  const isOpen = registrationStart <= now && registrationEnd >= now
  const diff = DateTime.fromJSDate(registrationEnd)
    .diff(DateTime.fromJSDate(now))
    .normalize()
    .shiftTo('days', 'hours', 'minutes')
  return (
    <Paper className={classes.paper} >
      <div className={classes.main} style={{marginTop: 32}}>
        <Button onClick={props.toggleDateFilter}>
          <Calendar date={registrationEnd} />
        </Button>
        <div className={classes.text}>
          <div className={classes.textheader}>NOx-report for {periodName}</div>
          <div>
            This period is currently{' '}
            <span className={(isOpen && classes.opentext) || classes.closedtext}>{(isOpen && 'open') || 'closed'}</span>
            {' for reporting.'}
            <br />
            {diff.days > 0 && 'NOx Report for Completed vessels can be submitted in '}
            <span className={classes.durationtext}>
              {diff.days > 0 && diff.days + ' days.'} {/* {diff.hours}h {diff.minutes.toFixed(0)}m */}
            </span>
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default DueWidget
