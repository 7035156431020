import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import React, { useLayoutEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useApis } from 'src/lib/ApiProvider'
import { EngineForm } from 'src/lib/apis/types/engine'

import { useAppContext } from '../../common/GriegProvider/wrappers/AppContextWrapper'
import ErrorModal from '../../components/ApolloErrorModal'
import EngineConfig from '../fleetoverview/add/components/EngineConfig'

const AddEngine = (props: GriegRouteProps) => {
  const [engineName, setEngineName] = useState<string>('')
  const [engineHasLng, setEngineHasLng] = useState<string>('nonLng')
  const [engineSystem, setEngineSystem] = useState<string>('nonScr')
  const [noxFactor, setNoxFactor] = useState<number>(0)
  const [noxFactorScr, setNoxFactorScr] = useState<number>(0)
//YAE 27.10.21 -> Added lng handling x2
  const [noxFactorLng, setNoxFactorLng] = useState<string>('')
  const [noxFactorScrLng, setNoxFactorScrLng] = useState<string>('')
  const [errorAcked, setErrorAcked] = useState<boolean>(false)
  const { vesselId } = useParams<{ vesselId: string }>()
  const { apis } = useApis()
  const { route } = props
  const { setRouteOptionsCallback } = useAppContext()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))
  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps
  const history = useHistory()
  const queryClient = useQueryClient()
  const back = () => {
    queryClient.invalidateQueries('vessels')
    queryClient.invalidateQueries(['vessel', vesselId])
    history.push(history.location.pathname.replace('/addEngine', ''))
  }


  const { mutate: addEngine, error: mutationError } = useMutation(
    ['addEngine', vesselId],
    async (form: EngineForm) => {return await apis.vesselsApi.createEngine(vesselId, form)}, 
    {
      onSuccess: back
    }
  )

  const handleChange = (key, value) => {
    switch (key) {
      case 'engineName':
        setEngineName(value)
        break
      case 'system':
        setEngineSystem(value)
        break
      case 'hasLng':
        setEngineHasLng(value)
        break
      case 'noxFactor':
        setNoxFactor(value)
        break
      case 'noxFactorScr':
        setNoxFactorScr(value)
        break
      case 'noxFactorLng':
        setNoxFactorLng(value)
        break
      case 'noxFactorScrLng':
        setNoxFactorScrLng(value)
        break
    }
  }

  const handleLeave = (reason) => {
    if (reason === 'complete') {
      setErrorAcked(false)
      addEngine({
        name: engineName,
        scrFactor: Number(noxFactorScr),
        noxFactor: Number(noxFactor),
        noxFactorLng: Number(noxFactorLng)
      })
    } else {
      back()
    }
  }

  if (mutationError && !errorAcked) {
    return <ErrorModal error={mutationError} onClose={() => setErrorAcked(true)} />
  }

  return (
    <EngineConfig
      action={'add'}
      onChange={handleChange}
      onLeave={handleLeave}
      engineName={engineName}
      // engineAmount={'1'} S.H. Removed Engine amount selection
      system={engineSystem}
      noxFactor={noxFactor + ''}
      noxFactorScr={noxFactorScr + ''}
      hasLng={engineHasLng}
      noxFactorLng={noxFactorLng + ''}
      noxFactorScrLng={noxFactorScrLng + ''}
    />
  )
}

export default AddEngine
