import { GriegRouteProps } from '@griegconnect/krakentools-react-router'
import React, { useLayoutEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { useOnboarding } from '@griegconnect/krakentools-react-onboarding'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      maxWidth: '500px',
      padding: 20,
      textAlign: 'center',
      margin: 'auto',
    },
  })
)

export const NotAuthenticated = (props: GriegRouteProps) => {
  const classes = useStyles()
  const { setOnboardingVisibility } = useOnboarding()
  const showOnboarding = () => setOnboardingVisibility(true)
  useLayoutEffect(showOnboarding, [setOnboardingVisibility])

  return (
    <div className={classes.wrapper}>
      <Typography component="h1" variant="h1">
        403
      </Typography>
      <Typography component="h5" variant="h5">
        You are not authenticated.
      </Typography>
      <Box m={2} />
      <Typography variant="body2">You need to login in order to see this page.</Typography>
    </div>
  )
}

export default NotAuthenticated
