import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import React, { useLayoutEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { useAppContext } from '../../GriegProvider/wrappers/AppContextWrapper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      maxWidth: '500px',
      padding: 20,
      textAlign: 'center',
      margin: 'auto',
    },
  })
)

export const NotFound = (props: GriegRouteProps) => {
  const { route } = props
  const { setRouteOptionsCallback } = useAppContext()
  const classes = useStyles()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))

  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.wrapper}>
      <Typography component="h1" variant="h1">
        404
      </Typography>
      <Typography component="h5" variant="h5">
        We are sorry, the page you requested cannot be found.
      </Typography>
      <Box m={2} />
      <Typography variant="body2">
        The URL may be misspelled or the page you're looking for is no longer available.
      </Typography>
    </div>
  )
}

export default NotFound
