import { useAuth } from '@griegconnect/krakentools-react-auth'
import { GriegRouteType, useRouter } from '@griegconnect/krakentools-react-router'
import { useTenants } from '../../GriegTenants'
// import { AppCurtain } from '@griegconnect/krakentools-react-ui'; // GRIEG app curtain
import { AppCurtain } from '../../AppCurtain' // Customized APP curtain to NOx Digital LOGO
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'

export type AppContextValues = {
  routeOptions: GriegRouteType['options'] | undefined
  setRouteOptionsCallback: (options: GriegRouteType['options']) => void
}

export const AppContext = React.createContext<AppContextValues | null>(null)
export const useAppContext = () => useContext(AppContext)!

export type AppWrapperProps = {
  children?: React.ReactNode
}

export const AppContextWrapper = (props: AppWrapperProps) => {
  const { children } = props

  const { isAuthenticated, isInitializing } = useAuth()
  const { setIsAuthenticated, setHasActiveTenant, setRoles } = useRouter()
  const { fetchTenants, activeTenant, roles } = useTenants()

  const [initializingFinished, setInitializingFinished] = useState(!isInitializing)
  const [routeOptions, setRouteOptions] = useState<GriegRouteType['options'] | undefined>(undefined)

  const setRouterAuth = () => {
    setIsAuthenticated(isAuthenticated)
  }

  const delayedFinishInitializing = () => {
    setTimeout(() => {
      setInitializingFinished(!isInitializing)
    }, 1500)
  }

  const setRouteOptionsCallback = (options: GriegRouteType['options'] | undefined) => {
    setRouteOptions(options)
  }

  const getTenants = () => {
    if (isAuthenticated) {
      fetchTenants()
    }
  }

  const setActiveTenantAndRoles = () => {
    setHasActiveTenant(activeTenant ? true : false)
    setRoles(roles)
  }

  useLayoutEffect(setRouterAuth, [isAuthenticated, setIsAuthenticated])
  useLayoutEffect(getTenants, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps
  useLayoutEffect(setActiveTenantAndRoles, [activeTenant, roles, setHasActiveTenant, setRoles])
  useEffect(delayedFinishInitializing, [isInitializing])

  return (
    <AppContext.Provider
      value={{
        routeOptions,
        setRouteOptionsCallback,
      }}
    >
      {!initializingFinished ? <AppCurtain /> : children}
    </AppContext.Provider>
  )
}

export default AppContextWrapper
