import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'

export default new (class PeriodsSelect {
  getPeriod(periods_data: NoxPeriod[] | undefined, filterState: any): NoxPeriod | undefined {
    return periods_data
      ? filterState?.quarter //YAE 10.11.21 -> return current period if filterState is null
        ? periods_data.find((p) => p.id === filterState.quarter[0])
        : periods_data
            .filter(
              (p) =>
                new Date(p.timeStart) <= new Date() &&
                new Date() <= new Date(new Date(p.registrationEnd).setHours(23, 59, 59, 999))
            )
            .sort((a, b) => (new Date(a.timeStart) > new Date(b.timeStart) ? 1 : -1))[0]
      : undefined
  }
})()
