import {
  Button,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import ExpandingInfoBox from '../../../vessels/add/components/ExpandingInfoBox'

const useStyles = makeStyles((theme: Theme) => ({
  titleRow: {
    margin: '32px 24px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  infoContainer: {
    margin: '32px 24px',
    '& p': {
      '& span': {
        color: '#BBBBBF',
        marginRight: '16px',
      },
    },
  },
  radio: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

interface IEngineConfigProps {
  action: 'show' | 'add' | 'edit'
  engineName: string
  // engineAmount: string // S.H. Removed Engine amount selection
  system: string
  noxFactor: string
  noxFactorScr: string
  hasLng: string
  noxFactorLng: string
  noxFactorScrLng?: string
  onChange: (key: string, value: string) => void
  onLeave: (action: 'cancel' | 'complete') => void
  infoSetup?: boolean
  infoSystem?: boolean
  infoNoxFactor?: boolean
  onInfoToggle?: (key: string) => void
}

const EngineConfig: React.FunctionComponent<IEngineConfigProps> = (props) => {
  const handleChange = (key: string) => (evt: any) => {
    const value = evt.target.value
    props.onChange(key, value)
  }

  const handleLeave = (action: 'cancel' | 'complete') => (evt: any) => {
    props.onLeave(action)
  }

  const classes = useStyles()
  return (
    <>
      <Typography component="h1" variant="h5" style={{ margin: '24px 24px 64px' }}>
        Engine configuration
      </Typography>

      <ExpandingInfoBox title="Engine setup" text='Name and NOx factors for engines. Example: "2x Wartsila 9L20".' />

      <Grid container={true} spacing={4} style={{ padding: '24px' }}>
        <Grid item={true} xs={6}>
          <TextField
            fullWidth={true}
            label="Engine name"
            name="engineName"
            value={props.engineName}
            onChange={handleChange('engineName')}
          />
        </Grid>
       {/* <Grid item={true} xs={6}> // S.H. Removed Engine Amount selection
          <FormControl fullWidth={true}>
            <InputLabel id="engines-select-label">Amount</InputLabel>
            <Select
              labelId="engines-select-label"
              id="engines-select"
              value={props.engineAmount}
              onChange={handleChange('engineAmount')}
            >
              <MenuItem value={'1'}>1 engine</MenuItem>
              <MenuItem value={'2'}>2 engines</MenuItem>
              <MenuItem value={'3'}>3 engines</MenuItem>
              <MenuItem value={'4'}>4 engines</MenuItem>
              <MenuItem value={'5'}>5 engines</MenuItem>
              <MenuItem value={'6'}>6 engines</MenuItem>
            </Select>
          </FormControl>
        </Grid>*/}
      </Grid>

      <ExpandingInfoBox
        title="SCR-system installed"
        text="Select Yes if the engine has selective catalytic reduction (SCR) system installed."
      />

      <ListItem selected={props.system === 'nonScr'}>
        <ListItemIcon>
          <Radio
            checked={props.system === 'nonScr'}
            onChange={handleChange('system')}
            value="nonScr"
            name="system"
            inputProps={{ 'aria-label': 'No' }}
            disableRipple={true}
            className={classes.radio}
          />
        </ListItemIcon>
        <ListItemText
          inset={false}
          style={{ marginTop: 10 }}
          primary={
            <Typography component="span" variant="body1">
              No
            </Typography>
          }
        />
      </ListItem>
      <ListItem selected={props.system === 'scr'}>
        <ListItemIcon>
          <Radio
            checked={props.system === 'scr'}
            onChange={handleChange('system')}
            value="scr"
            name="system"
            inputProps={{ 'aria-label': 'Yes' }}
            disableRipple={true}
            className={classes.radio}
          />
        </ListItemIcon>
        <ListItemText
          inset={false}
          style={{ marginTop: 10 }}
          primary={
            <Typography component="span" variant="body1">
              Yes
            </Typography>
          }
        />
      </ListItem>

      <ExpandingInfoBox
        title="Engine NOx-factor(s)"
        text="NOx-factor for the engine in [kg NOx/ton fuel]. If the engine has SCR installed, please enter additional Minimum (threshold) NOx-factor for SCR."
      />

      <Grid container={true} spacing={4} style={{ padding: '24px' }}>
        <Grid item={true} xs={12}>
          <TextField
            fullWidth={true}
            label="NOx-factor"
            name="noxFactor"
            type = "number" //YAE added
            value={props.noxFactor}
            onChange={handleChange('noxFactor')}
          />
        </Grid>
        {props.system === 'scr' && (
          <Grid item={true} xs={12}>
            <TextField
              fullWidth={true}
              label="Minimum NOx-factor (for SCR)"
              type = "number"  //YAE added
              name="noxFactorScr"
              value={props.noxFactorScr}
              onChange={handleChange('noxFactorScr')}
            />
          </Grid>
        )}

          <ExpandingInfoBox
              title="LNG fuel option"
              text="Select Yes if the engine has LNG capability."
          />

          <ListItem selected={props.hasLng === 'nonLng'}>
              <ListItemIcon>
                  <Radio
                      checked={props.hasLng === 'nonLng'}
                      onChange={handleChange('hasLng')}
                      value="nonLng"
                      name="hasLng"
                      inputProps={{ 'aria-label': 'No' }}
                      disableRipple={true}
                      className={classes.radio}
                  />
              </ListItemIcon>
              <ListItemText
                  inset={false}
                  style={{ marginTop: 10 }}
                  primary={
                      <Typography component="span" variant="body1">
                          No
                      </Typography>
                  }
              />
          </ListItem>
          <ListItem selected={props.hasLng === 'lng'}>
              <ListItemIcon>
                  <Radio
                      checked={props.hasLng === 'lng'}
                      onChange={handleChange('hasLng')}
                      value='lng'
                      name="hasLng"
                      inputProps={{ 'aria-label': 'Yes' }}
                      disableRipple={true}
                      className={classes.radio}
                  />
              </ListItemIcon>
              <ListItemText
                  inset={false}
                  style={{ marginTop: 10 }}
                  primary={
                      <Typography component="span" variant="body1">
                          Yes
                      </Typography>
                  }
              />
          </ListItem>

          {props.hasLng === 'lng' && (
                  <Grid item={true} xs={12}>
                      <TextField
                          fullWidth={true}
                          label="LNG NOx-factor"
                          type = "number"
                          name="noxFactorLng"
                          value={props.noxFactorLng}
                          onChange={handleChange('noxFactorLng')}
                      />
                  </Grid>
          )}
      </Grid>

      <Grid container={true} spacing={4}>
        <Grid item={true} xs={6} style={{ textAlign: 'right' }}>
          <Button color="primary" variant="outlined" size="large" onClick={handleLeave('cancel')}>
            Back
          </Button>
        </Grid>
        <Grid item={true} xs={6} style={{ textAlign: 'left' }}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleLeave('complete')}
            disabled={
              props.engineName.length === 0 ||
              props.system.length === 0 ||
              props.noxFactor.length === 0 ||
              (props.system === 'scr' && props.noxFactorScr.length === 0) ||
              (props.hasLng === 'lng' && props.noxFactorLng.length === 0)
            }
          >
            {props.action === 'add' ? 'Add' : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default EngineConfig
