import './mapboxPopup.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

import mapboxgl, { LngLatBounds } from 'mapbox-gl'
import React from 'react'
import ReactDOM from 'react-dom'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import FullscreenControl from './FullscreenControl'
import generateArrowImage from './generateArrowImage'
import NoxMap from './Map'
import NavigationControl from './NavigationControl'
import UiMapBoxControl from './UiMapBoxControl'
import VoyagePopup from './VoyagePopup'
import zones, {mapZones} from './zones'
import { GREEN_TAIL, RED_TAIL, WHITE_TAIL } from '../constants/colors'

interface IUiMapProps extends RouteComponentProps {
  zoom?: number
  lng?: number
  lat?: number
  voyages?: Array<any>
  offshoreZones?: mapZones[]
  preSelectedVoyageId?: number | null //YAE 02.11.21 -> Added for reverse highlight
  onVoyageSelected?: (voyage: any, vessel: any) => void
}

interface IUiMapState {
  zoom: number
  lng: number
  lat: number
  selectedVoyageId: number | null
}

const dashArrays = [
  [0, 1, 1],
  [0, 4, 4],
  [0, 6, 6],
]

const staticZones = zones

class VoyageMap extends React.Component<IUiMapProps, IUiMapState> {
  mapContainer: HTMLDivElement | null = null
  map: mapboxgl.Map | null = null
  fullscreenControl: UiMapBoxControl | null = null
  navigationControl: UiMapBoxControl | null = null
  hoveredVoyageId: string | number | null | undefined
  history: any
  selectedVoyageId: number | null = null

  constructor(props: IUiMapProps) {
    super(props)
    this.history = props.history
    this.state = {
      zoom: props.zoom || 0,
      lng: props.lng || 0,
      lat: props.lat || 4,
      selectedVoyageId: null,
    }
  }

  componentDidMount() {
    this.reloadMap()
  }

  componentDidUpdate(prevProps) {
    if (this.props.voyages) if (prevProps.voyages.length !== this.props.voyages.length) this.reloadMap()
  }

  componentWillUnmount() {
    if (this.fullscreenControl) {
      this.map?.removeControl(this.fullscreenControl)
    }
  }

  reloadMap = () => {
    if (this.mapContainer) {
      this.map = NoxMap(this.state.lat, this.state.lng, this.state.zoom, this.mapContainer).on('load', this.onMapLoad)
    }
    if (this.map) {
      this.fullscreenControl = FullscreenControl(this.map)
      this.navigationControl = NavigationControl(this.map, this.setExtent)
      this.map.addControl(this.fullscreenControl, 'top-right')
      this.map.addControl(this.navigationControl, 'bottom-right')
    }
  }

  onMapLoad = async () => {
    if (this.props.voyages && typeof this.map?.getSource('routes') === 'undefined') { //if source=routes already exist, don't add it again
      this.addFeatures(
        this.props.voyages.map((voyage) => ({
          type: 'Feature',
          id: voyage.id,
          properties: {
            voyageId: voyage.id,
            description: voyage.vessel.name,
            vesselId: voyage.vessel.id,
            taxable: voyage.taxable,
            color: voyage.taxable ? GREEN_TAIL : voyage.taxable === null ? RED_TAIL : WHITE_TAIL, // S.H. 20.10.2021 Tiles color to be the same as map tails color
          },
          geometry: voyage.geometry, //YAE 12.08.21 API change JSON.parse(voyage.trail),
        }))
      )
    }
    this.setExtent()
    // avoid re-plotting existing static boundaries and offshore zones
    if (typeof this.map?.getSource('zone_1') === 'undefined') { //zone_1 is Norwegian Territorial Boundary, don't add if it exists
      this.plotZones()
    }
    if (typeof this.map?.getSource('o_zone_1881') === 'undefined') { //o_zone_1881 is Valhall Flanke Nord, don't add if it exists
      this.plotOffshoreZones()
    }
  }

  addFeatures = async (features: any) => {
    const nontaxable_arrow = await generateArrowImage(15, 7, WHITE_TAIL) // S.H. 21.10.2021 - Resize tails direction arrow, YAE added arrow color: ;
    const undefined_arrow = await generateArrowImage(15, 7, RED_TAIL) // S.H. 21.10.2021 - Resize tails direction arrow, YAE added arrow color
    const taxable_arrow = await generateArrowImage(15, 7, GREEN_TAIL) // S.H. 21.10.2021 - Resize tails direction arrow, YAE added arrow color: ;
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: [0, -15],
      className: 'mapbox-popup-vesselname',
    })
    this.map?.addSource('routes', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    })
    //YAE 02.11.21 -> activate preselected voyage
    if (this.props.preSelectedVoyageId) {
      this.map?.setFeatureState({ source: 'routes', id: this.props.preSelectedVoyageId }, { selected: true })
    }

    const featuresOriginal = features
    //Create source for route of Undefined voyages as features
    features = featuresOriginal.filter((f) => f.properties.taxable === null)
    this.map?.addSource('undefined_routes', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    })
    //Create source for route of Taxable voyages as features
    features = featuresOriginal.filter((f) => f.properties.taxable === true)
    this.map?.addSource('taxable_routes', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    })
    //Create source for route of Non-taxable voyages as features
    features = featuresOriginal.filter((f) => f.properties.taxable === false)
    this.map?.addSource('nontaxable_routes', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    })
    this.map?.addImage('nontaxable_arrow', nontaxable_arrow)
    this.map?.addImage('undefined_arrow', undefined_arrow)
    this.map?.addImage('taxable_arrow', taxable_arrow)
    this.map?.addLayer({
      id: 'routes',
      type: 'line',
      source: 'routes',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'selected'], false],
          ['get', 'color'],
          ['case', ['boolean', ['feature-state', 'hover'], false], ['get', 'color'], ['get', 'color']],
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'selected'], false],
          2,
          ['case', ['boolean', ['feature-state', 'hover'], false], 2, 1],
        ],
        'line-dasharray': this.dashArray(1),
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'selected'], false],
          1,
          ['case', ['boolean', ['feature-state', 'hover'], false], 1.2, 0.75], // S.H. 20.10.2021 For Contrast & Opacity on the tails
        ],
      },
    })

    this.map?.addLayer({
      id: 'routes_hitboxes',
      type: 'line',
      source: 'routes',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-width': 10,
        'line-opacity': 0,
      },
    })

    this.map?.addLayer({
      id: 'taxable_routes_arrows',
      type: 'symbol',
      source: 'taxable_routes',
      paint: {
        'icon-opacity': 0.7,
      },
      layout: {
        'symbol-placement': 'line',
        'symbol-spacing': 150,
        'icon-allow-overlap': false,
        'icon-ignore-placement': false,
        'icon-image': 'taxable_arrow',
        'icon-size': 1,
        visibility: 'visible',
      },
    })

    this.map?.addLayer({
      id: 'nontaxable_routes_arrows',
      type: 'symbol',
      source: 'nontaxable_routes',
      paint: {
        'icon-opacity': 0.7,
      },
      layout: {
        'symbol-placement': 'line',
        'symbol-spacing': 150,
        'icon-allow-overlap': false,
        'icon-ignore-placement': false,
        'icon-image': 'nontaxable_arrow',
        'icon-size': 1,
        visibility: 'visible',
      },
    })

    this.map?.addLayer({
      id: 'undefined_routes_arrows',
      type: 'symbol',
      source: 'undefined_routes',
      paint: {
        'icon-opacity': 0.7,
      },
      layout: {
        'symbol-placement': 'line',
        'symbol-spacing': 150,
        'icon-allow-overlap': false,
        'icon-ignore-placement': false,
        'icon-image': 'undefined_arrow',
        'icon-size': 1,
        visibility: 'visible',
      },
    })

    this.map?.on('mousemove', 'routes_hitboxes', (e) => {
      if (this.map) {
        this.map.getCanvas().style.cursor = 'pointer'

        if (e.features && e.features[0].id) {
          var coordinates = e.lngLat
          const feature = e.features[0]
          var description = (feature.properties as any).description

          if (coordinates && description && feature.properties) {
            popup
              .setLngLat(coordinates)
              .setDOMContent(
                this.createPopupContent(
                  <VoyagePopup voyage={this.props.voyages?.find((v) => v.id === feature.properties!.voyageId)} />,
                  coordinates
                )
              )
              .addTo(this.map)
          }

          if (this.hoveredVoyageId) {
            this.map.setFeatureState({ source: 'routes', id: this.hoveredVoyageId! }, { hover: false })
          }
          this.hoveredVoyageId = feature.id
          this.map.setFeatureState({ source: 'routes', id: this.hoveredVoyageId! }, { hover: true })
        }
      }
    })

    this.map?.on('mouseleave', 'routes_hitboxes', (e) => {
      if (this.map) {
        if (this.hoveredVoyageId) {
          this.map.setFeatureState({ source: 'routes', id: this.hoveredVoyageId! }, { hover: false })
        }
        this.map.getCanvas().style.cursor = ''
        popup.remove()
        this.hoveredVoyageId = null
      }
    })

    this.map?.on('click', 'routes_hitboxes', (e) => {
      //YAE 28.10.21 -> remove any previously selected trail passed as props or manually clicked.
      if (this.props.preSelectedVoyageId) {
        this.map?.setFeatureState({ source: 'routes', id: this.props.preSelectedVoyageId }, { selected: false })
      }
      if (this.selectedVoyageId) {
        this.map?.setFeatureState({ source: 'routes', id: this.selectedVoyageId }, { selected: false })
      }
      //Then use onVoyageSelected to activate the list item and highlight the newly clicked map-trail
      if (e.features && e.features[0] && e.features[0].id && e.features[0].properties) {
        this.selectedVoyageId = e.features[0].properties.voyageId
        if (this.props.onVoyageSelected)
          this.props.onVoyageSelected(e.features[0].properties.voyageId, e.features[0].properties.vesselId)
        if (this.selectedVoyageId) {
          this.map?.setFeatureState({ source: 'routes', id: this.selectedVoyageId }, { selected: true })
        }
      }
    })
  }

  plotZones() {
    staticZones?.forEach((zone) => {
      this.map?.addSource('zone_' + zone.id, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: zone.coords,
          },
        },
      })
      this.map?.addLayer({
        id: 'layer_zone_' + zone.id,
        type: 'line',
        source: 'zone_' + zone.id,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-dasharray': [0, zone.id * 3.5, zone.id * 4],
          'line-opacity': 0.6,
          'line-color': '#777',
          'line-width': 1,
        },
      })

      this.map?.addLayer({
        id: 'zonesymbols_' + zone.id,
        type: 'symbol',
        source: 'zone_' + zone.id,
        layout: {
          'symbol-placement': 'line',
          'symbol-spacing': 250,
          'text-font': ['Open Sans Regular'],
          'text-field': zone.name,
          'text-size': 12,
        },
        paint: {
          'text-color': 'rgba(250,250,250,1)',
        },
      })
    })
  }

  plotOffshoreZones() {
    const offshoreZones = this.props.offshoreZones
    if(typeof offshoreZones !== 'undefined' && offshoreZones.length >= 1) {
      offshoreZones?.forEach((zone) => {
        this.map?.addSource('o_zone_' + zone.id, {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: zone.coords,
            },
          },
        })
        this.map?.addLayer({
          id: 'layer_o_zone_' + zone.id,
          type: 'line',
          source: 'o_zone_' + zone.id,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-dasharray': [0, 3, 6],
            'line-opacity': 0.4,
            'line-color': '#777',
            'line-width': 1,
          },
        })

        this.map?.addLayer({
          id: 'o_zonesymbols_' + zone.id,
          type: 'symbol',
          source: 'o_zone_' + zone.id,
          layout: {
            'symbol-placement': 'point',
            'symbol-spacing': 250,
            'text-font': ['Open Sans Regular'],
            'text-field': zone.name,
            'text-size': 9,
          },
          paint: {
            'text-color': 'rgba(250,250,250,1)',
          },
        })
      })
    }
  }

  setExtent = () => {
    if (this.props.voyages && this.props.voyages.length > 0) {
      const coords = this.props.voyages
        .filter((v: any) => v && v.geometry)
        .map((v: any) => v.geometry) //YAE 12.08.21 API changed - removed JSON.parse here
        .flat()
        .map((geojson: any) => geojson.coordinates)
        .flat()
      if (coords && coords.length > 0) {
        const bound = coords.reduce(
          (bounds: any, coord: any) => bounds.extend(coord),
          new LngLatBounds([coords[0], coords[0]])
        )
        this.map?.fitBounds(bound, { padding: 50 })
      }
    }
  }

  createPopupContent = (el: JSX.Element, coordinates: mapboxgl.LngLat) => {
    const placeholder = document.createElement('div')
    ReactDOM.render(el, placeholder)
    return placeholder
  }

  dashArray = (index: number) => dashArrays[Math.floor(index % dashArrays.length)]

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ width: '100%', height: '100%' }} ref={(el) => (this.mapContainer = el)} />
      </div>
    )
  }
}

export default withRouter(VoyageMap)
