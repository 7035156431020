
export interface mapZones {
  id: number
  name: string
  coords: number[][]
}

const zone1 = [
  [30.93880194201525, 69.56070817061725],
  [31.585494254317528, 70.14371177532524],
  [31.75216254393964, 70.35254725092567],
  [31.753874192341772, 70.42010826891925],
  [31.717997177488243, 70.46318482709856],
  [31.660349780356917, 70.50012547142012],
  [31.548710291276386, 70.5452385350104],
  [31.222525337303583, 70.64435573407185],
  [30.61353030455692, 70.82535390684647],
  [30.381036172351873, 70.88249249098487],
  [30.052062869832323, 70.94012546091452],
  [29.160777916463026, 71.12069094919083],
  [28.55561268838371, 71.26229316171553],
  [28.44186112101067, 71.28301686992634],
  [28.299626589034126, 71.29682483922119],
  [27.774299823259753, 71.32964867800077],
  [27.06069732355353, 71.35178335841324],
  [25.7151600026333, 71.38447899840382],
  [25.54345366175949, 71.3803462942387],
  [24.919795691761443, 71.33567620824229],
  [24.01632606814842, 71.30081339156975],
  [23.82842432833122, 71.29105837740002],
  [23.644575180257217, 71.26595650170177],
  [23.247464040575846, 71.18622793320091],
  [22.47638276135872, 71.02705672758736],
  [22.298338169495537, 70.9876803506441],
  [21.70119070158487, 70.85297421934924],
  [21.175926364340317, 70.7900178083981],
  [20.479732520651595, 70.70361634176608],
  [19.87947030765293, 70.62636883889535],
  [18.85320199416842, 70.48804647699771],
  [18.35949042444095, 70.39926668628893],
  [18.213605198831686, 70.3612471564921],
  [18.163047310037822, 70.34045040488351],
  [17.80524865766858, 70.16841666831844],
  [17.453682359135794, 69.99391672303948],
  [17.16004652009981, 69.811543582896],
  [16.570397714894387, 69.64942177827596],
  [16.25435079984714, 69.59670059340004],
  [15.792194420062154, 69.51809843496702],
  [15.698596568048961, 69.49800509786203],
  [15.022083467900122, 69.31597224717385],
  [14.815839008350451, 69.25933068523337],
  [14.733702685454924, 69.23144482039774],
  [14.188904045296693, 69.0010250724361],
  [13.891909427164148, 68.87260761486478],
  [13.80706428950978, 68.82158698602287],
  [13.263980806437713, 68.46355552253472],
  [13.003692575300612, 68.39317853573999],
  [12.83252860106907, 68.34643915902932],
  [12.734760871300049, 68.3117185894358],
  [12.643674934978554, 68.26982397937637],
  [12.584167366491846, 68.23572784061858],
  [12.470102725876892, 68.15245229695394],
  [11.706508187299498, 67.69915328801166],
  [11.50700069967599, 67.63086281552081],
  [11.454641313782943, 67.60930535146213],
  [11.39240534877504, 67.57139321187597],
  [11.359709464413632, 67.53782711469275],
  [11.306784348017521, 67.45533260408072],
  [11.071586762824175, 66.17215069424672],
  [10.578060311703391, 65.47017499956078],
  [10.365946415390482, 65.26520614965875],
  [9.699811956326721, 64.72844917376007],
  [9.325240225289837, 64.54729413653664],
  [8.940475932424965, 64.35771215847855],
  [8.16464881481015, 64.06632192037875],
  [8.090144848170727, 64.03107195094785],
  [7.6480939365726215, 63.76816766381788],
  [7.462159289692665, 63.65573183581422],
  [6.971293623449736, 63.34248971404413],
  [6.499303806085864, 63.145963803615146],
  [6.3380717939587194, 63.09133147933308],
  [5.993773890444958, 62.97337310629749],
  [5.88782251957679, 62.927082137230535],
  [5.641250066393101, 62.81198557127908],
  [5.341264842610721, 62.665950147850765],
  [4.9490900630196215, 62.47193404639297],
  [4.887400369541964, 62.43323676753925],
  [4.816602003568957, 62.38239486612631],
  [4.695910134321655, 62.29525691186573],
  [4.578919425421971, 62.18084406515499],
  [4.506142453612163, 62.10590853390439],
  [4.441188919929338, 62.0319392096365],
  [4.226493896510697, 61.7834637044684],
  [4.158191391031302, 61.69719419234875],
  [4.147661754038541, 61.66169972915339],
  [4.129897978940477, 61.49082903284052],
  [4.102969982287213, 61.22995096508698],
  [4.087978968973648, 61.083760388835124],
  [4.090958387844356, 61.011471905937604],
  [4.104082838953683, 60.97619437429173],
  [4.507142317401426, 60.23059176136996],
  [4.649186090916498, 59.77488005660569],
  [4.464687156476421, 59.33092850448132],
  [4.454505982102525, 59.27799072821208],
  [4.463016923700823, 59.23861274249395],
  [4.487337124189707, 59.195594162667895],
  [4.538696188492089, 59.14998495591332],
  [5.112238611047211, 58.700989569605454],
  [5.1632400343993785, 58.62362720354912],
  [5.21576352944306, 58.55946385026581],
  [5.327324093386413, 58.45606774314512],
  [5.415605288755719, 58.39452868749333],
  [5.519542090983557, 58.332508276231955],
  [5.6230191760497235, 58.27372442973097],
  [5.871745752951057, 58.15833083749438],
  [6.16452929594286, 58.02084614897208],
  [6.343953861188573, 57.93570061484401],
  [6.406043020999747, 57.90825559753765],
  [6.529112128257166, 57.871822512324286],
  [6.863290761986007, 57.79898191114436],
  [6.9348744738318935, 57.78774725583614],
  [7.1306302777185975, 57.765826377261355],
  [7.25780906526797, 57.76168168631187],
  [7.557423184116949, 57.75900521026464],
  [7.557423184116949, 57.75900521026464],
  [7.557423184116949, 57.75900521026464],
  [7.693071730793179, 57.766352901363504],
  [7.769676155782617, 57.77832847847365],
  [7.879243941672844, 57.80203451116391],
  [8.087193553390941, 57.848164313734905],
  [8.178427905807398, 57.86854745105806],
  [8.371680277229787, 57.91723404671258],
  [8.437784685462224, 57.934598271401725],
  [8.505876785920067, 57.960981947152675],
  [8.753823349366815, 58.08136928069413],
  [8.918873776801568, 58.16290068757077],
  [9.06726917196247, 58.25027141377341],
  [9.364087494934749, 58.42566488209222],
  [9.788358205880135, 58.67227869186247],
  [10.073833378570509, 58.74791928436987],
  [10.359371820077689, 58.771277317747916],
  [10.593095158213497, 58.76096038467888],
  [10.983193484435885, 58.96044953894351],
  [11.066417445020777, 58.97739441818088],
]
/* const zone2 = [
  [30.78221990877185, 69.81147344539829],
  [31.064462158945528, 70.01744451427645],
  [31.558386096749473, 70.19750866782967],
  [32.05231003455342, 70.31668515128997],
  [36.42706491224552, 73.39648736686733],
  [35.192255067735644, 73.66652295219805],
  [33.85160437941065, 73.96155240288321],
  [32.19343115964026, 74.19383395782391],
  [30.182455127152767, 74.3848949171336],
  [28.06563825085015, 74.46068716446094],
  [25.94882137454752, 74.50787498720108],
  [24.114246748418577, 74.47957909349299],
  [22.70303549755016, 74.39438862679764],
  [21.327104527953452, 74.34686373038706],
  [19.91589327708504, 74.20344088854581],
  [18.01075808841268, 73.92251694846702],
  [17.093470775348205, 73.70616321888303],
  [16.56426655627255, 73.59692664410396],
  [14.835532773958741, 73.37631298761977],
  [13.318480679275195, 73.12211507817705],
  [12.330632803667303, 72.91604315873131],
  [11.34278492805941, 72.62343717697726],
  [10.284376489908098, 72.22932165188251],
  [9.931573677190993, 72.00179996992729],
  [8.626203270137708, 71.60519558038855],
  [7.708915957073237, 71.29090497981164],
  [6.579946956378505, 70.74004085558487],
  [5.9449018934877165, 70.35230359093528],
  [5.345137111868639, 70.06561462634676],
  [4.992334299151536, 69.81147344539829],
  [4.674811767706141, 69.54190142721515],
  [3.933925861000223, 69.11850395837249],
  [3.4400019231962786, 68.6482403868331],
  [3.1224793917508853, 68.04950471303829],
  [3.1224793917508853, 67.7308162323632],
  [3.1224793917508853, 67.32627877977767],
  [3.3341610793811456, 66.90094964769965],
  [3.2988807981094364, 66.51030892568228],
  [2.346313203773255, 66.07054816046873],
  [1.8876695472410199, 65.75377067314426],
  [1.3231850468936521, 65.38901525172],
  [0.5117385776443125, 65.03401432732521],
  [0.08837520238378986, 64.80971053156233],
  [-0.47610929796357787, 64.41645058604125],
  [-0.5113895792352899, 63.89354724271071],
  [-0.08802620397476417, 63.565629204950675],
  [0.5470188589160245, 62.94648141910661],
  [1.1115033592633892, 62.31394976728133],
  [1.640707578339048, 61.60077744082095],
  [1.7818287034258868, 61.38187161038712],
  [2.0287906723278617, 59.92985988999944],
  [1.7818287034258868, 59.359337695697796],
  [1.5348667345239149, 58.392902405341225],
  [1.9582301097844408, 57.965059173150706],
  [2.2404723599581247, 57.34216162907232],
  [2.4874343288600964, 56.920932360595096],
  [2.6285554539469382, 56.65037572642214],
  [3.1930399542943033, 56.14271982782061],
  [5.027614580423246, 56.630975526343654],
  [6.509386393835084, 57.07466134445065],
  [7.603075113258106, 57.456211958262685],
  [8.343961019964022, 57.6455080563476],
  [8.76732439522455, 57.72095133932171],
  [9.402369458115338, 58.09581762274319],
  [10.460777896266649, 58.337388479648524],
  [11.42936658598111, 58.533239426112334],
]
*/
const zone3 = [
  [9.2409123, 53.8840692],
  [8.128669231766919, 53.76110897145046],
  [8.084116710181076, 53.758472910630154],
  [7.2100447150350515, 53.73674718193444],
  [7.188457798847532, 53.7369539578232],
  [6.884544299077354, 53.73943483521735],
  [5.582507604092934, 53.816122806063554],
  [5.4048357059554375, 53.83589855535866],
  [4.214025323033429, 54.02912509950931],
  [4.195418984914994, 54.03315199946166],
  [3.907678408430576, 54.09494297943988],
  [3.856076477176336, 54.10605395134441],
  [3.847917643901422, 54.107830386120284],
  [2.964607097486798, 54.33933643680835],
  [2.9322971584620796, 54.34931026057647],
  [1.9598217727694323, 54.706880025766026],
  [1.9294158190173494, 54.72002041137727],
  [1.799538419480814, 54.777361908353484],
  [1.5949831928340141, 54.86921452262068],
  [1.3455516610306097, 54.9801513005799],
  [1.0943208960982644, 55.090714118784085],
  [0.6683881272364645, 55.291061744200206],
  [0.6576113364754633, 55.29650533624482],
  [0.3903947519221845, 55.4378862090331],
  [0.2294478884748783, 55.52671170759645],
  [0.0013588624201885, 55.65729861919534],
  [-0.0410465563654027, 55.68256022960688],
  [-0.7867174382908266, 56.19731278003008],
  [-0.9076995459157859, 56.2942284700685],
  [-1.1724321543323402, 56.51942179672872],
  [-1.1845148106118546, 56.530391061248125],
  [-1.2363517719108041, 56.58383087852323],
  [-1.2363517719108041, 56.58383087852323],
  [-1.465188587645743, 56.71891697150544],
  [-1.4668553275318337, 56.720084722486824],
  [-2.362124901960393, 57.49829129948423],
  [-2.9548482704304506, 58.369057512980575],
  [-2.9606878720665053, 58.3815034531725],
  [-3.185679812617453, 59.13473808864645],
  [-3.177427468320835, 59.32082246982735],
  [-3.2337262210970947, 59.392061341480066],
  [-3.3673261839061848, 59.559847976002516],
  [-3.859585662661579, 60.401418582015545],
  [-3.8617085767711683, 60.407040166692624],
  [-3.9233210300358285, 60.59165845773198],
  [-3.934508984686547, 60.63019217151234],
  [-3.995381748314282, 60.897933700518195],
  [-4.0019451323027155, 60.93789522353876],
  [-4.031631546142283, 61.117157903244355],
  [-4.061822984253172, 61.297014508381515],
  [-4.092426567416025, 61.47685536415833],
  [-4.090070801263639, 62.19102399891141],
  [-3.827646441805058, 62.89752687496309],
  [-3.7556412870918634, 63.02316747939197],
  [-3.655071686964179, 63.19689656542315],
  [-3.650527307581653, 63.204671317294775],
  [-3.5937444328033616, 63.30112387200938],
  [-3.4975887060507964, 63.451987338669866],
  [-3.3832863516468934, 63.61793502846662],
  [-2.880974954143188, 64.1832995768877],
  [-2.7036268516193895, 64.34356618876593],
  [-1.8087700645198528, 64.97930429678455],
  [-1.6350019026218368, 65.07853192435897],
  [-1.380971524681094, 65.22195525543744],
  [-1.1238377946685085, 65.36518039846904],
  [-1.022003579491593, 65.42042066878584],
  [-1.0197685992188863, 65.42160963114226],
  [-0.7529443766215826, 65.56257165873224],
  [-0.1567158809675002, 65.82960467711364],
  [-0.1337805572899234, 65.84657607088467],
  [0.0794037918873873, 66.00313479904851],
  [0.2952425294860373, 66.15947219642565],
  [0.8992497854171738, 66.53815604677894],
  [0.9081754771284724, 66.54306449348341],
  [1.320579595651819, 66.74411940497345],
  [1.2165266228364058, 66.86463396142767],
  [0.9850430777938809, 67.86076275100864],
  [0.9850637700107714, 67.86103724025347],
  [1.031212170736541, 68.13973696539264],
  [1.0455271394272692, 68.19529457231806],
  [1.4472404818414917, 68.95542381020033],
  [2.2698643693309792, 69.67435057820452],
  [2.5660412742719396, 69.83186436472688],
  [3.4003039519701748, 70.47781250394861],
  [3.4806142696491347, 70.52435877868368],
  [3.7055887109019485, 70.62256949167653],
  [4.4484700697535615, 71.12404606360252],
  [4.62161862667863, 71.21853233609566],
  [4.907767750301061, 71.37240114336065],
  [5.198456672384593, 71.52586177009438],
  [6.872343832286202, 72.19781387221653],
  [6.94903232034157, 72.22172240528589],
  [7.362308287717376, 72.34902055057168],
  [7.781484675393499, 72.47551923221124],
  [7.855579499593203, 72.49378613824449],
  [9.17021700109845, 73.10484235173314],
  [11.567750679865886, 73.7418952045921],
  [12.058255940633662, 73.83840235429749],
  [13.358569222647452, 74.05815813203216],
  [13.752800594841998, 74.11498598426132],
  [14.335430995021285, 74.1969848783682],
  [14.407184008133516, 74.206902569906],
  [14.995963545653888, 74.28695353846429],
  [14.99717020433045, 74.28711522686163],
  [15.338301944315985, 74.36443391463588],
  [15.851895081960057, 74.4785048513825],
  [16.90113283176861, 74.68463993661848],
  [16.910650767961126, 74.68630832567364],
  [19.032544015245463, 74.98415744337557],
  [21.366522766355956, 75.17252179684021],
  [21.47273789682022, 75.17824138093096],
  [22.19192665149933, 75.20710247730658],
  [22.251243234980365, 75.21133574786349],
  [22.75716662918555, 75.22814903565254],
  [22.913714183957907, 75.23372481312931],
  [22.992133454098465, 75.23557069116647],
  [26.740614173449316, 75.32122516480166],
  [27.151898473829785, 75.31245735226486],
  [27.85256114912914, 75.29584960370134],
  [28.551264924775644, 75.2771814875022],
  [29.249747105302344, 75.25640378683384],
  [30.6750713382354, 75.19065465532185],
  [31.32815578727044, 75.14956440548667],
  [31.79525007346905, 75.11697792397614],
  [31.817487789768908, 75.11530692919025],
  [34.67803964476572, 74.79550216974874],
  [37.09998063925753, 74.30841015478124],
  [37.264178643945236, 74.26561560770797],
  [37.38031083800428, 74.23514500465467],
  [39.29376965377115, 73.77773983562754],
  [39.415199379624724, 73.74139063730163],
  [39.937793367536074, 73.5734097863833],
  [40.06909213571243, 73.52804774996127],
  [40.464531597722214, 73.38947533887566],
  [40.854414427131175, 73.2499181033254],
  [42.36315004463385, 72.54010249300262],
  [42.37649248362836, 72.531702702949],
  [43.089735279344744, 71.96307857912814],
  [43.092878723108235, 71.95976686881889],
  [43.578935038842616, 71.08935332529099],
  [43.4693395773404, 70.2057800346886],
  [43.46901412408255, 70.20503370094558],
  [42.88750223933002, 69.39291521365557],
  [42.61963600354149, 69.18988122197234],
  [42.48341584327066, 69.06109977367512],
  [42.3088152012599, 68.89355237109251],
  [42.13671983479656, 68.72562035830738],
  [41.189206388693144, 67.98965975715289],
  [40.739, 67.7525],
]

const zones = [
  {
    id: 1,
    name: 'Norwegian Territorial Boundary',
    coords: zone1,
  },
  /*  {
    id: 2,
    name: 'Norwegian Economic Boundary',
    coords: zone2,
  },
*/ {
    id: 3,
    name: 'Norwegian NOx Boundary',
    coords: zone3,
  },
]

export default zones
