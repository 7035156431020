import { Typography } from '@material-ui/core'
import React from 'react'

export interface IReleaseNoteProps {
  english: boolean
  openTaxRules?: () => void
}

const ReleaseNote1 = (props: IReleaseNoteProps) => {
  return (!props.english) ? (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q1-2022
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Tolkning av turer
      </Typography>
      <Typography variant="body1">
        Tidligere versjoner har hatt et logisk skille mellom arbeid på én installasjon vs. arbeid på flere. I tråd med presiseringer fra skatteetaten er nå alt petroleumsrelatert arbeid på sokkelen fritatt.
      </Typography>
      <Typography variant="body1">
        Fritak for DP operasjoner for Spesialskip er nå utvidet til også å inkludere Anchor Handling.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Faste installasjoner
      </Typography>
      <Typography variant="body1">
        De faste installasjonene som ligger i modellen vises nå på alle kart, inkl. sine respektive soner.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Subsea installasjoner
      </Typography>
      <Typography variant="body1">
        Alle aktive subsea-installasjoner er nå lagt inn i modellen, slik at arbeid ved disse også kan fritas.
      </Typography>
      <Typography variant="body1">
        Subsea områdene vises ikke i kartet, da det blir alt for mye overlappende data. Vær obs på at der installasjoner overlapper velger modellen den nærmeste.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Utenlandske havner
      </Typography>
      <Typography variant="body1">
        I denne versjonen er det lagt til 30 utenlandske havner med geo-fence:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1">
        Gi oss beskjed om hvilke andre utenlandshavner dere trenger, så vil vi oppdatere med de viktigste fortløpende.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Arkivering
      </Typography>
      <Typography variant="body1">
        Etter at kvartalet er rapportert anbefaler vi at hver båt settes til «Archived». Dette gjøres på samme måte som å endre fra «Pending» til «Approved», nederst i NOx Voyages kvartalsoversikten, og vil forhindre endring av data etter rapportering.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Slette endringer
      </Typography>
      <Typography variant="body1">
        Dersom det er gjort endringer i skattbarhet, forbruk eller lokasjon vil dette vises med et ikon i listen. Hvis man redigerer en endret tur, vil ikon og forklaring også vises i redigeringsvinduet, og man kan fjerne endringen enkelt ved å klikke på ikonet.
      </Typography>
      <Typography variant="body1">
        <b>NB!</b>&nbsp;&nbsp;Gi listen et par sekunder til å refreshe etter endringen.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        NOx Voyages
      </Typography>
      <Typography variant="body1">
        Nå er det mulig å definere et dato-intervall i oversikten vha. «Display date range». Dermed slipper man å måtte jobbe med hele kvartalet i listen.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Redigering av turer
      </Typography>
      <Typography variant="body1">
        Det er nå lagt til funksjoner for å gå til neste/forrige tur direkte i redigeringsvinduet.
      </Typography>
      <Typography variant="body1">
        Tidligere kom man direkte til «Missing Location» hvis turen var ‘Undefined’. Nå vil redigering alltid starte i det vanlige redigeringsvinduet, hvor man kan velge «Add a Missing Location» selv.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Vessel Details
      </Typography>
      <Typography variant="body1">
        Dette menyvalget er omdøpt fra «Vessels» og har blitt flyttet nederst i hovedmenyen.
      </Typography>
      <Typography variant="body1">
        Dersom en båt er tilknyttet Maress vil dette vises med en Maress-logo til høyre i fartøyslisten.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        LNG håndtering
      </Typography>
      <Typography variant="body1">
        Dersom det er satt opp LNG faktorer til motorer i Maress vil disse nå brukes etter intensjonen i alle beregninger av NOx.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        Denne har forbedret fordeling av NOx når bare deler av en tur skal være med i rapporten.
      </Typography>
    </>
    ) : (
      <>
        <Typography variant="h6" style={{ marginBottom: 16, textAlign: "center" }}>
          NOxDigital Q1-2022
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          Voyage taxability
        </Typography>
        <Typography variant="body1">
          Previous versions treated voyages to a single installation differently from multi-installation voyages.
          According to the tax authorities all petroleum-related activity on the shelf is tax exempt.
        </Typography>
        <Typography variant="body1">
          Exemption for Special vessles in DP operation is now extended to include Anchor Handling.
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          Fixed installations
        </Typography>
        <Typography variant="body1">
          All fixed installations in the model are now shown on the maps, including their zones.
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          Subsea installations
        </Typography>
        <Typography variant="body1">
          All active subsea-installations on the shelf have been added to the model, enabling tax-exemption for work
          here too.
        </Typography>
        <Typography variant="body1">
          The subsea-areas are not shown on the map, due to clarity/readability.
        </Typography>
        <Typography variant="body1">
          <b>Note</b> that where installations overlap, the model will choose the closest!
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          Foregin ports
        </Typography>
        <Typography variant="body1">
          This version has the following 30 foreign ports with geo-fence:
        </Typography>
        <Typography variant="body1" style={{ marginLeft: 16 }}>
          Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Esbjerg, Fredericia,
          Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Murmansk, Montrose,
          Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn,
          Wilhelmshaven.
        </Typography>
        <Typography variant="body1">
          Let us know which other ports are needed, and we will add the most frequently requested.
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          Archiving
        </Typography>
        <Typography variant="body1">
          When the final report for a quarter is completed, we recommend that all vessels are marked as "Archived". This
          is similar to changing status to "Approved" in the NOx Voyages list, and will prevent any change of data after
          submitting the report.
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          Revert changes
        </Typography>
        <Typography variant="body1">
          If the taxability, consumption or locations for a voyage have been edited, icons will show this in the list.
          When editing a modified voyage, a click on the icon will allow you to "Revert to original", nulling all
          changes.
        </Typography>
        <Typography variant="body1">
          <b>Note</b> that the list will need a couple of seconds to refresh after a change!
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          NOx Voyages
        </Typography>
        <Typography variant="body1">
          A "Display date range" control has been added, allowing users to narrow down the number of voyages in the list
          to those between the desired dates.
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          Editing voyages
        </Typography>
        <Typography variant="body1">
          New Previous and Next buttons have been added in the edit view, so editing multiple, adjacent voyages is much
          easier.
        </Typography>
        <Typography variant="body1">
          Previously an Undefined voyage would take you directly to "Missing Location". Now all editing starts in the
          regular edit view, where the "Missing Location" option can be activated manually.
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          Vessel Details
        </Typography>
        <Typography variant="body1">
          This menu-option has been renamed from "Vessels" and moved to the bottom of the main menu.
        </Typography>
        <Typography variant="body1">
          If a vessel is active in Maress, we will show a Maress-logo on the right side of the vessels list.
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          LNG handling
        </Typography>
        <Typography variant="body1">
          If LNG is activated and the correct factors are set in Maress, this will now be fully implemented in all NOx
          calculations.
        </Typography>
        <Typography variant="subtitle1"
                    style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold" }}>
          Charter Report
        </Typography>
        <Typography variant="body1">
          We have improved the distribution of NOx for voyages that partially overlap the Charter Report period entered.
        </Typography>
      </>
  )}

export default ReleaseNote1