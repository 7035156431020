import { IconButton } from '@material-ui/core'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import ExitFullscreenIcon from '@material-ui/icons/FullscreenExit'
import mapboxgl from 'mapbox-gl'
import React, { useEffect, useState } from 'react'

import UiMapBoxControl from './UiMapBoxControl'

interface IFullscreenControlProps {
  map: mapboxgl.Map
  children?: any
}

function FullscreenControl(props: IFullscreenControlProps): any {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const { map } = props
  const container = map.getContainer()
  const rfs =
    (container as any).requestFullscreen ||
    (container as any).webkitRequestFullScreen ||
    (container as any).mozRequestFullScreen ||
    (container as any).msRequestFullscreen

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleChangeFullscreen, false)
    document.addEventListener('mozfullscreenchange', handleChangeFullscreen, false)
    document.addEventListener('MSFullscreenChange', handleChangeFullscreen, false)
    document.addEventListener('webkitfullscreenchange', handleChangeFullscreen, false)

    return function cleanup() {
      document.removeEventListener('fullscreenchange', handleChangeFullscreen)
      document.removeEventListener('mozfullscreenchange', handleChangeFullscreen)
      document.removeEventListener('MSFullscreenChange', handleChangeFullscreen)
      document.removeEventListener('webkitfullscreenchange', handleChangeFullscreen)
    }
  })

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      rfs.call(container)
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  const handleChangeFullscreen = () => setIsFullscreen(document.fullscreenElement !== null)

  document.addEventListener('fullscreenchange', handleChangeFullscreen, false)
  document.addEventListener('mozfullscreenchange', handleChangeFullscreen, false)
  document.addEventListener('MSFullscreenChange', handleChangeFullscreen, false)
  document.addEventListener('webkitfullscreenchange', handleChangeFullscreen, false)

  return isFullscreen ? (
    <IconButton onClick={toggleFullscreen} className={'my-custom-control mapboxgl-ctrl'}>
      <ExitFullscreenIcon />
    </IconButton>
  ) : (
    <IconButton onClick={toggleFullscreen} className={'my-custom-control mapboxgl-ctrl'}>
      <FullscreenIcon />
    </IconButton>
  )
}

const FullscreenControlMap = (map: mapboxgl.Map) => new UiMapBoxControl(<FullscreenControl map={map} />)
export default FullscreenControlMap
