import 'typeface-roboto'

import { GriegBrowserRouter } from '@griegconnect/krakentools-react-router'
import { GriegThemeProvider } from '@griegconnect/krakentools-react-ui'
import React from 'react'

import AppContextWrapper from './wrappers/AppContextWrapper'
import AuthProvider from './wrappers/auth'
import ConfigWrapper from './wrappers/config/ConfigWrapper'
import HttpProvider from './wrappers/http'
import OnboardingWrapper from './wrappers/onboarding/OnboardingWrapper'
import TenantsWrapper from './wrappers/tenants/TenantsWrapper'

type GriegProviderProps = {
  children: React.ReactNode;
};

export const GriegProvider = (props: GriegProviderProps) => {
  const { children } = props

  return (
    <GriegThemeProvider useScopedCssBaseline={false} tool="kraken">
      <ConfigWrapper>
        <GriegBrowserRouter>
          <AuthProvider>
            <HttpProvider>
              <OnboardingWrapper>
                <TenantsWrapper>
                  <AppContextWrapper>{children}</AppContextWrapper>
                </TenantsWrapper>
              </OnboardingWrapper>
            </HttpProvider>
          </AuthProvider>
        </GriegBrowserRouter>
      </ConfigWrapper>
    </GriegThemeProvider>
  )
}

export default GriegProvider
