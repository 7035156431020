import { makeStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 900,
      margin: '0 auto',
    },
    reportSheet: {
      padding: 16,
    },
    printBtnWrapper: {
      width: '100%',
      marginBottom: 36,
      display: 'flex',
      justifyContent: 'center',
    },
    bottomTextPrint: {
      position: 'fixed',
      width: '100%',
      textAlign: 'center',
      fontSize: '0.5rem',
      color: '#3e3f3d',
      bottom: 16,
    },
  })
)

export const printStyleOverrides = `
  @page {
    size: auto;
    margin: 10mm 4mm;
  }

  @media print {
    
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }

    .bottom-text:after {
      content: " Powered by NOx Digital | noxdigital.no | yxney.com | noxdigitalapp.com ";
      width: 100%;
      color: #555 !important;
      font-size: 0.7em;
      text-align: center;
      position: fixed;
      bottom: 0;
    }
  }
`
