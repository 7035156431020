import Checkbox from '@material-ui/core/Checkbox'
import Drawer from '@material-ui/core/Drawer'
import FormControl from '@material-ui/core/FormControl/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup/FormGroup'
import FormLabel from '@material-ui/core/FormLabel/FormLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio/Radio'
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup'
import Select from '@material-ui/core/Select'
import { WithStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { Close } from '@material-ui/icons'
import * as React from 'react'

const drawerWidth = 300
const styles = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: drawerWidth,
      padding: '20px',
      top: '56px',
      paddingBottom: '64px',
    },
    filterHeader: {
      marginBottom: '8px',
      marginTop: '10px',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: '1199 !important' as unknown as number,
      '& .apply-button': {
        marginTop: '8px',
      },
      '& .apply-button-griditem': {
        margin: '0 auto',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    select: {
      '& .select': {
        width: '100%',
        marginBottom: '12px',
      },
    },
    selectMenu: {
      top: '41px',
    },
  })

interface IUiFilterDrawerProps extends WithStyles<typeof styles> {
  filterConfig: IUiFilter
  filterSelections: IUiFilterSelections
  onClose: () => void
  onChange: (filterSelection: IUiFilterSelections) => void
  open: boolean
}

export interface IUiFilter {
  [filterSectionKey: string]: IUiFilterSectionTypes
}

type IUiFilterSectionTypes =
  | IUiFilterSectionCheckboxes
  | IUiFilterSectionDate
  | IUiFilterSectionRadiobuttons
  | IUiFilterSectionSelect
  | IUiFilterSectionSwitches
type IFilterSectionType = 'checkboxes' | 'date' | 'radiobuttons' | 'select' | 'switches'

interface IUiFilterSectionDefaults {
  label: string
  type: IFilterSectionType
}

interface IUiFilterSectionValues {
  values: IUiFilterSectionValuesKeyValue[]
}

// interface IUiFilterSingleValue {
// 	value: any;
// }

interface IUiFilterSectionCheckboxes extends IUiFilterSectionDefaults, IUiFilterSectionValues {
  type: 'checkboxes'
}

interface IUiFilterSectionDate extends IUiFilterSectionDefaults {
  type: 'date'
  fromDate?: Date
  toDate?: Date
  timeOfDayType?: 'default' | 'start' | 'end'
}

interface IUiFilterSectionRadiobuttons extends IUiFilterSectionDefaults, IUiFilterSectionValues {
  type: 'radiobuttons'
}

export interface IUiFilterSectionSelect extends IUiFilterSectionDefaults, IUiFilterSectionValues {
  type: 'select'
  multiple: boolean
  placeholder?: string
}

interface IUiFilterSectionSwitches extends IUiFilterSectionDefaults, IUiFilterSectionValues {
  type: 'switches'
}

export interface IUiFilterSectionValuesKeyValue {
  label: string
  /**
   * An empty string value '' will always remove the filter selection
   *
   * @type {string}
   * @memberof IUiFilterSectionValues
   */
  value: string
}

export interface IUiFilterSelections {
  [filterSelectionKey: string]: string[]
}

class UiFilterDrawer extends React.Component<IUiFilterDrawerProps> {
  constructor(props: IUiFilterDrawerProps) {
    super(props)
    this.state = {
      open: props.open,
    }
  }

  onBeforeChangeFilter(newFilterSelections: IUiFilterSelections) {
    // TODO: Validate and clean up unused selections...
    Object.keys(newFilterSelections).forEach((key) => {
      const keyValue = newFilterSelections[key]
      let deleteSelection = keyValue === undefined || keyValue.length === 0 ? true : false
      if (deleteSelection === false && keyValue.length > 0) {
        for (const value of keyValue) {
          if (value === undefined || value === null || value === '') {
            deleteSelection = true
            break
          }
        }
      }
      if (deleteSelection) {
        delete newFilterSelections[key]
      }
    })
    this.props.onChange(newFilterSelections)
  }

  onChangeFilterSectionValues =
    (newValues: IUiFilterSectionValuesKeyValue, filterSectionKey: string) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const newFilterSelections = this.props.filterSelections
      const valueMatchIndex = newFilterSelections[filterSectionKey]
        ? newFilterSelections[filterSectionKey].indexOf(newValues.value)
        : undefined
      if (valueMatchIndex === undefined) {
        newFilterSelections[filterSectionKey] = []
        newFilterSelections[filterSectionKey].push(newValues.value)
      } else if (valueMatchIndex === -1) {
        newFilterSelections[filterSectionKey].push(newValues.value)
      } else {
        newFilterSelections[filterSectionKey].splice(valueMatchIndex, 1)
      }
      this.props.onChange(newFilterSelections)
    }

  onChangeFilterSectionSingleValue =
    (filterSectionKey: string) => (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      const newFilterSelections = this.props.filterSelections
      newFilterSelections[filterSectionKey] = [value]
      this.onBeforeChangeFilter(newFilterSelections)
    }

  renderCheckboxes(
    filterCheckboxes: IUiFilterSectionCheckboxes,
    filterSelectionValues: string[],
    filterSectionKey: string
  ) {
    return (
      <FormControl margin="normal" component="fieldset" fullWidth={true}>
        <FormLabel component="legend">{filterCheckboxes.label}</FormLabel>
        <FormGroup>
          {filterCheckboxes.values.map((value: IUiFilterSectionValuesKeyValue) => {
            const checked = filterSelectionValues ? filterSelectionValues.indexOf(value.value) >= 0 : false
            return (
              <FormControlLabel
                key={`${filterSectionKey}-value${value.value}`}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={this.onChangeFilterSectionValues(value, filterSectionKey)}
                    value={value.value}
                  />
                }
                label={value.label}
              />
            )
          })}
        </FormGroup>
      </FormControl>
    )
  }

  renderDate(filterDate: IUiFilterSectionDate, filterSelectionValues: string[], filterSectionKey: string) {
    // const onChange = (date: Date) => {
    //   const newFilterSelections = this.props.filterSelections
    //   if (date !== null) {
    //     let newDate = date
    //     if (filterDate.timeOfDayType) {
    //       if (filterDate.timeOfDayType === 'start') {
    //         newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
    //       }
    //       if (filterDate.timeOfDayType === 'end') {
    //         newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)
    //       }
    //     }
    //     newFilterSelections[filterSectionKey] = [newDate.toJSON()]
    //   } else {
    //     delete newFilterSelections[filterSectionKey]
    //   }
    //   this.onBeforeChangeFilter(newFilterSelections)
    // }
    return (
      <FormControl margin="normal" component="fieldset" fullWidth={true}>
        <FormLabel component="legend">{filterDate.label}</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={filterSelectionValues && filterSelectionValues.length > 0 ? filterSelectionValues[0] : ''}
          onChange={this.onChangeFilterSectionSingleValue(filterSectionKey)}
        >
          {/* <UiDatePicker value={filterSelectionValues ? filterSelectionValues[0] : null} onChange={onChange} fullWidth={true} /> */}
        </RadioGroup>
      </FormControl>
    )
  }

  renderRadiobuttons(
    filterRadiobuttons: IUiFilterSectionRadiobuttons,
    filterSelectionValues: string[],
    filterSectionKey: string
  ) {
    return (
      <FormControl margin="normal" component="fieldset" fullWidth={true}>
        <FormLabel component="legend">{filterRadiobuttons.label}</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={filterSelectionValues && filterSelectionValues.length > 0 ? filterSelectionValues[0] : ''}
          onChange={this.onChangeFilterSectionSingleValue(filterSectionKey)}
        >
          {filterRadiobuttons.values.map((value: IUiFilterSectionValuesKeyValue) => {
            return (
              <FormControlLabel
                key={`${filterSectionKey}-value${value.value}`}
                control={<Radio />}
                value={value.value}
                label={value.label}
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    )
  }

  renderSwitches(filterSwitches: IUiFilterSectionSwitches, filterSelectionValues: string[], filterSectionKey: string) {
    return (
      <>
        <FormControl margin="normal" component="fieldset" fullWidth={true}>
          <FormLabel component="legend">{filterSwitches.label}</FormLabel>
          <FormGroup>
            {filterSwitches.values.map((value: IUiFilterSectionValuesKeyValue) => {
              const checked = filterSelectionValues ? filterSelectionValues.indexOf(value.value) >= 0 : false
              return (
                <FormControlLabel
                  key={`${filterSectionKey}-value${value.value}`}
                  labelPlacement="end"
                  control={
                    <Switch
                      checked={checked}
                      onChange={this.onChangeFilterSectionValues(value, filterSectionKey)}
                      value={value.value}
                      color="primary"
                    />
                  }
                  label={value.label}
                />
              )
            })}
          </FormGroup>
        </FormControl>
      </>
    )
  }

  renderSelect(filterSelect: IUiFilterSectionSelect, filterSelectionValues: string[], filterSectionKey: string) {
    const handleChange = (event: any) => {
      const eventTarget = event.target
      const newFilterSelections = this.props.filterSelections
      newFilterSelections[filterSectionKey] = filterSelect.multiple ? [...eventTarget.value] : [eventTarget.value]

      this.onBeforeChangeFilter(newFilterSelections)
    }

    return (
      <FormControl margin="normal" component="fieldset" fullWidth={true}>
        <FormLabel component="legend">{filterSelect.label}</FormLabel>
        <Select
          fullWidth={true}
          multiple={filterSelect.multiple}
          displayEmpty={true}
          value={filterSelectionValues === undefined ? [] : filterSelectionValues}
          onChange={handleChange}
          input={<Input />}
          // tslint:disable-next-line: jsx-no-lambda
          renderValue={(selected) => {
            const selectedValues: undefined | string[] = selected as unknown as undefined | string[]
            if (selectedValues === undefined || selectedValues.length === 0) {
              return <em>{filterSelect.placeholder ? filterSelect.placeholder : 'No filtering'}</em>
            } else if (selectedValues.length <= 2) {
              const matches = filterSelect.values.filter((item) => selectedValues.indexOf(item.value) > -1)
              const retValues: string[] = []
              for (const item of matches) {
                retValues.push(item.label)
              }
              return retValues.join(', ')
            } else {
              return `${selectedValues.length} items selected`
            }
          }}
        >
          {filterSelect.values.map((item: IUiFilterSectionValuesKeyValue) => (
            <MenuItem key={item.value} value={item.value}>
              {filterSelect.multiple && (
                <Checkbox
                  checked={filterSelectionValues === undefined ? false : filterSelectionValues.indexOf(item.value) > -1}
                />
              )}
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  handleCloseDrawer = () => {
    this.props.onClose()
  }

  handleResetFilter = () => {
    const userFilter = this.props.filterSelections
    for (const key in userFilter) {
      if (userFilter.hasOwnProperty(key)) {
        delete userFilter[key]
      }
    }
    this.onBeforeChangeFilter(userFilter)
  }

  render() {
    const { classes, filterSelections, filterConfig, open } = this.props

    const items = Object.keys(filterConfig).map((filterSectionKey: string) => {
      const filterSection = filterConfig[filterSectionKey]
      const filterSelection = filterSelections[filterSectionKey]
      let component
      switch (filterSection.type) {
        case 'checkboxes':
          component = this.renderCheckboxes(filterSection, filterSelection, filterSectionKey)
          break
        case 'date':
          component = this.renderDate(filterSection, filterSelection, filterSectionKey)
          break
        case 'select':
          component = this.renderSelect(filterSection, filterSelection, filterSectionKey)
          break
        case 'switches':
          component = this.renderSwitches(filterSection, filterSelection, filterSectionKey)
          break
        case 'radiobuttons':
          component = this.renderRadiobuttons(filterSection, filterSelection, filterSectionKey)
          break
        default:
          component = null
      }
      return <React.Fragment key={`filter-group-${filterSectionKey}`}>{component}</React.Fragment>
    })

    return (
      <Drawer
        onClose={this.handleCloseDrawer}
        open={open}
        anchor="right"
        className={classes.drawer}
        variant="temporary"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ position: 'relative' }}>
          <Grid
            container={true}
            direction="row"
            className={classes.filterHeader}
            justify="space-between"
            alignItems="center"
          >
            <Grid item={true}>
              <Typography variant="body1">Filter</Typography>
            </Grid>
            <Grid item={true}>
              <Typography>
                <IconButton onClick={this.handleCloseDrawer}>
                  <Close />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>

          {items}

          {/*
          <Grid item={true} xs="auto" className="apply-button-griditem">
            <Button variant="outlined" className="apply-button" onClick={this.handleResetFilter}>
              Clear filter
            </Button>
          </Grid>
*/}
        </div>
      </Drawer>
    )
  }
}

class UiFilterUtilitiesClass {
  cleanSelections(filter: IUiFilter, selections: IUiFilterSelections): IUiFilterSelections {
    const cleanedSelections = { ...selections }
    Object.keys(selections).forEach((selectionKeyName: string, index: number) => {
      if (filter.hasOwnProperty(selectionKeyName)) {
        const filterSection = filter[selectionKeyName]
        let cleanedValues: string[] = []
        if (filter[selectionKeyName].type === 'select') {
          cleanedValues = [
            ...this._cleanSelectionWithMultipleValues(
              (filterSection as IUiFilterSectionSelect).values,
              selections[selectionKeyName]
            ),
          ]
        }
        if (cleanedValues.length === 0) {
          delete cleanedSelections[selectionKeyName]
        } else {
          cleanedSelections[selectionKeyName] = cleanedValues
        }
      }
    })
    return cleanedSelections
  }

  private _cleanSelectionWithMultipleValues(values: IUiFilterSectionValuesKeyValue[], selections: string[]): string[] {
    const cleanedSelections: string[] = []
    for (const valueItem of values) {
      if (selections.indexOf(valueItem.value) >= 0) {
        cleanedSelections.push(valueItem.value)
      }
    }
    return cleanedSelections
  }
}

export const UiFilterUtilities = new UiFilterUtilitiesClass()

export default withStyles(styles)(UiFilterDrawer)
