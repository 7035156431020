import { createMuiTheme } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'

import { QueryClient, QueryClientProvider } from 'react-query'

import GriegProvider from './common/GriegProvider'
import Navigation from './common/UIWrapper'
import ApisWrapper from './lib/ApiProvider'
import Routes from './Routes'

let theme = createMuiTheme()
const queryClient = new QueryClient()

//ApolloWrapper deviates from other Kraken app Grieg providers
ReactDOM.render(
  <GriegProvider>
    <RecoilRoot>
      <Navigation appbarTitle="NOxDigital">
        <QueryClientProvider client={queryClient}>
          <ApisWrapper>
            <SnackbarProvider
              classes={{
                variantSuccess: theme.palette.success.main,
                variantError: theme.palette.error.main,
                variantWarning: theme.palette.warning.main,
                variantInfo: theme.palette.info.main,
              }}
              autoHideDuration={4000}
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Routes />
            </SnackbarProvider>
          </ApisWrapper>
        </QueryClientProvider>
      </Navigation>
    </RecoilRoot>
  </GriegProvider>,
  document.getElementById('root') as HTMLElement
)
