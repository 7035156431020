import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import {Button, IconButton, Paper, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import React, {useEffect, useLayoutEffect, useState} from 'react'
import PeriodsSelect from '../../utils/PeriodsSelect'

import { useQuery } from 'react-query'

import { useAppContext } from '../../common/GriegProvider/wrappers/AppContextWrapper'
import ErrorModal from '../../components/ApolloErrorModal'
import LoadingSpinner from '../../components/LoadingSpinner'
import DueWidget from './DueWidget'
//import NoxHistoryWidget from './NoxHistoryWidget'
//import NoxTimeWidget from './NoxTimeWidget'
import ProgressWidget from './ProgressWidget'
import { useRecoilState } from 'recoil'
import { periodFilterState } from '../../atoms'
import InfoIcon from '@griegconnect/krakentools-react-icons/Information'
import { GREEN_HIGHLIGHT } from '../../constants/colors'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useApis } from 'src/lib/ApiProvider'
import { NoxPeriod } from 'src/lib/apis/types/noxPeriod'
import { VesselPeriod, VesselWithEngines, VesselWithEnginesAndStatus } from 'src/lib/apis/types/vessel'
import Dialog from "@material-ui/core/Dialog"
import {TaxRules, ReleaseNote} from "./DashboardPopUps"
import UiFilterDrawer, {IUiFilterSelections} from "../../components/UiFilterDrawer"
import {initialFilterConfig, selectedFilterConfig} from "../noxReport/config/filterConfig"
import FilterIcon from "@griegconnect/krakentools-react-icons/Filter"

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    display: 'grid',
    padding: 32,
    marginLeft: -8,
    marginRight: -8,
  },
  text: {
    padding: '0px 0px 7px 16px',
  },
  textheader: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    verticalAlign: '12px',
  },
  subheader: {
    fontWeight: 300,
    fontSize: '13px',
    padding: '12px 0px 4px 0px',
  },
  paper: {
    backgroundColor: theme.palette.action.hover,
  },
  filterBtn: {
    marginLeft: 'auto',
    marginRight: 32,
  },
  filterIcon: {
    color: 'white',
  },
}))

const Dashboard: React.FunctionComponent<GriegRouteProps> = (props) => {
  //YAE 03.09.21 -> Added filterConfig to use quarter like other components
  const [filterState, setFilterState] = useRecoilState(periodFilterState)
  const classes = useStyles()
  const { apis } = useApis()

  const { route } = props
  const { setRouteOptionsCallback } = useAppContext()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))
  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps
  const smallerWidth = useMediaQuery('(max-width: 768px)')

  const [taxRulesOpen, setTaxRulesOpen] = useState<boolean>(false)
  const [releaseNoteOpen, setReleaseNoteOpen] = useState<boolean>(false)
  const [languageEN, setLanguageEN] = useState<boolean>(false)
  const maxReleaseNoteId = 7
  const [releaseNoteId,setReleaseNoteId] = useState<number>(maxReleaseNoteId)
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [filterConfig, setFilterConfig] = useState(initialFilterConfig)
  const largerWidth = useMediaQuery('(min-width: 884px)')


  const {
    isLoading: isLoadingPeriods,
    isError: isErrorPeriods,
    data: periods, error: errorPeriods,
    refetch: refetchPeriods
  } = useQuery<NoxPeriod[], Error>('noxPeriods', async () =>
    await apis.periodsApi.getPeriods()
  )

  const currentPeriod: NoxPeriod | undefined = PeriodsSelect.getPeriod(periods, filterState)

  //PeriodsSelce.getPeriod will return current selected from filterState, or use todays date to set period.
  //Either way, setting filterState is a good idea here. YAE 14.02.22
  useEffect(() => {
    const selectedFilterConf = selectedFilterConfig(periods)
    setFilterConfig(selectedFilterConf)

    if(currentPeriod && periods) {
      setFilterState({...filterState, quarter: [currentPeriod.id]})
    }
    // eslint-disable-next-line
  }, [currentPeriod, periods])


  const {
    isLoading: isLoadingVessels,
    isError: isErrorVessels,
    error: errorVessels,
    data: vessels,
    refetch: refetchVessels
  } = useQuery<VesselWithEngines[], Error>('vessels', async () => 
   await apis.vesselsApi.getVessels()
  )

  //YAE: Catch 22 here. need to use a clean periods-fetch above the 'data' fetch instead of vesselPeriodsQuery...
  /*  const currentPeriod = data
    ? data.periods
        .filter(
          (p) =>
            new Date(p.timeStart) <= new Date() &&
            new Date() <= new Date(new Date(p.registrationEnd).setHours(23, 59, 59, 999))
        )
        .sort((a, b) => (new Date(a.timeStart) > new Date(b.timeStart) ? 1 : -1))[0]
    : null
*/

  const {
    isLoading: isLoadingVesselsPeriods,
    error: errorVessselsPeriods,
    data: vesselsPeriods,
    isError: isErrorVesselsPeriods,
    refetch: refetchVesselsPeriods,
  } = useQuery<VesselPeriod[], Error>(['vesselPeriods', currentPeriod], async () =>
    await apis.vesselsApi.getVesselsPeriods(currentPeriod?.id),
    { enabled: !!currentPeriod }
  )

  if (isErrorPeriods) {
    return <ErrorModal error={errorPeriods} refetch={refetchPeriods} />
  }

  if (isErrorVessels) {
    return <ErrorModal error={errorVessels} refetch={refetchVessels} />
  }

  if (isErrorVesselsPeriods) {
    return <ErrorModal error={errorVessselsPeriods} refetch={refetchVesselsPeriods} />
  }

  if (isLoadingVessels || isLoadingPeriods || isLoadingVesselsPeriods) {
    return <LoadingSpinner />
  }

  if (!periods || periods.length === 0) {
    return (
      <Paper>
        <p>No periods defined. Please contact the system administrator.</p>
      </Paper>
    )
  }

  if (!vessels) {
    return (
      <Paper>
        <p>No Vessels</p>
      </Paper>
    )
  }

  //YAE 13.08.21: commented out this filter - no need for this!
  // const vessels = data.vessels!.filter((v) => {
  //   if (v.voyages && v.voyages?.length > 0 && vesselperiods_data) {
  //     const vesselPeriod = vesselperiods_data.vesselsPeriods.find((vp) => vp.vesselId === v.id && vp.periodId === currentPeriod?.id)
  //     return !vesselPeriod || vesselPeriod.submissionStatus !== 'Approved'
  //   } else {
  //     return false
  //   }
  // })

  const vesselsWithStatus = vessels.map((v: VesselWithEnginesAndStatus) => {
    const vesselperiod =
        vesselsPeriods?.find((vp) => vp.vesselId === v.id && vp.periodId === currentPeriod?.id)
      v.status = vesselperiod ? vesselperiod.submissionStatus : 'not-approved'
      return v
  })
  
  const completedVessels = vesselsWithStatus.filter((v) => {
    return v.status === 'approved'
  })

  const vesselCompletedFraction = Math.round((completedVessels.length / vessels.length) * 100)

  const closeTaxRules = () => {
    setTaxRulesOpen(false)
  }

  const closeReleaseNote = () => {
    setReleaseNoteOpen(false)
  }

  const openTaxRules = () => {
    setTaxRulesOpen(true)
  }

  const openReleaseNote = () => {
    setReleaseNoteId(maxReleaseNoteId)
    setReleaseNoteOpen(true)
  }

  const switchLanguage = () => {
    setLanguageEN(!languageEN)
  }

  const nextReleaseNote = () => {
    if (releaseNoteId < maxReleaseNoteId) {
      setReleaseNoteId(releaseNoteId + 1)
    }
  }

  const prevReleaseNote = () => {
    if (releaseNoteId > 1) {
      setReleaseNoteId(releaseNoteId - 1)
    }
  }

  const registrationEnd = currentPeriod?.registrationEnd ? new Date(currentPeriod?.registrationEnd) : undefined
  const registrationStart = currentPeriod?.registrationStart ? new Date(currentPeriod?.registrationStart) : undefined
  const periodName = currentPeriod?.name

  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!filterDrawerOpen)
  }

  const handleFilterChange = (newFilterSelection: IUiFilterSelections) => {
    setFilterState({ ...(newFilterSelection as any) } as any)
  }

  if (!currentPeriod) {
    return (
        <div className={classes.mainGrid}>
          <Paper>
            <p>Please select a period below.</p>
            <IconButton onClick={toggleFilterDrawer} className={classes.filterBtn} style={{marginLeft:54}} >
              <FilterIcon fontSize="large" className={classes.filterIcon} />
            </IconButton>
          </Paper>
          <UiFilterDrawer
              filterConfig={filterConfig as any}
              filterSelections={{ ...filterState }}
              open={filterDrawerOpen}
              onClose={toggleFilterDrawer}
              onChange={handleFilterChange}
          />
        </div>
    )
  }

  return (
    <div className={classes.mainGrid}>
      <UiFilterDrawer
          filterConfig={filterConfig as any}
          filterSelections={{ ...filterState }}
          open={filterDrawerOpen}
          onClose={toggleFilterDrawer}
          onChange={handleFilterChange}
      />
      <div style={ largerWidth ? { justifyContent: 'space-evenly', display: 'flex'} : { flexDirection: 'column',display: 'flex'}}>
        <DueWidget registrationEnd={registrationEnd} registrationStart={registrationStart} periodName={periodName} toggleDateFilter={toggleFilterDrawer} />
        <IconButton onClick={toggleFilterDrawer} className={classes.filterBtn} >
          <FilterIcon fontSize="large" className={classes.filterIcon} />
        </IconButton>
      </div>
      <ProgressWidget progress={vesselCompletedFraction} vessels={vesselsWithStatus} />
      <Paper
        style={smallerWidth ? { padding: 12, margin: '0 4px' } : { padding: 24, margin: '0 36px' }}
        className={classes.paper}
      >
        <div style={{ display: 'inline-block', marginLeft: 10 }}>
          <InfoIcon fontSize="large" style={{ color: GREEN_HIGHLIGHT, marginRight: 10 }} />
          <span className={classes.textheader}> About NOx Digital</span>
          <Typography style={{ marginTop: 10 }} variant="body2">
            We assist our users in determining the  NOx emissions taxable status and actual tax for all voyages.
          </Typography>
          <Typography style={{ marginBottom: 10 }} variant="body2">
            By combining available data and NOx tax rules, a large portion of voyages can be handled automatically.
          </Typography>
          <Typography variant="body1" className={classes.subheader}>
            Undefined voyages
          </Typography>
          <Typography variant="body2">
            Voyages that span the start and end of a reporting period, call at non-geofenced ports or cross economic boundaries may have insufficient
            data to determine the taxable status. This will also occur if the positioning data is insufficient or lacking.
          </Typography>
          <Typography variant="body2">
            These voyages will be labeled ‘Undefined’, marked in red, and must be manually edited.
          </Typography>
          <Typography className={classes.subheader}>User responsibilities</Typography>
          <Typography variant="body2">
            The complexity of the tax rules and data gathering also leaves a chance of getting the classification wrong.
          </Typography>
          <Typography variant="body2">
            Users are responsible for verifying each voyage and change taxable status, add missing locations and/or edit the fuel consumption as required.
          </Typography>
          <Typography variant="body2">
            Once all voyages are verified, a vessel can be marked as Completed to activate inclusion in the NOx Report and track progress.
          </Typography>
          <Typography variant="body2">
            After submitting the quarterly NOx Report, vessels should be set to Archived, preventing further change to submitted data.
          </Typography>
          <Button style={{marginTop: 16, marginBottom: 16, minWidth: "230px"}} variant="outlined" color="secondary" onClick={openTaxRules}>
            Tax rule interpretations
          </Button>
          <div>
              <Button style={{minWidth: "230px"}} variant="outlined" color="secondary" onClick={openReleaseNote}>
                About current version
              </Button>
          </div>
        </div>
      </Paper>


      <Dialog fullScreen={true} open={taxRulesOpen} onClose={closeTaxRules}>
          <TaxRules
              english={languageEN}
              closeTaxRules={closeTaxRules}
              switchLanguage={switchLanguage}
          />
      </Dialog>
      <Dialog fullScreen={true} open={releaseNoteOpen} onClose={closeReleaseNote}>
          <ReleaseNote
              english={languageEN}
              openTaxRules={openTaxRules}
              closeReleaseNote={closeReleaseNote}
              switchLanguage={switchLanguage}
              nextReleaseNote={nextReleaseNote}
              prevReleaseNote={prevReleaseNote}
              releaseNoteId={releaseNoteId}
              maxReleaseNoteId={maxReleaseNoteId}
          />
      </Dialog>
      {/*      <NoxTimeWidget timeInNox={timeInNox} timeOutOfNox={timeOutOfNox} totalTime={totalTime} />
      <NoxHistoryWidget costData={costData} emissionData={noxData} />
      */}
    </div>
  )
}
export default Dashboard
