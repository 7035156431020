import {Button, Paper, Typography} from "@material-ui/core"
import React from "react"
import ReleaseNote1 from './ReleaseNotes/ReleaseNote1'
import ReleaseNote2 from './ReleaseNotes/ReleaseNote2'
import ReleaseNote3 from './ReleaseNotes/ReleaseNote3'
import ReleaseNote4 from './ReleaseNotes/ReleaseNote4'
import ReleaseNote5 from './ReleaseNotes/ReleaseNote5'
import ReleaseNote6 from './ReleaseNotes/ReleaseNote6'
import ReleaseNote7 from './ReleaseNotes/ReleaseNote7'

export interface ITaxRulePopUp {
    english: boolean
    closeTaxRules: () => void
    switchLanguage: () => void
}

export interface IReleaseNotePopUp {
    english: boolean
    openTaxRules: () => void
    closeReleaseNote: () => void
    switchLanguage: () => void
    nextReleaseNote: () => void
    prevReleaseNote: () => void
    releaseNoteId: number
    maxReleaseNoteId: number
}

export const TaxRules = (props: ITaxRulePopUp) => {
    return (
        <Paper style={{ marginTop: 16, marginLeft: 32, verticalAlign: "center", alignSelf: "center", maxWidth: "1000px"}}>
            <div style={{ padding: 24, marginLeft: 16 }}>
                <Button style={{ position: 'absolute'}} variant="outlined" onClick={props.switchLanguage} color="primary">
                    EN/NO
                </Button>
                {!props.english ? (
                    <>
                        <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
                            Tolkning av regelverket
                        </Typography>
                        <Typography variant="body1">
                            NOxDigital er en software som er tilgjengelig for alle NOx-pliktige aktører på noxdigitalapp.com. Formålet med NOxDigital er å understøtte rapportering av NOx-pliktig fart i henhold til det norske særavgifts-regimet. Ved hjelp av en entydig, felles tolkning av avgiftspliktig fart og korrekt utregning av tilhørende utslipp, søker vi å gjøre rapporteringen så enkel, ensartet og riktig som mulig.
                        </Typography>
                        <Typography variant="body1" style={{marginTop: 8}}>
                            Gjeldende fortolkning av regelverk er utarbeidet i samarbeid med NOxDigital sine brukere og i samråd med NOx-fondet. Konklusjonene er fremlagt Skatteetaten for uttalelse, uten innsigelser.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Direkte fart
                        </Typography>
                        <Typography variant="body1">
                            Direkte fart er i vår modell implementert slik: Fart mellom norske havner og mellom norsk havn og særdestinasjoner/offshore installasjoner er skattepliktig. Fart mellom særdestinasjoner/offshore installasjoner eller der utenlandsk havn er involvert er fritatt.          </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Arbeid på sokkelen
                        </Typography>
                        <Typography variant="body1">
                            Skatteetaten har uttalt at virkeområdet for avgiften ikke er ment å omfatte arbeid på sokkelen. Dette gjelder uavhengig av type operasjon, type fartøy eller antall installasjoner, så lenge det utføres arbeid i tilknytning til virksomhet på sokkelen.
                        </Typography>
                        <Typography variant="body1" style={{marginTop: 8}}>
                            Nye presiseringer for 2024 klargjør at fritaksreglene for arbeid på installasjoner på sokkelen gjelder for alle typer installasjoner, inkludert vind- og oppdretts-anlegg, og altså ikke lenger er begrenset til petroleumsrelatert virksomhet.
                        </Typography>
                        <Typography variant="body1" style={{marginTop: 8}}>
                            NOxDigital vil beregne skatteplikt for transitt fra norsk havn til arbeidets start ved innretning og tilsvarende for transitt tilbake til norsk havn, mens alt arbeid på innretninger og transitt mellom slike er fritatt.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Ankomst- og avreisetidspunkt
                        </Typography>
                        <Typography variant="body1">
                            Ankomst- og avreisetidspunkt defineres med utgangspunkt i detekterte bevegelser inn/ut av sikkerhetssone basert på AIS. I tillegg vil vi benytte informasjon fra registrerte operasjonelle aktiviteter (modes) dersom slike supplerende data ligger i Maress.
                        </Typography>
                        <Typography variant="body1" style={{marginTop: 8}}>
                            Arbeid på sokkelen anses å starte i det skipet går fra Transit til Standby modus forut for DP innenfor sikkerhetssonen ved første innretning. Arbeidet anses avsluttet i det skipet starter Transit tilbake til havn fra siste innretning.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Force Majeure situasjoner
                        </Typography>
                        <Typography variant="body1">
                            Med hensyn til håndtering av et planlagt oppdrag på en offshore installasjon som blir forhindret, f.eks. pga. dårlig vær, henvises det til at fritak kun gjelder for faktisk arbeid på sokkelen. Intensjonen med turen er ikke i seg selv en fritaksgrunn.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Spesielle regler for Spesialfartøy
                        </Typography>
                        <Typography variant="body1">
                            Spesialskip som er i DP modus utenfor Territorialgrensen og innenfor NOx grensen likestilles med skip i DP innenfor sikkerhetssonen ved fast installasjon, og anses å være i arbeid på innretning.
                        </Typography>
                        <Typography variant="body1">
                            Transitt mellom to DP-aktiviteter som er fritatt iht ovennevnte anses dermed som fart mellom innretninger og gis fritak.
                        </Typography>
                        <Typography variant="body1">
                            Seismic og Towing operasjoner er pliktige aktiviteter basert på gjeldende forståelse av regelverket.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Brukers ansvar
                        </Typography>
                        <Typography variant="body1">
                            NOxDigital har ingen myndighet eller autorisasjon til å bestemme skattepliktighet for genererte turer. Vi har implementert tolkningen beskrevet over etter beste evne ut fra regelverket, innspill fra våre brukere og i samråd med NOx-fondet.
                        </Typography>
                        <Typography variant="body1">
                            Brukere av NOxDigital har både frihet til og ansvar for å overprøve vår tolkning og definere turer som fritatte eller pliktige slik de finner det passende, uavhengig av vår tolkning.
                        </Typography>
                    </>
                    ) : (
                    <>
                        <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
                            Tax rule interpretations
                        </Typography>
                        <Typography variant="body1">
                            The NOxDigital software is available for all NOx-compliant entities through noxdigitalapp.com. The purpose of NOxDigital is to support NOx-reporting according to the Norwegian tax-regulations. Using an unambiguous, common interpretation for taxable voyages, and the correct calculation of related emissions, we seek to make reporting as easy, fair and correct as possible.
                        </Typography>
                        <Typography variant="body1" style={{marginTop: 8}}>
                            Our current interpretation is a result of collaboration with our customers and the Norwegian NOx Fund. Our conclusions have been conveyed to the Norwegian Tax Authorities for comments, and have not received dispute.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Direct voyages
                        </Typography>
                        <Typography variant="body1">
                            In our model this is implemented as follows: Voyages between norwegian ports and from norwegian ports to special-destinations and offshore installations are taxable. Voyages between special-destinations/offshore installations or whenever foreign ports are involved are exempt.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Work at installations on the shelf
                        </Typography>
                        <Typography variant="body1">
                            Norwegian tax authorities have clarified that the NOx-tax is not intended to include offshore work at installations on the shelf. This is regardless of the type of operation, type of vessel or number of installations involved, as long as the activities are defined as installation-related work.
                        </Typography>
                        <Typography variant="body1" style={{marginTop: 8}}>
                            Regulatory updates in 2024 determine that tax exemption during work at installations on the shelf applies to any type of installation, including wind- and fish-farms, and is no longer exclusive to petroleum-related installations.
                        </Typography>
                        <Typography variant="body1" style={{marginTop: 8}}>
                            NOxDigital will deem as taxable the transit from norwegian port until work starts at the installation and the corresponding transit back to Norwegian port, while all work at installations, including transit between them, is exempt.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Arrival- and Departure times
                        </Typography>
                        <Typography variant="body1">
                            Arrival- and departure-times are defined on the basis of detected movements in/out of safety-zones based on AIS. In addition, information from operational activities (modes) will be used whenever such data is available in Maress.
                        </Typography>
                        <Typography variant="body1" style={{marginTop: 8}}>
                            Work on the shelf starts when the vessel transitions from Transit- to Standby-mode prior to entering the safety zone of the first installation. Departure will be the staring time of the final Transit to shore after leaving the last installation.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Force Majeure situations
                        </Typography>
                        <Typography variant="body1">
                            Regarding planned offshore missions that are being cancelled or prevented due to weather conditions or other factors, note that tax exemption applies only for actual work on the shelf. The intention of the voyage, on its own, has no merit for exemption.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            Special vessels, special rules
                        </Typography>
                        <Typography variant="body1">
                            Special Vessels in DP-mode on the shelf will be treated equally to vessels inside the safety zone of an installation, and deemed to be at work on the shelf regardless of location.
                            Transit between activities that are exempt by the above rule will be treated as transit between offshore installations and exempted from tax.
                        </Typography>
                        <Typography variant="body1">
                            Seismic and Towing operations are taxable operations, based on the current understanding of the regulations.
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
                            User's responsibility
                        </Typography>
                        <Typography variant="body1">
                            NOxDigital has no authority to decide taxability for any generated voyage. We have implemented the interpretation described above to our best ability based on regulations, input from our users and in consultation with the Norwegian NOx-fund.
                        </Typography>
                        <Typography variant="body1">
                            NOxDigital users have both the ability and the responsibility to review our interpretation and to define events as taxable or exempt contrary to our model as they see fit.
                        </Typography>
                    </>

                    )}
                <div  style={{textAlign: "center"}}>
                    <Button style={{marginTop: 16, marginBottom: 16}} variant="contained" onClick={props.closeTaxRules} color="primary">
                        Got it!
                    </Button>
                </div>
            </div>
        </Paper>
    )
}

export const ReleaseNote = (props: IReleaseNotePopUp) => {
    return (
        <Paper style={{ marginTop: 16, marginLeft: 32, verticalAlign: "center", alignSelf: "center", maxWidth: "1000px"}}>
            <div style={{ padding: 24, marginLeft: 16 }}>
                <Button style={{ position: 'relative'}} variant="outlined" onClick={props.switchLanguage} color="primary">
                    EN/NO
                </Button>
                <Button style={{ position: 'relative', marginLeft: 32}} disabled={props.releaseNoteId<=1} variant="outlined" onClick={props.prevReleaseNote} color="primary">
                    &lt;&lt;
                </Button>
                <Button style={{ position: 'relative', marginLeft: 4}} disabled={props.maxReleaseNoteId === props.releaseNoteId} variant="outlined" onClick={props.nextReleaseNote} color="primary">
                    &gt;&gt;
                </Button>
                {props.releaseNoteId === 7 && <ReleaseNote7 english={props.english} openTaxRules={props.openTaxRules}/>}
                {props.releaseNoteId === 6 && <ReleaseNote6 english={props.english}/>}
                {props.releaseNoteId === 5 && <ReleaseNote5 english={props.english}/>}
                {props.releaseNoteId === 4 && <ReleaseNote4 english={props.english}/>}
                {props.releaseNoteId === 3 && <ReleaseNote3 english={props.english} openTaxRules={props.openTaxRules}/>}
                {props.releaseNoteId === 2 && <ReleaseNote2 english={props.english} openTaxRules={props.openTaxRules}/>}
                {props.releaseNoteId === 1 && <ReleaseNote1 english={props.english}/>}
                <div  style={{textAlign: "center"}}>
                    <Button style={{marginTop: 16, marginBottom: 16}} variant="contained" onClick={props.closeReleaseNote} color="primary">
                        Got it!
                    </Button>
                </div>
            </div>
        </Paper>
    )
}
