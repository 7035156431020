import { GriegAuthProvider, RedirectLoginResult } from '@griegconnect/krakentools-react-auth'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useConfig } from '../config/grieg-config-provider'

export type AuthWrapperProps = {
  children: React.ReactElement;
};

export const AuthWrapper = (props: AuthWrapperProps) => {
  const { children } = props
  const history = useHistory()
  const { config } = useConfig()

  const redirectCallback = (appState: RedirectLoginResult['appState']) => {
    if (appState?.targetUrl) {
      history.push(appState.targetUrl ?? '/')
    }
  }

  return (
    <GriegAuthProvider
      domain={config!.auth0.domain}
      scope={config!.auth0.scope}
      client_id={config!.auth0.clientId}
      redirect_uri={config!.auth0.redirectUri}
      audience={config!.auth0.audience}
      onRedirectCallback={redirectCallback}
      useRefreshTokens={config!.auth0.rotatingRefreshTokens}
      cacheLocation={config!.auth0.cacheLocation}
    >
      {children}
    </GriegAuthProvider>
  )
}

export default AuthWrapper
