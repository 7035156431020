import { makeStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportSheet: {
      padding: 16,
    },
    reportTitle: {
      paddingBottom: 36,
      textAlign: 'center',
    },
    headerRow: {
      width: '100%',
      paddingTop: 8,
      paddingBottom: 8,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerIcon: {
      marginRight: 6,
    },
    headerRight: {
      marginLeft: 'auto',
    },
  })
)

export default useStyles
