import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'


const useStyles = makeStyles((theme: Theme) => ({
	progressWrapper: {
		position: 'relative'
	},
	backgroundCircle: {
		position: 'absolute',
		top: 0,
		left: 0
	},
	vesselIcon: {
		position: 'absolute',
	},
}))

interface INoxProgressCircle {
	progress: number;
	Icon: any;
	thickness?: number;
	size?: number;
	iconSize?: number;
	color?: string;
}

const NoxProgressCircle: React.FunctionComponent<INoxProgressCircle> = (props) => {
	const classes = useStyles()
	const { progress, Icon } = props
	const circleWidth = props.size || 58
	const thickness = (props.thickness && (props.thickness * 2) < circleWidth) ? props.thickness : 2
	const iconSize = props.iconSize && (props.iconSize < circleWidth) ? props.iconSize : 24
	const color = props.color || 'default'
	return (
		<div className={classes.progressWrapper}>
			<CircularProgress variant="determinate" thickness={thickness} value={progress} size={circleWidth} style={{ color }} />
			<svg height={circleWidth} width={circleWidth} className={classes.backgroundCircle}>
				<circle cx={circleWidth / 2} cy={circleWidth / 2} r={circleWidth / 2 - (thickness * 0.75)} stroke="rgba(249, 219, 123, 0.3)" style={{ strokeWidth: thickness - 1 }} fill="none" />
			</svg>
			<Icon className={classes.vesselIcon} style={{ top: (circleWidth / 2) - iconSize / 2, left: circleWidth / 2 - (iconSize / 2), width: iconSize, height: iconSize }} />
		</div>
	)
}

export default NoxProgressCircle
