import { Typography } from '@material-ui/core'
import React from 'react'
import { IReleaseNoteProps } from './ReleaseNote1'

const ReleaseNote6 = (props: IReleaseNoteProps) => {
  return (!props.english) ? (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q4-2023
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Utenlandske havner
      </Typography>
      <Typography variant="body1">
        I denne versjonen er det 34 utenlandske havner:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Lerwick, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Gi oss beskjed om hvilke andre utenlandshavner dere trenger, så vil vi oppdatere med de viktigste fortløpende.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        Man kan nå inkludere NOx utslipp for skattefrie turer for å vise totale utslipp i den aktuelle perioden.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        NOx Voyages
      </Typography>
      <Typography variant="body1">
        For turer som inkluderer period-start og/eller period-end er det nå åpnet for å legge til lokasjoner en-route.<br/>
        "Add a missing location" er fortsatt kun tillatt for turer definert som Transit.
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="h6" style={{marginBottom: 16, textAlign: "center"}}>
        NOxDigital Q4-2023
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Foreign ports
      </Typography>
      <Typography variant="body1">
        This version has 34 foreign ports:
      </Typography>
      <Typography variant="body1" style={{marginLeft: 16}}>
        Aberdeen, Antwerpen, Ängeltofta, Bremerhaven, Cuxhaven, Den Helder, Dunkerque, Eemshaven, Edinburgh, Esbjerg, Fredericia, Frederikshavn, Gøteborg, Hirtshals, Invergordon, Inverness, Kiel, København, Las Palmas, Lerwick, Middlesborough, Murmansk, Montrose, Muuga, Newcastle, Peterhead, Portsmouth, Porvoon, Rotterdam, Southhampton, Stenungsund, Strømstad, Tallinn, Wilhelmshaven.
      </Typography>
      <Typography variant="body1" style={{marginTop: 6}}>
        Let us know which other ports are needed, and we will add the most frequently requested.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        Charter Report
      </Typography>
      <Typography variant="body1">
        It is now an option to include NOx emissions from non-taxable voyages, enabling reporting of total emissions for any period.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 4, textAlign: "left", fontWeight: "bold"}}>
        NOx Voyages
      </Typography>
      <Typography variant="body1">
        Voyages including period-start and/or period-end can now be modified with additional location.<br/>
        "Add a missing location" is still limited to Transit voyages.
      </Typography>
    </>
  )}

export default ReleaseNote6