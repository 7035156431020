import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, Container, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 36,
      maxWidth: 900,
      margin: '0 auto',
      minHeight: '40vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      padding: '36px 24px',
      margin: 16,
      backgroundColor: theme.palette.action.focus,
    },
  })
)

const NoxReportMessage: React.FC = () => {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="body1">
          {' '}
          No vessels have a Completed status in NOx Voyages for the selected Period.{' '}
        </Typography>
      </Paper>
    </Container>
  )
}

export default NoxReportMessage
