import { useAuth } from '@griegconnect/krakentools-react-auth'
import { useHttp } from '@griegconnect/krakentools-react-http'
import { GriegOnboardingProvider, signUpFieldNames, SignUpFormState } from '@griegconnect/krakentools-react-onboarding'

import React, { useMemo } from 'react'
import { TenantsApi } from 'src/common/@griegconnect/react-tenants/src'

import { useConfig } from '../config/grieg-config-provider'
import { ReactComponent as Slide } from './slides/slide-3.svg'

export type OnboardingWrapperProps = {
  children: React.ReactNode;
};

export const OnboardingWrapper = (props: OnboardingWrapperProps) => {
  const { children } = props
  const { loginWithRedirect } = useAuth()
  const { config } = useConfig()
  const { httpClient } = useHttp()
  const getHttpClient = useMemo(() => httpClient, [httpClient])
  const tenantsApi = useMemo(() => new TenantsApi(getHttpClient, config!.api.tenantApiUrl, null, false), [
    getHttpClient,
    config,
  ])

  const onLogin = () => {
    return new Promise<void>((resolve) => {
      const redirectOptions = {
        appState: {
          targetUrl: window.location.pathname + window.location.search + window.location.hash,
        },
      }
      loginWithRedirect(redirectOptions)
      resolve()
    })
  }

  const onSignUp = (formState: SignUpFormState) => {
    return new Promise<boolean>((resolve, reject) => {
      tenantsApi
        .signup(
          formState.email.value,
          `${formState.first_name.value} ${formState.last_name.value}`,
          formState.password.value,
          formState.phone_number.value
        )
        .then((res) => {
          resolve(res)
        })
        .catch(() => {
          reject(false)
        })
    })
  }

  return (
    <GriegOnboardingProvider
      carouselElements={[
        {
          component: <Slide />,
          title: 'NOx Digital',
          description: 'Easily report NOx emissions',
        },
      ]}
      termsOfServiceUrl="#"
      privacyPolicyUrl="#"
      signUpFields={[
        signUpFieldNames.EMAIL,
        signUpFieldNames.FIRST_NAME,
        signUpFieldNames.LAST_NAME,
        signUpFieldNames.PASSWORD,
        signUpFieldNames.PASSWORD_REPEAT,
        signUpFieldNames.PHONE_NUMBER,
      ]}
      onLogin={onLogin}
      onSignUp={onSignUp}
    >
      {children}
    </GriegOnboardingProvider>
  )
}

export default OnboardingWrapper
