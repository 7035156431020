// export type AisCountry = {
//     number: number,
//     shortName: string,
//     fullName: string
// }
export const aisCountryMap = new Map<number, string>()

aisCountryMap.set(201, 'AL')
aisCountryMap.set(202, 'AD')
aisCountryMap.set(203, 'AT')
aisCountryMap.set(204, 'PT')
aisCountryMap.set(205, 'BE')
aisCountryMap.set(206, 'BY')
aisCountryMap.set(207, 'BG')
aisCountryMap.set(208, 'VA')
aisCountryMap.set(209, 'CY')
aisCountryMap.set(210, 'CY')
aisCountryMap.set(211, 'DE')
aisCountryMap.set(212, 'CY')
aisCountryMap.set(213, 'GE')
aisCountryMap.set(214, 'MD')
aisCountryMap.set(215, 'MT')
aisCountryMap.set(216, 'ZZ')
aisCountryMap.set(218, 'DE')
aisCountryMap.set(219, 'DK')
aisCountryMap.set(220, 'DK')
aisCountryMap.set(224, 'ES')
aisCountryMap.set(225, 'ES')
aisCountryMap.set(226, 'FR')
aisCountryMap.set(227, 'FR')
aisCountryMap.set(228, 'FR')
aisCountryMap.set(229, 'MT')
aisCountryMap.set(230, 'FI')
aisCountryMap.set(231, 'FO')
aisCountryMap.set(232, 'GB')
aisCountryMap.set(233, 'GB')
aisCountryMap.set(234, 'GB')
aisCountryMap.set(235, 'GB')
aisCountryMap.set(236, 'GI')
aisCountryMap.set(237, 'GR')
aisCountryMap.set(238, 'HR')
aisCountryMap.set(239, 'GR')
aisCountryMap.set(240, 'GR')
aisCountryMap.set(241, 'GR')
aisCountryMap.set(242, 'MA')
aisCountryMap.set(243, 'HU')
aisCountryMap.set(244, 'NL')
aisCountryMap.set(245, 'NL')
aisCountryMap.set(246, 'NL')
aisCountryMap.set(247, 'IT')
aisCountryMap.set(248, 'MT')
aisCountryMap.set(249, 'MT')
aisCountryMap.set(250, 'IE')
aisCountryMap.set(251, 'IS')
aisCountryMap.set(252, 'LI')
aisCountryMap.set(253, 'LU')
aisCountryMap.set(254, 'MC')
aisCountryMap.set(255, 'PT')
aisCountryMap.set(256, 'MT')
aisCountryMap.set(257, 'NO')
aisCountryMap.set(258, 'NO')
aisCountryMap.set(259, 'NO')
aisCountryMap.set(261, 'PL')
aisCountryMap.set(262, 'ME')
aisCountryMap.set(263, 'PT')
aisCountryMap.set(264, 'RO')
aisCountryMap.set(265, 'SE')
aisCountryMap.set(266, 'SE')
aisCountryMap.set(267, 'SK')
aisCountryMap.set(268, 'SM')
aisCountryMap.set(269, 'CH')
aisCountryMap.set(270, 'CZ')
aisCountryMap.set(271, 'TR')
aisCountryMap.set(272, 'UA')
aisCountryMap.set(273, 'RU')
aisCountryMap.set(274, 'MK')
aisCountryMap.set(275, 'LV')
aisCountryMap.set(276, 'EE')
aisCountryMap.set(277, 'LT')
aisCountryMap.set(278, 'SI')
aisCountryMap.set(279, 'RS')
aisCountryMap.set(301, 'AI')
aisCountryMap.set(303, 'US')
aisCountryMap.set(304, 'AG')
aisCountryMap.set(305, 'AG')
aisCountryMap.set(306, 'CW')
aisCountryMap.set(307, 'AW')
aisCountryMap.set(308, 'BS')
aisCountryMap.set(309, 'BS')
aisCountryMap.set(310, 'BM')
aisCountryMap.set(311, 'BS')
aisCountryMap.set(312, 'BZ')
aisCountryMap.set(314, 'BB')
aisCountryMap.set(316, 'CA')
aisCountryMap.set(319, 'KY')
aisCountryMap.set(321, 'CR')
aisCountryMap.set(323, 'CU')
aisCountryMap.set(325, 'DM')
aisCountryMap.set(327, 'DO')
aisCountryMap.set(329, 'GP')
aisCountryMap.set(330, 'GD')
aisCountryMap.set(331, 'GL')
aisCountryMap.set(332, 'GT')
aisCountryMap.set(334, 'HN')
aisCountryMap.set(336, 'HT')
aisCountryMap.set(338, 'US')
aisCountryMap.set(339, 'JM')
aisCountryMap.set(341, 'KN')
aisCountryMap.set(343, 'LC')
aisCountryMap.set(345, 'MX')
aisCountryMap.set(347, 'MQ')
aisCountryMap.set(348, 'MS')
aisCountryMap.set(350, 'NI')
aisCountryMap.set(351, 'PA')
aisCountryMap.set(352, 'PA')
aisCountryMap.set(353, 'PA')
aisCountryMap.set(354, 'PA')
aisCountryMap.set(355, 'PA')
aisCountryMap.set(356, 'PA')
aisCountryMap.set(357, 'PA')
aisCountryMap.set(358, 'PR')
aisCountryMap.set(359, 'SV')
aisCountryMap.set(361, 'PM')
aisCountryMap.set(362, 'TT')
aisCountryMap.set(364, 'TC')
aisCountryMap.set(366, 'US')
aisCountryMap.set(367, 'US')
aisCountryMap.set(368, 'US')
aisCountryMap.set(369, 'US')
aisCountryMap.set(370, 'PA')
aisCountryMap.set(371, 'PA')
aisCountryMap.set(372, 'PA')
aisCountryMap.set(373, 'PA')
aisCountryMap.set(374, 'PA')
aisCountryMap.set(375, 'VC')
aisCountryMap.set(376, 'VC')
aisCountryMap.set(377, 'VC')
aisCountryMap.set(378, 'VG')
aisCountryMap.set(379, 'VI')
aisCountryMap.set(401, 'AF')
aisCountryMap.set(403, 'SA')
aisCountryMap.set(405, 'BD')
aisCountryMap.set(408, 'BH')
aisCountryMap.set(410, 'BT')
aisCountryMap.set(412, 'CN')
aisCountryMap.set(413, 'CN')
aisCountryMap.set(414, 'CN')
aisCountryMap.set(416, 'TW')
aisCountryMap.set(417, 'LK')
aisCountryMap.set(419, 'IN')
aisCountryMap.set(422, 'IR')
aisCountryMap.set(423, 'AZ')
aisCountryMap.set(425, 'IQ')
aisCountryMap.set(428, 'IL')
aisCountryMap.set(431, 'JP')
aisCountryMap.set(432, 'JP')
aisCountryMap.set(434, 'TM')
aisCountryMap.set(436, 'KZ')
aisCountryMap.set(437, 'UZ')
aisCountryMap.set(438, 'JO')
aisCountryMap.set(440, 'KR')
aisCountryMap.set(441, 'KR')
aisCountryMap.set(443, 'PS')
aisCountryMap.set(445, 'KP')
aisCountryMap.set(447, 'KW')
aisCountryMap.set(450, 'LB')
aisCountryMap.set(451, 'ZZ')
aisCountryMap.set(453, 'ZZ')
aisCountryMap.set(455, 'MV')
aisCountryMap.set(457, 'MN')
aisCountryMap.set(459, 'NP')
aisCountryMap.set(461, 'OM')
aisCountryMap.set(463, 'PK')
aisCountryMap.set(466, 'QA')
aisCountryMap.set(468, 'SY')
aisCountryMap.set(470, 'AE')
aisCountryMap.set(472, 'TJ')
aisCountryMap.set(473, 'YE')
aisCountryMap.set(475, 'YE')
aisCountryMap.set(477, 'HK')
aisCountryMap.set(478, 'BA')
aisCountryMap.set(501, 'AQ')
aisCountryMap.set(503, 'AU')
aisCountryMap.set(506, 'MM')
aisCountryMap.set(508, 'BN')
aisCountryMap.set(510, 'FM')
aisCountryMap.set(511, 'PW')
aisCountryMap.set(512, 'NZ')
aisCountryMap.set(514, 'KH')
aisCountryMap.set(515, 'KH')
aisCountryMap.set(516, 'CX')
aisCountryMap.set(518, 'CK')
aisCountryMap.set(520, 'FJ')
aisCountryMap.set(523, 'CC')
aisCountryMap.set(525, 'ID')
aisCountryMap.set(529, 'KI')
aisCountryMap.set(531, 'LA')
aisCountryMap.set(533, 'MY')
aisCountryMap.set(536, 'MP')
aisCountryMap.set(538, 'MH')
aisCountryMap.set(540, 'NC')
aisCountryMap.set(542, 'NU')
aisCountryMap.set(544, 'NR')
aisCountryMap.set(546, 'TF')
aisCountryMap.set(548, 'PH')
aisCountryMap.set(553, 'PG')
aisCountryMap.set(555, 'PN')
aisCountryMap.set(557, 'SB')
aisCountryMap.set(559, 'AS')
aisCountryMap.set(561, 'WS')
aisCountryMap.set(563, 'SG')
aisCountryMap.set(564, 'SG')
aisCountryMap.set(565, 'SG')
aisCountryMap.set(566, 'SG')
aisCountryMap.set(567, 'TH')
aisCountryMap.set(570, 'TO')
aisCountryMap.set(572, 'TV')
aisCountryMap.set(574, 'VN')
aisCountryMap.set(576, 'VU')
aisCountryMap.set(577, 'VU')
aisCountryMap.set(578, 'WF')
aisCountryMap.set(601, 'ZA')
aisCountryMap.set(603, 'AO')
aisCountryMap.set(605, 'DZ')
aisCountryMap.set(607, 'XX')
aisCountryMap.set(608, 'IO')
aisCountryMap.set(609, 'BI')
aisCountryMap.set(610, 'BJ')
aisCountryMap.set(611, 'BW')
aisCountryMap.set(612, 'CF')
aisCountryMap.set(613, 'CM')
aisCountryMap.set(615, 'CG')
aisCountryMap.set(616, 'KM')
aisCountryMap.set(617, 'CV')
aisCountryMap.set(618, 'AQ')
aisCountryMap.set(619, 'CI')
aisCountryMap.set(620, 'KM')
aisCountryMap.set(621, 'DJ')
aisCountryMap.set(622, 'EG')
aisCountryMap.set(624, 'ET')
aisCountryMap.set(625, 'ER')
aisCountryMap.set(626, 'GA')
aisCountryMap.set(627, 'GH')
aisCountryMap.set(629, 'GM')
aisCountryMap.set(630, 'GW')
aisCountryMap.set(631, 'GQ')
aisCountryMap.set(632, 'GN')
aisCountryMap.set(633, 'BF')
aisCountryMap.set(634, 'KE')
aisCountryMap.set(635, 'AQ')
aisCountryMap.set(636, 'LR')
aisCountryMap.set(637, 'LR')
aisCountryMap.set(642, 'LY')
aisCountryMap.set(644, 'LS')
aisCountryMap.set(645, 'MU')
aisCountryMap.set(647, 'MG')
aisCountryMap.set(649, 'ML')
aisCountryMap.set(650, 'MZ')
aisCountryMap.set(654, 'MR')
aisCountryMap.set(655, 'MW')
aisCountryMap.set(656, 'NE')
aisCountryMap.set(657, 'NG')
aisCountryMap.set(659, 'NA')
aisCountryMap.set(660, 'RE')
aisCountryMap.set(661, 'RW')
aisCountryMap.set(662, 'SD')
aisCountryMap.set(663, 'SN')
aisCountryMap.set(664, 'SC')
aisCountryMap.set(665, 'SH')
aisCountryMap.set(666, 'SO')
aisCountryMap.set(667, 'SL')
aisCountryMap.set(668, 'ST')
aisCountryMap.set(669, 'SZ')
aisCountryMap.set(670, 'TD')
aisCountryMap.set(671, 'TG')
aisCountryMap.set(672, 'TN')
aisCountryMap.set(674, 'TZ')
aisCountryMap.set(675, 'UG')
aisCountryMap.set(676, 'CD')
aisCountryMap.set(677, 'TZ')
aisCountryMap.set(678, 'ZM')
aisCountryMap.set(679, 'ZW')
aisCountryMap.set(701, 'AR')
aisCountryMap.set(710, 'BR')
aisCountryMap.set(720, 'BO')
aisCountryMap.set(725, 'CL')
aisCountryMap.set(730, 'CO')
aisCountryMap.set(735, 'EC')
aisCountryMap.set(740, 'UK')
aisCountryMap.set(745, 'GF')
aisCountryMap.set(750, 'GY')
aisCountryMap.set(755, 'PY')
aisCountryMap.set(760, 'PE')
aisCountryMap.set(765, 'SR')
aisCountryMap.set(770, 'UY')
aisCountryMap.set(775, 'VE')
