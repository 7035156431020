import { Paper } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

export interface ILoadingSpinnerProps {
  label?: string
}

const LoadingSpinner = (props) => {
  const label = props.label ?? 'Loading...'
  return (
    <Paper style={{ textAlign: 'center', padding: 36 }}>
      <CircularProgress style={{ margin: '0 auto' }} />
      <div>{label}</div>
    </Paper>
  )
}

export default LoadingSpinner
