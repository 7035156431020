import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useLayoutEffect } from 'react'

import { useAppContext } from '../../GriegProvider/wrappers/AppContextWrapper'
import { Tenant, useTenants } from '../../GriegTenants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {},
    gridItem: {
      width: '100%',
    },
    paper: {
      maxHeight: 'calc(100vh - 80px)',
      marginTop: 64,
      width: '100%',
      padding: theme.spacing(2),
    },
    refreshButtonLoading: {
      marginTop: theme.spacing(1.5),
    },
    refreshButton: {
      marginTop: theme.spacing(2),
    },
    list: {
      maxHeight: 'calc(100vh - 256px)',
      overflow: 'auto',
    },
  })
)

export const NoActiveTenant = (props: GriegRouteProps) => {
  const { route } = props
  const { tenants, tenantsLoading, fetchTenants, changeTenant } = useTenants()
  const classes = useStyles()
  const { setRouteOptionsCallback } = useAppContext()

  const updateOptions = () => setRouteOptionsCallback({ ...mergeOptions(route), showAppDrawer: false })

  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTenantChange = (code: string) => {
    // history.push(`/company/${code}/`);
    changeTenant(code)
  }

  const createTenantList = tenants ? (
    tenants.map((tenant: Tenant, index: number) => {
      return (
        <ListItem
          key={`${index}-${tenant.code}`}
          button={true}
          onClick={() => handleTenantChange(tenant.code)}
          divider={true}
        >
          <ListItemText>{tenant.name}</ListItemText>
        </ListItem>
      )
    })
  ) : (
    <></>
  )

  return (
    <Backdrop open={true}>
      <Grid className={classes.grid} container={true} direction="column" justify="center" alignItems="center">
        <Grid className={classes.gridItem} item={true} xs={11} sm={7} md={5} lg={4}>
          {tenants ? (
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6" gutterBottom={false}>
                Select tenant
              </Typography>
              <Typography color="textSecondary" variant="subtitle1" gutterBottom={true}>
                Please select the tenant you want to work on.
              </Typography>
              <List className={classes.list}>{createTenantList}</List>
              {tenantsLoading && <LinearProgress />}
              <Button
                className={tenantsLoading ? classes.refreshButtonLoading : classes.refreshButton}
                color="primary"
                variant="contained"
                onClick={fetchTenants}
              >
                Refresh
              </Button>
            </Paper>
          ) : (
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6" gutterBottom={false}>
                No tenants found
              </Typography>
              <Typography color="textSecondary" variant="subtitle1" gutterBottom={true}>
                No tenants found for this user.
              </Typography>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Backdrop>
  )
}

export default NoActiveTenant
