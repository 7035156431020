import Chip from '@material-ui/core/Chip/Chip'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography/Typography'
import React from 'react'

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      userSelect: 'none',
    },
    chip: {
      marginLeft: theme.spacing(),
    },
  })

interface IUiShowActiveFilterProps extends WithStyles<typeof styles> {
  filterConfig: any
  filterSelections: any
  onChange: (filterSelection: any) => void
}

const UiShowActiveFilter: React.FunctionComponent<IUiShowActiveFilterProps> = ({
  classes,
  filterConfig,
  filterSelections,
  onChange,
}) => {
  // const onChipClickedHandler = (key: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
  // 	const newFilterSelections = { ...filterSelections };
  // 	delete newFilterSelections[key];
  // 	onChange(newFilterSelections);
  // };

  const getLabelForValue = (key: any, id: any): string => {
    const filterSection = filterConfig[key]
    let label = ''
    if (filterSection.type !== 'date') {
      const matches = filterSection.values.filter((valueItem) => valueItem.value === id)
      if (matches.length > 0) {
        label = matches[0].label
      }
    } else if (filterSection.type === 'date' && filterSelections[key][0].length > 0) {
      const date = new Date(filterSelections[key][0])
      label = date.toLocaleDateString('nb-NO', { year: 'numeric', month: '2-digit', day: '2-digit' })
    }
    return label
  }

  const filterSectionKeys = Object.keys(filterConfig)
  const filterSelectionKeys = Object.keys(filterSelections)

  const renderActiveFilterChip = (key: string) => {
    if (filterSelections[key] !== undefined) {
      return (
        // <Chip size="small" className={classes.chip} key={key} label={`${filterConfig[key].label}: ${getLabelForValue(key, filterSelections[key][0])}`} onDelete={onChipClickedHandler(key)} deleteIcon={<CloseIcon />} />
        <Chip
          size="medium"
          className={classes.chip}
          key={key}
          label={`${filterConfig[key].label}: ${getLabelForValue(key, filterSelections[key][0])}`}
        />
      )
    } else {
      return null
    }
  }

  return (
    <>
      {filterSelectionKeys.length > 0 && (
        <div className={classes.wrapper}>
          <Typography variant="body2" component="span">
            Active filter:
          </Typography>
          {filterSectionKeys.map((key) => renderActiveFilterChip(key))}
        </div>
      )}
    </>
  )
}

export default withStyles(styles, { withTheme: true })(UiShowActiveFilter)
