import { makeStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { Tooltip, withStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateInputWrapper: {
      display: 'flex',
    },
    dateInput: {
      borderRadius: 3,
      '& .MuiInputLabel-root': {
        color: '#94979a',
      },
      '& .Mui-focused': {
        color: '#ffffff !important',
      },
      '& .MuiSvgIcon-root': {
        color: '#94979a',
      },
      '& .MuiFilledInput-underline': {
        color: '#fff',
        borderBottom: '1px solid #94979a',
      },
      '& .MuiFilledInput-root::after': {
        borderBottom: '1.8px solid #ffffff',
      },
    },
    periodsList: {
      '&:hover': {
        background: theme.palette.action.hover,
      },
    },
    reportWrapperPrint: {
      minHeight: '100vh',
      margin: '4em 0em',
      padding: '0 2em 2em 2em',
    },
    bottomTextPrint: {
      position: 'fixed',
      width: '100%',
      textAlign: 'center',
      fontSize: '0.5rem',
      color: '#3e3f3d',
      bottom: 16,
    },
    getReportBtnWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      margin: '16px 0',
    },
    printBtnWrapper: {
      display: 'flex',
      justifyContent: 'center',
      margin: '5em 0 3em 0',
    },
  })
)

export const ReportTooltip = withStyles({
  tooltip: {
    fontWeight: 400,
    fontSize: '0.875rem',
  },
})(Tooltip)

export const printStyleOverrides = `
  @page {
    size: auto;
    margin: 10mm 0;
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }

    .bottom-text:after {
      content: " Powered by NOx Digital | noxdigital.no | yxney.com | noxdigitalapp.com ";
      width: 100%;
      color: #555 !important;
      font-size: 0.7em;
      text-align: center;
      position: fixed;
      bottom: 0;
    }
  }  
`
