import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Button, Container } from '@material-ui/core'

import NoxReportHeader from './NoxReportHeader'
import NoxReportTable from './NoxReportTable'

import { useStyles, printStyleOverrides } from './NoxReportPrintView.styles'

interface Props {
  activeTenant: any
  currentPeriod: number | any
  completedVessels: any
}

const NoxReportPrintView = ({ activeTenant, currentPeriod, completedVessels }: Props) => {
  const componentRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: printStyleOverrides,
  })
  const classes = useStyles()

  return (
    <>
      <Container className={classes.container}>
        <div className={classes.reportSheet}>
          <NoxReportHeader printable={false} activeTenant={activeTenant} currentPeriod={currentPeriod} />

          <NoxReportTable printable={false} completedVessels={completedVessels} currentPeriod={currentPeriod} />
        </div>
      </Container>

      {/* Printable version for PDF-print view container */}
      <div style={{ display: 'none' }}>
        <Container ref={componentRef} className={classes.container}>
          <div className={classes.reportSheet}>
            <NoxReportHeader printable={true} activeTenant={activeTenant} currentPeriod={currentPeriod} />

            <NoxReportTable printable={true} completedVessels={completedVessels} currentPeriod={currentPeriod} />
          </div>
          <div className="bottom-text"></div>
        </Container>
      </div>

      <div className={classes.printBtnWrapper}>
        <Button variant="contained" color="primary" onClick={handlePrint}>
          Print report
        </Button>
      </div>
    </>
  )
}

export default NoxReportPrintView
