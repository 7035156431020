import InformationIcon from '@griegconnect/krakentools-react-icons/Information'
import { Paper } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

const useStyles = makeStyles((theme: Theme) => ({
  titleRow: {
    margin: '16px 16px 16px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  infoInActive: {
    opacity: 0.6,
    '&:hover': {
      cursor: 'pointer',
      opacity: 1,
    },
    marginLeft: 16,
  },
  infoActive: {
    opacity: 1,
    '&:hover': {
      cursor: 'pointer',
    },
    marginLeft: 16,
  },
  explanationContainer: {
    width: '100%',
    height: 'auto',
    // marginLeft: '-8px',
    padding: '32px',
    paddingLeft: '32px',
    paddingRight: '32px',
    '& p': {
      display: 'block',
    },
    marginBottom: 1,
  },
  explanationContainerHidden: {
    height: '0px',
    paddingLeft: '32px',
    paddingRight: '32px',
    opacity: 0,
    '& p': {
      display: 'none',
    },
  },
}))

interface IExpandingInfoBoxProps {
  title: string;
  titleVariant?: Variant;
  text: string;
  text2?: string;
}

const ExpandingInfoBox: React.FunctionComponent<IExpandingInfoBoxProps> = (props) => {
  const [active, setActive] = useState<boolean>(false)
  const { text, title, text2 } = props
  const titleVariant = props.titleVariant || 'body1'

  const classes = useStyles()
  return (
    <>
      <div className={classes.titleRow}>
        <Typography variant={titleVariant}>{title}</Typography>
        <InformationIcon
          className={active ? classes.infoActive : classes.infoInActive}
          fontSize="default"
          onClick={() => setActive(!active)}
        />
      </div>

      <Paper
        square={true}
        elevation={10}
        className={active ? classes.explanationContainer : classes.explanationContainerHidden}
        style={{
          transition: 'padding 0.2s ease-in',
        }}
      >
        <Typography variant="body1" style={{ opacity: '0.6' }}>
          {text}
        </Typography>
        {text2 && (
            <Typography variant="body1" style={{ opacity: '0.6' }}>
              {text2}
            </Typography>
        )}
      </Paper>
    </>
  )
}

export default ExpandingInfoBox
