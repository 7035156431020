import { atom } from 'recoil'
import {mapZones} from "./components/zones"

export const periodFilterState = atom({
  key: 'periodFilterState',
  default: {} as any,
})

export const charterReportTotal = atom({
  key: 'charterReportTotal',
  default: {
    totalNox: 0,
    totalFuel: 0,
    totalLng: 0,
    totalUrea: 0,
  } as {
    totalNox: number | string
    totalFuel: number | string
    totalLng: number | string
    totalUrea: number | string
  },
})

export const voyageListItemkey = atom({
  key: 'voyagesListItemKey',
  default: {
    vesselIdentifier: null,
    listItemId: null,
  } as {
    vesselIdentifier: null | string
    listItemId: null | number
  },
})

export const ureaPriceState = atom({
  key: 'ureaKeyState',
  default: 0 as number,
})

export const fuelPriceState = atom({
  key: 'fuelKeyState',
  default: 0 as number,
})

export const lngPriceState = atom({
  key: 'lngKeyState',
  default: 0 as number,
})

export interface VesselNoxRateStateType {
  name: undefined | string
  imo: undefined | number
  noxRate: undefined | string
}

export const vesselNoxRateState = atom({
  key: 'vesselNoxRateState',
  default: {
    name: undefined,
    imo: undefined,
    noxRate: undefined,
  } as VesselNoxRateStateType,
})

export const offshoreZonesState = atom( {
  key: 'offshoreZonesState',
  default: {
    id: undefined,
    name: undefined,
    coords: undefined
  } as mapZones | any,
})