import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import { Link } from 'react-router-dom'

export const UiListItemLink = (props) => {
  const { to } = props

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to]
  )

  return (
    <li>
      <ListItem button component={CustomLink}>
        {props.children}
      </ListItem>
    </li>
  )
}
