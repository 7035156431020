import { AxiosInstance, AxiosResponse } from "axios"
import { Area, AreaType } from "./types/area"

export default class AreasAPI {
  httpClient: AxiosInstance
  baseUrl: string

  constructor(httpClient: AxiosInstance, baseUrl: string) {
    this.httpClient = httpClient
    this.baseUrl = baseUrl
  }
  
  async getAreas(types: AreaType[]): Promise<Area[]> {
    const typeString = types.map(t => `area_types=${t}`).join('&')
    const response: AxiosResponse = await this.httpClient.get(`${this.baseUrl}/areas?${typeString}`)
    return response.data
  }
}