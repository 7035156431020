import { NoxPeriod } from "src/lib/apis/types/noxPeriod"

export const initialFilterConfig = () => ({
  quarter: {
    label: 'Period',
    multiple: false,
    type: 'select',
    values: [] as any,
  },
})

export const selectedFilterConfig = (periods?: NoxPeriod[]) =>
  ({
    quarter: {
      label: 'Period',
      multiple: false,
      type: 'select',
      values: periods?.map((period) => ({
        label: period.name,
        value: period.id,
      })),
    },
  } as any)
