import mapboxgl from 'mapbox-gl'

// TODO: fetch token from config-endpoint
// 'http://maps.shiplog.com:443/MapService/wmts.axd/Night/tile/default/CMap/Default.256.3857/{TileMatrix}/{TileRow}/{TileCol}.png'
mapboxgl.accessToken =
  'pk.eyJ1IjoiZ3JpZWdjb25uZWN0IiwiYSI6ImNrODdlM2xxNDAzMG4zZW02bWszaXlxOG8ifQ.HLuYGpum8T5N9iWvP6oJqA'
const NoxMap = (lat: number, lng: number, zoom: number, container: HTMLElement): mapboxgl.Map => {
  const map = new mapboxgl.Map({
    container,
    style: 'mapbox://styles/griegconnect/ck87ex4co03821irnetzqs2fz',
    // style: {
    // 	'version': 8,
    // 	'sources': {
    // 		'raster-tiles': {
    // 			'type': 'raster',
    // 			'tiles': [
    // 				'https://maps.shiplog.com/MapService/wms.axd/Night/Permissive?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256'
    // 			],
    // 			'tileSize': 256,
    // 			'attribution':
    // 				'Map tiles by Erling'
    // 		}
    // 	},
    // 	"glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
    // 	'layers': [
    // 		{
    // 			'id': 'simple-tiles',
    // 			'type': 'raster',
    // 			'source': 'raster-tiles',
    // 			'minzoom': 0,
    // 			'maxzoom': 22
    // 		}
    // 	]
    // },
    center: [lng, lat],
    zoom: zoom,
  })

  return map
}

export default NoxMap
