import { makeStyles, Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import { DateTime } from 'luxon'

interface IVoyagePopup {
  voyage: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      //width: '160px',
      // display: 'grid',
      // gridTemplateColumns: '50% 50%'
    },
  })
)

const VoyagePopup = (props: IVoyagePopup): JSX.Element => {
  const { voyage } = props
  const classes = useStyles()
  const startTime = voyage.timeStart ? DateTime.fromISO(voyage.timeStart) : false
  const endTime = voyage.timeEnd ? DateTime.fromISO(voyage.timeEnd) : false
  const events = voyage.events
  const startEvent = events && events.length > 0 ? events[0] : false
  const endEvent = events && events.length > 0 ? events[events.length - 1] : false
  const duration =
    startTime && endTime ? endTime.diff(startTime).normalize().shiftTo('days', 'hours', 'minutes', 'seconds') : false
  const durationString = duration
    ? duration.days > 0
      ? duration.days + 'd ' + duration.hours + 'h ' + duration.minutes + 'm '
      : duration.hours + 'h ' + duration.minutes + 'm '
    : false
  const noxEmission =
    props.voyage.consumption && props.voyage.consumption.noxCalculated ? props.voyage.consumption.noxCalculated : 0
  const emissionStyle = props.voyage.taxable ? { marginLeft: 8 } : { textDecoration: 'line-through' }

  return (
    <div className={classes.grid}>
      <div>{voyage.vessel.name}</div>
      {startTime && <div>{startTime.toLocal().toFormat('ff')}</div>}
      {startEvent &&
        endEvent &&
        (props.voyage.classification === 'stay' ? (
          <div>{startEvent.name}</div>
        ) : (
          <div>
            {startEvent.name} ... {endEvent.name}
          </div>
        ))}
      {duration && (
        <>
          <div>{durationString}</div>
          <div style={emissionStyle}>{Number(noxEmission.toFixed(0)).toLocaleString()} Kg NOx</div>
        </>
      )}
    </div>
  )
}

export default VoyagePopup
