//import CompanyIcon from '@griegconnect/krakentools-react-icons/HostCompany'
import Stay from '@griegconnect/krakentools-react-icons/Port'
import DurationIcon from '@griegconnect/krakentools-react-icons/TimeDuration'
import VesselIcon from '@griegconnect/krakentools-react-icons/Vessel'
import DistanceIcon from '@griegconnect/krakentools-react-icons/VoyageDistance'
//import TransitEndIcon from '@griegconnect/krakentools-react-icons/VoyageEnd'
import TransitStartIcon from '@griegconnect/krakentools-react-icons/VoyageStart'
import { Typography } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
//import { useTenants } from 'common/GriegTenants'
import { DateTime, Duration } from 'luxon'
import React from 'react'

import { timeFormat } from '../../../constants/timeFormat'
import { Vessel } from 'src/lib/apis/types/vessel'
import { Voyage } from 'src/lib/apis/types/voyage'

const styles = (theme: Theme) =>
  createStyles({
    map: {
      width: 'calc(100% + 15px)',
      height: '100%',
      marginLeft: -7,
      marginTop: -16,
      marginBottom: 16,
      minHeight: 300,
      maxHeight: 400,
      '& canvas': {
        outline: 0,
      },
    },
    titleRow: {
      marginBottom: 25,
      marginLeft: 15,
      paddingRight: 15,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    infoGrid: {
      display: 'grid',
      gridTemplateColumns: '30px 50% auto',
      gridAutoRows: 'minmax(max-content, 40px)',
      margin: '0 auto',
      padding: 16,
    },
  })

interface IVoyageDetailsProps extends WithStyles<typeof styles> {
  voyage: Voyage
  vessel: Vessel
  classes: any
}

interface IDuration {
  duration: Duration
}

const DurationDisplay = (props: IDuration) => {
  const { duration } = props
  const normalizedDuration = duration.normalize().shiftTo('days', 'hours', 'minutes', 'seconds')

  return (
    <span>
      {normalizedDuration.days}d {normalizedDuration.hours}h {normalizedDuration.minutes}m
    </span>
  )
}

const VoyageDetailsFC = (props: IVoyageDetailsProps) => {
  const { classes, voyage, vessel } = props
  // voyage.distance = 536;
  // voyage.timeEnd = DateTime.local().plus({ days: -1498 }).toISO();
  const voyageStart = DateTime.fromISO(voyage.timeStart).toLocal()
  const voyageEnd = DateTime.fromISO(voyage.timeEnd).toLocal()
  const voyageDuration = voyageEnd.diff(voyageStart)
//  const { activeTenant } = useTenants()
//  const companyName = activeTenant?.name

  const events = voyage.events ? JSON.parse(JSON.stringify(voyage.events)) : []
  // const consumption = voyage.consumption ? JSON.parse(voyage.consumption) : null;

  const startEvent = events[0]
  const endEvent = events[events.length - 1]
  const startLocation = startEvent.name
  const endLocation = endEvent.name
  const startTime = DateTime.fromISO(startEvent.arrival)
  const duration = DateTime.fromISO(endEvent.departure)
    .diff(startTime)
    .normalize()
    .shiftTo('days', 'hours', 'minutes', 'seconds')
  const durationString =
    duration.days > 0
      ? duration.days + 'd ' + duration.hours + 'h ' + duration.minutes + 'm '
      : duration.hours + 'h ' + duration.minutes + 'm '

  return (
    <>
      <div className={classes.titleRow}>
        <Typography variant="h5">NOx details</Typography>
      </div>
      <div className={classes.infoGrid}>
        <VesselIcon />
        <Typography component="span" variant="body1">
          Vessel name
        </Typography>
        <Typography component="span" variant="body1" style={{ justifySelf: 'end' }}>
          {vessel?.name}
        </Typography>

{/* YAE 24.11.21 -> don't need this
        <CompanyIcon />
        <Typography component="span" variant="body1">
          Company
        </Typography>
        <Typography component="span" variant="body1" style={{ justifySelf: 'end' }}>
          {companyName}
        </Typography>
*/}
        {voyage.distance && (
          <>
            <DistanceIcon />
            <Typography component="span" variant="body1">
              Distance
            </Typography>
            <Typography component="span" variant="body1" style={{ justifySelf: 'end' }}>
              {durationString}
            </Typography>
          </>
        )}

        <DurationIcon />
        <Typography component="span" variant="body1">
          Time in NOx-area
        </Typography>
        <Typography component="span" variant="body1" style={{ justifySelf: 'end' }}>
          {(voyageDuration && <DurationDisplay duration={voyageDuration} />) || '---'}
        </Typography>

        {props.voyage.classification === 'stay' ? <Stay /> : <TransitStartIcon />}
        <Typography component="span" variant="body1">
          Voyage timespan
        </Typography>
        <div style={{ justifySelf: 'end' }}>
          <div style={{ textAlign: 'end' }}>
            <Typography component="span" variant="body1">
              {voyageStart.toFormat(timeFormat)}&nbsp;&nbsp;&#8212;&nbsp;&nbsp;
              {
                (voyageDuration.normalize().shiftTo('days').days < 1)
                    ? voyageEnd.toFormat('HH:mm')
                    : voyageEnd.toFormat(timeFormat)
              }
            </Typography>
          </div>
          <div style={{ textAlign: 'end' }}>
            <Typography component="span" variant="body1">
              {startLocation}{(startLocation !== endLocation) ? " - "+endLocation : ""}
            </Typography>
          </div>
        </div>

        {/* don't need this
        {props.voyage.classification === Classification.Stay ? <Stay /> : <TransitEndIcon />}
        <Typography component="span" variant="body1">
          End
        </Typography>
        <div style={{ justifySelf: 'end', marginBottom: 16 }}>
          <div style={{ textAlign: 'end' }}>
            <Typography component="span" variant="body1">
              {voyageEnd.toFormat(timeFormat)}
            </Typography>
          </div>
          <div style={{ textAlign: 'end' }}>
            <Typography component="span" variant="body1">
              {endLocation}
            </Typography>
          </div>
        </div>
*/}
      </div>
    </>
  )
}

export const VoyageDetails = withStyles(styles, { withTheme: true })(VoyageDetailsFC)
