const generateArrowImage = (width: number, height: number, color: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const svg =
      '<svg xmlns="http://www.w3.org/2000/svg" width="' +
      width +
      '" height="' +
      height +
      '" viewBox="0 0 ' +
      width +
      ' ' +
      height +
      '"><title>Artboard 3</title><path d="M1,6.85,22.3,3.75a.25.25,0,0,0,0-.5L1,.15A.25.25,0,0,0,.86.6L4.71,3.3a.24.24,0,0,1,0,.4L.86,6.4A.25.25,0,0,0,1,6.85Z" fill="' +
      color +
      '"/></svg>'
    const image = new Image(width, height)
    image.onload = () => resolve(image)
    image.src = 'data:image/svg+xml;base64,' + btoa(svg)
  })
}
export default generateArrowImage
