import { GriegRouteType } from '@griegconnect/krakentools-react-router'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'

import { routes } from '../../Routes'
import { useAppContext } from '../GriegProvider/wrappers/AppContextWrapper'
import { useTenants } from '../GriegTenants'
import AppBar from './AppBar'
import Drawer from './Drawer'
import TenantSwitcher from './TenantSwitcher'
import {useConfig} from "../GriegProvider/wrappers/config/grieg-config-provider"

const drawerWidth = 260

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbarSpacing: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
    },
    listItem: {
      fontWeight: 100,
      '&:hover': {
        cursor: 'pointer',
      },
      color: theme.palette.primary.dark,
    },
    listItemSize: {
      width: drawerWidth,
    },
    listItemActive: {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.action.selected,
    },
    listItemIcon: {
      minWidth: 24,
      paddingLeft: 4,
      background: "transparent",
    },
    listItemText: {
      paddingLeft: 20,
    },
    tenantSwitcher: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(5),
    },
    showTenantSwitcher: {
      height: 120,
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hideTenantSwitcher: {
      height: 0,
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
    },
    drawerTopSpacing: {
      height: 40,
    },
  })
)

export type NavigationWrapperProps = {
  appVersion?: string;
  appbarTitle?: string;
  children: React.ReactNode;
};

export const NavigationWrapper = (props: NavigationWrapperProps) => {
  const isMobile = useMediaQuery('(min-width:600px)')
  const classes = useStyles()
  const { routeOptions } = useAppContext()
  const { roles, activeTenant, changeTenant } = useTenants()

  //YAE 16.09.21 -> added to correctly reflect test vs prod api, appVersion and client-site
  const { config } = useConfig()
  const appConfigDisplayText = (config) ?
      (
      config.app.appVersion + '  [' +
      ((config.api.noxApiUrl.indexOf('test') > 0) ? 'Test' : 'Prod') +
      ((config.auth0.redirectUri.indexOf('localhost') > 0) ? ' - local]' : ']')
      ) :
      props.appVersion
  //end

  const history = useHistory()
  const [drawerOpen, setDrawerStatus] = useState<boolean>(isMobile)
  const toggleDrawer = () => {
    setDrawerStatus(!drawerOpen)
  }

  useEffect(() => {
    setDrawerStatus(isMobile)
  }, [isMobile])

  const checkIfActivePath = (route: GriegRouteType) => {
    return matchPath(history.location.pathname, {
      path: route.path,
      exact: !route.hasChildRoutes,
      strict: false,
    })
      ? true
      : false
  }
  const navigate = (path: string, skipCompany: boolean = false) => {
    if (history.location.pathname !== path) {
      if (activeTenant && !skipCompany) history.push(`${path}`)
      else history.push(path)
    }
  }

  const renderRoute = (route: GriegRouteType, index: number, prefix: string = '', admin: boolean = false) => {
    if (
      route.options?.showModuleInDrawer &&
      route.roles &&
      (route.roles.length === 0 || route.roles.some((role: string) => roles.includes(role)))
    ) {
      return (
        <ListItem
          key={`drawer-${index}`}
          className={clsx(classes.listItemSize, classes.listItem, {
            [classes.listItemActive]: checkIfActivePath(route),
          })}
          onClick={() => navigate(prefix + route.path, admin)}
        >
          <ListItemIcon
            className={clsx(classes.listItemIcon, classes.listItem, {
              [classes.listItemActive]: checkIfActivePath(route),
            })}
          >
            {route.options?.moduleIcon ? route.options?.moduleIcon : <LinkOutlinedIcon />}
          </ListItemIcon>
          <ListText className={classes.listItemText}>{route.name}</ListText>
        </ListItem>
      )
    }
    return ''
  }
  const isAdministration = history.location.pathname.indexOf('/administration') !== -1
  if (isAdministration) {
    setTimeout(() => changeTenant('griegconnect'))
  }
  const menuItems = routes.map((r, i) => renderRoute(r, i, '', false))
  const renderUI = () => (
    <div className={classes.root}>
      <AppBar onToggleDrawer={toggleDrawer} appbarTitle={props.appbarTitle} />
      {routeOptions?.showAppDrawer && (
        <Drawer open={drawerOpen} applicationVersion={appConfigDisplayText}>
          <div className={classes.toolbarSpacing}></div>
          {routeOptions?.showTenantSwitcher ? (
            <>
              <div
                className={clsx({
                  [classes.showTenantSwitcher]: drawerOpen,
                  [classes.hideTenantSwitcher]: !drawerOpen,
                })}
              >
                <div className={classes.tenantSwitcher}>
                  <TenantSwitcher />
                </div>
              </div>
              <Divider />
            </>
          ) : (
            <div className={classes.drawerTopSpacing}></div>
          )}
          {menuItems}
        </Drawer>
      )}
      <main className={classes.content}>
        <div className={classes.toolbarSpacing} />
        {props.children}
      </main>
    </div>
  )

  return renderUI()
}

NavigationWrapper.defaultProps = {
  appbarTitle: 'Grieg Connect',
  appVersion: 'v 1.0  [Prod]',
}

export default NavigationWrapper
