import { GriegRouteProps, mergeOptions } from '@griegconnect/krakentools-react-router'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useApis } from 'src/lib/ApiProvider'
import { Engine, EngineForm } from 'src/lib/apis/types/engine'

import { useAppContext } from '../../common/GriegProvider/wrappers/AppContextWrapper'
import ErrorModal from '../../components/ApolloErrorModal'
import LoadingSpinner from '../../components/LoadingSpinner'
import EngineConfig from '../fleetoverview/add/components/EngineConfig'

// interface IEngine {
// 	id: number;
// 	name: string;
// 	scrFactor: number;
// 	noxFactor: number;
// }

const EditEngine = (props: GriegRouteProps) => {
  const history = useHistory()
  const { engineId } = useParams<{ engineId: string }>()
  const { vesselId } = useParams<{ vesselId: string }>()
  const [engineName, setEngineName] = useState<string>('')
  const [engineScrFactor, setEngineScrFactor] = useState<string>('')
  const [engineNoxFactor, setEngineNoxFactor] = useState<string>('')
//YAE 27.10.21 -> Added lng handling x2 + hasLng
//  const [engineScrFactorLng, setEngineScrFactorLng] = useState<string>('')
  const [engineNoxFactorLng, setEngineNoxFactorLng] = useState<string>('')
  const [engineHasLng, setEngineHasLng] = useState<string>('nonLng')
  const [engineSystem, setEngineSystem] = useState<string>('nonScr')
  const { apis } = useApis()
  const { route } = props
  const { setRouteOptionsCallback } = useAppContext()
  const updateOptions = () => setRouteOptionsCallback(mergeOptions(route))
  useLayoutEffect(updateOptions, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading, error, data: engine, refetch } = useQuery<Engine, Error>(
    ['engine', vesselId, engineId],
    () => apis.vesselsApi.getEngine(vesselId, engineId),
    { enabled: !!vesselId && !!engineId }
  )

  const pushReturnPath = () => {
    const newpath = history.location.pathname.replace('/editengine/' + engineId, '')
    //YAE 08.09.21 -> include state in return to sender, if received. YAE 10.11.21 -> removed again - won't edit engine from Voyage Nox Calculation after all
//    if(history.location.state) {
//      history.push(newpath, history.location.state)  //Need state if returning from Voyage Nox Calculation
//    } else {
      history.push(newpath)
//    }
  }

  const handleUpdateCompleted = () => {
    pushReturnPath()
  }

  const { mutate: updateEngine, error: mutationError } = useMutation(
    async (form: EngineForm) => { return await apis.vesselsApi.updateEngine(vesselId, engineId, form) },
    {
      onSuccess: handleUpdateCompleted,
    }
  )

  useEffect(() => {
    if (!isLoading && engine) {
      setEngineName(engine.name)
      setEngineNoxFactor(engine.noxFactor + '')
      setEngineScrFactor(engine.scrFactor + '')
      setEngineNoxFactorLng(engine.noxFactorLng + '')
//      setEngineScrFactorLng(engine.scrFactorLng + '')
      if (Number(engine.scrFactor) > 0) {
        setEngineSystem('scr')
      }
      if (Number(engine.noxFactorLng) > 0) {
        setEngineHasLng('lng')
      }
    }
  }, [isLoading, engine])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorModal error={error} refetch={refetch} />
  }
  if (mutationError) {
    return <ErrorModal error={mutationError} refetch={refetch} />
  }

//YAE 27.10.21 -> added Lng factor handling
  const handleChange = (key, value) => {
    switch (key) {
      case 'system':
        if (value === 'nonScr') {
          setEngineScrFactor('0')
//          setEngineScrFactorLng('0')
        }
        setEngineSystem(value)
        break
      case 'hasLng':
        if (value === 'nonLng') {
          setEngineNoxFactorLng('0')
//          setEngineScrFactorLng('0')
        }
        setEngineHasLng(value)
        break
      case 'noxFactor':
        setEngineNoxFactor(value)
        break
      case 'noxFactorScr':
        setEngineScrFactor(value)
        break
      case 'noxFactorLng':
        setEngineNoxFactorLng(value)
        break
//      case 'noxFactorScrLng':
//        setEngineScrFactorLng(value)
//        break
      case 'engineName':
        setEngineName(value)
        break
    }
    return false
  }

//YAE 27.10.21 -> added Lng factor handling
  const handleLeave = (action) => {
    if (action === 'complete') {
      updateEngine({
          name: engineName,
          scrFactor: isNaN(parseFloat(engineScrFactor)) ? 0.00 : parseFloat(engineScrFactor),
          noxFactor: isNaN(parseFloat(engineNoxFactor)) ? 0.00 : parseFloat(engineNoxFactor),
          noxFactorLng: isNaN(parseFloat(engineNoxFactorLng)) ? 0.00 : parseFloat(engineNoxFactorLng),
        }
      )
    } else {
      pushReturnPath()
    }
  }

  return (
    <EngineConfig
      action={'edit'}
      onChange={handleChange}
      onLeave={handleLeave}
      system={engineSystem}
      engineName={engineName}
      // engineAmount={"1"}
      noxFactor={engineNoxFactor + ''}
      noxFactorScr={engineScrFactor + ''}
      hasLng={engineHasLng}
      noxFactorLng={engineNoxFactorLng + ''}
//      noxFactorScrLng={engineScrFactorLng + ''}
    />
  )
}

export default EditEngine
