import { useEffect, useState } from 'react'
import { TableRow } from '@material-ui/core'
import TableCell from '@mui/material/TableCell'
import { withStyles } from '@material-ui/styles'
import {CharterListItemsProps, CharterVoyagesType, TableDisplayRow} from './CharterReport.types'
import { DateTime } from 'luxon'
import { useRecoilState } from 'recoil'
import { charterReportTotal } from '../../../atoms'
import { timeFormat } from '../../../constants/timeFormat'

const CharterListItems = (props: CharterListItemsProps) => {
  const { detailedView, vesselId, timeFrom, timeTo, printable, fuelPrice, lngPrice, ureaPrice, reportableVoyages, taxableOnly, includeAllNox } = props
  const noxConsumptionsQuery: any = document.querySelectorAll('#noxConsumptions')
  const fuelConsumptionsQuery: any = document.querySelectorAll('#fuelConsumptions')
  const lngConsumptionsQuery: any = document.querySelectorAll('#lngConsumptions')
  const ureaConsumptionsQuery: any = document.querySelectorAll('#ureaConsumptions')
  let noxResultDataList: string[] = []
  let ureaResultDataList: string[] = []
  let fuelResultDataList: string[] = []
  let lngResultDataList: string[] = []
  const [noxTotal, setNoxTotal] = useState<number | string>(0)
  const [ureaTotal, setUreaTotal] = useState<number | string>(0)
  const [fuelTotal, setFuelTotal] = useState<number | string>(0)
  const [lngTotal, setLngTotal] = useState<number | string>(0)
  const [, setTotalConsumptions] = useRecoilState(charterReportTotal)

  const formatTime = (time) =>
    DateTime.fromISO(time as string)
      .setLocale('no')
      .toFormat(timeFormat)

  const printableStyle = printable ? { paddingRight: 10 } : { color: 'rgba(255, 255, 255, 0.80)' }
  const numCellPrintableStyle = printable ? { paddingRight: 10 } : { color: 'rgba(255, 255, 255, 0.80)', minWidth: 105 }

  const TableCellColumn = withStyles((theme) => ({
    body: {
      fontSize: 14,
      borderBottom: printable
        ? '1px solid rgba(182, 181, 181, 0.2) !important'
        : '1px solid rgba(81, 81, 81, 0.5) !important',
    },
  }))(TableCell)

/* moved to CPSelect
  const { loading, error, data, refetch } = useQuery<CharterVoyagesDataType>(CharterVoyagesQuery, {
    skip: !vesselId || (!timeFrom && !timeTo) || !reportButtonClicked,
    fetchPolicy: 'network-only',
    variables: {
      vesselId,
      timeFrom, // Time format has been automatically coverted to ISO string from storage
      timeTo, // Time format has been automatically coverted to ISO string from storage
    },
  })
*/

  const outputVoyages: CharterVoyagesType[] | undefined = reportableVoyages
      ? reportableVoyages
        .filter((voyage) => //filter voyages overlapping the current period
          !(
              voyage.timeStart < new Date(timeFrom).toISOString()
              &&
              voyage.timeEnd < new Date(timeFrom).toISOString())
          &&
          !(
              voyage.timeStart > new Date(timeTo).toISOString()
              &&
              voyage.timeEnd > new Date(timeTo).toISOString()
          )
          &&
          (
            voyage.consumption.noxCalculated > 0
            ||
            (ureaPrice > 0 && voyage.consumption.ureaConsumption > 0)
            ||
            (fuelPrice > 0 && voyage.consumption.fuelConsumption > 0)
            ||
            (lngPrice > 0 && voyage.consumption.lngConsumption > 0)    
          )
        ) : null

  useEffect(() => {
      let noxInTotal: number = 0
      let ureaInTotal: number = 0
      let fuelInTotal: number = 0
      let lngInTotal: number = 0

      noxConsumptionsQuery &&
      noxConsumptionsQuery?.forEach((item, index) => {
          const nodeValue: any = item?.innerHTML / 2
          noxInTotal += parseFloat(nodeValue)

          return noxInTotal
      })
      setNoxTotal(noxInTotal)

      fuelConsumptionsQuery &&
      fuelConsumptionsQuery?.forEach((item, index) => {
          const nodeValue: any = item?.innerHTML / 2
          fuelInTotal += parseFloat(nodeValue)

          return fuelInTotal
      })
      setFuelTotal(fuelInTotal)

      lngConsumptionsQuery &&
      lngConsumptionsQuery?.forEach((item, index) => {
          const nodeValue: any = item?.innerHTML / 2
          lngInTotal += parseFloat(nodeValue)

          return lngInTotal
      })
      setLngTotal(lngInTotal)

      ureaConsumptionsQuery &&
      ureaConsumptionsQuery?.forEach((item, index) => {
          const nodeValue: any = item?.innerHTML / 2
          ureaInTotal += parseFloat(nodeValue)

          return ureaInTotal
      })
      setUreaTotal(ureaInTotal)

      // eslint-disable-next-line
  }, [noxResultDataList, fuelResultDataList, lngResultDataList, ureaResultDataList, vesselId, taxableOnly, includeAllNox])

  useEffect(() => {
    setTotalConsumptions({
      totalNox: noxTotal,
      totalFuel: fuelTotal,
      totalLng: lngTotal,
      totalUrea: ureaTotal,
    })
    // eslint-disable-next-line
  }, [noxTotal, ureaTotal, fuelTotal, lngTotal, fuelPrice, lngPrice, ureaPrice, taxableOnly, includeAllNox])

  const detailedDataResults: TableDisplayRow[] | undefined = outputVoyages?.map((v, idx) => {
    const voyageTimeStart = new Date(v.timeStart)
    const voyageTimeEnd = new Date(v.timeEnd)
    const tempTimeFrom = new Date(timeFrom)
    const tempTimeTo = new Date(timeTo)
    const timeStartLocale = voyageTimeStart > tempTimeFrom ? formatTime(v.timeStart) : formatTime(tempTimeFrom.toISOString())
    const timeEndLocale = voyageTimeEnd < tempTimeTo ? formatTime(v.timeEnd) : formatTime(tempTimeTo.toISOString())

    const startEvent = v?.events[0].name
    const endEvent = v?.events[v.events.length - 1].name
    const location = startEvent === endEvent ? startEvent : `${startEvent} → ${endEvent}`

    //First and last (or only) voyage may need to be adjusted with time-proportional consumption
    const consumptionFraction =
        (outputVoyages?.length === 1) ?  //single
            (voyageTimeStart >= tempTimeFrom && voyageTimeEnd <= tempTimeTo ? // whole voyage inside report timeframe
                1
                : voyageTimeStart < tempTimeFrom && voyageTimeEnd <= tempTimeTo ? // only start of voyage is outside report interval
                    (voyageTimeEnd.getTime() - tempTimeFrom.getTime()) / (voyageTimeEnd.getTime() - voyageTimeStart.getTime())
                    : voyageTimeEnd > tempTimeTo && voyageTimeStart >= tempTimeFrom ? // only end of voyage is outside report interval
                        (tempTimeTo.getTime() - voyageTimeStart.getTime()) / (voyageTimeEnd.getTime() - voyageTimeStart.getTime())
                        : // both ends are outside of report interval
                        ( tempTimeTo.getTime() - tempTimeFrom.getTime() ) / ( voyageTimeEnd.getTime() - voyageTimeStart.getTime() )
            )
            :
            (idx===0 && voyageTimeStart < tempTimeFrom) ?
                ( voyageTimeEnd.getTime() - tempTimeFrom.getTime() ) / ( voyageTimeEnd.getTime() - voyageTimeStart.getTime() )
                :
                (idx===outputVoyages?.length-1 && voyageTimeEnd > tempTimeTo) ?
                    ( tempTimeTo.getTime() - voyageTimeStart.getTime() ) / ( voyageTimeEnd.getTime() - voyageTimeStart.getTime() )
                    :
                    1

    const noxConsumption = (v.taxable || includeAllNox)? v.consumption?.noxCalculated? Number((v.consumption.noxCalculated * consumptionFraction).toFixed(1)) : 0 : 0
    const fuelConsumption = (v.consumption?.fuelConsumption? Number((v.consumption.fuelConsumption * consumptionFraction).toFixed(2)) : 0)
                          + (v.consumption?.fuelConsumptionScr? Number((v.consumption.fuelConsumptionScr * consumptionFraction).toFixed(2)) : 0)
    const lngConsumption = v.consumption?.lngConsumption? Number((v.consumption.lngConsumption * consumptionFraction).toFixed(2)) : 0
    const ureaConsumption = v.consumption?.ureaConsumption? Number((v.consumption.ureaConsumption * consumptionFraction).toFixed(1)) : 0

    noxResultDataList.push(noxConsumption.toFixed(1))
    fuelResultDataList.push(fuelConsumption.toFixed(2))
    lngResultDataList.push(lngConsumption.toFixed(2))
    ureaResultDataList.push(ureaConsumption.toFixed(1))

    return (
        {
            start: timeStartLocale,
            end: timeEndLocale,
            activity: location,
            nox: noxConsumption,
            fuel: fuelConsumption,
            lng: lngConsumption,
            urea: ureaConsumption
        }
    )
  })

  const tableOutput = detailedDataResults?.map( (row, idx) => {
     return (
        <TableRow hover={true} key={idx}>
          <TableCellColumn
            style={printableStyle}
          >
          <div>{row.start}</div>
          <div> {row.end} </div>
          </TableCellColumn>
          <TableCellColumn style={printableStyle}>{row.activity}</TableCellColumn>
          <TableCellColumn style={numCellPrintableStyle} id="noxConsumptions" align="right">
            {row.nox.toFixed(1)}
          </TableCellColumn>
          {fuelPrice > 0 && (
          <TableCellColumn style={numCellPrintableStyle} id="fuelConsumptions" align="right">
            {row.fuel.toFixed(2)}
          </TableCellColumn>
          )}
          {lngPrice > 0 && (
                <TableCellColumn style={numCellPrintableStyle} id="lngConsumptions" align="right">
            {row.lng.toFixed(2)}
          </TableCellColumn>
          )}
          {ureaPrice > 0 && (
          <TableCellColumn style={numCellPrintableStyle} id="ureaConsumptions" align="right">
            {row.urea.toFixed(1)}
          </TableCellColumn>
          )}
        </TableRow>
      )
  })

    if(!detailedView)  { //Compact view: display aggregate for each period in report = sum from tableOutput
        let totNox = 0.0
        let totFuel = 0.0
        let totLng = 0.0
        let totUrea = 0.0
        detailedDataResults?.forEach(row => {
            totNox += row.nox
            totFuel += row.fuel
            totLng += row.lng
            totUrea += row.urea
        })
        return (
            <TableRow hover={true} >
                <TableCellColumn
                    style={printableStyle}
                >
                    <div>{formatTime(new Date(timeFrom).toISOString())}</div>
                    <div> {formatTime(new Date(timeTo).toISOString())} </div>
                </TableCellColumn>
                <TableCellColumn style={printableStyle}>{detailedDataResults?.length} voyages</TableCellColumn>
                <TableCellColumn style={numCellPrintableStyle} id="noxConsumptions" align="right">
                    {totNox.toFixed(1)}
                </TableCellColumn>
                {fuelPrice > 0 && (
                <TableCellColumn style={numCellPrintableStyle} id="fuelConsumptions" align="right">
                    {totFuel.toFixed(2)}
                </TableCellColumn>
                )}
                {lngPrice > 0 && (
                <TableCellColumn style={numCellPrintableStyle} id="lngConsumptions" align="right">
                    {totLng.toFixed(2)}
                </TableCellColumn>
                )}
                {ureaPrice > 0 && (
                <TableCellColumn style={numCellPrintableStyle} id="ureaConsumptions" align="right">
                    {totUrea.toFixed(1)}
                </TableCellColumn>
                )}
            </TableRow>
        )
    }
    else { //Detailed view: display directly form tableOutput
        return <>{tableOutput}</>
    }
}

export default CharterListItems
