import React, {useEffect, useState} from "react"
import {NoxPeriod} from "../../../lib/apis/types/noxPeriod"
import {VesselCharterReport } from "../../../lib/apis/types/vessel"
import {useApis} from "../../../lib/ApiProvider"
import {useQuery} from "react-query"
import LoadingSpinner from "../../../components/LoadingSpinner"
import ErrorModal from "../../../components/ApolloErrorModal"
import ExpandingInfoBox from "../../vessels/add/components/ExpandingInfoBox"
import {Avatar, Box, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Divider} from "@mui/material"
import {MenuItem, Select, Typography} from '@material-ui/core'
import {DateTime} from "luxon"
import {timeFormat} from "../../../constants/timeFormat"
import { useStyles } from "./CharterPeriodsSelect.styles"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import EventIcon from "@griegconnect/krakentools-react-icons/Event"

export interface IStoredCharterPeriods {
    vesselId: string | any,
    currentPeriod: NoxPeriod,
    createVacantPeriods: (storedPeriods: VesselCharterReport[], monthNum: number) => void
}

const StoredCharterPeriods = (props: IStoredCharterPeriods) => {
    const { apis } = useApis()
    const classes = useStyles()
    const {vesselId, currentPeriod, createVacantPeriods} = props
    const startMinusOneSecond = new Date(new Date(currentPeriod.timeStart).getTime() - 1000).toISOString().substr(0, 19) + 'Z'

    const [ usablePeriods, setUsablePeriods ] = useState<VesselCharterReport[] | undefined>(undefined)
    const [ vacantPeriod, setVacantPeriod ] = useState<Number>(0)

    const {
        isLoading: isLoadingPeriods,
        error: errorPeriods,
        data: periods,
        isError: isErrorPeriods,
        refetch: refetchPeriods,
    } = useQuery<VesselCharterReport[], Error>(['charter-periods', vesselId, startMinusOneSecond], async () =>
            await apis.vesselsApi.getVesselCharterReports(vesselId,startMinusOneSecond),
        {enabled: !!vesselId && !!currentPeriod}
    )

    const handleRemoveCharterReport = ((id: string) => {
        apis.vesselsApi.removeVesselCharterReport(vesselId,id)
        setUsablePeriods(usablePeriods?.filter((p) => {return p.id !== id}))
    })

    const getMonthName = ((quarter: number, monthNum: number) => {
        const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
        return months[3 * (quarter - 1) + monthNum - 1]
    })

    const handlePeriodGeneration = (event) => {
        setVacantPeriod(Number(event.target.value))
        if ( typeof usablePeriods?.length !== 'undefined' &&  usablePeriods.length > 0) {
            createVacantPeriods(usablePeriods, Number(event.target.value))
        }
    }

    useEffect( () => {
        if (!isLoadingPeriods && periods) {
            setUsablePeriods(periods
                .sort((a,b) => ( new Date(a.start).getTime() - new Date(b.start).getTime() || new Date(a.end).getTime() - new Date(b.end).getTime() ))
                .filter((p) => { return p.start < currentPeriod.timeEnd })
                )
        }
    }, [periods, isLoadingPeriods, currentPeriod])

    if (isLoadingPeriods) {
        return <LoadingSpinner label={'Loading logged charter periods...'}/>
    }

    if (isErrorPeriods) {
        return <ErrorModal error={errorPeriods} refetch={refetchPeriods} />
    }

    if ( typeof usablePeriods?.length !== 'undefined' &&  usablePeriods.length > 0) {
        return (
            <>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                            <ExpandingInfoBox
                                title="Logged report periods"
                                text={"These are the logged charter report periods for this vessel in "+currentPeriod.name}
                            />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>
                            Create vacant periods for:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Select
                            style={{width: '160px'}}
                            label = 'Create vacant periods'
                            variant = 'outlined'
                            value={vacantPeriod}
                            onChange={handlePeriodGeneration}
                        >
                            <MenuItem disabled value='0'>Select period</MenuItem>
                            <MenuItem value='1'>{currentPeriod.name.substr(5,2)}</MenuItem>
                            <MenuItem value='2'>{getMonthName(Number(currentPeriod.name.substr(6,1)),1)}</MenuItem>
                            <MenuItem value='3'>{getMonthName(Number(currentPeriod.name.substr(6,1)),2)}</MenuItem>
                            <MenuItem value='4'>{getMonthName(Number(currentPeriod.name.substr(6,1)),3)}</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <List dense={true}>
                                {usablePeriods
                                    ?.map((period) => {
                                        const timeStartFrom = DateTime.fromMillis(Date.parse(period.start)).toFormat(timeFormat)
                                        const timeEndTo = DateTime.fromMillis(Date.parse(period.end)).toFormat(timeFormat)
                                        const id = period.id ?? ""

                                        return (
                                            <div className={classes.periodsList} style={{color: '#fff'}} key={id}>
                                                <span>
                                                    <ListItem
                                                        secondaryAction={
                                                            <IconButton
                                                                onClick={() => handleRemoveCharterReport(id)}
                                                                color="inherit"
                                                                edge="end"
                                                                aria-label="delete"
                                                            >
                                                                <RemoveCircleIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar sx={{ bgcolor: 'inherit', color: '#888' }}>
                                                              <EventIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText style={{color: '#888'}} primary={`${timeStartFrom} - ${timeEndTo} ${(period.name.length > 0) ? "("+period.name+")" : ""}`} />
                                                    </ListItem>
                                                    <Divider variant="fullWidth" component="li" />
                                                </span>
                                            </div>
                                        )
                                    })}
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }
    return (<></>)
}

export default StoredCharterPeriods